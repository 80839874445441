import * as z from "zod"
const ErrNonEmpty = "This field cannot be empty";

export const SBilling = z.object({
    firstName: z.string().min(1, { message: ErrNonEmpty }),
    lastName: z.string().min(1, { message: ErrNonEmpty }),
    phone: z.string().min(10, { message: "Must be a valid phone number" }).max(10, { message: "Must be a valid phone number" }),
    address: z.string().min(1, { message: ErrNonEmpty }),
    state: z.string().min(1, { message: ErrNonEmpty }),
    city: z.string().min(1, { message: ErrNonEmpty }),
    pincode: z.string().min(6, { message: "Must be 6 digit long" }).max(6, { message: "Must be 6 digit long" }),
    terms: z.literal<boolean>(true, { errorMap: () => ({ message: "Accept term & conditions to proceed further", }), })
})  