import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import CollapseButton from "../shared/CollapseButton";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { SelectItem } from "..//ui/select";
import { Button, buttonVariants } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Checkbox } from "../ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import EOSpot from "./EOSpot";
import EOTable from "./EOTable";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import SelectBox from "../shared/SelectBox";
import { toast } from "sonner";
import {
  ExpiryStrikePrice,
  InstrumentExpiryStrikePrice,
  getExpertOpinionData,
} from "../../api/options";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { getIndexSubscription } from "../../api/futures/open-interest";
import convertToYYYYMMDD, { findNearestDate, findNearestStrikePrices, formatDate } from "../shared/dateConverter";
import { ScrollArea } from "../ui/scroll-area";
import { dateSort } from "../../utils/datesort";
import useSpotPriceStore from "../../store/spotPrice";
import { configEnvironemt } from "../../config";
import { expertOpinionBreadcrumb, expertOpinionLiveMessageType } from "./type";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { parseMessageDate } from "./helper";
import { baseURL } from "../../api/base";

export default function ExpertOpinion(props: {
  opinionInstrumentList: InstrumentExpiryStrikePrice[];
}) {
  const expiryDates = props.opinionInstrumentList[0].ExpiryStrikePriceList.map(
    (exp) => exp.Expiry
  );
  const { spotPrices } = useSpotPriceStore();
  const initialDate = findNearestDate(expiryDates);
  const environment = configEnvironemt.environment;
  const [historical, setHistorical] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [instrument, setInstrument] = useState(props.opinionInstrumentList[0].ProductName);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [selectedStrikePrices, setSelectedStrikePrices] = useState<string[]>([]);
  const [strikePriceList, setStrikePriceList] = useState<string[]>([]);
  const [dataMap, setDataMap] = useState(new Map<string, { CE: any[], PE: any[] }>());
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [historicSpotData, setHistoricSpotData] = useState<InstrumentExpiryStrikePrice[]|null>(null);
  const marketStatus = useMarketStatus();
  const [collapseData, setCollapseData] = useState<any>({});
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  const [topicName, setTopicName] = useState("oistrategydata_data");
  const [toIdentifier, setToIdentifier] = useState("");
  const [toHistoricIdentifier, setToHistoricIdentifier] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  let id:any;
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const handleSwitch = () => {
    dataMap.clear();
    setSelectedStrikePrices([]);
    // historic to live
    if (historical) {
      setHistorical(false);
      setIsLoading(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(props.opinionInstrumentList[0]?.ProductName);
      Init();
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setIsLoading(false)
      setHistorical(true);
      dataMap.clear();
     setCollapseData({});
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  const updatedDataMap = new Map<string, { CE: any[], PE: any[] }>(dataMap);

  function processMessage(messages: string[]) {
    if (messages?.length) {
      messages?.forEach((item) => {
        const identifier = item.split(",")[0];
        const identifierParts = identifier.split("_");
        const messageType = identifierParts[3];
        const strikePrice = identifierParts[4];
        if (selectedStrikePrices.includes(strikePrice)) {
          const row = {
            type: messageType,
            strikePrice: strikePrice,
            date: parseMessageDate(item.split(",")[1]),
            open:  parseFloat(item.split(",")[2]),
            high: item.split(",")[3],
            low: item.split(",")[4],
            close:  parseFloat(item.split(",")[5]),
            changeInPricePercent: item.split(",")[6],
            changeInOiPercent: item.split(",")[7],
            totalOi: item.split(",")[8],
            buildUps: item.split(",")[9],
          };
          if(row?.close ===0 && row?.open ===0){
            return;
          }
          if(updatedDataMap.has(strikePrice)){
            const existingData = updatedDataMap.get(strikePrice)|| {CE:[], PE:[]};
            if (row.type.toUpperCase() === "CE") {
              existingData.CE.push(row);
              } else if (row.type.toUpperCase() === "PE") {
                existingData.PE.push(row);
            }
            updatedDataMap.set(strikePrice, existingData);
          } else{
            const existingData = updatedDataMap.get(strikePrice) || { CE: [], PE: [] };
            if (row.type.toUpperCase() === "CE") {
              existingData.CE.push(row);
            } else if (row.type.toUpperCase() === "PE") {
              existingData.PE.push(row);
            }
            updatedDataMap.set(strikePrice, existingData);
          }
        }
      });

      for (const [strikePrice, data] of Array.from(updatedDataMap.entries())) {
        data.CE.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            console.error("Invalid date encountered:", a.date, "or", b.date);
            return 0; 
          }
          return dateA.getTime() - dateB.getTime();
        });
        data.PE.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            console.error("Invalid date encountered:", a.date, "or", b.date);
          }
          return dateA.getTime() - dateB.getTime();
        });
      }

      useSpotPriceStore.getState().setSpotPrice(instrument, parseFloat(messages[messages.length - 1].split(',')[13]));
      const CollapseData = {
        currentfutureprice: messages[messages.length - 1].split(',')[10],
        premiuminCall: messages[messages.length - 1].split(',')[11],
        premiumInput: messages[messages.length - 1].split(',')[12],
        SpotPrice: messages[messages.length - 1].split(',')[13]
      };
      setCollapseData(CollapseData);
    }
    setIsLoading(false);
    return updatedDataMap;
  }

  async function liveMsgExpertOpinion(topicName:string, toIdentifier:string, toHistoricIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier, toHistoricIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
      const response = await processMessage(messages);
      setDataMap(response);
  }
}

  const getHistoricOpinionData = async (identifier: string, refDate?:any) => {
    let response;
  if(identifier!==""){
    if (historical && date !== "") {
      response = await getExpertOpinionData(identifier, !refDate? date: refDate);
   }else{
      response = await getExpertOpinionData(identifier, String(new Date()));
    }
    if (response != null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const data = await processMessage(messages);
      setDataMap(data);
    }
    setIsLoading(false);
  }
  };

  const handleProductChange = (productName: string) => {
    dataMap.clear();
    setCollapseData({});
    setInstrument(productName);
    if(!historical){
    getExpiryList(productName, props.opinionInstrumentList);
    }
    if(historical){
      setDate("");
      setExpiry("");
      setSelectedStrikePrices([]);
    }
  };

  function handleDateSelect (date:any) {
    dataMap.clear();
    setCollapseData({});
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
      setHistoricSpotData(data);
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  const getExpiryList = (
    productName: string,
    opinionDataList: InstrumentExpiryStrikePrice[] | null,
    refDate?: any
  ) => {
    const expiryList: string[] = [];
    let nearSpotPrice:any;
    if (opinionDataList !== null && opinionDataList.length > 0) {
      let filteredList = opinionDataList.filter((product) => product.ProductName === productName)
      let expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expiryStrikePriceData.map((exp) => exp.Expiry);
      expiryStrikePriceData.forEach((item: any) => expiryList.push(item.Expiry));
      const sortedDate = dateSort(expiryList);
      setExpiryList(sortedDate);
      const nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null && nearestDate !== undefined) {
        setExpiry(nearestDate);
        if(!historical){
          nearSpotPrice = spotPrices[productName] ? spotPrices[productName] : opinionDataList.find((item) => item.ProductName === productName)?.SpotPrice;
        }else{
          nearSpotPrice = filteredList[0].SpotPrice
        }
        getStrikePriceList(expiryStrikePriceData, nearestDate, nearSpotPrice, productName, refDate);
      }
    }
  };

  const getStrikePriceList = async (
    expiryStrikePriceList: ExpiryStrikePrice[],
    expiry: string,
    spot: string,
    productName: string,
    refDate?:any
  ) => {
    let strikePriceList: string[] = [];
    let nearestStrikePrice = [];
    if (expiryStrikePriceList !== null && expiryStrikePriceList.length > 0) {
      let filteredList = expiryStrikePriceList.filter(
        (item) => item.Expiry === expiry
      );
      strikePriceList = filteredList[0].StrikePrice;
      const shortArray = strikePriceList.sort((a, b) => parseFloat(a) - parseFloat(b));
      const multipleOf500 = strikePriceList.filter((e) => parseFloat(e) % 500 === 0);
      const multipleOf100 = strikePriceList.filter((e) => parseFloat(e) % 100 === 0);
     if(!historical){
      if (productName?.toLowerCase() === "midcpnifty") {
        nearestStrikePrice = await findNearestStrikePrices(spot, multipleOf100, 2, 2, productName);
      } else {
        if (productName !== undefined)
          nearestStrikePrice = await findNearestStrikePrices(spot, multipleOf500, 2, 2, productName);
      }
     }else if(historical){
      if (productName?.toLowerCase() === "midcpnifty") {
        nearestStrikePrice = await findNearestStrikePrices(spot, multipleOf100, 2, 2);
      } else {
        if (productName !== undefined)
          nearestStrikePrice = await findNearestStrikePrices(spot, multipleOf500, 2, 2);
      }
     }
        const newSpotPrice = parseFloat(spot);
        const nearestValue = shortArray.reduce((prev, curr) => 
          Math.abs(parseFloat(curr) - newSpotPrice) < Math.abs(parseFloat(prev) - newSpotPrice) ? curr : prev
        );
        const roundedSpotPrice = parseFloat(nearestValue);
        const rangeStart = roundedSpotPrice - 9000;
        const rangeEnd = roundedSpotPrice + 9000;
        const filteredStrikePrices = shortArray.filter(price => {
          const strike = parseFloat(price);
          return strike >= rangeStart && strike <= rangeEnd;
        });
        setStrikePriceList(filteredStrikePrices);
      setSelectedStrikePrices(nearestStrikePrice);
      if (productName !== undefined && productName !== null) {
        handleIdentifierChange(productName, expiry, nearestStrikePrice, refDate)
      }
    }
  };

  async function handleIdentifierChange (
    product: string,
    expiry: string,
    strikePrices: string[],
    refDate?:any
  ){
    if(historical && date === "" && instrument===""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    strikePrices.forEach((strikePrice) => {
      if(!dataMap.has(strikePrice)){
        updatedDataMap.set(strikePrice, { CE: [], PE: [] });
      }
  });
    let localToIdentifier = `OPTIDX_${product}_${expiry}`;
    let localHistoricIdentifier = "";
    strikePrices.forEach((item, index) => {
      const ceIdentifier = `OPTIDX_${product}_${expiry}_CE_${item}`;
        const peIdentifier = `OPTIDX_${product}_${expiry}_PE_${item}`;
        if (index !== 0) {
          localHistoricIdentifier += ","; 
      }
      localHistoricIdentifier += `${ceIdentifier},${peIdentifier}`;
    })
    setSelectedStrikePrices(strikePrices);
    if (!historical) {
      if (marketStatus === "open") {
       await getIndexSubscription(
          topicName,
          userId === undefined ? "" : userId,
          localToIdentifier
        );
      }
    }else if(historical &&  refDate!=="" && refDate !== date && toIdentifier  === localToIdentifier){
      getHistoricOpinionData(localHistoricIdentifier, refDate)
    }
    setToHistoricIdentifier(localHistoricIdentifier)
    setToIdentifier(localToIdentifier);
  };

  function handleExpiryChange(expiryDate: string) {
    dataMap.clear();
    setCollapseData({});
    setExpiry(expiryDate);
    if (instrument !== "" && !historical) {
      getStrikePriceListByExpiry(instrument, props.opinionInstrumentList, expiryDate);
    }else{
      getStrikePriceListByExpiry(instrument, historicSpotData, expiryDate)
    }
  }

  async function getStrikePriceListByExpiry(
    productName: string,
    opinionDataList: InstrumentExpiryStrikePrice[] | null,
    expiry: string
  ) {
    let selectedStrikePrices: string[] = [];
    if (opinionDataList !== null && opinionDataList.length > 0) {
      const filteredList = opinionDataList.filter((product) => product.ProductName === productName)
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryData = expiryStrikePriceData.filter((exp) => exp.Expiry === expiry)
      const nearSpotPrice = spotPrices[productName] ? spotPrices[productName] : opinionDataList.find((item) => item.ProductName === productName)?.SpotPrice;
      selectedStrikePrices = expiryData[0].StrikePrice;
      const shortArray = selectedStrikePrices.sort((a, b) => parseFloat(a) - parseFloat(b));
      setStrikePriceList(shortArray);
      const multipleOf500 = shortArray.filter((e) => parseFloat(e) % 500 === 0);
      const multipleOf100 = shortArray.filter((e) => parseFloat(e) % 100 === 0);
        let nearestStrikePrice: [] | string[] = []
       if(!historical){
        if (productName.toLocaleLowerCase() === "midcpnifty") {
          nearestStrikePrice = await findNearestStrikePrices(nearSpotPrice, multipleOf100, 2, 2, productName);
        }
        else {
          nearestStrikePrice = await findNearestStrikePrices(nearSpotPrice, multipleOf500, 2, 2, productName);
        }
       }else{
        const historicSpot = filteredList[0].SpotPrice;
        if (productName.toLocaleLowerCase() === "midcpnifty") {
          nearestStrikePrice = await findNearestStrikePrices(historicSpot, multipleOf100, 2, 2);
        }
        else {
          nearestStrikePrice = await findNearestStrikePrices(historicSpot, multipleOf500, 2, 2);
        }
       }
        setSelectedStrikePrices(nearestStrikePrice);
      handleIdentifierChange(productName, expiry, nearestStrikePrice);
    }
  }

  async function Init() {
    dataMap.clear();
    await getInstrumentExpiryStrikePriceList(props.opinionInstrumentList);
  }
  const getInstrumentExpiryStrikePriceList = async (
    indexExpiryPriceList: any[]
  ) => {
    if (indexExpiryPriceList != null && indexExpiryPriceList.length > 0) {
      const initialInstrument = instrument ? instrument : indexExpiryPriceList[0].ProductName
      await getExpiryList(
        initialInstrument,
        indexExpiryPriceList
      );
    }
  };

  const fetchDataAtInterval = async () => {
    await liveMsgExpertOpinion(topicName, toIdentifier, toHistoricIdentifier);
  };

  const handleCheckboxChange = async(checked: string | boolean, item: string) => {
    dataMap.clear();
  let updatedItems = [];
  if (!checked) {
    updatedItems = selectedStrikePrices.filter(
      (selectedItem) => selectedItem !== item
    );
  } else {
    updatedItems = [...selectedStrikePrices, item]
  }
  setSelectedStrikePrices(updatedItems);
  await handleIdentifierChange(instrument, expiry, updatedItems)
};

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},25000)
  },[instrument,expiry, selectedStrikePrices, date])

  useEffect(() => {
    if(!historical){
      Init();
    }
  }, [historical, environment, userId, marketStatus]);

  React.useEffect(() => {
    if (environment === "test") {
      if(topicName === "oistrategydata_data_test"){
        setTopicName(topicName)
      } else{
        setTopicName(topicName + "_test");
      }
    }
  }, [environment]);

  useEffect(() => {
    if(!historical && marketStatus === "open"){
      fetchDataAtInterval();
    }
  },[toHistoricIdentifier, marketStatus]);

  useEffect(() => {
    let timeoutId:any;
    if(!historical  && marketStatus === "open"){
       id = setInterval(fetchDataAtInterval, 8 * 1000);
      }else if(marketStatus === "after-open" && !historical){
        timeoutId = setTimeout(fetchDataAtInterval, 1000)
      }else if( marketStatus!=="loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
        getHistoricOpinionData(toHistoricIdentifier);
      }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toHistoricIdentifier, historical, marketStatus])

  useEffect(() => {
    if (historical) {
      dataMap.clear();
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (date!=="" && instrument !== "" && toHistoricIdentifier!=="") {
        getHistoricOpinionData(toHistoricIdentifier);
      }
    }
  }, [historical, toHistoricIdentifier]);

  if (isLoggedIn === false) {
    navigate("/login");
  }

  if (userId === undefined) return <></>;

  return (
    <div className="mx-auto max-w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={expertOpinionBreadcrumb} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 min-[1071px]:grid-cols-4">
        <SelectBox title="Name:" placeholder="Please Select Name" value={instrument}
          onValueChange={(e: string) => {
            handleProductChange(e);
          }}
        >
          {props.opinionInstrumentList && props.opinionInstrumentList.slice(0, 4).map((item, index) => {
            return (
              <SelectItem key={index} value={`${item.ProductName}`}>
                {item.ProductName && item.ProductName}
              </SelectItem>
            );
          })}
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox title="Expiry:" placeholder="Please Select Expiry" value={expiry}
          onValueChange={(e: string) => {
              handleExpiryChange(e);
            }}
        >
          {expiryList &&
            expiryList.map((item, index) => {
              const date = formatDate(item);
              return (
                <SelectItem key={index} value={item}>
                  {date}
                </SelectItem>
              );
            })}
        </SelectBox>

        <div className="w-full mt-5">
          <AlertDialog>
            <AlertDialogTrigger className="mt-0 flex w-full items-center justify-between border bg-white px-3 py-2.5 text-start text-[10px] shadow border-blue-600 dark:bg-transparent sm:text-[12px]">
              <span className="text-blue-600 font-medium">
                Change Strike Prices
              </span>
            </AlertDialogTrigger>
            <AlertDialogContent className="w-full max-w-[1280px] pb-6 pt-4 text-sm  dark:text-white">
              <p className="border-b pb-1">Select strike prices</p>
              <p className="mt-4">
                Total Strike Prices : {strikePriceList.length}
              </p>

              <p className="mt-4 text-xs sm:text-sm">
                <span className="mr-1 inline-block">
                  Currently Selected Strike Prices:{" "}
                </span>
                {selectedStrikePrices.map((item, index) => (
                  <span
                    className="mr-1 inline-block text-xs font-semibold "
                    key={index}
                  >
                    {item}
                    {index === selectedStrikePrices.length - 1 ? "" : ","}
                  </span>
                ))}
              </p>

              <div className="flex items-center justify-center gap-4">
              </div>
              <ScrollArea className="h-48 max-w-max mx-auto">
                <div className="mt-6 grid max-w-max mx-auto grid-cols-2 min-[260px]:grid-cols-3 min-[450px]:grid-cols-5 sm:grid-cols-6 gap-y-6 md:grid-cols-8 lg:grid-cols-10">
                  {strikePriceList &&
                    strikePriceList?.map((item, index) => {
                      return (
                        <div key={index} className="flex items-center ">
                          <Checkbox
                            checked={selectedStrikePrices.includes(
                              item.toString()
                            )}
                            onCheckedChange={(checked) =>
                              handleCheckboxChange(checked, item.toString())
                            }
                          />
                          <label className="pl-3 pr-4 text-xs capitalize md:text-sm">
                            {item}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </ScrollArea>

              <div className="mt-8 flex justify-end gap-4">
                <Button
                  onClick={() => {
                    dataMap.clear();
                    setSelectedStrikePrices([]);
                  }}
                >
                  Clear All
                </Button>

                <AlertDialogAction
                  className={buttonVariants({ variant: "default" })}
                >
                  Ok
                </AlertDialogAction>
              </div>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <div className={cn({ hidden: collapsed }, "mt-[12px]")}>
        <EOSpot row={collapseData} />
      </div>

      <div className="mb-2 flex items-center justify-between">
        <div>
          <span className="mr-1 inline-block text-xs font-medium">
            Selected Strike Prices:{" "}
          </span>
          {selectedStrikePrices.sort((a:string,b:string)=> parseFloat(a) - parseFloat(b)).map((item, index) => (
            <span
              className="mr-1 inline-block text-xs font-semibold "
              key={index}
            >
              {item}
              {index === selectedStrikePrices.length - 1 ? "" : ","}
            </span>
          ))}
        </div>

        <CollapseButton handleCollapse={handleCollapse} collapsed={collapsed} />
      </div>
      <div className="custom-scrollbar overflow-auto">
        {selectedStrikePrices.sort((a:string,b:string)=> parseFloat(a) - parseFloat(b)).map((strikePriceSelect, index) => {
          return (
            <div key={index} className="grid grid-cols-2 gap-4 min-w-[1200px] ">
              <EOTable
                loading={isLoading}
                title={`${strikePriceSelect.toString()} CE`}
                key={`${strikePriceSelect.toString()} CE`}
                rowData={dataMap.get(strikePriceSelect)?.CE.reverse() || []}
                height={(dataMap.get(strikePriceSelect)?.CE || []).length > 0 || (dataMap.get(strikePriceSelect)?.PE || []).length > 0}
              />
              <EOTable
                loading={isLoading}
                title={`${strikePriceSelect.toString()} PE`}
                key={`${strikePriceSelect.toString()} PE`}
                rowData={dataMap.get(strikePriceSelect)?.PE.reverse() || []}
                height={(dataMap.get(strikePriceSelect)?.PE || []).length > 0 || (dataMap.get(strikePriceSelect)?.CE || []).length > 0}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}