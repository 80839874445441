import AppLayout from "../../layout/AppLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import "./styles.css";
import { SBilling } from "../../lib/validation/plans";
import * as z from "zod";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import usePaymentDetails from "../../store/paymentDetails";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { baseURL } from "../../api/base";
import { toast } from "sonner";
import { breadcrumbPlan, terms } from "./constant";
import {useTheme} from "../../utils/ThemeProvider"
import { Icons } from "../../utils/icons";
import { Loader2 } from "lucide-react";

type TSBilling = z.infer<typeof SBilling>;
declare global {
  interface Window {
    Razorpay: any;
  }
}
export default function Purchase() {
  const location = useLocation();
  const { merchantTransactionPlanId, setMerchantTransID } = usePaymentDetails();
  // const userId = useGetFromStore(useAuthStore, (state) => state.user.newId);
  const user = useGetFromStore(useAuthStore, (state) => state.user);
  const [details, setDetails] = useState({
    planName: "",
    validity: "",
    price: 0,
    gst: 0,
    total: 0,
  });
  const [couponCode, setCouponCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TSBilling>({ resolver: zodResolver(SBilling) });
  const navigate = useNavigate();
  const { theme } = useTheme()
  
  const handleCouponBtn = async () => {
    setBtnLoading(true);
    const dbURL = `${baseURL}/api/Account/CouponValidationAndCalculation?userId=${user?.id}&subscriptionId=${location.state.ID}&couponCode=${couponCode}`;
    fetch(dbURL, {
      method: "GET",
    }).then((res) => {
      res
        .json()
        .then((data) => {
          if (data.IsCouponValid === true) {
            setDetails({
              ...details,
              price: data.DiscountedAmount,
              gst: parseInt(data.DiscountedAmount) * (18 / 100),
              total: data.FinalAmount.toFixed(2),
            });
            toast.success(`${data.Message}`,{
              position:"top-right"
            });
          } else {
            toast.warning(`${data.Message}`,{
              position:"top-right"
            });
          }
        })
        .catch((err: any) => 
        toast.error(err.message, {
          position:"top-right"
        }));
    });
    setBtnLoading(false);
  };

  function submitRazorpayResponse(response:any, userData:any){
    setLoading(true);
    const responseBody = {
      userId: user?.id,
      subscriptionId: parseInt(location.state.ID),
      merchantTransactionId:response.razorpay_payment_id,
      // paymentId: response.razorpay_payment_id,
      // orderId: response.razorpay_order_id,
      // razorpaySignature: response.razorpay_signature,
      couponCode: couponCode,
      ...userData,
    }
  fetch(`${baseURL}/api/Account/CheckRazorPayPaymentStatus`, 
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(responseBody)
  }).then((res) =>{
    res.json().then ((data) =>{
      if(data === true){
        setLoading(false);
        navigate("/dashboard");
        toast.success(`Plan Successfully Subscribed`,{
          position:"top-right"
        });
      } else {
        setLoading(false);
        navigate("/features/plans");
        toast.warning("Something went wrong",{
          position:"top-right"
        });
      }
    })
  }).catch((err: any) => {
    setLoading(false);
    navigate("/features/plans");
    toast.error(err.message,{
      position:"top-right"
    });
  });
  }

  function failedPayemnt(response:any, userData:any){
    const options = {
      userId: user?.id,
      subscriptionId: parseInt(location.state.ID),
      merchantTransactionId:response.error.metadata.payment_id,
      couponCode: couponCode,
      ...userData
    }
    fetch(`${baseURL}/api/Account/CheckRazorPayPaymentStatus`, 
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
       body: JSON.stringify(options)
      }).then((res) => {
        res.json().then((data) => {
          if(data === false){
            toast.error(`Payment Failed`, {
              position:"top-right"
            });
          } else{
            toast.error(`Something went wrong`, {
              position:"top-right"
            })
          }
        })
      })
      .catch((err) => {
        toast.error(`Something went wrong`, {
          position:"top-right"
        })
      })
  }

  const onSubmit: SubmitHandler<TSBilling> = (userData, e) => {
    e?.preventDefault();
    let amount;
    if (typeof details.total === 'number') {
       amount = details.total.toFixed(2);
    }else{
      amount = parseInt(details.total).toFixed(2)
    }
    setLoading(true);
    if (parseFloat(details.total.toString()) === 0) {
      const body = {
        userId: user?.id,
        subscriptionId: parseInt(location.state.ID),
        couponCode: couponCode,
        ...userData,
      };
      fetch(`${baseURL}/api/Account/InsertUserSubscribtionDetailsWithCoupon`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          res.json().then((data) => {
            if (data.success === true) {
              setLoading(false);
              navigate("/dashboard");
              toast.success(`Plan Successfully Subscribed`,{
                position:"top-right"
              });
            } else {
              setLoading(false);
              toast.error(`${data.message}`,{
                position:"top-right"
              });
            }
          });
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error(`${err.message}`,{
            position:"top-right"
          });
          navigate("/features/plans");
        });
    } else {
      fetch(
      `${baseURL}/api/Account/CreateRazorPayOrder`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // UserId: user?.id,
            Amount: amount
          })
        }
      ).then((res) => {
          res.json().then((data) => {
            const orderAttributes = data?.Order.Attributes;
            const options = {
              key:data.RazorPayKey,
              amount: orderAttributes.amount,
              currency: orderAttributes.currency,
              order_id: orderAttributes.id,
              name: "Trade Dons",
              description: details.planName,
              image: theme === "dark" ? `/assets/logo-dark.png` : `/assets/logo-light.png`,
              handler: function (response:any){
                submitRazorpayResponse(response, userData);
            },
              prefill:{
                name: `${userData.firstName} ${userData.lastName}`,
                contact: userData.phone,
                email: user?.email
              },
              notes: {
                address: "Razorpay Corporate Office"
            },
              theme:{
                color:"#3399cc"
              },
              
            }
            var razor = new window.Razorpay(options);
            razor.on("payment.failed", function(response:any){
              failedPayemnt(response, userData)
            })
            razor.open();  
          });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${err.message}`, {
            position:"top-right"
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setDetails({
      ...details,
      planName: location?.state?.Name,
      price: location?.state?.BasicPrice,
      gst: parseInt(location?.state?.BasicPrice) * (18 / 100),
      total: location?.state?.TotalPrice,
      validity:
        parseInt(location?.state?.Duration) / 30 >= 1
          ? `${(parseInt(location?.state?.Duration) / 30).toString().split(".")[0]
          }${parseInt(location?.state?.Duration) / 30 >= 2 ? " Months" : " Month"
          }`
          : `${location?.state?.Duration} Days`,
    });
  }, [location.state]);

  if (loading) {
    return (
      <div className="flex h-[calc(100vh-90px)] items-center justify-center">
        <Icons.spinner
          className="mr-2 h-12 w-12 animate-spin"
          aria-hidden="true"
        />
      </div>
    );
  }
  return (
    <>
      <AppLayout>
        <section className="mx-auto max-w-full px-2 py-4">
          <Breadcrumb items={breadcrumbPlan} />

          <div className="dark:bg-zinc-800 bg-white shadow mt-6 border dark:border-zinc-600 rounded-md py-4">
            <h1 className="text-center mb-4 font-medium text-lg">
              Price Breakup
            </h1>
            <ul className="px-4">
              <Item
                className="border-t"
                title="Plan Name"
                desc={`(Validity: ${details.validity})`}
                value={details.planName}
              />
              <Item title="Price" desc={"(Base price)"} value={details.price} />
              <Item title="GST" desc={"(18%)"} value={details.gst} />
              <Item
                title="Total"
                desc={`(Price + GST)`}
                value={details.total}
              />
            </ul>

            <div className="px-4 flex gap-2 mt-6">
              <input
                className="input"
                placeholder="Enter Promo Code (if available)"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Button
                className={couponCode === "" ?"bg-blue-500 px-6":"px-6"}
                onClick={handleCouponBtn}
                disabled={couponCode===""}
              >
                {btnLoading ?<Loader2 className="mr-2 h-4 w-4 animate-spin" />:""}
                Apply
              </Button>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="dark:bg-zinc-800 dark:border-zinc-600 border rounded-md shadow mt-4 p-4"
          >
            <h2 className="text-center font-medium text-lg">Billing Details</h2>
            <p className="text-center text-[14px]">
              Please ensure accuracy when filling out the billing details as
              they cannot be modified once the invoice is generated.
            </p>

            <div className="mt-12">
              <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4 gap-y-0">
                <div className="mb-4">
                  <label className="label">First Name</label> <br />
                  <input
                    className="input"
                    placeholder="First name"
                    {...register("firstName")}
                  />
                  {errors.firstName && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.firstName?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="label">Last Name</label> <br />
                  <input
                    className="input"
                    placeholder="Last name"
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.lastName?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="label">Number</label> <br />
                  <input
                    type="number"
                    className="input"
                    placeholder="Number"
                    {...register("phone")}
                  />
                  {errors.phone && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.phone?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4 ">
                  <label className="label">Address</label> <br />
                  <input
                    className="input"
                    placeholder="Address"
                    {...register("address")}
                  />
                  {errors.address && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.address?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="label">State</label> <br />
                  <input
                    className="input"
                    placeholder="State"
                    {...register("state")}
                  />
                  {errors.state && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.state?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="label">City</label> <br />
                  <input
                    className="input"
                    placeholder="City"
                    {...register("city")}
                  />
                  {errors.city && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.city?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="label">Pin Code</label> <br />
                  <input
                    className="input"
                    type="number"
                    placeholder="Pin code"
                    {...register("pincode")}
                  />
                  {errors.pincode && (
                    <span className="text-red-500 text-[12px]">
                      {errors?.pincode?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-medium mb-3">
                By proceeding with the purchase of Trade Dons plan, you
                acknowledge and accept the following terms:
              </h3>
              <ul className="list-decimal pl-4">
                {terms.map((e, index) => (
                  <li
                    className="mb-3 text-zinc-500 dark:text-zinc-300  text-[14px]"
                    key={index}
                  >
                    {e}
                  </li>
                ))}
                <li className="mb-3 text-zinc-500 dark:text-zinc-300  text-[14px]">
                  I have read{" "}
                  <Link
                    to="/terms-and-conditions"
                    className="text-blue-500 underline"
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </Link>{" "}
                  and{" "}
                  <Link to="/refund" className="underline text-blue-500">
                    {" "}
                    Refund Policy.
                  </Link>
                </li>
              </ul>
              <p className="text-zinc-500 dark:text-zinc-300 text-[14px] mb-3">
                Please check the box below to confirm your acceptance of these
                terms before proceeding with the purchase
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <input
                className="cursor-pointer"
                type="checkbox"
                {...register("terms")}
              />
              <label htmlFor="terms" className="text-sm font-medium ">
                Accept Terms and Conditions
              </label>{" "}
              <br />
            </div>
            {errors.terms && (
              <p className="text-[12px] text-red-500">
                {errors?.terms?.message}
              </p>
            )}
            <Button type="submit" className="w-full rounded-full mt-3">
              Pay Now
            </Button>
          </form>
        </section>
      </AppLayout>
    </>
  );
}

interface TItem {
  title: string;
  className?: string;
  desc: string;
  value: string | number;
}
const Item = ({ title, desc, value, className }: TItem) => {
  return (
    <li className="py-3 border-b border-dashed dark:border-zinc-600 px-4">
      <div className="flex items-center justify-between">
        <div>
          <p>{title}</p>
          <p className="text-[14px] text-zinc-400">{desc}</p>
        </div>
        <p>{value}</p>
      </div>
    </li>
  );
};
