import { format } from "date-fns";
import { Skeleton } from "../ui/skeleton";
import { ScrollArea } from "../ui/scroll-area";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

type EventsProps = {
  title: string;
};

interface NewsItem {
  title: string;
  link: string;
  description: string;
  pubDate: string;
}
const parseHtml = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};
function Events({ title }: EventsProps) {

  const [news, setNews] = useState<NewsItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.moneycontrol.com/rss/latestnews.xml');
        const xmlText = await response.text();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'application/xml');

        const items = xmlDoc.querySelectorAll('item');

        const newsData: NewsItem[] = Array.from(items).map((item) => ({
          title: item.querySelector('title')?.textContent || '',
          link: item.querySelector('link')?.textContent || '',
          description: parseHtml(item.querySelector('description')?.textContent || ''),
          pubDate: item.querySelector('pubDate')?.textContent || '',
        }));

        setNews(newsData);
      } catch (error) {
        console.error('Error fetching news:', error);
        // Handle the error using your eventDummy here
        // e.g., dispatchEvent(new EventDummyErrorEvent(error));
      }
    };

    fetchData();
  }, []);



  return (
    <div>
 <p className=" rounded-t-lg border  border-blue-600 bg-blue-600 px-3 py-3  capitalize text-white">
        {title}
      </p>
      <ScrollArea className="h-[335px] w-full border bg-white shadow-sm dark:border-zinc-600 dark:bg-zinc-800">
        <div className="dark:bg-zinc-800 bg-zinc-100 px-3">
          {news.map((event, index) => (
            <Link
              to={event.link}
              key={index}
              className="py-3 border-b-2 block  dark:border-zinc-600"
              target="_blank"
            >
              <p className="mt-2 text-sm dark:text-zinc-300">
                {format(new Date(event.pubDate), "EEE dd MMM Y")}
              </p>
              <p className="capitalize text-zinc-500 text-lg dark:text-white">
                {event.title}
              </p>
              <p className="dark:text-zinc-300 text-sm mt-3">
                <img src={event.description} alt="" />
                {/* <p dangerouslySetInnerHTML={{ __html: event.description }} /> */}
                {event.description}
              </p>
            </Link>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}

export default Events;

const renderSkeletons = (count: number) => {
  const skeletons = Array.from({ length: count }, (_, index) => (
    <div key={index}>
      <Skeleton className="h-12 w-full" />
      <Skeleton className="mt-1 h-6 w-full max-w-[180px]" />
    </div>
  ));
  return <div>{skeletons}</div>;
};

export function EventDummy() {
  return (
    <ScrollArea className="h-[335px] w-full rounded-lg border bg-white shadow-sm dark:border-zinc-600 dark:bg-zinc-800">
      <div className="px-3">{renderSkeletons(6)}</div>
    </ScrollArea>
  );
}
