import { useState, useEffect, useRef } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "..//ui/select";
import {FoiExpiry, FoiTimeInterval } from "../../data/openInterest";
import OpenInterestTable from "./OpenInterestTable";
import { OpenInterestSpot } from "./OpenInterestSpot";
import LiveSwitch from "../shared/LiveSwitch";
import Breadcrumb from "../shared/Breadcrumb";
import { TFuturesOpenInterst, getFOIInterMediateData, getIndexSubscription } from "../../api/futures/open-interest";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import useMarketStatus from "../../hooks/useMarketStatus";
import { toast } from "sonner";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { OpenInterestMobileTable } from "./OpenInterestMobileTable";
import { SelectLabel } from "@radix-ui/react-select";
import { cn } from "../../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { CalendarDaysIcon, Loader } from "lucide-react";
import { Calendar } from "../ui/calendar";
import format from "date-fns/format/index";
import { RefreshRateData } from "../../constants/TimeInterval";
import { Icons } from "../../utils/icons";
import { configEnvironemt } from "../../config";
import { foiNavigation } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { getProductSpotPrice } from "../../api/options";
import convertToYYYYMMDD from "../shared/dateConverter";
import { baseURL } from "../../api/base";

type hisotricDataType ={
    ProductName: string;
    CurrentMonth: string;
    NextMonth: string;
    FarMonth: string;
}

export default function OpenInterest({ indexList }: {
  indexList: TFuturesOpenInterst;
}) {
  const environment = configEnvironemt.environment;
  const [timeInterval, setTimeInterval] = useState(FoiTimeInterval[1].value);
  const [instrument, setInstrument] = useState(indexList[0].ProductName);
  const [expiry, setExpiry] = useState<string>(FoiExpiry[0].value);
  const [expiryData, setExpiryData] = useState(indexList[0].CurrentMonth);
  const [selectedInstrument, setSelectedInstrument] = useState(indexList[0]);
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `FUTIDX_${indexList[0].ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [toIdentifier, setToIdentifier] = useState(
    `FUTIDX_${indexList[0].ProductName}_${indexList[0].CurrentMonth}_0_5Min`
  );
  const [mobile, setMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [historical, setHistorical] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [lastRow, setLastRow] = useState<any | []>([]);
  const [date, setDate] = useState<any>(new Date())
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const marketStatus = useMarketStatus();
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const [tableHeight, setTableHeight] = useState(380);
  const containerRef = useRef<HTMLDivElement>(null);
  let id:any;
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };
  const handleSwitch = () => {
    setRows([]);
    setLastRow([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(indexList[0]?.ProductName);
      setExpiryData(indexList[0].CurrentMonth)
      setExpiry(FoiExpiry[0].value);
      handleTopicNameChange(indexList[0]?.ProductName, indexList[0].CurrentMonth, timeInterval)
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setIsLoading(false)
      setHistorical(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  async function firstSubscriptionName() {
   await getIndexSubscription(
      topicName,
      userId === undefined ? "" : userId,
      toIdentifier
    );
  }

  function processMessage(message: string[], newSpot?:string) {
    // TimeInterval,Buildups,LTP,ChangeinLTP,CurrentOIChange,TodaysChangeinOI,TotalOI,Volume,BreakOut,DayHigh,DayLow,CurrentFuturePrice,SpotPrice
    const upRows = message.map((item) => {
      const splitMsg = item.split(",")
      return ({
        time: splitMsg[0],
        buildup: splitMsg[1],
        ltp: splitMsg[2],
        changeinltp: splitMsg[3],
        currentoichange: splitMsg[4],
        todaysoichange: splitMsg[5],
        totaloi: splitMsg[6],
        volume: splitMsg[7],
        breakout: splitMsg[8],
        dayhigh: splitMsg[9],
        dayLow: splitMsg[10],
        prevClose: splitMsg[11],
        todayOpen: splitMsg[12],
        spotPrice: (marketStatus=== "open" || marketStatus==="after-open")&& !historical ? splitMsg[13]: newSpot
      })
    });
    const filteredRow = upRows.filter((item) => item.time !== "15:30")
    setIsLoading(false);
    return filteredRow;
  }

  async function liveMsgFOIData(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const fRows = processMessage(messages);
    const reversedRow = fRows.reverse();
    setRows(reversedRow);
    setLastRow(reversedRow[0]);
    }
  }

  async function getHistoricFOIData(identifier: string, topicName:string, productName?:string, refDate?:any) {
    let response;
    let newSpot;
    if(productName !=="" && productName !== undefined){
      newSpot = await getProductSpotPrice(productName)
    }
   if (historical && identifier !== '' && date !== '') {
      response = await getFOIInterMediateData(identifier, topicName, !refDate ? date : refDate);
    } else{
      response = await getFOIInterMediateData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const fRows = processMessage(messages, newSpot);
      const reversedRow = fRows.reverse();
      setRows(reversedRow);
      setLastRow(reversedRow[0]);
    }
  }

  async function handleTopicNameChange (
    productName: string,
    instrumentExpiry: string,
    instrumentInterval: string,
    refDate?:any
  ) {
    setRows([]);
    setLastRow([]);
    if(historical && date === ""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
      (idx) => idx === productName
    );
    let topicNameLocal = `${!!isIndex ? "FUTIDX" : "FUTSTK"}_${productName}`;
    let identifierLocal = `${!!isIndex ? "FUTIDX" : "FUTSTK"
      }_${productName}_${instrumentExpiry}_0_${instrumentInterval}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    setToIdentifier(identifierLocal);
    if(!historical){
      if ((marketStatus === "open" || marketStatus === "after-open") && historical=== false) {
        await getIndexSubscription(
          topicNameLocal,
          userId === undefined ? "" : userId,
          identifierLocal
        );
      } else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        await getHistoricFOIData(identifierLocal,topicNameLocal, productName);
      }
    } else if(historical && refDate!=="" && refDate !== date && toIdentifier === identifierLocal){
      getHistoricFOIData(identifierLocal, topicNameLocal, "", refDate)
    }
  };

  function handleDateSelect (date:any) {
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/FOS/GetIndexAndExpiryDetailsForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:TFuturesOpenInterst) => {
      const found = data.find((i:hisotricDataType) => i.ProductName === instrument);
      if (!found) throw new Error("Not Found in the list");
      setExpiryData(found.CurrentMonth);
      setExpiry("CurrentMonth");
      handleTopicNameChange(instrument, found.CurrentMonth, timeInterval, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  const fetchDataAtInterval = async () => {
      await liveMsgFOIData(topicName, toIdentifier);
  };
  const calculateTableHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const topBarHeight = document.querySelector("header")?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      const offset = containerHeight + topBarHeight + 36;
      setTableHeight(windowHeight - offset);
    }
  }
  useEffect(() => {
    calculateTableHeight(); 
    window.addEventListener("resize", calculateTableHeight);
    return () => window.removeEventListener("resize", calculateTableHeight);
  }, [collapsed, historical, date, isLoading]);

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[instrument,expiry, date])

  useEffect(() => {
    if (historical) {
      setRows([]);
      setLastRow([]);
      if (instrument !== "" && date!=="" && toIdentifier!== "") {
        getHistoricFOIData(toIdentifier, topicName);
      }
    } else {
      if (marketStatus === "open" && historical === false) {
        firstSubscriptionName();
        fetchDataAtInterval()
      }else if(marketStatus === "after-open" && !historical){
        firstSubscriptionName();
        fetchDataAtInterval()
      }else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        getHistoricFOIData(toIdentifier, topicName, instrument);
      }
    }
  }, [historical, userId, marketStatus, toIdentifier]);

  useEffect(() => {
    if(!historical && marketStatus === "open"){
      const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
          id = setInterval(fetchDataAtInterval, seconds * 1000);
    }
  }else if(marketStatus === "after-open" && !historical){
    fetchDataAtInterval()
  }else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
    getHistoricFOIData(toIdentifier, topicName);
  }
  return () => clearInterval(id);
  },[historical, refreshRate, toIdentifier, marketStatus])

  useEffect(() => {
    if (windowWidth && windowWidth < 640) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowWidth]);

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="flex items-center justify-between mt-4">
        <Breadcrumb items={foiNavigation} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-3 min-[1064px]:grid-cols-5">
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Name:</p>
          <Select
            value={instrument}
            onValueChange={(e) => {
              const found = indexList.find((i: any) => i.ProductName === e);
              if (!found) throw new Error("Not Found in the list");
              setRows([]);
              setLastRow([]);
              setInstrument(e);
              setSelectedInstrument(found);
              if(!historical){
                handleTopicNameChange(e, found.CurrentMonth, timeInterval);
              setExpiryData(found.CurrentMonth);
              setExpiry("CurrentMonth");
              }else {
                setDate("");
                setExpiry("")
              }
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Name" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
                <SelectLabel>Index</SelectLabel>
                {indexList && indexList.slice(0, 4).map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.ProductName}`}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
                <SelectLabel>Stocks</SelectLabel>
                {indexList && indexList.slice(5, indexList.length).map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.ProductName}`}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen} >
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Expiry:</p>
          <Select
            value={expiry}
            onValueChange={(e: keyof (typeof indexList)[0]) => {
              handleTopicNameChange(instrument, selectedInstrument[e], timeInterval);
              setExpiryData(selectedInstrument[e]);
              setExpiry(e);
              }}
          >
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Expiry" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {FoiExpiry.map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.value}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Time Interval:</p>
          <Select
            value={timeInterval}
            onValueChange={(e) => {
              setTimeInterval(e);
              handleTopicNameChange(
                instrument,
                selectedInstrument[
                expiry as
                | "ProductName"
                | "CurrentMonth"
                | "NextMonth"
                | "FarMonth"
                ],
                e
              );
            }}
          >
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Interval" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {FoiTimeInterval.map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.value}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-2 w-full min-[1064px]:col-span-1 col-span-2 min-[1064px]:gap-2 gap-4">
          <div className="">
            <p className="mb-1 pl-1 text-xs">Refresh Rate:</p>

            <Select
              value={refreshRate}
              onValueChange={(e) => setRefreshRate(e)}
            >
              <SelectTrigger className="w-full shadow">
                <SelectValue placeholder="Refresh Rate" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {RefreshRateData.map((item, index) => {
                    return (
                      <SelectItem key={index} value={`${item.value}`}>
                        {item.name}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Button
              onClick={handleCollapse}
              variant="select"
              className="px-2 py-2.5 shadow-sm"
            >
              <span>
                {collapsed ? "Expand" : "Collapse"}
              </span>
              <Icons.chevronDown className={cn("ml-2 w-4 h-4 dark:text-zinc-400", { "rotate-180": !collapsed })} />
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-2 flex items-center justify-between sm:hidden">
      </div>
      <div className={cn({ "hidden": collapsed })}>
        <OpenInterestSpot rows={lastRow} />
      </div>
      </div>
      <div className="mt-3">
        {!mobile && <OpenInterestTable
          loading={isLoading}
          rowData={rows}
          tableHeight = {tableHeight}
        />}
        {mobile && <>
          {isLoading ? <Loader className="mx-auto animate-spin my-12" /> :
            <OpenInterestMobileTable rows={rows} />
          }
        </>
        }
      </div>
    </div>
  );
}