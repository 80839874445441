import React from 'react'

const RefundConstant = () => {
  return (
   <>
   <h1
        className="font-montserrat text-center text-[36px] sm:text-[48px] font-semibold"
      >
        Refund and Cancellation Policy
      </h1>
      <ol className="mt-[60px] ml-4 list-decimal text-sm sm:text-lg font-extralight text-zinc-300">
        <li className="mb-4">
          Amount once paid through the payment gateway shall not be refunded
          other than in the following circumstances: Multiple times debiting of
          Customer’s Card/Bank Account due to technical error OR Customer&apos;s
          account being debited with excess amount in a single transaction due
          to technical error. In such cases, excess amount excluding Payment
          Gateway charges would be refunded to the Customer.Due to technical
          error, payment being charged on the Customer’s Card/Bank Account but
          the subscription is unsuccessful. Customer would be provided with the
          subscription by Trade Dons Fintech Pvt Ltd at no extra cost. However,
          if in such cases, Customer wishes to seek refund of the amount, he/she
          would be refunded net the amount, after deduction of Payment Gateway
          charges or any other charges
        </li>
        <li className="mb-4">
          The customer will have to submit an application for refund along with
          the transaction number and original payment receipt, if any, generated
          at the time of making payments.
        </li>
        <li className="mb-4">
          The application in the prescribed format should be sent to
          care@tradedons.com.
        </li>
        <li className="mb-4">
          The application will be processed manually, and after verification, if
          the claim is found valid, the amount received in excess will be
          refunded by Trade Dons Fintech Pvt Ltd through electronic mode in
          favour of the applicant, with confirmation sent to the mailing address
          given in the online registration form, within a period of 7 calendar
          days of receipt of such a claim. It will take 5–21 days for the money
          to show up in your bank account, depending on your bank’s policy.
        </li>
        <li className="mb-4">
          <p>
            The company assumes no responsibility and shall incur no liability
            if it is unable to affect any payment instruction(s) on the payment
            date owing to any one or more of the following circumstances:
          </p>

          <ul className="ml-4 mt-2 list-disc ">
            <li className="mb-2">
              If the payment instruction(s) issued by you are incomplete,
              inaccurate, invalid, and delayed.
            </li>
            <li className="mb-2">
              If the payment account has insufficient funds or limits to cover
              the amount as mentioned in the payment instruction(s)
            </li>
            <li className="mb-2">
              If the funds available in the payment account are subject to any
              encumbrance or charge
            </li>
            <li className="mb-2">
              If your bank or the NCC refuses or delays honouring the payment
              instruction(s)
            </li>
            <li className="mb-2">
              events beyond the company&apos;s control (including, but not
              limited to, fire, flood, natural disasters, bank strikes, power
              outages, and system failures such as computer or telephone lines
              breakdown due to an unforeseeable cause or interference from an
              outside force).
            </li>
          </ul>
        </li>

        <li className="mb-4">
          User agrees that Company, in its sole discretion, for any or no
          reason, and without penalty, may suspend or terminate his/her account
          (or any part thereof) or use of the services and remove and discard
          all or any part of his or her account, user profile, or recipient
          profile at any time. Company may also in its sole discretion and at
          any time discontinue providing access to the services, or any part
          thereof, with or without notice. User agrees that any termination of
          his /her access to the Services or any account he/she may have or
          portion thereof may be effected without prior notice, and also agrees
          that the company will not be liable to the user or any third party for
          any such termination. Any suspected fraudulent, abusive, or illegal
          activity may be referred to appropriate law enforcement authorities.
          These remedies are in addition to any other remedies the company may
          have at law or in equity. Upon termination for any reason, the user
          agrees to immediately stop using the services
        </li>
        <li>
          The company may elect to resolve any dispute, controversy, or claim
          arising out of or relating to this agreement or the service provided
          in connection with this agreement by binding arbitration in accordance
          with the provisions of the Indian Arbitration & Conciliation Act,
          1996. Any such dispute, controversy, or claim shall be arbitrated on
          an individual basis and shall not be consolidated in any arbitration
          with any claim or controversy of any other party.
        </li>
      </ol>
   </>
  )
}

export default RefundConstant