import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { useTheme } from "../../utils/ThemeProvider";
import formatCurrency from "../../utils/formatCurrency";
import "./style.css"

interface TPData {
    callIV: string,
    putIV: string,
    price: string,
    time: string,
}

interface TPChart {
    data: TPData[],
    loading: boolean,
    graphHeight:number
}

const IVGraph = (props: TPChart) => {
    const [loading, setLoading] = useState<boolean>(true)
    const { theme } = useTheme()
    const [chartData, setChartData] = useState<TPData[]>([]);
    const [ivInterval, setIVInterval] = useState<number>(0.5);
    const [priceInterval, setPriceInterval] = useState<number>(50);
    const options = {
        title: {
            show: true,
            text: "TRADEDONS",
            left: "center",
            top: "center",
            textStyle: {
                fontWeight: 'bold',
                fontSize: 20,
                color: "rgba(208, 193, 201, 0.5)"
            },
            subtext: chartData.length <= 0 ? "No Data Available" : "",
            subtextStyle: {
                fontSize: 18
            }
        },
        grid: { top: 30, right: "5%", bottom: 50, left: "4%" },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            },
            right: 28,
            top: -7
        },
        legend: {
            data: ['Call IV', 'Put IV', "Price"],
            itemGap: 5,
            bottom: 2
        },
        xAxis: [
            {
                type: 'category',
                data: chartData.map(item => item.time),
                splitLine: {
                    show: false
                },
                boundaryGap: true,
            },
            {
                type: 'value',
                data: chartData.map(item => item.price),
            },

        ],
        yAxis: [
            {
                type: 'value',
                name: 'IV',
                position: 'left',
                nameLocation: "start",
                alignTicks: false,
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    formatter: function(value:number){
                    if (value % 1 !== 0) {
                        return value.toFixed(1);
                    }
                    return value;
                    },
                },
                nameTextStyle: {
                    padding: [0, 0, 0, 8],
                    fontSize: 14
                },
                nameGap: 30,
                min:(Math.floor(Math.min(
                    ...chartData.map(item => parseFloat(item.callIV)),
                    ...chartData.map(item => parseFloat(item.putIV))
                ) / ivInterval) * ivInterval).toFixed(1),
                max: (Math.ceil(Math.max(
                    ...chartData.map(item => parseFloat(item.callIV)),
                    ...chartData.map(item => parseFloat(item.putIV))
                ) / ivInterval) * ivInterval).toFixed(1),
                // interval: ivInterval
            },
            {
                type: 'value',
                name: 'Price',
                position: 'right',
                nameLocation: "start",
                min: Math.floor(Math.min(...chartData.map(item => parseFloat(item.price))) / priceInterval) * priceInterval, 
                max: Math.ceil(Math.max(...chartData.map(item => parseFloat(item.price))) / priceInterval) * priceInterval, 
                interval: priceInterval,
                axisLine: {
                    show: true,
                },
                data: chartData.map(item => item.price),
                nameTextStyle: {
                    padding: [0, 0, 0, 8],
                    fontSize: 14
                },
                nameGap: 30,
            },
        ],
        color: ["#22c55e", "#ef4444"],
        backgroundColor: [theme === "light" ? "white" : "#27272a"],
        series: [
            {
                name: "Call IV",
                data: chartData.map(item => parseFloat(item.callIV)),
                type: 'line',
                smooth: false,
            },
            {
                name: "Put IV",
                data: chartData.map(item => parseFloat(item.putIV)),
                type: 'line',
                smooth: false,
            },
            {
                name: "Price",
                data: chartData.map(item => parseFloat(item.price)),
                type: 'line',
                smooth: false,
                yAxisIndex: 1,
                lineStyle: {
                    type: 'dashed', 
                },
                markPont: {
                    show: true
                }
            },
        ],

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true
                }
            },
            formatter: (params: any) => {
                const time = chartData[params[0]?.dataIndex]?.time;
                return (
                    `<div>
                        <p class="font-bold">${time}</p>
                        <div class="font-medium">
                        <div class="flex items-center gap-2"> 
                        <div class="icon bg-green-500"></div>
                         Call IV: <span class="font-semibold">${formatCurrency(params[0].value)}</span>
                         </div>
                        <div class="flex items-center gap-2">
                         <div class="icon bg-red-500"></div>
                          Put IV: <span class="font-semibold"> ${formatCurrency(params[1].value)} </span>
                          </div>
                        <div class="flex items-center gap-2"> 
                        <div class="icon bg-yellow-500"></div> 
                        Price: <span class="font-semibold"> ${formatCurrency(params[2].value)} </span>
                         </div>
                        </div>
                    </div>`
                )
            }
        },
    };
    const loadingOption = {
        text: "",
        color: theme === "light" ? 'black' : "white",
        textColor: 'white',
        maskColor: theme === "light" ? "white" : '#27272a',
        zlevel: 0
    };

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setChartData(props.data);
        const currentTime = new Date().getHours();
        if(currentTime > 9 && currentTime < 10){
            setIVInterval(0.5);
            setPriceInterval(50);
        }else if(currentTime>10 && currentTime<13){
            setIVInterval(1);
            setPriceInterval(100);
        } else{
            setIVInterval(1);
            setPriceInterval(200);
        }
    }, [props.data]);

    return (
        <div className="rounded-lg shadow border bg-white dark:bg-zinc-800 dark:border-zinc-600 overflow-hidden">
            <p className="text-lg text-center mt-2 font-semibold">
                Implied Volatility Graph
            </p>
            <ReactECharts loadingOption={loadingOption} option={options} theme={theme} style={{ height: `${props.graphHeight}px`}} showLoading={loading} />
        </div>
    );
};

export default IVGraph;