import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import React, { useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { SelectItem } from "../ui/select";
import { marketMoverExpiry } from "../../data/openInterest";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import { Input } from "../ui/input";
import FSTable from "./FSTable";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { getFutureSpurtzData, getIndexSubscription } from "../../api/futures/open-interest";
import SelectBox from "../shared/SelectBox";
import { configEnvironemt } from "../../config";
import { breadcrumbSpurtz } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";

export default function FutureSpurtz() {
  const environment = configEnvironemt.environment;
  const marketStatus = useMarketStatus();
  const [historical, setHistorical] = useState(false)
  const [date, setDate] = useState<any>(new Date());
  const [instrument, setInstrument] = useState("All F&O Stocks");
  const [expiry, setExpiry] = useState(marketMoverExpiry[0].value);
  const [searchInput, setSearchInput] = useState("");
  const [topicName, setTopicName] = useState("futurespurtz_data");
  const [toIdentifier, setToIdentifier] = useState(`futurespurtz_data_${marketMoverExpiry[0].value}`);
  const [buildUp, setBuildUp] = useState<any | []>([]);
  const [shortBuilUp, setShortBuildUp] = useState<any | []>([]);
  const [covering, setCovering] = useState<any | []>([]);
  const [unwinding, setUnwinding] = useState<any | []>([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  let id:any;
  const handleSwitch = () => {
    setBuildUp([]);
    setShortBuildUp([]);
    setUnwinding([]);
    setCovering([]);
     if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setExpiry(marketMoverExpiry[0].value);
    }
    if (!historical) {
      clearInterval(id);
      setHistorical(true);
      setIsLoading(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setExpiry("")
    }
  };

  const firstSubscription = async () => {
    await getIndexSubscription(
      topicName,
      userId === undefined ? "" : userId,
      toIdentifier
    );
  }

  const processMessage = (messages: string[]) => {
    const msgRows = messages.map((message) => {
      const splitMsg = message.split(",");
      return {
        name: splitMsg[0],
        ltp: splitMsg[1],
        prevClose: splitMsg[2],
        changeInLtpPer: splitMsg[3],
        changeInOiPer: splitMsg[4],
        newOi: splitMsg[5],
        oldOi: splitMsg[6],
        oiChange: splitMsg[7],
        type: splitMsg[8],
      }
    });
    const longBuildUp = msgRows.filter((row) => row.type === "Long Build Up") .sort((a, b) => parseFloat(b.changeInOiPer) - parseFloat(a.changeInOiPer));
    const shortBuilUp = msgRows.filter((row) => row.type === "Short Build Up") .sort((a, b) => parseFloat(b.changeInOiPer) - parseFloat(a.changeInOiPer));
    const shortCovering = msgRows.filter((row) => row.type === "Short Covering") .sort((a, b) => parseFloat(a.changeInOiPer) - parseFloat(b.changeInOiPer));
    const longUnwinding = msgRows.filter((row) => row.type === "Long Unwinding") .sort((a, b) => parseFloat(a.changeInOiPer) - parseFloat(b.changeInOiPer));
    setIsLoading(false);
    return { longBuildUp, shortBuilUp, shortCovering, longUnwinding };
  }

  async function liveMsgSpurtzData(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const { longBuildUp, shortBuilUp, shortCovering, longUnwinding } = processMessage(messages);
    setBuildUp(longBuildUp);
    setShortBuildUp(shortBuilUp);
    setCovering(shortCovering);
    setUnwinding(longUnwinding);
    }
  }

  const getHistoricSpurt = async (identifier: string, topicName:string) => {
    // setIsLoading(true);
    let response;
   if (historical && identifier !== undefined && date !== '') {
      response = await getFutureSpurtzData(identifier, topicName,date);     
    }  else{
      response = await getFutureSpurtzData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const { longBuildUp, shortBuilUp, shortCovering, longUnwinding } = processMessage(messages);
      setBuildUp(longBuildUp);
      setShortBuildUp(shortBuilUp);
      setCovering(shortCovering);
      setUnwinding(longUnwinding);
    }
  }

  const handleExpiryChange = (exp: string) => {
    setBuildUp([]);
    setShortBuildUp([]);
    setCovering([]);
    setUnwinding([]);
    setIsLoading(true)
    setExpiry(exp);
  }

  function handleDateSelect (date:any) {
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    setExpiry(marketMoverExpiry[0].value);
    handleTopicNameChange(instrument, marketMoverExpiry[0].value)
  }

  const handleTopicNameChange = async (productName: string, exp: string) => {
    if (historical && date === "" && productName!=="") {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setBuildUp([]);
    setShortBuildUp([]);
    setCovering([]);
    setUnwinding([]);
    const localIdentifier = `futurespurtz_data_${exp}`;
    setToIdentifier(localIdentifier);
  }
  const fetchDataAtInterval = async () => {
    await liveMsgSpurtzData(topicName, toIdentifier);
};

  React.useEffect(() => {
    if (environment === "test") {
      setTopicName(topicName + "_test");
    }
  }, [environment]);

  React.useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[instrument,expiry, date])

  React.useEffect(() => {
    if (historical) {
      setBuildUp([]);
      setShortBuildUp([]);
      setCovering([]);
      setUnwinding([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (expiry !== '' && date !== '' && toIdentifier !== "") {
        getHistoricSpurt(toIdentifier, topicName);
      }
    } else {
      if (marketStatus === "open" && historical === false) {
        firstSubscription();
         id = setInterval(fetchDataAtInterval, 2 * 1000);
      }else if(marketStatus === "after-open" && !historical){
        firstSubscription();
        fetchDataAtInterval()
      } else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        getHistoricSpurt(toIdentifier, topicName);
      }
    }
    return () => clearInterval(id);
  }, [historical, userId, toIdentifier, date, marketStatus]);

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbSpurtz} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 min-[1071px]:grid-cols-4">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e: string) => {
            if(!historical){
            handleTopicNameChange(e, expiry);
            }else{
              setDate("");
              setExpiry("");
            }
          }}
          placeholder="Please Select Name"
        >
          <SelectItem key={instrument} value={instrument}>
            {instrument}
          </SelectItem>
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox
          title="Expiry:"
          value={expiry}
          onValueChange={(e: string) => {
            handleExpiryChange(e);
            handleTopicNameChange(instrument, e)
          }}
          placeholder="Please Select Expiry"
        >
          {marketMoverExpiry.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectBox>
      
        <div>
          <p className="text-[12px]">Search</p>
          <div className="border dark:border-zinc-600 mt-0.5 shadow">
            <Input
              placeholder="Search"
              className="h-full border-0 px-2 dark:zoom-in-100 bg-white  dark:bg-zinc-900"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="md:grid grid-cols-2 gap-3 lg:gap-4 mt-4">
        <FSTable loading={isLoading} height={buildUp.length > 0 || shortBuilUp.length > 0} searchInput={searchInput} title="Long Build Up" data={buildUp} />
        <FSTable loading={isLoading} height={buildUp.length > 0 || shortBuilUp.length > 0} searchInput={searchInput} title="Short Build Up" data={shortBuilUp} />
        <FSTable loading={isLoading} height={unwinding.length > 0 || covering.length > 0} searchInput={searchInput} title="Short Covering" data={covering} />
        <FSTable loading={isLoading} height={unwinding.length > 0 || covering.length > 0} searchInput={searchInput} title="Long Unwinding" data={unwinding} />
      </div>
    </div>
  );
}