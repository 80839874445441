import { useState, useEffect } from "react";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import useAuthStore from "../../store/auth";
import {Mail, Smartphone, MapPin} from "lucide-react";

export default function UserProfile() {

  const { user } = useAuthStore();

  return (
      <div className="w-full py-8 mx-auto">
        <div className="flex flex-col items-center py-4">
          <Avatar className="w-20 h-20 rounded-full cursor-pointer">
            <AvatarImage src="/assets/avatar.jpg" alt="User avatar" />
            <AvatarFallback>SC</AvatarFallback>
          </Avatar>
          <p className="pt-1 text-xl capitalize font-semibold mt-2 text-gray-900 dark:text-gray-100">
            {user.name}
          </p>
        </div>
        <div className="mt-4 border-b border-gray-200 dark:border-gray-600">
          <nav className="flex justify-center">
            <button className="pb-1 px-2 text-blue-600 border-b-2 border-blue-600 focus:outline-none">
              About
            </button>
          </nav>
        </div>
        <div className="pt-4">
          <div className="pt-2 flex justify-between items-center pr-2">
            <div className="flex flex-1 items-start flex-col">
              <div className="ml-2 flex items-center gap-3">
              <Mail size={15}/>
                <p className="text-gray-600 dark:text-gray-400 capitalize">email</p>
              </div>
              <p className="font-medium text-gray-900 dark:text-gray-100 pl-3">
                  {user.email !== undefined ? user.email :"-"}
                </p>
            </div>
            <div  className="flex flex-1 items-center flex-col">
              <div className="ml-2 flex items-center gap-3">
                <Smartphone size={15}/>
                <p className="text-gray-600 dark:text-gray-400 capitalize">phone</p>
              </div>
              <p className="font-medium text-gray-900 dark:text-gray-100 pl-3">
                  {user.phone !== undefined ? user.phone :"-"}
                </p>
            </div>
            <div  className="flex flex-1 flex-col items-end pr-2">
              <div className="ml-2 flex items-center gap-3">
                <MapPin size={15}/>
                <p className="text-gray-600 dark:text-gray-400 capitalize">Lives in</p>
              </div>
              <p className="font-medium text-gray-900 dark:text-gray-100 capitalize">
                  {(user.city !== null && user.city !== undefined) || (user.state !== null && user.state !== undefined) || (user.street!==null && user.street !== undefined) ? `${user.street} ${user.city} ${user.state}`:"-"}
                </p>
            </div>
          </div>
        </div>
      </div>
    
  );
}
