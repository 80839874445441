import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import BuildUpBadge from "../shared/BuildUpBadge";
import { useEffect, useState, useMemo } from "react";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import { Loader } from "lucide-react";

export type EoTypes = {
  date: string;
  open: string;
  high: string;
  low: string;
  close: string;
  changeInPricePercent: string;
  changeInOiPercent: string;
  totalOi: string;
  buildUps: string;
};

export default function EOTable({ title, rowData, loading, height }: { title?: string, rowData?: any[], loading: boolean, height?: boolean }) {
  const [newRowData, setNewRowData] = useState<any[]>([]);
  const [loader, setLoader] = useState(true);

  const highestHigh = useMemo(() => {
    return Math.max(...newRowData.map((row) => parseFloat(row.high)));
  }, [newRowData]);

  const lowestLow = useMemo(() => {
    return Math.min(...newRowData.map((row) => parseFloat(row.low)));
  }, [newRowData]);

  const columns: any = useMemo(() => [
    {
      accessorKey: "date",
      header: "Date",
    },
    {
      accessorKey: "open",
      header: "Open",
      cell: ({ row }: any) => {
        const open = parseFloat(row.getValue("open"));
        return <p>{formatCurrency(open)}</p>;
      },
    },
    {
      accessorKey: "high",
      header: "High",
      cell: ({ row }: any) => {
        const high = parseFloat(row.getValue("high"));
        return (
          <p className={high === highestHigh ? "bg-[#69B969] py-1" : ""}>
            {formatCurrency(high)}
          </p>
        );
      },
    },
    {
      accessorKey: "low",
      header: "Low",
      cell: ({ row }: any) => {
        const low = parseFloat(row.getValue("low"));
        return (
          <p className={low === lowestLow ? "bg-[#C06E65] py-1" : ""}>
            {formatCurrency(low)}
          </p>
        );
      },
    },
    {
      accessorKey: "close",
      header: "Close",
      cell: ({ row }: any) => {
        const close = parseFloat(row.getValue("close"));
        return <p>{formatCurrency(close)}</p>;
      },
    },
    {
      accessorKey: "changeInPricePercent",
      header: <div>Chng. in Price%</div>,
      cell: ({ row }: any) => {
        const value = parseFloat(row.getValue("changeInPricePercent"));
        return (
          <p className={`${value > 0 ? "text-green-500" : value < 0 ? "text-red-600" : ""}`}>
            {formatDecimal(row.getValue("changeInPricePercent"))}
            {isNaN(value) || row.getValue("changeInPricePercent") === "-" ? "" : "%"}
          </p>
        );
      },
    },
    {
      accessorKey: "changeInOiPercent",
      header: <div>Chng. in OI%</div>,
      cell: ({ row }: any) => {
        const value = parseFloat(row.getValue("changeInOiPercent"));
        return (
          <p className={cn({ "text-green-500": value > 0 }, { "text-red-600": value < 0 })}>
            {formatDecimal(row.getValue("changeInOiPercent"))}
            {isNaN(value) || row.getValue("changeInOiPercent") === "-" ? "" : "%"}
          </p>
        );
      },
    },

    {
      accessorKey: "totalOi",
      header: "Total OI",
      cell: ({ row }: any) => {
        const value = parseFloat(row.getValue("totalOi"));
        return <p className={value > 0 ? "text-green-500" : "text-red-600"}>{formatCurrency(row.getValue("totalOi"))}</p>;
      },
    },

    {
      accessorKey: "buildUps",
      header: () => <div>Build Ups</div>,
      cell: ({ row }: any) => (
        <div className="capitalize text-black dark:text-zinc-900 pr-2">
          <BuildUpBadge text={row.getValue("buildUps").replace("\r", "")} />
        </div>
      ),
    },
  ], [highestHigh, lowestLow]);
  
  const table = useReactTable({
    data: newRowData,
    columns,  
    getCoreRowModel: getCoreRowModel(),
  });  

  useEffect(() => {
    setLoader(loading)
  }, [loading]);

  useEffect(() => {
    setNewRowData(rowData? rowData :[])
  }, [rowData])

  return (
    <>
    <div className="mb-4 w-full">
      <h3 className="dark:bg-zinc-800 border-b-zinc-300  rounded-t-lg border text-center py-3 text-sm border-b font-semibold dark:border-zinc-600 bg-white">{title}</h3>
      <div className={cn("custom-scrollbar border-t-0 rounded-t-none relative w-full overflow-auto rounded-b-lg border-2 dark:border-zinc-600",
          { "h-[380px]": height })}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  <th className="border-b dark:border-zinc-600 border-zinc-200" />
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="min-w-[60px] border-b dark:border-zinc-600 text-black dark:text-white py-3 px-0">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header, 
                            header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
            {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                className={cn(
                  { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },  
                )}
              >
                <td className="pl-1 pr-0.5">{index + 1}.</td>
                {row.getVisibleCells().map((cell) => (
                      <TableCell
                      key={cell.id}
                      className={cn(
                        "py-2 !text-xs px-0"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                  </TableCell>
                ))}
              </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell />
                    <TableCell
                      colSpan={columns.length}
                      className="py-8 text-center"
                    >
                      {loader ? <Loader className="mx-auto animate-spin" /> : "No results."}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
    </div>
  </div>
</>
  );
}
