import { formatIfCurrency } from "../../utils/formatCurrency";

const InnerCard = ({ title, value, className }: { title: string, value: string | number, className?: string }) => {
    return (
        <div className="border px-3 py-2 dark:border-zinc-600 flex items-center justify-between gap-2">
            <span>{title}:</span>
            <span className={className ? className : ""}>{value}</span>
        </div>
    )
}

export default function TFMobileCard({ rows }: { rows: any[] }) {
    return (
        <div>
            {rows.map((item, index) => {
                return (
                    <>
                        {rows.length === 0 ? <p className="text-center py-12">No Results</p> : <div
                            key={index}
                            className="mt-2 grid grid-cols-2 gap-3 rounded-md border p-3 text-[10px] font-medium dark:border-zinc-600"
                        >
                            <InnerCard title="Time" value={item.time} />
                            <InnerCard title="Change In Call Oi" value={formatIfCurrency(item.changeInCallOi)} />

                            <InnerCard title="Diff. In OI"
                                value={formatIfCurrency(item.diffInOi)} className={parseFloat(item.diffInOi) > 0 ? "text-green-500" : "text-red-500"} />

                            <InnerCard title="LTP" value={formatIfCurrency(item.ltp)} />
                            <InnerCard title="Change In Put OI" value={formatIfCurrency(item.changeInPutOi)} />

                            {/* <InnerCard title="Change In Direction" value={formatIfCurrency(item.changeInDirection)} /> */}

                            <InnerCard title="Trend" value={formatIfCurrency(formatIfCurrency(item.trend))} />

                            <InnerCard title="Direction Of Chng %" value={formatIfCurrency(formatIfCurrency(item.directionofchangepercent))} />
                            <InnerCard title="Change In Call Oi" value={formatIfCurrency(item.changeInCallOi)} />

                            <InnerCard title="Diff. In OI"
                                value={formatIfCurrency(item.diffInOi)} className={parseFloat(item.diffInOi) > 0 ? "text-green-500" : "text-red-500"} />

                            <InnerCard title="LTP" value={formatIfCurrency(item.ltp)} />
                            <InnerCard title="Change In Put OI" value={formatIfCurrency(item.changeInPutOi)} />

                            <InnerCard title="Change In Direction" value={formatIfCurrency(item.changeInDirection)} className={parseFloat(item.changeInDirection) > 0 ? "text-green-500" : "text-red-500"} />

                            <InnerCard title="Trend" value={formatIfCurrency(formatIfCurrency(item.trend))} className={item.trend === "Bullish" ? "text-green-500" : "text-red-500"} />

                            <InnerCard title="Direction Of Chng %" value={`${formatIfCurrency(formatIfCurrency(item.directionOfChangePercent))}%`} className={parseFloat(item.directionOfChangePercent) > 0 ? "text-green-500" : "text-red-500"} />
                        </div>}
                    </>

                );
            })}
        </div>
    );
}
