import {
  ColumnDef,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

import { ScrollArea } from "../ui/scroll-area";
import { cn } from "../../lib/utils";
import { Skeleton } from "../ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import formatCurrency from "../../utils/formatCurrency";

export type OsTableData = {
  StockName: string;
  LastTradePrice: number;
  PriceChangePercent: number;
  OIChangePercent: number;
};

const columns: ColumnDef<OsTableData>[] = [
  {
    accessorKey: "StockName",
    header: "Symbol",
  },
  {
    accessorKey: "LastTradePrice",
    header: "LTP",
    cell: ({ row }) => <p>{formatCurrency(row.getValue("LastTradePrice"))}</p>
  },
  {
    accessorKey: "PriceChangePercent",
    header: "LTP Change%",
    cell: ({ row }) => {
      return <p>{formatCurrency(row.getValue("PriceChangePercent"))}
        {row.getValue("PriceChangePercent") && row.getValue("PriceChangePercent") !== "-" ? "%" : ""}</p>
    }
  },
  {
    accessorKey: "OIChangePercent",
    header: "OI Change%",
    cell: ({ row }) => {

      return <p>{formatCurrency(row.getValue("OIChangePercent"))}
        {row.getValue("OIChangePercent") && row.getValue("OIChangePercent") !== "-" ? "%" : ""}
      </p>
    }
  },
];

function DashboardLoader() {
  return (
    <div className="border dark:border-zinc-600 rounded-lg overflow-hidden">
      <div className="h-16 bg-zinc-300 dark:bg-zinc-800 border-b dark:border-zinc-600 " />
      <div className="h-[260px] flex flex-col gap-2 shadow-sm gap-3px-2 w-full">
        <Skeleton className="h-12 rounded-none w-full mt-2" />
        <Skeleton className="h-12 rounded-none w-full" />
        <Skeleton className="h-12 rounded-none w-full" />
        <Skeleton className="h-12 rounded-none w-full" />
        <Skeleton className="h-12 rounded-none w-full" />
      </div>
    </div>
  );
}

function DataTable({ data, loading }: { data: any[], loading: boolean }) {

  return <>{
    loading ? <DashboardLoader /> :
      <TableUi tabledata={data} loading={loading} />
  }
  </>
}

function TableUi({ tabledata, loading }: { tabledata: OsTableData[], loading?: boolean }) {

  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data: tabledata,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      rowSelection,
    },
  });
  return (
    <div className="w-full pb-4">
      <div className="custom-scrollbar relative h-[315px] w-full overflow-x-auto overflow-y-auto rounded-lg border-2 dark:border-zinc-600">
        <Table className="w-full">
          <TableHeader >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="h-4">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="px-4 py-3 text-start text-sm font-semibold text-zinc-800 dark:text-zinc-50 border-b dark:border-zinc-600 border-zinc-200 bg-white dark:bg-zinc-800"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length !== 0 ?
              table.getRowModel().rows.map((row, index) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-zinc-100/50 dark:hover:bg-zinc-800/50",
                      { "bg-zinc-200 dark:bg-zinc-900 ": index % 2 === 0 },
                      { "bg-zinc-50 dark:bg-zinc-800": index % 2 !== 0 }
                    )}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            "px-4 py-3 text-start text-xs sm:text-sm",
                            { "text-red-500": (cell.getValue() as number) < 0 },
                            { "text-green-500": (cell.getValue() as number) > 0 },
                            { "text-zinc-800 dark:text-zinc-50": index === 1 }
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : <TableRow><TableCell colSpan={columns.length} className="text-center">No Results</TableCell></TableRow>}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
export default DataTable;

export function TableLiveData({
  liveData,
  table,
}: {
  liveData: any[];
  table: any;
}) {
  return (
    <ScrollArea className="h-full max-h-[360px] w-full overflow-auto  rounded-md border border-zinc-200 dark:border-zinc-600 lg:max-h-[314px]">
      <table className=" w-full rounded-md">
        <thead className="sticky top-0 w-full border-b bg-white    dark:bg-zinc-800">
          {table
            .getHeaderGroups()
            .map(
              (headerGroup: {
                id: React.Key | null | undefined;
                headers: any[];
              }) => (
                <tr key={headerGroup.id} className="h-4">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        className="px-4 py-3 text-start text-sm font-semibold text-zinc-800 dark:text-zinc-50 sm:py-4 "
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </th>
                    );
                  })}
                </tr>
              )
            )}
        </thead>
        <tbody>
          {liveData?.map((row, index) => {
            return (
              <tr
                key={index}
                // data-state={row.getIsSelected() && "selected"}
                className={cn(
                  "hover:bg-zinc-100/50 dark:hover:bg-zinc-800/50",
                  { "bg-zinc-200 dark:bg-zinc-900 ": index % 2 === 0 },
                  { "bg-zinc-50 dark:bg-zinc-800": index % 2 !== 0 }
                )}
              >
                <td
                  key={0}
                  className={cn(
                    "p-4 text-start text-xs sm:text-sm"
                    // { "text-red-500": (cell.getValue() as number) < 0 },
                    // { "text-green-500": (cell.getValue() as number) > 0 },
                    // { "text-zinc-800 dark:text-zinc-50": index === 1 }
                  )}
                >
                  {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                  {row.split(",")[0]}
                  {/* {index === 2 && "%"}
                  {index === 3 && "%"} */}
                </td>
                <td
                  key={1}
                  className={cn(
                    "p-4 text-start text-xs sm:text-sm",
                    { "text-red-500": (row.split(",")[1] as number) < 0 },
                    { "text-green-500": (row.split(",")[1] as number) > 0 },
                    "text-zinc-800 dark:text-zinc-50"
                  )}
                >
                  {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                  {row.split(",")[1]}
                  {/* {index === 2 && "%"}
                  {index === 3 && "%"} */}
                </td>
                <td
                  key={2}
                  className={cn(
                    "p-4 text-start text-xs sm:text-sm",
                    { "text-red-500": (row.split(",")[2] as number) < 0 },
                    { "text-green-500": (row.split(",")[2] as number) > 0 }
                  )}
                >
                  {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                  {row.split(",")[2]}
                  {"%"}
                </td>
                <td
                  key={3}
                  className={cn(
                    "p-4 text-start text-xs sm:text-sm",
                    { "text-red-500": (row.split(",")[3] as number) < 0 },
                    { "text-green-500": (row.split(",")[3] as number) > 0 }
                  )}
                >
                  {/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                  {row.split(",")[3]}
                  {"%"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ScrollArea>
  );
}
