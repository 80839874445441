import {
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useRef, useState } from "react";
import CollapseButton from "../shared/CollapseButton";
import ColumnSettings from "./ColumnSettings";
import { OptionChainData, columns } from "./TableColumns";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { Loader } from "lucide-react";
import ExpertOptionPopup from "./ExpertOpinionPopup";

interface TableTypes {
  collapsed: boolean;
  handleCollapse: React.ReactEventHandler;
  loading: boolean;
  rowData: OptionChainData[];
  spotPrice?: string;
  setCollapsedField:any;
  productName:string,
  expiry:string,
  refDate?:any
  marketStatus:string,
  historical:boolean
}

export default function OptionChainTable({
  collapsed,
  handleCollapse,
  loading,
  rowData,
  spotPrice,
  setCollapsedField,
  productName,
  expiry,
  refDate,
  marketStatus,
  historical
}: TableTypes) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    callivchange: false,
    thetacall: false,
    thetaput: false,
    oequaltohcall: false,
    oequaltolcall: false,
    oequallput: false,
    oequalhput: false,
    volumecall: false,
    volumeput: false,
    ltppercall: false,
    ltpperput: false,
    todayoichangecall: false,
    todayoichangeput: false,
    oichangepercall: false,
    oichangeperput: false,
    deltacall: false,
    deltaput: false,
    pcrratio: false,
    ltpchangeput: false,
    changeinltpcall: false,
    changeinivput: false,
  });
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStrikePrice, setSelectedStrikePrice] = useState<OptionChainData | null>(null);
  const [spotRow, setSpotRow] = useState<OptionChainData | undefined>(undefined)
  const [spotPriceRowIndex, setSpotPriceRowIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(400);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const table = useReactTable({
    data: rowData === undefined ? [] : rowData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const handleStrikePriceClick = (strikePrice: OptionChainData) => {
    setSelectedStrikePrice(strikePrice);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStrikePrice(null);
  };

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (spotPriceRowIndex !== null && tableWrapperRef.current) {
      const tableRows = tableWrapperRef.current.querySelectorAll("tr");
      const spotRowScrollView = tableRows[spotPriceRowIndex];
      if (spotRowScrollView) {
        spotRowScrollView.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
      }
    }
  }, [spotPriceRowIndex]);

  useEffect(() => {
    if (rowData && rowData.length > 0 && spotPrice) {
      let rowWithSpotPrice: OptionChainData | undefined;
      for (const row of rowData) {
        if (row.strikeprice === spotPrice) {
          rowWithSpotPrice = row;
          break;
        }
      }
      setCollapsedField({
        callAtmDelta:rowWithSpotPrice?.deltacall,
        callAtmTheta:rowWithSpotPrice?.thetacall,
        callAtmIV:rowWithSpotPrice?.calliv,
        putAtmIV:rowWithSpotPrice?.putiv,
        putAtmDelta:rowWithSpotPrice?.deltaput,
        putAtmTheta:rowWithSpotPrice?.thetaput
      })
      setSpotRow(prev => {
        if (prev?.strikeprice !== rowWithSpotPrice?.strikeprice) {
          return rowWithSpotPrice
        } else {
          return prev
        }
      });
      const rowIndex = rowData.findIndex(
        (row) => row.strikeprice === spotPrice
      );
      setSpotPriceRowIndex(rowIndex !== -1 ? rowIndex : null);
    } else {
      setSpotPriceRowIndex(null);
    }

  }, [spotPrice, rowData]);
  const calculateTableHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const topBarHeight = document.querySelector("header")?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      let offset:number;
      if(collapsed){
         offset = containerHeight + topBarHeight + 130;
      }else{
         offset = containerHeight + topBarHeight + 236;
      }
      if(windowHeight-offset > 400){
        setTableHeight(windowHeight - offset);
      }else{
        setTableHeight(400)
      }
    }
  }
  useEffect(() => {
    calculateTableHeight(); 
    window.addEventListener("resize", calculateTableHeight);
    return () => window.removeEventListener("resize", calculateTableHeight);
  }, [collapsed, containerRef, handleCollapse]);

  return (
    <>
    <div className="w-full" >
      <div className="flex items-center justify-between" ref={containerRef}>
        <CollapseButton handleCollapse={handleCollapse} collapsed={collapsed} />
        <ColumnSettings table={table} />
      </div>
      <div
        className="custom-scrollbar table-wrapper"
        ref={tableWrapperRef}
        style={{maxHeight: `${tableHeight}px`}}
      >
        <table
          style={{ borderCollapse: "separate", borderSpacing: "0" }}
          className="w-full"
        >
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn(
                        "min-w-[100px] bg-zinc-200 px-2 py-2 text-center text-[12px] font-semibold text-zinc-800 dark:border-zinc-600 dark:bg-zinc-900 dark:text-zinc-50",
                        {
                          "strike-price": header.column.id === "strikeprice",
                        },
                        {
                          "table-heading-border": ["CALLS", "PUTS"].includes(
                            header.column.id
                          ),
                        },
                        {
                          "table-heading-border":
                            header.column.id.includes("pcrratio") ||
                            header.column.id.includes("put") ||
                            header.column.id.includes("call"),
                        }
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!loader ? (
              table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                       const isStrikePriceColumn = cell.column.id === "strikeprice";
                      return (
                        <TableCell
                          key={index}
                          style={{ cursor: isStrikePriceColumn ? 'pointer' : 'auto' }}
                          className={cn("border-b !text-xs py-2 border-zinc-300 dark:border-b-zinc-600",
                            { "strike-price border-none": cell.column.id === "strikeprice" },
                            { "strike-price border-none ": row?.getVisibleCells().some(cell => cell.column.id === "strikeprice" && cell.getValue() === spotPrice?.toString()) },
                            { "highlight-cell": row.index < rowData.findIndex(row => row.strikeprice === (spotRow as OptionChainData)?.strikeprice) && cell.column.id.includes("call") && !cell.column.id.includes("pcrratio") },
                            { "highlight-cell": row.index > rowData.findIndex(row => row.strikeprice === (spotRow as OptionChainData)?.strikeprice) && cell.column.id.includes("put") },
                            { "highlight-cell": row.index > rowData.findIndex(row => row.strikeprice === (spotRow as OptionChainData)?.strikeprice) && cell.column.id.includes("pcrratio") },
                          )}
                          onClick={isStrikePriceColumn ? () => handleStrikePriceClick(row.original) : undefined}
                        >
                          <span className="relative z-10 block">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length / 0.1}
                    className="h-24 text-center"
                  >
                    No Results
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length / 0.2}
                  className="h-24 text-center"
                >
                  <Loader className="animate-spin mx-auto" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </table>
      </div>
    </div >
    {isModalOpen && selectedStrikePrice && (
      <ExpertOptionPopup
        isOpen={isModalOpen}
        onClose={closeModal}
        strikePrice={selectedStrikePrice.strikeprice || ""}
        productName={productName}
        expiry={expiry}
        refDate={refDate}
        marketStatus={marketStatus}
        historical={historical}
      />
    )}
    </>
  );
}