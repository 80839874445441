import React from 'react';
import Plans from '../../components/plans/Plans'
import { useParams } from 'react-router-dom';
import PaymentCheckStatus from '../../components/plans/checkStatus';
import Footer from '../../components/landing-page/Footer';
import Header from '../../components/landing-page/Header';
import AppLayout from '../../layout/AppLayout';
import { useGetFromStore } from '../../hooks/useGetFromStore';
import useAuthStore from '../../store/auth';

export default function PlansPage() {
    const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
    const { loading } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(loading === "true");
    }, [loading]);

    return (
        <>
            {!isLoggedIn &&
                <>
                    <Header />
                    <div className='h-24' />
                     <Plans />
                     <Footer />
                </>
            }
            {isLoggedIn &&
                <AppLayout>
                    {
                        isLoading ? <PaymentCheckStatus /> : <Plans />
                    }
                </AppLayout>
            }
        </>
    )
}