import React from "react";
import Topbar from "../components/shared/Topbar";
import { useGetFromStore } from "../hooks/useGetFromStore";
import useAuthStore from "../store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import useScreensDetails from "../store/screensDetails";


export default function AppLayout({ children }: { children: React.ReactNode }) {
    const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
    const navigate = useNavigate()
    const { screens } = useScreensDetails();
    const location = useLocation()
    if (isLoggedIn === false) { 
        navigate("/login"); 
    }
    React.useEffect(() =>{
        if(screens.length>0){
            if (!screens.includes(location.pathname)) { 
                navigate("/features/plans") 
            }
            }else{
                navigate("/dashboard") 
            }
    },[screens])
    return (
        <div>
            <Topbar />
            <div className="px-2">{children}</div>
        </div>
    );
}

