import formatCurrency from "../../utils/formatCurrency";
import BuildUpBadge from "../shared/BuildUpBadge";

const innerCardStyles =
  "border px-3 py-1 dark:border-zinc-600 flex items-center justify-between gap-2";
export function OpenInterestMobileTable(props: { rows: any[] }) {
  return (
    <div>
      {props.rows.length === 0 ? <p className="py-12 text-center">No Results</p> : props.rows.map((item, index) => {
        return (
          <div
            key={index}
            className="mt-2 grid grid-cols-2 gap-3 rounded-md border p-3 text-[10px] font-medium dark:border-zinc-600 sm:hidden "
          >
            <div className="flex items-center justify-between gap-2 border px-3 py-1.5 dark:border-zinc-600">
              <p>Time: </p>
              <p className="text-end">{item.time}</p>
            </div>

            <div className={innerCardStyles}>
              <p>OI-Change:</p>
              <p>
                {item.buildup === ""
                  ? "-"
                  : formatCurrency(item.currentoichange)}
              </p>
            </div>
            <div className={innerCardStyles}>
              <p>Change In LTP:</p>
              <p>
                {item.buildup === ""
                  ? "-"
                  : formatCurrency(item.changeinltp)}
              </p>
            </div>

            <div className={innerCardStyles}>
              <p>Change In OI: </p>
              <p>
                {item.buildup === ""
                  ? "-"
                  : formatCurrency(item.todaysoichange)}
              </p>
            </div>
            <div className={innerCardStyles}>
              <p>Volume: </p>
              <p>{item.buildup === "" ? "-" : formatCurrency(item.volume)}</p>
            </div>
            <div className={innerCardStyles}>
              <p>Total OI: </p>
              <p>{item.buildup === "" ? "-" : formatCurrency(item.totaloi)}</p>
            </div>
            <div className="col-span-2 text-center">
              <BuildUpBadge text={item.buildup.trim()} />
            </div>
            <div className="col-span-2 flex  items-center justify-center text-center  text-sm">
              <p>LTP: </p>
              <p>
                {item.buildup === ""
                  ? "-"
                  : formatCurrency(item.ltp)}
              </p>
            </div>
          </div>

        );
      })}
    </div>
  );
}

