export const breadcrumbTrendFinder = [
    { label: "Dashboard", link: "/dashboard" },
    { label: "Options", link: "/dashboard" },
    { label: "Trend Finder", link: "/options/trend-finder" },
  ];

  export const breadcrumbAdvancedTF = [
    { label: "Dashboard", link: "/dashboard" },
    { label: "Options", link: "/dashboard" },
    { label: "Advanced Trend Finder", link: "/options/advanced-trend-finder" },
  ];
  export type TrendfinderLiveMessageType = {
    ltp: string;
    calloi: string;
    putoi: string;
    oidiff: string;
    changeindir: string;
    changeindirper: string;
    trend: string;
    dayHighLow:string
  };

  export type TrendFinderDataType = {
    time: string;
    ltp: string;
    dayHighLow: string;
    changeInCallOi: string;
    changeInPutOi: string;
    diffInOi: string;
    directionOfChange: string;
    changeInDirection: string;
    directionOfChangePercent: string;
    DayHighLowDiffInOI: string;
    trend: string;
  };

  