export const buzzBreadcrumb = [
    { label: "Dashboard", link: "/dashboard" },
    { label: "Futures", link: "/dashboard" },
    { label: "OI Buzz", link: "/futures/buzz" },
  ];

  export type BuzzLiveMessageType = {
    name:string;
    oi: string;
    change: string;
    open: string;
    high: string;
    low: string;
    ltp: string;
  };

  export const sampleRowData = [
    {
        oi: 'TATA',
        change: '1.5', 
        open: '100',
        high: '110',
        low: '95',
        ltp: '105'
    },
    {
        oi: 'JIO',
        change: '-0.5',
        open: '200',
        high: '205',
        low: '195',
        ltp: '198'
    },
    {
        oi: 'ADANI',
        change: '3.2',
        open: '300',
        high: '310',
        low: '295',
        ltp: '305'
    },
    {
        oi: 'RELAINCE',
        change: '-1.8',
        open: '400',
        high: '405',
        low: '390',
        ltp: '398'
    },
    {
        oi: 'PAYTM',
        change: '4.7',
        open: '500',
        high: '510',
        low: '495',
        ltp: '505'
    },
    {
        oi: 'BHARATPE',
        change: '2.1',
        open: '600',
        high: '610',
        low: '595',
        ltp: '605'
    },
    {
        oi: 'PHONEPE',
        change: '7.3',
        open: '700',
        high: '710',
        low: '695',
        ltp: '705'
    },
    {
        oi: 'TCS',
        change: '3.9',
        open: '800',
        high: '810',
        low: '795',
        ltp: '805'
    },
    {
        oi: 'WIPRO',
        change: '-2.6',
        open: '900',
        high: '905',
        low: '890',
        ltp: '898'
    },
    {
        oi: 'DUKAAN',
        change: '6.5',
        open: '1000',
        high: '1010',
        low: '995',
        ltp: '1005'
    },
    {
        oi: 'ZOMATO',
        change: '-6.9',
        open: '1100',
        high: '1110',
        low: '1095',
        ltp: '1098'
    },
    {
        oi: 'SWIGGY',
        change: '1.8',
        open: '1200',
        high: '1210',
        low: '1195',
        ltp: '1205'
    },
    {
        oi: 'TAKILA',
        change: '-2.3',
        open: '1300',
        high: '1310',
        low: '1295',
        ltp: '1298'
    }
  ];