import { Link, useLocation } from "react-router-dom";
import { Icons } from "../../utils/icons";

interface BreadcrumbItem {
  label: string;
  link: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const pathname = useLocation().pathname;
  return (
    <div className="300 inline-block rounded-md py-1 pr-4 text-[12px] font-medium capitalize md:px-4 dark:md:bg-zinc-800">
      {items.map((item, index) => (
        <span key={index}>
          {index > 0 && (
            <span className="mx-1 mt-1 text-zinc-800 dark:text-zinc-400">
              <Icons.chevronRight className="inline-block h-4 w-4" />
            </span>
          )}
          {pathname !== item.link ? (
            <Link to={item.link}>
              <span className="min-w-max text-zinc-800  dark:text-zinc-400 ">
                {item.label}
              </span>
            </Link>
          ) : (
            <span className="min-w-max text-green-500  inline-block">{item.label}</span>
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
