export const RefreshRateData = [
  {
    name: "2 sec",
    value: "2 sec",
  },

  {
    name: "15 sec",
    value: "15 sec",
  },
  {
    name: "30 sec",
    value: "30 sec",
  },
  {
    name: "1 min",
    value: "1 min",
  },
  {
    name: "2 min",
    value: "2 min",
  },
  {
    name: "5 min",
    value: "5 min",
  },
];

export const TimeIntervalData = [
  {
    name: "3 min",
    value: "3Min",
  },
  {
    name: "5 min",
    value: "5Min",
  },
  {
    name: "10 min",
    value: "10Min",
  },
  {
    name: "15 min",
    value: "15Min",
  },
  {
    name: "30 min",
    value: "30Min",
  },
  {
    name: "60 min",
    value: "60Min",
  },
];
