export const navigationPremium = [
    { label: "Dashboard", link: "/dashboard" },
    { label: "options", link: "/dashboard" },
    { label: "Premium", link: "/options/premium" },
  ];

  export const nearStrikesPremium = [
    { name: "Near ATM 10 Strikes", value: "10" },
    { name: "Near ATM 15 Strikes", value: "15" },
    { name: "Near ATM 25 Strikes", value: "25" },
    { name: "All Strikes", value: "all" }
]