import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import { useEffect, useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { OptionChainSpotCard } from "./OptionChainSpot";
import OptionChainTable from "./OptionChainTable";
import "./optionschain.css";
import { RefreshRateData, TimeIntervalData } from "../../constants/TimeInterval";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import { toast } from "sonner";
import { InstrumentExpiryStrikePrice, getOptionChainData } from "../../api/options";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import convertToYYYYMMDD, { findNearestDate, findNearestStrikePrice, formatDate } from "../shared/dateConverter";
import { dateSort } from "../../utils/datesort";
import { configEnvironemt } from "../../config";
import { getIndexSubscription } from "../../api/futures/open-interest";
import useSpotPriceStore from "../../store/spotPrice";
import { PopoverClose } from "@radix-ui/react-popover";
import { breadcrumbOptionChain } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { baseURL } from "../../api/base";

 export default function OptionChainPopulateData({ optionInstrumentList }: { optionInstrumentList: InstrumentExpiryStrikePrice[] }) {
  const expiryDates = optionInstrumentList[0].ExpiryStrikePriceList.map(
    (exp) => exp.Expiry
  );
  const initialDate = findNearestDate(expiryDates);
  const environment = configEnvironemt.environment;
  const [date, setDate] = useState<any>(new Date());
  const [timeInterval, setTimeInterval] = useState(TimeIntervalData[1].value);
  const [historical, setHistorical] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [instrument, setInstrument] = useState(optionInstrumentList[0].ProductName);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value)
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `OPTIONCHAIN_OPTIDX_${optionInstrumentList[0].ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [toIdentifier, setToIdentifier] = useState(
    `optionchain_OPTIDX_${optionInstrumentList[0].ProductName}_${expiry}_${timeInterval}`
  );
  const [rows, setRows] = useState<any[]>([]);
  const [lastRow, setLastRow] = useState<any | []>([]);
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [tableSpot, setTableSpot] = useState<string>("");
  const [collapseField, setCollapsedField] = useState({
    callAtmDelta:"",
    callAtmTheta:"",
    callAtmIV:"",
    putAtmIV:"",
    putAtmDelta:"",
    putAtmTheta:""
  });
  const { spotPrices } = useSpotPriceStore();
  const marketStatus = useMarketStatus();
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  let id:any;

  const handleSwitch = () => {
    setRows([]);
    setLastRow([]);
    setCollapsedField({
      callAtmDelta:"",
      callAtmTheta:"",
      callAtmIV:"",
      putAtmIV:"",
      putAtmDelta:"",
      putAtmTheta:""
    })
        // historic to live
        if (historical) {
          setIsLoading(true);
          setHistorical(false);
          toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
          setDate(new Date())
          setInstrument(optionInstrumentList[0]?.ProductName);
        }
        // Live to historic
        if (!historical) {
          clearInterval(id);
          setHistorical(true);
          setIsLoading(false);
          toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
          setDate("")
          setInstrument("")
          setExpiry("")
        }
  };

  const handleCollapse = () => setCollapsed((collapse) => !collapse);

  function processMessage(messages: string[], historicSpot?:string) {
    const upRows = messages.map((item) => ({
      buildupcall: item.split(",")[0],
      totaloicall: item.split(",")[1],
      todayoichangecall: item.split(",")[2],
      oichangecall: item.split(",")[3],
      oichangepercall: item.split(",")[4],
      premiumleftincall: item.split(",")[5],
      calliv: isNaN(parseFloat(item.split(",")[6])) ? '-' : item.split(",")[6],
      callivchange: isNaN(parseFloat(item.split(",")[7])) ? '-' : item.split(",")[7],
      ltppercall: item.split(",")[8],
      changeinltpcall: item.split(",")[9],
      ltpcall: item.split(",")[10],
      volumecall: item.split(",")[11],
      oequaltohcall: item.split(",")[12],
      oequaltolcall: item.split(",")[13],
      deltacall: item.split(",")[14],
      thetacall: item.split(",")[15],
      strikeprice: item.split(",")[16],
      ltpput: item.split(",")[17],
      ltpchangeput: item.split(",")[18],
      ltpperput: item.split(",")[19],
      putiv: item.split(",")[20],
      premiumleftinput: item.split(",")[21],
      changeinivput: item.split(",")[22],
      changeinoiput: item.split(",")[23],
      todayoichangeput: item.split(",")[24],
      changeinltpput: item.split(",")[25],
      oichangeperput: item.split(",")[26],
      totaloiput: item.split(",")[27],
      buildupput: item.split(",")[28],
      volumeput: item.split(",")[29],
      oequallput: item.split(",")[30],
      oequalhput: item.split(",")[31],
      deltaput: item.split(",")[32],
      thetaput: item.split(",")[33],
      pcrratio: isNaN(parseFloat(item.split(",")[34])) ? '-' : item.split(",")[34],
      currentFuturePrice: item.split(",")[35],
      spotPrice: (marketStatus === "open" || marketStatus === "after-open") && !historical ? item.split(",")[36] : historicSpot,
    }));
    useSpotPriceStore.getState().setSpotPrice(instrument, parseFloat(messages[messages.length - 1].split(',')[36]));
    const strikePriceListArr = optionInstrumentList.find((item) => item.ProductName=== instrument)?.ExpiryStrikePriceList[0].StrikePrice;
    if(strikePriceListArr && historical === false && marketStatus === "open"){
     findNearestStrikePrice(messages[messages.length - 1].split(',')[36], strikePriceListArr)
     .then((data) =>{
      setTableSpot(data);
     });
    }
    const sortedRow = upRows.filter((item) => item.strikeprice !== "" && item.strikeprice !== undefined).sort((a, b) => parseInt(b.strikeprice) - parseInt(a.strikeprice));
    setIsLoading(false);
    return sortedRow;
  }

  async function liveMsgOptionChain(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const fRows = processMessage(messages);
    const reversedRow = fRows.reverse();
    const filteredRowData = reversedRow.filter(row => row?.ltpcall !== "0" && row?.ltpput !== "0");
    setRows(filteredRowData);
    setLastRow(reversedRow[0]);
    }
  }

  async function getHistoricOptionChainData(identifier: string, topicName:string, historicSpot?:string, refDate?:any) {
      let response;
      if (historical && identifier !== undefined && date !== '') {
       response = await getOptionChainData(identifier,  topicName, !refDate? date : refDate);
      }else {
        response = await getOptionChainData(identifier, topicName);
      }
      if (response != null && response.length > 0) {
        const messages = response.split("\n") as string[];
        const fRows = processMessage(messages, historicSpot);
        const reversedRow = fRows.reverse();
        const filteredRowData = reversedRow.filter(row => row?.ltpcall !== "0" && row?.ltpput !== "0");
        setRows(filteredRowData);
        setLastRow(reversedRow[0]);
    }
  }

  function handleProductChange(productName: string) {
    setRows([]);
    setLastRow([]);
    setInstrument(productName);
    if(!historical){
      getExpiryList(productName, optionInstrumentList);
    }
    if(historical){
      setDate("")
      setExpiry("")
    }
  }

  function handleExpiryChange(expiry: string) {
    setExpiry(expiry);
    handleIdentifierChange(instrument, expiry, timeInterval)
  }

  function handleDateSelect (date:any) {
    setRows([]);
    setLastRow([]);
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }
  
  async function handleIdentifierChange(
    product: string,
    expiry: string,
    timeInterval: string,
    historicFilteredList?:any,
    refDate?:any
  ) {
    if(historical && date === ""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    setRows([]);
    setLastRow([]);
    setCollapsedField({
      callAtmDelta:"",
      callAtmTheta:"",
      callAtmIV:"",
      putAtmIV:"",
      putAtmDelta:"",
      putAtmTheta:""
    })
    let strikePriceList: string[] = [];
    let nearestStrikePrice:any;
    if(!historical){
      const nearSpotPrice = spotPrices[product] ? spotPrices[product] : optionInstrumentList.find((item) => item.ProductName === product)?.SpotPrice;
    const filteredList = optionInstrumentList.filter(
      (item) => item.ProductName === product
    );
    const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
    const filterExpiryList = expiryStrikePriceData.filter((item) => item.Expiry === expiry);
    strikePriceList = filterExpiryList[0].StrikePrice;
     nearestStrikePrice = await findNearestStrikePrice(nearSpotPrice, strikePriceList, product);
    }else{
      const filteredList = historicFilteredList?.filter((item:any) => item.ProductName === product)
      if(filteredList && filteredList.length > 0){
        const expiryData = filteredList[0].ExpiryStrikePriceList.filter((exp:any) => exp.Expiry === expiry);
       if(expiryData && expiryData.length>0){
        strikePriceList = expiryData[0].StrikePrice;
       }
        const historicSpot = filteredList[0].SpotPrice;
        nearestStrikePrice = await findNearestStrikePrice(historicSpot, strikePriceList);
      }
    }
    setTableSpot(nearestStrikePrice);
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
      (idx) => idx === product
    );
    let topicNameLocal = `OPTIONCHAIN_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}`;
    let localToIdentifier = `optionchain_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}_${expiry}_${timeInterval}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    if(!historical){
      if (marketStatus === "open" || marketStatus === "after-open") {
         await getIndexSubscription(
          topicNameLocal,
          userId === undefined ? "" : userId,
          localToIdentifier
        );
      }else if(marketStatus!=="loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
        getHistoricOptionChainData(localToIdentifier, topicNameLocal, nearestStrikePrice);
      }
    }else if(historical &&  refDate!=="" && refDate !== date && toIdentifier  === localToIdentifier){
      getHistoricOptionChainData(localToIdentifier, topicNameLocal, nearestStrikePrice, refDate);
    }
    setToIdentifier(localToIdentifier);
  }

  function getExpiryList(
    productName: string,
    indexdataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    const expiryList: string[] = [];
    let nearestDate: any;
    if (indexdataList != null && indexdataList.length > 0) {
      let filteredList = indexdataList.filter(
        (product) => product.ProductName === productName
      );
      const expirystrikePricedata = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expirystrikePricedata.map((exp) => exp.Expiry);
      nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== undefined && nearestDate !== "") {
        setExpiry(nearestDate);
      }
      expirystrikePricedata.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
      const sortedDate = dateSort(expiryList)
      setExpiryList(sortedDate);
      handleIdentifierChange(productName, nearestDate, timeInterval, filteredList, refDate);
    }
  }

  async function Init() {
    if (optionInstrumentList != null && optionInstrumentList.length > 0) {
      const initialInstrument = instrument ? instrument : optionInstrumentList[0].ProductName
      getExpiryList(
        initialInstrument,
        optionInstrumentList
     );
   }
  }
  const fetchDataAtInterval = async () => {
    await liveMsgOptionChain(topicName, toIdentifier);
};

useEffect(()=>{
  setTimeout(()=>{
    setIsLoading(false)},12000)
},[instrument,expiry, date])

  useEffect(() => {
    if (historical) {
      setRows([]);
      setLastRow([]);
      setCollapsedField({
        callAtmDelta:"",
        callAtmTheta:"",
        callAtmIV:"",
        putAtmIV:"",
        putAtmDelta:"",
        putAtmTheta:""
      })
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (date !== "" && instrument !== "" && toIdentifier !== undefined) {
        getHistoricOptionChainData(toIdentifier, topicName);
      }
    } 
  }, [historical,toIdentifier]);

  useEffect(() => {
    if(!historical){
      Init();
      }
  }, [historical, userId, marketStatus]);

  useEffect(()=>{
    if(!historical && marketStatus === "open"){
    fetchDataAtInterval()
    }
  },[toIdentifier, marketStatus])

  useEffect(() => {
    let timeoutId:any;
    if(!historical && marketStatus === "open"){
      const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            }  
          id = setInterval(fetchDataAtInterval, seconds * 1000);
        }
      }else if(marketStatus === "after-open" && !historical){
        timeoutId = setTimeout(fetchDataAtInterval, 1000)
      }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toIdentifier, historical, marketStatus])

  if (isLoggedIn === false) {
    navigate("/login");
  }

  if (userId === undefined) return <></>;

  return (
    <div className="mx-auto max-w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbOptionChain} />
        <LiveSwitch
          handleSwitch={handleSwitch}
        />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-[22px] sm:grid-cols-3 min-[1072px]:grid-cols-5">
        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Instrument:</p>
          <Select
            value={instrument}
            onValueChange={(e) => {
              handleProductChange(e);
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Name" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
                <SelectLabel className="-ml-3">Index</SelectLabel>
                {optionInstrumentList && optionInstrumentList.slice(0, 4).map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.ProductName}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
                <SelectLabel className="-ml-3">Stocks</SelectLabel>
                {optionInstrumentList && optionInstrumentList.slice(5, optionInstrumentList.length).map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.ProductName}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Expiry:</p>
          <Select
            value={expiry}
            onValueChange={(e) => {
              handleExpiryChange(e);
            }
            }
          >
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Expiry" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {expiryList &&
                  expiryList.map((item, index) => {
                    const date = formatDate(item);
                    return (
                      <SelectItem key={index} value={`${item}`}>
                        {date}
                      </SelectItem>
                    );
                  })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

       

        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Time Interval:</p>
          <Select
            value={timeInterval}
            onValueChange={(e) => {
              setTimeInterval(e);
              handleIdentifierChange(instrument, expiry, e)
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="5 min" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {TimeIntervalData.map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.value}`}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Refresh Rate:</p>
          <Select value={refreshRate} 
          onValueChange={(e)=> {
            setRefreshRate(e)
          }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="5 min" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {RefreshRateData.map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.value}`}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className={cn({ hidden: collapsed }, "mt-[12px]")}>
        <OptionChainSpotCard row={lastRow} collapseField={collapseField}/>
      </div>
        <OptionChainTable
          loading={isLoading}
          collapsed={collapsed}
          handleCollapse={handleCollapse}
          rowData={rows}
          spotPrice={tableSpot}
          setCollapsedField={setCollapsedField}
          productName={instrument}
          expiry={expiry}
          refDate={date}
          marketStatus={marketStatus}
          historical={historical}
        />
    </div>
  );
}