import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { Link } from "react-router-dom";
import { Icons } from "../../utils/icons";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Loader, Triangle, TriangleIcon, } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import browserName from "../shared/BrowserName";

let browser = browserName();
export type SpurtzTypes = {
  name: string;
  ltp: string;
  prevClose: string;
  ltpChangePercent: string;
  oiChangePercent: string;
  newOi: string;
  oldOi: string;
  oiChange: string;
  chart: ReactNode;
};

const SortingUi = ({ column, title, className }: { column: any, title: string, className?: string }) => (
  <div className={cn("flex items-center min-w-max text-center justify-center mx-auto gap-1 cursor-pointer", className)} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
    <span>{title}</span>
    <div>
      <Triangle className={cn("h-1.5 w-1.5 text-zinc-600 fill-zinc-600 dark:fill-[#d9d9d9]", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "desc" })} />
      <Triangle className={cn("h-1.5 w-1.5 text-zinc-600 fill-zinc-600 dark:fill-[#d9d9d9] rotate-180", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "asc" })}
      />
    </div>
  </div>)
const columns: any = [
  {
    accessorKey: "name",
    header: () => <div className="pl-2 text-left">Name</div>  
  },
  {
    accessorKey: "chart",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="" />,
    cell: ({ row }: any) => <Link to={row.getValue("chart")} className="w-5 mx-auto block">
      <Icons.spurtzChart className="dark:invert" />
    </Link>
  },

  {
    accessorKey: "ltp",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="LTP" />,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("ltp"));
      return <p>{formatCurrency(value)}</p>
    }
  },
  {
    accessorKey: "prevClose",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Prev. Close" />,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("prevClose"));
      return <p>{formatCurrency(value)}</p>
    }
  },
  {
    accessorKey: "changeInLtpPer",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="LTP Chng.%" />,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("changeInLtpPer"));
      return <p className={`${value > 0 && "text-green-500"} ${value < 0 && "text-red-500"}`}>
        {formatDecimal(value)} {value && row.getValue("changeInLtpPer") !== "-" ? "%" : ""}
      </p>
    }
  },
  {
    accessorKey: "changeInOiPer",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="OI Chng.%" />,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("changeInOiPer"));
      return <p className={`${value > 0 && "text-green-500"} ${value < 0 && "text-red-500"}`}>
        {formatDecimal(value)}
        {value && row.getValue("changeInOiPer") !== "-" ? "%" : ""}
      </p>
    }
  },
  {
    accessorKey: "newOi",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="New OI" />,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("newOi"));
      return <p >{value ? formatCurrency(value) : "-"}</p>
    }
  },
  {
    accessorKey: "oldOi",
    header: () => <div className="min-w-max">Old OI</div>,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("oldOi"));
      return <p >{value ? formatCurrency(value) : "-"}</p>
    }
  },
  {
    accessorKey: "oiChange",
    header: () => <div className="min-w-max">OI Chng.</div>,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("oiChange"));
      return <p className={`${value > 0 && "text-green-500"} ${value < 0 && "text-red-500"}`}>
        {formatCurrency(value)}
      </p>
    }
  },
];

export default function FSTable({ title, searchInput, loading, data, height }: { title?: string, searchInput?: string, loading: boolean, data: any[], height?: boolean }) {
  const [filtering, setFiltering] = useState(searchInput)
  const memoizeColumns = useMemo(() => columns, [])

  const table = useReactTable({
    data: data,
    columns: memoizeColumns,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { globalFilter: filtering },
    onGlobalFilterChange: setFiltering,
  });

  const [loader, setLoader] = useState(true)
  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
    setFiltering(searchInput)
  }, [searchInput])

  return (
    <>
      {<div className="mb-4 w-full">
        <h3 className="dark:bg-zinc-800  rounded-t-lg border text-center py-3 text-sm border-b font-semibold dark:border-zinc-600 bg-white">
          {title}
        </h3>
        <div className={cn(browser==="apple safari"?"twh custom-scrollbar border-t-0 whitespace-nowrap relative  border-b-0 w-full overflow-auto border-2 dark:border-zinc-600":"twh custom-scrollbar border-t-0 relative  border-b-0 w-full overflow-auto border-2 dark:border-zinc-600",
          { "h-[450px]": height })}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className=" dark:bg-zinc-800 bg-white" >
                  <TableHead className="border-b bg-white dark:bg-zinc-800 text-black dark:text-white">
                  </TableHead>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="border-b bg-white dark:bg-zinc-800 text-black dark:text-white py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                  >
                    <td className={cn({ "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 }, "pl-2")} >{index + 1}.</td>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                            { "text-start pl-1": cell.column.id === "name" },"!text-xs"
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {loader ? <Loader className="animate-spin mx-auto" /> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1 text-zinc-400">
          <div className="flex pl-4 items-center justify-between w-full ">
            <div className="flex items-center ">
              <p className="text-sm font-medium">Rows per page</p>
              <Select
                value={`${table.getState().pagination.pageSize}`}
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
              >
                <SelectTrigger className="ml-1 h-8 w-[64px] dark:bg-zinc-800 bg-zinc-50 border-none">
                  <SelectValue placeholder={table.getState().pagination.pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                  {[10, 20, 30, 40].map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => table.previousPage()}
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                disabled={!table.getCanPreviousPage()}
              >
                <TriangleIcon className="w-3 text-zinc-500 -rotate-90 fill-zinc-500 hover:text-fill-600 hover:text-zinc-600" />
                Prev
              </button>
              <div className="flex w-[50px] items-center justify-center text-sm font-medium">
                {table.getPageCount() === 0 ? 0 : table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </div>
              <button
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                Next
                <TriangleIcon className="w-3 rotate-90 fill-zinc-500 text-zinc-500 hover:fill-zinc-600 hover:text-zinc-600" />
              </button>
            </div>
          </div>
        </div>
      </div>}
    </>

  );
}
