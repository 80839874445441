// import { useQuery } from "react-query";
import ImpliedVolatility from "../../../components/implied-volatility/ImpliedVolatility";
import AppLayout from "../../../layout/AppLayout";
// import { getInstrumentExpiryStrikePrice } from "../../../api/options";
import { Icons } from "../../../utils/icons";
import useInstrumentStrikePrice from "../../../store/instrumentExpiryStrikePrice";
import { useEffect, useState } from "react";

export default function ImpliedVolatilityPage() {
  // const { isLoading, data: optionImplied } = useQuery({
  //   queryKey: ["optionImpliedInstrument-list"],
  //   queryFn: () => getInstrumentExpiryStrikePrice(),
  // });

  // const { GetInstrumentPrice, bears } = useInstrumentStrikePrice();
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (bears === null) {
  //     setLoading(true);
  //     GetInstrumentPrice();
  //     setLoading(false);
  //   }
  // }, [bears]);
  return (
    <AppLayout>
      {/* {loading ? (
        <div className="flex h-[calc(100vh-90px)] items-center justify-center">
          <Icons.spinner
            className="mr-2 h-12 w-12 animate-spin"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>{bears && <ImpliedVolatility optionImplied={bears} />}</>
      )} */}
       <ImpliedVolatility/>
    </AppLayout>
  );
}
