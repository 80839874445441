import { HasError } from "./ErrorPage";
import { ReactNode, ErrorInfo, Component } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode; 
}

interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends Component<ErrorBoundaryProps>{
  constructor(props: ErrorBoundaryProps) {
  super(props);
  this.state = { hasError: false };
  }
  
  static getDerivedStateFromError(error: any): ErrorBoundaryState {
  return { hasError: true };
  }
  
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  console.log(error, errorInfo);
  }
  
  render(): ReactNode {
  if ((this.state as ErrorBoundaryState).hasError) {
  return <HasError />;
  }
  return this.props.children;
  }
  }
  
  export default ErrorBoundary;

// interface ErrorBoundaryState {
//   hasError: boolean;
// }
// class ErrorBoundary extends Component<ErrorBoundaryProps>{
//   constructor(props: ErrorBoundaryProps) {
//   super(props);
//   this.state = { hasError: false };
//   }
  
//   static getDerivedStateFromError(error: any): null {
//     const setError = useErrorStore().setError;
//     setError(error)
//   return null;
//   }
  
//   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//   console.log(error, errorInfo);
//   }
  
//   render(): ReactNode {
//     const errorStore = useErrorStore();
//   if (errorStore.hasError) {
//   return <HasError />;
//   }
//   return this.props.children;
//   }
//   }
  
//   export default ErrorBoundary;

// const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
//   const errorStore = useErrorStore();

//   React.useEffect(() => {
//     const setError = errorStore.setError; 

//     // window.onerror = (error: ErrorEvent) => {
//     //   if (typeof error.error === 'object' && error.error !== null) {
//     //     setError(error.error);
//     //   } else {
//     //     console.error('Unknown error:', error); 
//     //   }
//     // };

//     window.onerror = (m, s, l, c, e) => {
//       if (e instanceof Error) {
//         setError(true); 
//       } else {
//         console.error('Unknown error:', e); 
//       }
//     };

//     return () => {
//       window.onerror = null; 
//     };
//   }, []); 

//   return (
//     <React.Fragment>
//       {errorStore.hasError && <HasError />} 
//       {children} 
//     </React.Fragment>
//   );
// };

// export default ErrorBoundary
