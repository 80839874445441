import * as React from "react";
import { toast } from "sonner";
import useMarketStatus from "../../hooks/useMarketStatus";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "../../lib/utils";


export default function LiveSwitch({ handleSwitch }: any) {
  const marketStatus = useMarketStatus();
  const [historical, setHistorical] = React.useState(false);

  const localHandleSwitch = () => {
    // if (marketStatus === "closed") {
    // } else {
      setHistorical(!historical);
    // }
  };
  // React.useEffect(() => {
  //   if (marketStatus === "closed") {
  //     setHistorical(true);
  //     toast("Market Closed ");
  //   }
  // }, [marketStatus]);

  return (
    <div className="flex items-center gap-3 text-[12px] dark:text-neutral-400 md:text-sm">
      <p>
        <span>Live </span>
        <span className="hidden sm:inline">Data</span>
      </p>
      <Switch
        checked={historical}
        onClick={() => {
          if (handleSwitch) {
            handleSwitch();
          }
          localHandleSwitch();
        }}
      />
      <p>
        <span>Historical </span>
        <span className="hidden sm:inline">Data</span>
      </p>
    </div>
  );
}


const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex h-4 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-yellow-500 data-[state=unchecked]:bg-zinc-500 dark:focus-visible:ring-zinc-300 dark:focus-visible:ring-offset-zinc-950 dark:data-[state=checked]:bg-yellow-500 dark:data-[state=unchecked]:bg-zinc-500",
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none relative block h-4 w-4 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 "
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
