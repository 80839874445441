import { useEffect } from "react";
import LandingLayout from "../../layout/LandingLayout";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import TermsConstant from "./TermsConstant";
import AppLayout from "../../layout/AppLayout";

export default function Terms() {
  const isLoggedIn = useGetFromStore(useAuthStore,(state) => state.isLoggedIn);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    {
      !isLoggedIn ?
      <LandingLayout>
      <TermsConstant/>
      </LandingLayout>
      :
      <AppLayout>
      <TermsConstant/>
      </AppLayout>
    }
    </>
  );
}


