import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type PaymentDetails = {
  merchantTransactionPlanId: {
    id: string;
    merchantTransactionId: string;
  };
  userInfo: {
    firstName: string;
    lastName: string;
    phone: string;
    address: string;
    state: string;
    city: string;
    pincode: string;
    terms: boolean;
  };
  setMerchantTransID: (data: { id: string; merchantTransactionId: string }) => void;
  setUserInfo: (data: {
    firstName: string;
    lastName: string;
    phone: string;
    address: string;
    state: string;
    city: string;
    pincode: string;
    terms: boolean;
  }) => void;
};

const usePaymentDetails = create<PaymentDetails>()(
  devtools(
    persist(
      (set) => ({
        merchantTransactionPlanId: {
          id: "", 
          merchantTransactionId: "",
        },
        userInfo: {
          firstName: "",
          lastName: "",
          phone: "",
          address: "",
          state: "",
          city: "",
          pincode: "",
          terms: false,
        },
        setMerchantTransID: (data) =>
        set(() => ({
          merchantTransactionPlanId: {
            ...data,
          },
        })),
        setUserInfo: (data) =>
          set(() => ({
            userInfo: {
              ...data,
            },
          })),
      }),
      {
        name: "paymentDetails",
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => !["setMerchantTransID", "setUserInfo"].includes(key)
            )
          ),
      }
    ),
    { name: "paymentDetails" }
  )
);

export default usePaymentDetails;

