import "./hovercard.css"
import { ChevronDown } from "lucide-react";
import React, { useState } from "react";
import { cn } from "../../lib/utils";
import browserName from "../shared/BrowserName";
let browser = browserName()

interface ChildrenType {
  children: React.ReactNode;
  className?: string;
}
export function HoverCard({ children }: ChildrenType) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className={cn("hover-card", { hovered: isHovered })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

export function HoverHeader({ children, className }: ChildrenType) {
  return (
    <div className={`hover-header ${className}`}>
      {children}
      <span className="hover-icon ml-0.5 block">
        <ChevronDown className="mt-0.5" />
      </span>
    </div>
  );
}

export function HoverContent({ children }: ChildrenType) {
  return <div className={browser === "apple safari" ? "hover-content-safari" : "hover-content"}>{children}</div>;
}
