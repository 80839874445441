import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader, MoveHorizontal } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { Icons } from "../../utils/icons";
import formatCurrency, { formatIfCurrency } from "../../utils/formatCurrency";
import { useEffect, useState } from "react";
import { TrendFinderDataType } from "./constant";

export const columns: any = [
  {
    accessorKey: "time",
    header: "Time",
  },
  {
    accessorKey: "ltp",
    header: "LTP",
    cell: ({ row }: any) => {
      const value = row.getValue("ltp");
      if (value === "-") {
        return <p>{row.getValue("ltp")}</p>
      }
      else {
        return <p>{formatCurrency(row.getValue("ltp"))}</p>;
      }
    },
  },
  {
    accessorKey: "dayHighLow",
    header: () => <div >Day H/L</div>,
    cell: ({ row }: any) => {
      const breakoutValue = row.getValue("dayHighLow") || '';
      if (breakoutValue === '' || breakoutValue === '-') {
        return <div>-</div>;
      }
      const matchResult = breakoutValue.match(/\(([^)]+)\)/);
      const numericPart = matchResult?.[1] ? parseFloat(matchResult[1]) : 0;
      const formattedNumericPart = formatCurrency(numericPart);
      const formattedBreakoutValue = matchResult
        ? breakoutValue.replace(/\(([^)]+)\)/, `(${formattedNumericPart})`)
        : breakoutValue;
  
      return (
        <div
          className={cn(
            "border border-transparent min-w-max p-1.5",
            { "border-green-500 text-green-500": breakoutValue.includes("D.H.B.") },
            { "border-red-500 text-red-500": breakoutValue.includes("D.L.B.") }
          )}
        >
          {formattedBreakoutValue}
        </div>
      );
    }
  },
  {
    accessorKey: "changeInCallOi",
    header: () => <div>Change in Call OI</div>,
    cell:({row}:any) => <>{formatCurrency(parseFloat(row.getValue("changeInCallOi")))}</>
  },
  {
    accessorKey: "changeInPutOi",
    header: () => <div>Change in Put OI</div>,
    cell:({row}:any) => <>{formatCurrency(parseFloat(row.getValue("changeInPutOi")))}</>
  },
  {
    accessorKey: "diffInOi",
    header: () => <div>Diff. in OI</div>,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("diffInOi"));
      return <p className={value >= 0 ? "text-green-500" : "text-red-600"}>{formatCurrency(value)}</p>;
    },
  },
  {
    accessorKey: "directionOfChange",
    header: () => <div>Direction Of Change</div>,
    cell: ({ row }: any) => (
      <>
        {row.getValue("directionOfChange") === "up" && (
          <div className="mx-auto h-6 w-6 rounded-md bg-green-500">
            <Icons.arrowUp className="mx-auto w-4 text-white" />
          </div>
        )}
        {row.getValue("directionOfChange") === "down" && (
          <div className="mx-auto h-6 w-6 rotate-180 rounded-md bg-red-500">
            <Icons.arrowUp className="mx-auto w-4 text-white" />
          </div>
        )}
        {row.getValue("directionOfChange") === "noChange" && (
          <div className="mx-auto h-6 w-6 rounded-md bg-purple-500 ">
            <MoveHorizontal className="mx-auto w-4 text-white" />
          </div>
        )}
      </>
    ),
  },
  {
    accessorKey: "changeInDirection",
    header: () => <div>Change in Direction</div>,
    cell: ({ row }: any) => {
      const value = parseFloat(row.getValue("changeInDirection"));
      return <p className={value >= 0 ? "text-green-500" : "text-red-600"}>{formatCurrency(value)}</p>;
    },
  },
  {
    accessorKey: "directionOfChangePercent",
    header: () => <div>Direction Of Change%</div>,
    cell: ({ row }: any) => {
      const data = row.getValue("directionOfChangePercent");
      const directionOfChange = row.getValue("directionOfChange");
      const isValidNumber = !isNaN(parseFloat(data));
      let sign;
      if(directionOfChange === "down"){
        sign = "-";
      }else{
        sign = "";
      }
      if (data === "-∞" || data === "∞")
        return (<p>{data}</p>);
      else if (data === null) {
        return (<p>{"∞"}</p>);
      }
      else
        return (
          <p  className={`${isValidNumber && directionOfChange === "up" ? "text-green-500" : "text-red-500"}`}>
          {isValidNumber? `${sign}${Math.abs(parseFloat(formatCurrency(parseFloat(data))))}%`: "-"}
        </p>
        );
    }
  },
  {
    accessorKey: "DayHighLowDiffInOI",
    header: () => <div>Day High/Low Diff. in OI</div>,
    cell: ({ row }: any) => (
      <>
        <div
          className={cn(
            " p-1.5 min-w-max max-w-max mx-auto text-[12px]",
            {
              "text-green-500 border-green-500 rounded-md border":
                row.getValue("DayHighLowDiffInOI") === "Day High Break",
            },
            {
              "text-red-500 border-red-500 rounded-md border":
                row.getValue("DayHighLowDiffInOI") === "Day Low Break",
            }
          )}
        >
          {row.getValue("DayHighLowDiffInOI")}
        </div>
      </>
    ),
  },
  {
    accessorKey: "trend",
    header: "Trend",
    cell: ({ row }: any) => (
      <div
        className={cn(
          "min-w-max p-1.5 text-center rounded",
          { "bg-green-500": row.getValue("trend") === "Bullish" },
          { "bg-green-500": row.getValue("trend") === "Mild Bullish" },
          { "bg-red-500": row.getValue("trend") === "Bearish" },
          { "bg-red-500": row.getValue("trend") === "Mild Bearish" }
        )}
      >
        {row.getValue("trend")}
      </div>
    ),
  },
];

export function TrendFinderTable(props: {
  rowData: TrendFinderDataType[],
  loading: boolean,
  tableHeight: number
}) {
  const table = useReactTable({
    data: props.rowData === undefined ? [] : props.rowData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setLoader(props.loading)
  }, [props.loading])

  return (
    <div className="w-full">
      <div style={{ maxHeight: `${props.tableHeight}px` }} className="custom-scrollbar relative max-h-[520px] w-full overflow-auto rounded-lg border-2 dark:border-zinc-600">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <th className="border-b dark:border-zinc-600 border-zinc-200" />
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="border-b py-3 dark:border-zinc-600"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            { !loader ?
            table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  className={cn("border-b border-zinc-300 dark:border-zinc-600",
                    { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                  )}
                >
                  <td className="px-1">{index + 1}.</td>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "py-2 !text-xs"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell />
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            ): <TableRow>
            <TableCell />
            <TableCell
              colSpan={columns.length}
              className="h-24 text-center"
            >
              <Loader className="animate-spin mx-auto" /> 
            </TableCell>
          </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </div>
  );
}