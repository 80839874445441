import { ReactNode } from "react";

const spotPriceParaStyles =
  "border border-zinc-200 p-2 min-w-[120px] tracking-wide font-semibold dark:border-zinc-600 w-full";

const ValueStyles =
  "border border-zinc-200 p-2 min-w-[120px] tracking-wide font-medium  dark:border-zinc-600  ";
const spotPriceBoxStyles =
  "border w-full bg-white dark:bg-zinc-900 grid grid-cols-2 gap-2 text-center dark:border-zinc-600 p-1 sm:p-1 min-w-max shadow";

interface SpotPriceType {
  title1: string;
  value1: string | number | ReactNode;
  title2: string;
  value2: string | number | ReactNode;
}

export function SpotPriceCard({
  title1,
  value1,
  title2,
  value2,
}: SpotPriceType) {
  return (
    <div className={spotPriceBoxStyles}>
      <p className={spotPriceParaStyles}>{title1}</p>
      <p className={ValueStyles}>{value1}</p>
      <p className={spotPriceParaStyles}>{title2}</p>
      <p className={ValueStyles}>{value2}</p>
    </div>
  );
}
