import convertToYYYYMMDD from "../components/shared/dateConverter";
import baseApi from "./base";

export type ExpiryStrikePrice = {
  Expiry: string;
  StrikePrice: string[];
};

export type InstrumentExpiryStrikePrice = {
  ProductName: string;
  ExpiryStrikePriceList: ExpiryStrikePrice[];
  SpotPrice?: any;
};


// interface BearStoreState {
//   bears: number;
//   increasePopulation: () => void; 
//   removeAllBears: () => void;
// }




export const getInstrumentExpiryStrikePrice = async () => {
  try {
    const response = await baseApi.get(
      `/api/Options/GetInstrumentExpiryStrikePrice`
    );
    return response.data as InstrumentExpiryStrikePrice[];

  } catch (error: any) {
    return [];
  }
};

export const getOptionsInterMediateData = async (identifier: any, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date !== undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const response = await baseApi.get(
      `/api/Options/GetOptionsInterMediateData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getExpertOpinionData = async (identifier: string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetExpertOpinionData?Identifier=${identifier}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.post(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getOptionChainData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetOptionChainData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data
  } catch (error) {
    return [];
  }
}

export const getTrendFinderData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetOptionsTrendFinder?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getOptionSpurtzData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetOptionsSprutzData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getOptionPremiumData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetPremiumData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getHeadOfficeData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetHeadOfficeData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getImpliedVolitilityData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetActiveStrikeIVData?identifier=${identifier}&TopicName=${topicName}${date ? `&date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getOiGraphData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Options/GetActiveStrikeOIData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getOptionChainIndexSubscription = async (
  indexName: string,
  userId: string,
  toIdentifier: string
) => {
  try {
    // console.log("Hi am here", indexName, "user", userId, "toIden", toIdentifier);
    if (
      userId !== null &&
      userId !== "" &&
      indexName !== null &&
      indexName !== "" &&
      toIdentifier !== ""
    ) {
      const { data } = await baseApi.get(
        `api/OptionChain/GetOptionChainSubscriptionWithData?IndexName=${indexName}&UserId=${userId}&ToIdentifier=${toIdentifier}`
      );

      return { data };
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
}

export const getTrendFinderLiveData = async (identifier: string) => {
  try {
    const requestBody = {
      OIStrikePriceList: identifier
    }
    const apiUrl = `api/Options/RegisterTrendingOISubscription`;
    const response = await baseApi.post(apiUrl, requestBody);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getProductSpotPrice = async (product: string) => {
  try {
    const response = await baseApi.get(`/api/Options/GetSpotPriceForProduct?product=${product}`);
    return response.data;
  } catch (error) {
    return "";
  }
}