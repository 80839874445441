import { InlineWidget } from 'react-calendly'
import Header from '../landing-page/Header'
import Footer from '../landing-page/Footer'
import { useTheme } from '../../utils/ThemeProvider'
import { useEffect, useState } from 'react'

export default function BookSlot() {
    const { theme } = useTheme()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const simulateLoading = setTimeout(() => {
            setIsLoading(false);
        }, 2300); // Adjust timeout as needed

        return () => clearTimeout(simulateLoading); // Cleanup function
    }, []);
    return (
        <>
            <div className='h-[87px] bg-blue-900 top-0 left-0 w-full absolute dark:hidden' />
            <Header />
            <div className='py-28 px-4'>
                {isLoading ? <div className=''>
                    <p className='text-center'>
                        Getting available slot info <br /> <span className='animate-pulse text-7xl'>...</span>
                    </p>
                    {/* <Loader className='w-8 h-8 animate-spin mx-auto ' /> */}
                </div> : ""}
                <InlineWidget
                    styles={{
                        height: '1000px'
                    }}
                    // onLoad={() => setLoading(false)}
                    pageSettings={{
                        backgroundColor: theme === "dark" ? "27272a" : 'ffffff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '2563eb',
                        textColor: theme === "dark" ? 'fff' : "4d5055",
                    }} url="https://calendly.com/swetha-uli/tradedons-demo-session" />
            </div>

            <Footer />
        </>
    )
}
