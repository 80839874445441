import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
  } from "@tanstack/react-table";
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/table";
  import { cn } from "../../lib/utils";
  import { useEffect, useMemo, useState } from "react";
  import { Loader, Triangle, TriangleIcon, } from "lucide-react";
  import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
  import formatCurrency from "../../utils/formatCurrency";
import BuildUpBadge from "../shared/BuildUpBadge";
import browserName from "../shared/BrowserName";
  
  export type PreaOpenTypes = {
    name: string,
    todayOpen: string;
    prevDayClose: string;
    ltpChangePercent: string;
    ltpChange: string;
    prevDayBreak: string;
  };
  
  const SortingUi = ({ column, title, className }: { column: any, title: string, className?: string }) => (
    <div className={cn("flex items-center min-w-max text-center gap-1 cursor-pointer", className)} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
      <span>{title}</span>
      <div>
        <Triangle className={cn("h-2 w-1.5 fill-zinc-600 dark:fill-[#d9d9d9]", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "desc" })} />
        <Triangle className={cn("h-2 w-1.5 fill-zinc-600 dark:fill-[#d9d9d9] rotate-180", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "asc" })}
        />
      </div>  </div>)

  const columns: any = [
    {
      accessorKey: "name",
      header: ({ column }: { column: any }) => <SortingUi column={column} title="Name" />,
      cell: ({ row }: any) => {
        return <p>{row.getValue("name")}</p>
      }
    },
    {
      accessorKey: "todayOpen",
      header: ({ column }: { column: any }) => <SortingUi column={column} title="Today Open" />,
      cell: ({ row }: any) => {
        const type = row.getValue("todayOpen");
        return <p>{type}</p>
      }
    },
  
    {
      accessorKey: "prevDayClose",
      header: ({ column }: { column: any }) => <SortingUi column={column} title="Prev. Day Close" />,
      cell: ({ row }: any) => {
        const ltp = parseFloat(row.getValue("prevDayClose"));
        return <p>{formatCurrency(ltp)}</p>
      }
    },
    {
      accessorKey: "ltpChangePercent",
      header: ({ column }: { column: any }) => <SortingUi column={column} title="LTP Chng %" />,
      cell: ({ row }: any) => {
        const data = parseFloat(row.getValue("ltpChangePercent"));
        return <p className={`${data > 0 && "text-green-500"}  ${data < 0 && "text-red-500"}`}>
          {formatCurrency(data)}
          {data && row.getValue("ltpChangePercent") !== "-" ? "%" : ""}</p>
      }
    },
    {
      accessorKey: "ltpChange",
      header: ({ column }: { column: any }) => <SortingUi column={column} title="LTP Chng" />,
      cell: ({ row }: any) => {
        const data = parseFloat(row.getValue("ltpChange"));
        return <p className={`${data > 0 && "text-green-500"}  ${data < 0 && "text-red-500"}`}>
          {formatCurrency(data)}</p>
      }
    },
    {
        accessorKey: "prevDayBreak",
        header: () => <div>Prev. Day Break</div>,
        cell: ({ row }: any) => {
          const prevDayBreak = row.getValue("prevDayBreak");
          return(
            <div className="max-w-28 text-center inline-block">
            <div className={`${prevDayBreak === "-" ? "bg-transparent": prevDayBreak === "High Break" ? "bg-green-500" : "bg-red-500"}  w-full py-1 px-3 capitalize rounded`}>
              {prevDayBreak}
            </div>
          </div>
        )
      }
      },
  ];
  
  export default function POTable({ title, searchInput, loading, data, height }: { title?: string, searchInput?: string, loading: boolean, data: any[], height?: boolean }) {
    const [filtering, setFiltering] = useState(searchInput)
    const memoizeColumns = useMemo(() => columns, [])
    let browser = browserName();
    const table = useReactTable({
      data: data,
      columns: memoizeColumns,
      getFilteredRowModel: getFilteredRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      state: { globalFilter: filtering },
      onGlobalFilterChange: setFiltering,
    });
  
    const [loader, setLoader] = useState(true)
    useEffect(() => {
      setLoader(loading)
    }, [loading])
  
    useEffect(() => {
      setFiltering(searchInput)
    }, [searchInput])
  
    return (
      <>
         {<div className="mb-4 w-full">
        <h3 className="dark:bg-zinc-800  rounded-t-lg border text-center py-3 text-sm border-b font-semibold dark:border-zinc-600 bg-white">
          {title}
        </h3>
        <div className={cn(browser==="apple safari"?"twh custom-scrollbar border-t-0 whitespace-nowrap relative  border-b-0 w-full overflow-auto border-2 dark:border-zinc-600":"twh custom-scrollbar border-t-0 relative  border-b-0 w-full overflow-auto border-2 dark:border-zinc-600",
          { "h-[450px]": height })}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className=" dark:bg-zinc-800 bg-white" >
                  <TableHead className="border-b bg-white dark:bg-zinc-800 text-black dark:text-white">
                  </TableHead>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="border-b bg-white dark:bg-zinc-800 text-black dark:text-white py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                  >
                    <td className={cn({ "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 }, "pl-2")} ></td>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                            { "text-start pl-1": cell.column.id === "name" },"!text-xs py-1.5"
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {loader ? <Loader className="animate-spin mx-auto" /> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1 text-zinc-400">
          <div className="flex pl-4 items-center justify-between w-full ">
            <div className="flex items-center ">
              <p className="text-sm font-medium">Rows per page</p>
              <Select
                value={`${table.getState().pagination.pageSize}`}
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
              >
                <SelectTrigger className="ml-1 h-8 w-[64px] dark:bg-zinc-800 bg-zinc-50 border-none">
                  <SelectValue placeholder={table.getState().pagination.pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                  {[10, 20, 30, 40].map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => table.previousPage()}
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                disabled={!table.getCanPreviousPage()}
              >
                <TriangleIcon className="w-3 text-zinc-500 -rotate-90 fill-zinc-500 hover:text-fill-600 hover:text-zinc-600" />
                Prev
              </button>
              <div className="flex w-[72px] items-center justify-center text-sm font-medium">
                {table.getPageCount() === 0 ? 0 : table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </div>
              <button
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                Next
                <TriangleIcon className="w-3 rotate-90 fill-zinc-500 text-zinc-500 hover:fill-zinc-600 hover:text-zinc-600" />
              </button>
            </div>
          </div>
        </div>
      </div>}
      </>
    );
  }
  