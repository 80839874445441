import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { useEffect, useState } from "react";
import { TEodTable, columns } from "./Columns";


export default function EodTable(props: {
  rowData: TEodTable[],
  loading: boolean,
  tableHeight: number
  // collapsed: boolean,
  // handleCollapse:()=> void,
}) {
  const table = useReactTable({
    data: props.rowData === undefined ? [] : props.rowData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setLoader(props.loading)
  }, [props.loading])


  return (
    <div className="pb-4 w-full">

      <div style={{ maxHeight: `${props.tableHeight}px` }} className="custom-scrollbar relative max-h-[640px] w-full overflow-auto rounded-lg border-2 dark:border-zinc-600 shadow">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <th className="border-b dark:border-zinc-600 border-zinc-200" />
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="border-b text-black py-3 dark:border-zinc-600"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  className={cn("border-b border-zinc-300 dark:border-zinc-600",
                    { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                  )}
                >
                  <td className="px-1">{index + 1}.</td>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "py-2 !text-xs"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell />
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {loader ? <Loader className="animate-spin mx-auto" /> : "No results."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
