import OptionChain from "../../../components/option-chain";
import AppLayout from "../../../layout/AppLayout";

export default function OptionChainPage() {
    return (
        <AppLayout>
            <OptionChain />
        </AppLayout>
    )
}
