import { MobileNav } from "./MobileNav";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Logo } from "../ui/logo";
import { Switch } from "../ui/switch";
import { dashboardNav } from "../../data/dashboardNav";
import { Link, useLocation } from "react-router-dom";
import useSidebarStore from "../../store/sidebar";
import { Icons } from "../../utils/icons";
import { useTheme } from "../../utils/ThemeProvider";
import useAuthStore from "../../store/auth";
import { HoverCard, HoverContent, HoverHeader } from "../ui/HoverCard";
import { ArrowRightSquare } from "lucide-react";
import useScreensDetails from "../../store/screensDetails";
import useSpotPriceStore from "../../store/spotPrice";
import { useState } from "react";
import useToggleStore from "../../store/openInNewTab";

export default function Topbar() {
  const { screens } = useScreensDetails();
  const {openInSameTab} = useToggleStore();

  return (
    <header className="border-b bg-white px-4 py-3 dark:border-zinc-600 dark:bg-zinc-900">
      <div className="m-auto flex max-w-full items-center justify-between">
        <div className="flex items-center">
          <div className="md:hidden">
            <MobileNav />
          </div>
          <Link to="/dashboard">
            <Logo showText={false} />
          </Link>
        </div>
        <div className="hidden gap-x-8 md:flex">
          {dashboardNav.map((section, index) => {
            return (
              <HoverCard key={index}>
                <HoverHeader>{section.heading}</HoverHeader>
                <HoverContent>
                  {section.links.map((item, subIndex) => (
                    <Link
                      to={screens.includes(item.href) ? item.href : "/features/plans"}
                      key={subIndex}
                      className="hover-link text-sm"
                      target={openInSameTab ? "_blank":""}
                    >
                      {item.name}
                    </Link>
                  ))}
                </HoverContent>
              </HoverCard>
            );
          })}
        </div>

        <DashboardDropdown />
      </div>
    </header>
  );
}

export function DashboardDropdown() {
  const { theme, setTheme } = useTheme()
  const handleTheme = () => setTheme(theme === "light" ? "dark" : "light");
  const { toggleSidebar, isSidebarOpen } = useSidebarStore();
  const pathname = useLocation().pathname;
  const {openInSameTab, toggleOpenInSameTab} = useToggleStore();

  const logOut = useAuthStore((state) => state.logOut);
  const { clearSpotPrice } = useSpotPriceStore();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className=" cursor-pointer ">
          <AvatarImage src="/assets/avatar.jpg" />
          <AvatarFallback>SC</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-4 min-h-[100vh] w-full min-w-[100vw] py-2 sm:h-auto sm:min-h-max sm:w-72 sm:min-w-max">
        <DropdownMenuGroup>
          <div className="flex justify-end pr-5 ">
            <DropdownMenuItem className="max-w-max sm:hidden">
              <Icons.close />
            </DropdownMenuItem>
            <DropdownMenuSeparator className="sm:hidden" />
          </div>

          <div className="flex items-center justify-between gap-4 rounded-md px-2 py-4 pr-4 text-sm hover:bg-zinc-100 dark:bg-transparent dark:hover:bg-zinc-800">
            <span className="inline-block pl-1"> Dark color theme </span>
            <Switch
              checked={theme === "dark" ? true : false}
              onClick={handleTheme}
              className="mr-2"
            />
          </div>
          <DropdownMenuSeparator
            className={`${pathname === "/dashboard" ? "" : "hidden"}`}
          />
          <div
            style={{ display: pathname === "/dashboard" ? "flex" : "none" }}
            className="flex items-center justify-between gap-4 rounded-md px-2 py-4 pr-4 text-sm hover:bg-zinc-100 dark:bg-transparent dark:hover:bg-zinc-800"
          >
            <span className="inline-block pl-1">
              {isSidebarOpen ? "Hide" : "Show"} Sidebar{" "}
            </span>
            <Switch
              checked={isSidebarOpen === true ? true : false}
              onClick={toggleSidebar}
              className="mr-2 "
            />
          </div>
          <div
            className="flex items-center justify-between gap-4 rounded-md px-2 py-4 pr-4 text-sm hover:bg-zinc-100 dark:bg-transparent dark:hover:bg-zinc-800"
          >
            <span className="inline-block pl-1">
              Page will open in {openInSameTab ? "new" : "same"} tab{" "}
            </span>
            <Switch
              checked={openInSameTab}
              onClick={toggleOpenInSameTab}
              className="mr-2 "
            />
          </div>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />

        <DropdownMenuItem asChild>
          <Link to="/user-billing" className="rounded-md py-4 hover:cursor-pointer">
            <Icons.user className="mr-2 h-5 w-5" />
            <span>My Billing</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="rounded-md py-4 hover:cursor-pointer"
          onClick={() => {
            clearSpotPrice();
            logOut();
          }}
        >
          <ArrowRightSquare className="mr-2 h-5 w-5" />
          <span className="text-red-500">Log Out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
