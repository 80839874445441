import React from 'react';
import UserPlansInfo from '../../components/user-billing';
import AppLayout from '../../layout/AppLayout';

export default function UserBillingInfo() {

    return (
        <AppLayout>
        <UserPlansInfo />
      </AppLayout>
    )
}