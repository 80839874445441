import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export default function EOSpot({ row }: { row: any | [] }) {
  return (
    <div className="mt-4 flex custom-scrollbar h-full w-full justify-between gap-4 overflow-x-auto text-xs">
      <SpotPriceCard
        title1="Spot Price"
        value1={formatCurrency(parseFloat(row?.SpotPrice)) || "-"}
        title2="Current Future Price"
        value2={formatCurrency(parseFloat(row?.currentfutureprice)) || "-"}
      />
      <SpotPriceCard
        title1="Dow Jones(US 30)"
        value1="-"
        title2="Hang Seng (HKEX)"
        value2="-"
      />

      <SpotPriceCard
        title1="Dollar Index (DXY)"
        value1="-"
        title2="FTSE (UK 100)"
        value2="-"
      />

      <SpotPriceCard
        title1="Premium In Call(ATM)"
        value1={formatCurrency(parseFloat(row?.premiuminCall)) || "-"}
        title2="Premium In Put(ATM)"
        value2={ formatCurrency(parseFloat(row?.premiumInput)) || "-"}
      />
    </div>
  );
}
