import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export function OiSpot(props: {
  rows?: any;
}) {
  return (
    <div className="mt-4 flex h-full w-full justify-between custom-scrollbar gap-4 overflow-x-auto text-xs">
      <SpotPriceCard
        title1="Spot Price"
        value1={props.rows.SpotPrice === undefined || props.rows.spotPrice === "" ? "-" : formatCurrency(parseFloat(props.rows?.SpotPrice))}
        title2="Current Future Price"
        value2={props.rows.currentfutureprice === undefined || props.rows.currentfutureprice === "" ? "-" : formatCurrency(parseFloat(props.rows?.currentfutureprice))}
      />
      <SpotPriceCard
        title1="Call Build-Ups ATM"
        value1={props.rows.callbuildupsATM === undefined || props.rows.callbuildupsATM === "" ? "-" : props.rows.callbuildupsATM}
        title2="Put Build-Ups ATM"
        value2={props.rows.PutBuildUpATM === undefined || props.rows.PutBuildUpATM === "" ? "-" : props.rows.PutBuildUpATM}
      />
      <SpotPriceCard
        title1="ATM Call IV"
        value1={props.rows.ATMcallIV === undefined || props.rows.ATMcallIV === "" ? "-" : formatCurrency(parseFloat(props.rows.ATMcallIV))}
        title2="ATM Put IV"
        value2={props.rows.ATMputIV === undefined || props.rows.ATMputIV === "" ? "-" : formatCurrency(parseFloat(props.rows.ATMputIV))}
      />
      <SpotPriceCard
        title1="Premium In Call(ATM)"
        value1={props.rows.PremiumincallATM === undefined || props.rows.PremiumincallATM === "" ? "-" : formatCurrency(parseFloat(props.rows.PremiumincallATM))}
        title2="Premium In Put(ATM)"
        value2={props.rows.PremiuminputATM === undefined || props.rows.PremiuminputATM === "" ? "-" : formatCurrency(parseFloat(props.rows.PremiuminputATM))}
      />
    </div>
  );
}
