import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './components/landing-page/LandingPage';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Reset from './pages/auth/ForgotPassword';
import ComingSoon from './components/landing-page/ComingSoon';
import PrivacyPolicy from './pages/landing-page/PrivacyPolicy';
import Terms from './pages/landing-page/Terms';
import Refund from './pages/landing-page/Refund';
import OpenInterestPage from './pages/futures/open-interest/OpenInterest';
import OptionChainPage from './pages/options/option-chain/OptionChain';
import StrikePriceWisePage from './pages/options/strike-price-wise/StrikePriceWise';
import Disclaimer from './pages/landing-page/Disclaimer';
import TrendFinderPage from './pages/options/trend-finder/TrendFinder';
import ExpertOpinionPage from './pages/options/expert-opinion/ExpertOpinion';
import MarketMoverPage from './pages/futures/intraday-mover/IntradayMover';
import FutureSpurtzPage from './pages/futures/future-spurtz/FutureSpurtz';
import OptionSpurtzPage from './pages/options/option-spurtz/OptionSpurtz';
import PremiumPage from './pages/options/premium/Premium';
import OpenHighPage from './pages/options/open-high/OpenHigh';
import OiGraphPage from './pages/options/oi-graph/OiGraph';
import ImpliedVolatilityPage from './pages/options/implied-volatility/ImpliedVolatilityPage';
import AdvancedChartPage from './pages/advanced-charts/AdvancedCharts';
import PlansPage from './pages/features/PlansPage';
import NetworkErr from './pages/NetworkErr';
import PreOpenPage from './pages/features/pre-open';
import EodPage from './pages/futures/eod/Eod';
import BuzzPage from './pages/futures/buzz/Buzz';
import Purchase from './components/plans/Purchase';
import Support from './components/support/support';
import BookSlot from './components/book-slot/BookSlot';
import AdvancedTrendFinder from './pages/options/advanced-trendFinder';
import titleConfig from './config';
import UserBillingInfo from './pages/features/user-profile';

function App() {
const location = useLocation();

React.useEffect(() =>{
  const defaultTitle = "TradeDons - FAST DATA"
const pageTitle = titleConfig[location.pathname as keyof typeof titleConfig];
if(pageTitle!== undefined){
  document.title = `${pageTitle} | TradeDons`
}else{
  document.title = defaultTitle
}
}, [location.pathname]);

  return (
    <>
      <Routes>
        {/* HOME ROUTES (LANDING PAGE) */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/pricing" element={<PlansPage />} />

        {/* AUTH ROUTES */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset" element={<Reset />} />

        {/* APP ROUTES */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/futures/open-interest" element={<OpenInterestPage />} />
        <Route path="/futures/intraday-mover" element={<MarketMoverPage />} />
        <Route path="/futures/future-spurtz" element={<FutureSpurtzPage />} />
        <Route path="/futures/eod" element={<EodPage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/futures/buzz" element={<BuzzPage />} />

        {/* ________OPTIONS_______ */}
        <Route path="/options/strike-price-wise" element={<StrikePriceWisePage />} />
        <Route path="/options/option-chain" element={<OptionChainPage />} />
        <Route path="/options/trend-finder" element={<TrendFinderPage />} />
        <Route path="/options/advanced-trend-finder" element={<AdvancedTrendFinder />} />
        <Route path="/options/expert-opinion" element={<ExpertOpinionPage />} />
        <Route path="/options/option-spurtz" element={<OptionSpurtzPage />} />
        <Route path="/options/premium" element={<PremiumPage />} />
        <Route path="/options/implied-volatility" element={<ImpliedVolatilityPage />} />
        <Route path="/options/head-office" element={<OpenHighPage />} />
        <Route path="/options/oi-graph" element={<OiGraphPage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        {/* <Route path="/error" element={<ErrorPage />} /> */}
         <Route path="/network-error" element={<NetworkErr />} />

        {/*_____________CHARTS______________ */}
        {/* <Route path="/charts/advanced-charts" element={<AdvancedChartPage />} /> */}
        {/* <Route path="/coming-soon" element={<AdvancedChartPage />} /> */}
        <Route path="/features/pre-open" element={<PreOpenPage />} />
        <Route path="/features/plans/:loading?" element={<PlansPage />} />
        <Route path="/features/purchase" element={<Purchase />} />
        <Route path="/support" element={<Support />} />
        <Route path="/book-slot" element={<BookSlot />} />
        <Route path="/user-billing" element={<UserBillingInfo/>}/>

        {/* <Route path='/*' element={<ErrorPage />} /> */}

      </Routes>
    </>

  );
}

export default App;
