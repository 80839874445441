import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { useTheme } from "../../utils/ThemeProvider";
import formatCurrency from "../../utils/formatCurrency";

interface TPData {
    callOi: string,
    putOi: string,
    strikePrice: string,
}

interface TPChart {
    data: TPData[],
    loading: boolean,
    graphHeight:number
}


const SentimentChart = (props: TPChart) => {

    const [loading, setLoading] = useState<boolean>(true)
    const { theme } = useTheme()
    const [chartData, setChartData] = useState<any[]>([]);

    const options = {
        title: {
            show: true,
            text: "TRADEDONS",
            left: "center",
            top: "center",
            textStyle: {
                fontWeight: 'bold',
                fontSize: 20,
                color: "rgba(208, 193, 201, 0.5)"
            },
            subtext: chartData.length <= 0 ? "No Data Available" : "",
            subtextStyle: {
                fontSize: 18
            }
        },
        // graphic: { elements: [{ type: "text", textContent: "TRADEDONS" }] },
        grid: { top: 32, right: "2.5%", bottom: 54, left: "7%" },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            },
            right: 28,
            top: -7
        },
        legend: {
            data: ['Sentiment %'],
            itemGap: 15,
            bottom: 4
        },
        xAxis: {
            type: 'category',
            data: chartData.map(item => item.time),
            axisLine: {
                show: true
            },
            splitLine: {
                show: false
            },
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true
            }
        },

        color: ["#2563eb",],
        backgroundColor: [theme === "light" ? "white" : "#27272a"],

        series: [
            {
                name: "Sentiment %",
                data: chartData.map(item => parseFloat(item.sentiment)),
                type: 'line',
                smooth: false,
            },
        ],

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true
                }
            },
            formatter: (params: any) => {
                const time = chartData[params[0]?.dataIndex]?.time;

                return (
                    `<div>
                    <p class="font-bold">${time}</p>
                    <div class="font-medium">
                    <div class="flex items-center gap-2"> 
                    <div class="icon bg-blue-500"></div>
                    Sentiment %  <span class="font-semibold">${formatCurrency(params[0].value)}</span>
                     </div>
                    </div>
                </div>`
                )
            }
        },
    };
    const loadingOption = {
        text: "",
        color: theme === "light" ? 'black' : "white",
        textColor: 'white',
        maskColor: theme === "light" ? "white" : '#27272a',
        zlevel: 0
    };

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setChartData(props.data)
    }, [props.data])


    return (
        <div className="rounded-lg shadow border bg-white dark:bg-zinc-800 dark:border-zinc-600 overflow-hidden">
            <p className="text-lg text-center mt-2 font-semibold">
                Sentiment % Graph
            </p>
            <ReactECharts loadingOption={loadingOption} option={options} theme={theme} style={{ height: `${props.graphHeight}px` }} showLoading={loading} />
        </div>
    );
};

export default SentimentChart;