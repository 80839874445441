export type expertOpinionLiveMessageType = {
    type: string;
    strikePrice: string;
    date: string;
    open: string;
    high: string;
    low: string;
    close: string;
    changeInPricePercent: string;
    changeInOiPercent: string;
    totalOi: string;
    buildUps: string;
  };
  

 export const expertOpinionBreadcrumb = [
    { label: "Dashboard", link: "/dashboard" },
    { label: "Options", link: "/dashboard" },
    { label: "Expert Opinion", link: "/options/expert-opinion" },
  ];