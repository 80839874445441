import { VerifyPhoneValidation } from "../../../../lib/validation/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import FormError from "../../../../pages/forms/FormError";
import FormInput from "../../../../pages/forms/FormInput";
import { Icons } from "../../../../utils/icons";
import { Button } from "../../../../components/ui/button";
import { Form, FormField } from "../../../../components/ui/form";
import { SendOTPToMobile, VerifyOTPToMobile } from "../../../../api/auth";
import useTimer from "../../../../hooks/useTimer";
import useRegisterStore from "../../../../store/register";
import { AxiosError } from "axios";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { Label } from "../../../../components/ui/label";
import { Input } from "../../../../components/ui/input";
import ValidateOTP from "../../../../lib/validation/OtpValidation";

type Inputs = z.infer<typeof VerifyPhoneValidation>;

const VerifySignupPhone = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateScreen, values: globalValues } = useRegisterStore();
  const { isActive: isTimerRunning, count, reset: resetTimer } = useTimer(60);

  const getOtp = useCallback(async (callback?: () => void) => {
    setIsLoading(true);
    try {
      await SendOTPToMobile(globalValues.email, globalValues.phone);
      if (callback) {
        form.setValue("code", "");
        callback();
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast.error(error?.response?.data);
        return;
      }
      toast.error("Something Went Wrong");
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<Inputs>({
    resolver: zodResolver(VerifyPhoneValidation),
    defaultValues: {
      phone: globalValues.phone,
      code: "",
    },
  });

  useEffect(() => {
    getOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimerResetClick = () => {
    getOtp(resetTimer);
  };

  async function onSubmit(values: Inputs) {
    try {
      await VerifyOTPToMobile(globalValues.email, values.code);
      updateScreen("register");
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast.error(error?.response?.data);
        return;
      }
      toast.error("Something Went Wrong");
    }
  }

  return (
    <>
      <Form {...form}>
        <motion.form
          onSubmit={form.handleSubmit(onSubmit)}
          className="relative m-auto w-full max-w-md sm:w-[400px] rounded-xl border  bg-white px-6 py-12 shadow-lg dark:border-zinc-600 dark:bg-zinc-800 dark:text-white"
          initial={{ x: 10 }}
          animate={{ x: 0 }}
          exit={{ x: 0 }}
          transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
        >
          <h3 className="mb-8 text-center text-xl font-semibold sm:text-2xl ">
            Verify Phone
          </h3>
          <Label className="text-blue-600">Phone Number</Label>
          <div className="flex items-center relative">
            <Input value={globalValues.phone} disabled />
            <Icons.pencil onClick={() => updateScreen("phone")} className="w-4 dark:bg-zinc-800 bg-white cursor-pointer absolute right-0 text-zinc-400" />
          </div>
          <FormError error={form.formState.errors.phone?.message} />
          <div className=" relative z-10">
            <FormField
              name="code"
              control={form.control}
              render={({ field }) => (
                <FormInput
                  field={field}
                  label="Otp"
                  placeholder=""
                  type="number"
                  onInput={ValidateOTP}
                />
              )}
            />
          </div>

          {isTimerRunning ? (
            <p className="relative -top-11 ml-auto mt-4 max-w-max text-[12px] text-zinc-400">
              Resend in <span className="w-4 inline-block">{count}</span> seconds
            </p>
          ) : (
            <p
              onClick={handleTimerResetClick}
              className="relative -top-11 z-10 ml-auto mt-4 max-w-max  cursor-pointer text-end text-sm text-blue-600"
            >
              Resend
            </p>
          )}
          <FormError error={form.formState.errors.code?.message} />

          <p className="mx-auto mt-4 block w-max rounded-full border bg-zinc-200 px-3 py-1 text-xs dark:border-zinc-600  dark:bg-zinc-400">
            Enter a 6 digit OTP sent to this number
          </p>
          <Button className="mt-4 w-full" disabled={isLoading}>
            {isLoading ? (
              <Icons.spinner
                className="mr-2 h-4 w-4 animate-spin"
                aria-hidden="true"
              />
            ) : (
              "Continue"
            )}
          </Button>
        </motion.form>
      </Form>
    </>
  );
};

export default VerifySignupPhone;
