import { useState, useEffect } from 'react';
import EOTable from '../expert-opinion/EOTable';
import { X } from 'lucide-react';
import { parseMessageDate } from '../expert-opinion/helper';
import { getLiveMarketTimeData } from "../../api/dashboard";
import { getExpertOpinionData } from '../../api/options';

interface popup {
  isOpen: boolean;
  onClose: () => void;
  strikePrice: string;
  productName:string;
  expiry:string;
  refDate:any;
  marketStatus:string;
  historical:boolean
}

const ExpertOptionPopup = ({ isOpen, onClose, strikePrice, productName, expiry, refDate, marketStatus, historical }: popup) => {
  const [isLoading, setIsLoading] = useState(true);
  const [ceData, setCeData] = useState<any[]>([]);
  const [peData, setPeData] = useState<any[]>([]);
  const topicName = "oistrategydata_data";

  const processMessage = (messages: string[]) => {
    const ceDataTemp: any[] = [];
    const peDataTemp: any[] = [];

    if (messages?.length) {
      messages.forEach((item) => {
        const identifier = item.split(",")[0];
        const identifierParts = identifier.split("_");
        const messageType = identifierParts[3];
        const currentStrikePrice = identifierParts[4];
        if (currentStrikePrice === strikePrice) {
          const row = {
            type: messageType,
            strikePrice: currentStrikePrice,
            date: parseMessageDate(item.split(",")[1]),
            open: parseFloat(item.split(",")[2]),
            high: item.split(",")[3],
            low: item.split(",")[4],
            close: parseFloat(item.split(",")[5]),
            changeInPricePercent: item.split(",")[6],
            changeInOiPercent: item.split(",")[7],
            totalOi: item.split(",")[8],
            buildUps: item.split(",")[9],
          };

          if (row.close !== 0 || row.open !== 0) {
            if (row.type.toUpperCase() === "CE") {
              ceDataTemp.push(row);
            } else if (row.type.toUpperCase() === "PE") {
              peDataTemp.push(row);
            }
          }
        }
      });
     const sortedCERow = ceDataTemp.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
     const sortedPERow = peDataTemp.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      setCeData(sortedCERow);
      setPeData(sortedPERow);
    }
    setIsLoading(false);
  };

  const liveMsgExpertOpinion = async () => {
   const {localToIdentifier, localHistoricIdentifier} = handleIdentifierChange();
    const data = await getLiveMarketTimeData(topicName, localToIdentifier, localHistoricIdentifier);
    if (data) {
      const messages = data.split("\n");
      processMessage(messages);
    }
  };

  const getHistoricOpinionData = async () => {
    const {localToIdentifier, localHistoricIdentifier} = handleIdentifierChange();
      const response = await getExpertOpinionData(localHistoricIdentifier, historical ? refDate : String(new Date()));
      if (response) {
        const messages = response.split("\n");
        processMessage(messages);
      }
  };

  function handleIdentifierChange (){
      setIsLoading(true);
      const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
        (idx) => idx === productName
      );
    let localToIdentifier = `${!!isIndex ? "OPTIDX" : "OPTSTK"}_${productName}_${expiry}`;
    let localHistoricIdentifier = "";
      const ceIdentifier = `${!!isIndex ? "OPTIDX" : "OPTSTK"}_${productName}_${expiry}_CE_${strikePrice}`;
        const peIdentifier = `${!!isIndex ? "OPTIDX" : "OPTSTK"}_${productName}_${expiry}_PE_${strikePrice}`;
      localHistoricIdentifier += `${ceIdentifier},${peIdentifier}`;
    return {localToIdentifier, localHistoricIdentifier}
  };

  useEffect(() => {
    if (!historical && (marketStatus === "open" || marketStatus === "after-open")) {
      liveMsgExpertOpinion();
    }else{
      getHistoricOpinionData();
    }
  }, [ marketStatus]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 15000);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-[95%] max-h-[90vh] overflow-auto shadow-lg relative z-50">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-black dark:text-white rounded-full p-2 z-50"
        >
          <X size={24} />
        </button>
        <div className="mb-4">
          <p className="font-bold dark:text-white">
            Expert Opinion for Strike Price: {strikePrice}
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
          <div className="md:w-1/2">
            <EOTable
              loading={isLoading}
              title={`${strikePrice} CE`}
              key={`${strikePrice} CE`}
              rowData={ceData}
              height={ceData.length > 0 || peData.length > 0}
            />
          </div>
          <div className="md:w-1/2 ">
            <EOTable
              loading={isLoading}
              title={`${strikePrice} PE`}
              key={`${strikePrice} PE`}
              rowData={peData}
              height={peData.length > 0 || ceData.length > 0}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
  }  

export default ExpertOptionPopup;
