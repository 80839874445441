import Register from "./component/Register";
import SignupEmail from "./component/SignUpEmail";
import VerifySignUpEmail from "./component/VerifySignUpEmail";
import VerifySignUpWithPhone from "./component/VerifySignUpWithPhone";
import { useGetFromStore } from "../../../hooks/useGetFromStore";
import useAuthStore from "../../../store/auth";
import useRegisterStore from "../../../store/register";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SignUpWithPhone from "./component/SignUpWithPhone";
import "./index.scss"
import AuthNavBar from "../../../components/shared/AuthNavBar";

function SignUp() {
    const navigate = useNavigate();
  const { screen, updateScreen, updateValues } = useRegisterStore();

  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);

  useEffect(() => {
    updateScreen("email");
    updateValues({ email: "", phone: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    navigate("/waitlist");
  }

  return (
    <div className="flex flex-col h-screen">
    <AuthNavBar/>
    <div className="flex justify-center items-center h-full">
    <AnimatePresence mode="wait" >
      <motion.div key={screen} >
        {screen === "email" ? (
          <SignupEmail />
        ) : screen === "verify-email" ? (
          <VerifySignUpEmail />
        ) : screen === "phone" ? (
          <SignUpWithPhone />
        ) : screen === "verify-phone" ? (
          <VerifySignUpWithPhone />
        ) : screen === "register" ? (
          <Register />
        ) : (
          <></>
        )}
      </motion.div>
    </AnimatePresence>
    </div>
    </div>
  );
}

export default SignUp;
