import { useEffect, useRef, useState } from "react";
import { Checkbox } from "../ui/checkbox";
import { SelectGroup, SelectItem } from "../ui/select";
import { FoiExpiry } from "../../data/openInterest";
import SelectBox from "../shared/SelectBox";
import { cn } from "../../lib/utils";
import useMarketStatus from "../../hooks/useMarketStatus";
import Breadcrumb from "../shared/Breadcrumb";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import EodSpot from "./EodSpot";
import CollapseButton from "../shared/CollapseButton";
import EodGraph from "./EodGraph";
import EodTable from "./EodTable";
import { TFuturesOpenInterst, getIndexSubscription } from "../../api/futures/open-interest";
import { SelectLabel } from "@radix-ui/react-select";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { futureEODDate } from "../shared/dateConverter";
import { breadcrumbEOD } from "./constant";

export default function Eod(props:{
  indexList: TFuturesOpenInterst
}) {
  const [collapsed, setCollapsed] = useState(false)
  const [instrument, setInstrument] = useState(props.indexList[0].ProductName)
  const [expiry, setExpiry] = useState(FoiExpiry[0].value)
  const [isLoading, setIsLoading] = useState(true);
  const [graphVisible, setGraphVisible] = useState<boolean>(false);
  const [showCumlative, setShowCumlative] = useState<boolean>(false);
  const [topicName, setTopicName] = useState(`EOD_FUTIDX_${props.indexList[0].ProductName}`) // EOD_FUTIDX_BANKNIFTY
  const [toIdentifier, setToIdentifier] = useState(`EOD_FUTIDX_${props.indexList[0].ProductName}_${FoiExpiry[0].value}`);
  const [rows, setRows] = useState<any[]>([]);
  const [lastRow, setLastRow] = useState<any | []>([]);
  const marketStatus = useMarketStatus()
  const navigate = useNavigate()
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const [tableHeight, setTableHeight] = useState(380);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const handleCollapsed = () => setCollapsed(prev => !prev);

  const handleShowCumlative= async(checked:boolean) => {
    setShowCumlative(checked);
    handleTopicNameChange(instrument, expiry, checked)
  }

  async function firstSubscriptionName() {
    await getIndexSubscription(
       topicName,
       userId === undefined ? "" : userId,
       toIdentifier
     );
   }

  function processMessage(message: string[]) {
    const upRows = message.map((item) => {
      const splitMsg = item.split(",")
      return ({
        date: splitMsg[0],
        totalOi: splitMsg[1],
        dayOpen: splitMsg[2],
        dayHigh: splitMsg[3],
        dayLow: splitMsg[4],
        volume: splitMsg[5],
        ltp: splitMsg[6],
        dayRange: splitMsg[7],
        ltpChange: splitMsg[8],
        oiChange: splitMsg[9],
        ltpChangePercent: splitMsg[10],
        oiChangePercent: splitMsg[11],
        oiInterpretation: splitMsg[12],
        prevClose: splitMsg[13],
        spotPrice: splitMsg[14]
      })
    });
    const filteredRows = upRows.filter((item) =>item.date!=="" && item.ltp !=="0");
    const uniqueRowsMap = new Map();
    filteredRows.forEach((row) => {
      uniqueRowsMap.set(row.date, row);
    });
    const uniqueRows = Array.from(uniqueRowsMap.values());
    const sortedRows = uniqueRows.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((item) => ({
      ...item,
      date: futureEODDate(item.date)
    }));
    setIsLoading(false);
    return sortedRows;
  }

  async function liveMsgEODData(topicName:string, toIdentifier:string, isCimulative?:boolean) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier, "", isCimulative? isCimulative: false);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const fRows = processMessage(messages);
    const reversedRow = fRows.reverse();
    setRows(reversedRow);
    setLastRow(reversedRow[0]);
    setIsLoading(false)
    }
  }

  const handleTopicNameChange = async (
    productName: string,
    instrumentExpiry: string,
    checked?:boolean
  ) => {
    setRows([]);
    setLastRow([]);
      setIsLoading(true);
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
      (idx) => idx === productName
    );
    if(!checked){
      let topicNameLocal = `EOD_${!!isIndex ? "FUTIDX" : "FUTSTK"}_${productName}`;
      let identifierLocal = `EOD_${!!isIndex ? "FUTIDX" : "FUTSTK"
        }_${productName}_${instrumentExpiry}`;
      setTopicName(topicNameLocal);
      setToIdentifier(identifierLocal);
    }else{
      let topicNameCumlative = `CUMULATIVE_EOD_${!!isIndex ? "FUTIDX" : "FUTSTK"}_${productName}`;
      setTopicName(topicNameCumlative);
      setToIdentifier(topicNameCumlative);
      setExpiry(FoiExpiry[0].value);
      await getIndexSubscription(topicNameCumlative, 
        userId === undefined ? "" : userId,
        topicNameCumlative);
        liveMsgEODData(topicNameCumlative, topicNameCumlative, true)
    }
  };

    const fetchDataAtInterval = async () => {
      await liveMsgEODData(topicName, toIdentifier);
  };

const calculateTableHeight = () => {
  if(containerRef.current){
    const containerHeight = containerRef.current.clientHeight;
    const topBarHeight = document.querySelector("header")?.clientHeight || 0;
    const windowHeight = window.innerHeight;
    let offset:number;
    if(graphVisible){
      offset = containerHeight + topBarHeight + 30;
    }else{
      offset = containerHeight + topBarHeight + 33;
    }
    setTableHeight(windowHeight - offset);
  }
}

  // USE EFFECTS =>__________________________________________________________________
  useEffect(() => {
    calculateTableHeight(); 
    window.addEventListener("resize", calculateTableHeight);
    return () => window.removeEventListener("resize", calculateTableHeight);
  }, [collapsed, isLoading, graphVisible]);

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[instrument,expiry])

useEffect(() => {      
      if(userId!== undefined && userId !=="" && !showCumlative){
        firstSubscriptionName();
        liveMsgEODData(topicName, toIdentifier)
      }
  },[userId, toIdentifier]);

  useEffect(() => {     
    let id:any; 
    if(marketStatus === "open" && !showCumlative){
      id = setInterval(fetchDataAtInterval, 10000)
    }
    return () => clearInterval(id);
},[marketStatus, toIdentifier]);

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="mt-4">
        <Breadcrumb items={breadcrumbEOD} />
      </div>

      <div className="mt-4 grid grid-cols-4 gap-3 sm:grid-cols-3 min-[1072px]:grid-cols-4 items-center">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e:string) =>{
            const found = props.indexList.find((i:any) => i.ProductName ===e);
            if(!found) throw new Error("Not Found in the list");
            setRows([]);
            setLastRow([]);
            setInstrument(e);
            setExpiry(FoiExpiry[0].value);
            handleTopicNameChange(e, FoiExpiry[0].value)
          }}
          placeholder="Nifty"
        >
          <SelectGroup className="max-h-[400px] custom-scrollbar overflow-y-scroll">
          <SelectLabel className="ml-2">Index</SelectLabel>
                {props.indexList && props.indexList.slice(0, 4).map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.ProductName}`}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
                <SelectLabel className="ml-2">Stocks</SelectLabel>
                {props.indexList && props.indexList.slice(5, props.indexList.length).map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.ProductName}`}>
                      {item.ProductName}
                    </SelectItem>
                  );
                })}
          </SelectGroup>
        </SelectBox>

        <SelectBox
          title="Expiry:"
          value={expiry}
          onValueChange={(e: string) => {
            setExpiry(e);
            handleTopicNameChange(instrument, e)
          }}
          placeholder="Expiry"
          disabled={showCumlative === true}
        >
          {
            FoiExpiry?.map((item, index) => {
              return (
                <SelectItem key={index} value={item.value}>
                  {item.name}
                </SelectItem>
              )
            }
            )}
        </SelectBox>

        <div className="pt-5 flex items-center gap-6">
          <div className="flex items-center gap-2" >
            <Checkbox onCheckedChange={(checked:boolean) => setGraphVisible(checked)} checked={graphVisible} />
            <p className="text-[12px]">Show Chart Data</p>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox onCheckedChange={(checked:boolean) => handleShowCumlative(checked)} checked={showCumlative} />
            <p className="text-[12px]">Show Cummulative OI</p>
          </div>
        </div>
      </div>

      <div className={cn({ "hidden": collapsed })}>
        <EodSpot row={lastRow} />
      </div>
      <div className="flex items-center justify-between gap-5">
        <CollapseButton collapsed={collapsed} handleCollapse={handleCollapsed} />
      </div>
      </div>
      {graphVisible ? (
        <EodGraph loading={isLoading} rowData={rows.reverse()}  graphHeight = {tableHeight}/>
      ) : (
        <EodTable loading={isLoading} rowData={rows}  tableHeight = {tableHeight}/>
      )}
    </div>
  );
}