import AppLayout from '../../../layout/AppLayout'
import IntradayMover from '../../../components/intraday-mover/IntradayMover';

export default function MarketMoverPage() {

  return (
    <AppLayout>
  <IntradayMover/>
    </AppLayout>
  )
}
