export function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <g clipPath="url(#clip0_2672_19445)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.619175 62.6667V17.3334C0.614625 15.0558 1.05986 12.7998 1.92933 10.6948C2.7988 8.58978 4.07539 6.67716 5.68584 5.0667C7.2963 3.45625 9.20892 2.17966 11.3139 1.31019C13.419 0.440718 15.675 -0.00451573 17.9525 3.4528e-05H63.2858C65.5634 -0.00451573 67.8194 0.440718 69.9244 1.31019C72.0294 2.17966 73.942 3.45625 75.5525 5.0667C77.163 6.67716 78.4395 8.58978 79.309 10.6948C80.1785 12.7998 80.6237 15.0558 80.6192 17.3334V62.6667C80.6237 64.9442 80.1785 67.2002 79.309 69.3053C78.4395 71.4103 77.163 73.3229 75.5525 74.9334C73.942 76.5438 72.0294 77.8204 69.9244 78.6899C67.8194 79.5593 65.5634 80.0046 63.2858 80H17.9525C13.3561 79.9979 8.9485 78.171 5.69833 74.9209C2.44816 71.6707 0.621295 67.2631 0.619175 62.6667Z"
          fill="#1E3A8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.6193 57.4657C66.0097 57.8127 65.3208 57.9965 64.6193 57.999H16.6193C15.9167 58.0053 15.2255 57.821 14.6193 57.4657L30.6193 41.4657L34.5526 45.399C35.3551 46.1716 36.3076 46.7713 37.351 47.1612C38.3945 47.551 39.5068 47.7227 40.6193 47.6657C41.7311 47.7167 42.8418 47.5421 43.8844 47.1526C44.927 46.763 45.8799 46.1665 46.686 45.399L50.6193 41.4657L66.6193 57.4657ZM68.0193 55.999L52.0193 39.999L68.0193 23.999C68.3663 24.6086 68.5501 25.2976 68.5526 25.999V53.999C68.6148 54.7076 68.426 55.4155 68.0193 55.999ZM13.1526 55.999C12.8056 55.3894 12.6219 54.7005 12.6193 53.999V25.999C12.613 25.2964 12.7973 24.6052 13.1526 23.999L29.1526 39.999L13.1526 55.999ZM66.6193 22.5324L45.2193 43.9324C44.61 44.5179 43.8881 44.9735 43.0973 45.2715C42.3066 45.5694 41.4635 45.7036 40.6193 45.6657C39.7751 45.7036 38.932 45.5694 38.1413 45.2715C37.3505 44.9735 36.6286 44.5179 36.0193 43.9324L14.6193 22.5324C15.2289 22.1853 15.9178 22.0016 16.6193 21.999H64.6193C65.3208 22.0016 66.0097 22.1853 66.6193 22.5324Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2672_19445">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.619141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LiveChat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
    >
      <path
        d="M66.1818 68.1019L66.0803 68.0438H65.9633H31.118C28.7314 68.026 26.43 67.1537 24.6314 65.5848C22.9071 64.0808 21.7543 62.0323 21.3608 59.7845L23.6842 58.4586L23.6868 58.4572L24.822 57.7992L59.5367 57.7992L59.5385 57.7992C62.3997 57.7876 65.1405 56.6459 67.1637 54.6227C69.1869 52.5995 70.3287 49.8587 70.3402 46.9975V46.9957V22.2773H74.3715C76.9981 22.2918 79.5128 23.3426 81.3689 25.2012C83.225 27.0598 84.2724 29.5761 84.2832 32.2028C84.2832 32.2031 84.2832 32.2034 84.2832 32.2037L84.2832 78.5636L67.4348 68.82L67.4333 68.8191L66.1818 68.1019Z"
        fill="#1E3A8A"
        stroke="#FAFAFA"
        strokeWidth="0.878906"
      />
      <path
        d="M24.7039 56.9213H24.5858L24.4836 56.9805L23.247 57.6972L23.2461 57.6977L6.41211 67.4002V21.0945C6.42295 18.4677 7.4703 15.9515 9.32645 14.0928C11.1825 12.2342 13.6973 11.1834 16.3239 11.1689H59.5358C62.1649 11.1798 64.6832 12.229 66.5422 14.0881C68.4012 15.9471 69.4504 18.4654 69.4613 21.0944C69.4613 21.0947 69.4613 21.095 69.4613 21.0953V46.9949C69.4613 46.9952 69.4613 46.9955 69.4613 46.9958C69.4504 49.6249 68.4012 52.1431 66.5422 54.0022C64.6832 55.8612 62.1649 56.9104 59.5359 56.9213C59.5356 56.9213 59.5353 56.9213 59.5349 56.9213L24.7039 56.9213Z"
        fill="#1E3A8A"
        stroke="#FAFAFA"
        strokeWidth="0.878906"
      />
      <path
        d="M20.0361 24.1871C19.7079 24.1871 19.3931 24.0567 19.161 23.8246C18.9289 23.5925 18.7986 23.2778 18.7986 22.9496C18.8004 22.7895 18.8339 22.6315 18.8973 22.4845C18.9606 22.3375 19.0525 22.2046 19.1677 22.0934C19.2828 21.9823 19.4188 21.895 19.5679 21.8368C19.7169 21.7786 19.8761 21.7505 20.0361 21.7543H55.8673C56.0253 21.7504 56.1825 21.7787 56.3293 21.8374C56.476 21.8961 56.6093 21.984 56.7211 22.0958C56.8329 22.2075 56.9208 22.3409 56.9795 22.4876C57.0382 22.6344 57.0665 22.7915 57.0626 22.9496C57.0683 23.11 57.0416 23.27 56.984 23.42C56.9265 23.5699 56.8394 23.7067 56.7278 23.8222C56.6162 23.9377 56.4825 24.0295 56.3347 24.0922C56.1868 24.1549 56.0279 24.1872 55.8673 24.1871H20.0361ZM20.0361 35.9152C19.709 35.9115 19.3963 35.78 19.1651 35.5487C18.9338 35.3174 18.8022 35.0048 18.7986 34.6777C18.8095 34.3569 18.9447 34.0528 19.1756 33.8298C19.4065 33.6068 19.715 33.4822 20.0361 33.4824H55.8673C56.0248 33.4805 56.1811 33.5101 56.327 33.5695C56.4728 33.6289 56.6054 33.7169 56.7167 33.8283C56.8281 33.9396 56.9161 34.0722 56.9755 34.218C57.0349 34.3639 57.0645 34.5202 57.0626 34.6777C57.0664 34.8377 57.0383 34.9968 56.9801 35.1459C56.9218 35.295 56.8346 35.431 56.7234 35.5461C56.6122 35.6612 56.4793 35.7531 56.3324 35.8165C56.1854 35.8798 56.0273 35.9134 55.8673 35.9152H20.0361ZM20.0361 47.6293C19.8653 47.6476 19.6926 47.6299 19.5292 47.5771C19.3658 47.5244 19.2153 47.4378 19.0876 47.3231C18.9598 47.2083 18.8577 47.068 18.7877 46.9112C18.7178 46.7544 18.6816 46.5846 18.6816 46.4129C18.6816 46.2411 18.7178 46.0713 18.7877 45.9145C18.8577 45.7577 18.9598 45.6174 19.0876 45.5026C19.2153 45.3879 19.3658 45.3013 19.5292 45.2486C19.6926 45.1958 19.8653 45.1781 20.0361 45.1964H55.8673C56.1674 45.2288 56.4449 45.3708 56.6466 45.5954C56.8482 45.8199 56.9598 46.1111 56.9598 46.4129C56.9598 46.7146 56.8482 47.0058 56.6466 47.2303C56.4449 47.4549 56.1674 47.5969 55.8673 47.6293H20.0361Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}

export function WhatsApp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      viewBox="0 0 80 79"
      fill="none"
    >
      <g clipPath="url(#clip0_2672_19458)">
        <path
          d="M79.0098 58.7345C79.0098 59.1649 78.9967 60.0983 78.9709 60.8193C78.9078 62.5835 78.7682 64.8601 78.5571 65.8973C78.2399 67.4561 77.761 68.9273 77.1366 70.1519C76.3978 71.6005 75.4554 72.8981 74.3356 74.018C73.2183 75.1357 71.9245 76.0762 70.4808 76.8134C69.2523 77.4407 67.7747 77.9208 66.2101 78.2373C65.1852 78.4446 62.9301 78.5818 61.1806 78.6441C60.4602 78.6699 59.5285 78.6829 59.1005 78.6829L20.47 78.6767C20.0404 78.6767 19.1089 78.6635 18.3893 78.6376C16.6285 78.5744 14.3564 78.4345 13.3211 78.2231C11.7654 77.9051 10.297 77.4254 9.07484 76.7998C7.62899 76.0594 6.33399 75.1153 5.21628 73.9933C4.10078 72.8738 3.16211 71.5775 2.42639 70.131C1.80015 68.9 1.32101 67.4195 1.00514 65.8519C0.798279 64.825 0.66137 62.5656 0.599083 60.8125C0.573495 60.0909 0.560547 59.1573 0.560547 58.7285L0.566676 20.0224C0.566676 19.592 0.579777 18.6586 0.605596 17.9376C0.668802 16.1733 0.808315 13.8968 1.01939 12.8596C1.33664 11.3009 1.81548 9.82955 2.43995 8.60495C3.17874 7.15635 4.12116 5.85874 5.24087 4.73884C6.3582 3.62125 7.65205 2.68067 9.09568 1.94343C10.3243 1.31613 11.802 0.836049 13.3665 0.519554C14.3914 0.312217 16.6465 0.175041 18.396 0.112709C19.1164 0.0869934 20.0482 0.0740204 20.476 0.0740204L59.1066 0.0802382C59.5363 0.0802382 60.4678 0.0933647 61.1874 0.119234C62.9482 0.182564 65.2202 0.322349 66.2555 0.533832C67.8112 0.851708 69.2796 1.33148 70.5018 1.95718C71.9476 2.6974 73.2426 3.64167 74.3603 4.76356C75.4757 5.88308 76.4145 7.17938 77.1502 8.6259C77.7764 9.85695 78.2556 11.3374 78.5715 12.905C78.7783 13.9319 78.9151 16.1914 78.9775 17.9444C79.0032 18.6661 79.0161 19.5997 79.0161 20.0285L79.0098 58.7345Z"
          fill="#1E3A8A"
        />
        <path
          d="M60.5755 18.6724C55.264 13.346 48.2002 10.4112 40.6742 10.4082C25.1674 10.4082 12.5468 23.0486 12.5406 38.5858C12.5386 43.5523 13.834 48.4003 16.2959 52.6735L12.3047 67.2761L27.2187 63.3576C31.3279 65.6025 35.9543 66.7855 40.663 66.7875H40.6745C56.1797 66.7875 68.8015 54.1456 68.8078 38.6082C68.8108 31.0788 65.8872 23.9987 60.5755 18.6724ZM40.6745 62.0283H40.665C36.4691 62.0267 32.3537 60.8977 28.7635 58.7636L27.9096 58.2561L19.0594 60.5815L21.4217 51.9385L20.8656 51.0524C18.5249 47.3233 17.2886 43.0131 17.2905 38.5877C17.2955 25.6739 27.7854 15.1675 40.6836 15.1675C46.9292 15.1699 52.8003 17.6094 57.2152 22.0364C61.63 26.4635 64.06 32.3482 64.0577 38.6065C64.0526 51.5213 53.5628 62.0283 40.6745 62.0283ZM53.5008 44.4868C52.7977 44.1343 49.3418 42.4309 48.6974 42.1959C48.053 41.9609 47.5845 41.8434 47.1157 42.5484C46.6472 43.2533 45.3 44.8393 44.8898 45.3091C44.4798 45.7791 44.0697 45.8379 43.3668 45.4854C42.6638 45.1329 40.3988 44.3894 37.7138 41.9907C35.6242 40.1238 34.2133 37.8179 33.8032 37.1129C33.3932 36.408 33.7596 36.027 34.1115 35.6759C34.4277 35.3605 34.8144 34.8535 35.1659 34.4424C35.5174 34.0313 35.6346 33.7375 35.8689 33.2676C36.1032 32.7976 35.986 32.3864 35.8103 32.034C35.6346 31.6816 34.2287 28.2159 33.6429 26.8061C33.0723 25.4331 32.4927 25.6189 32.0612 25.5973C31.6516 25.5769 31.1825 25.5726 30.7139 25.5726C30.2454 25.5726 29.4837 25.7488 28.8394 26.4537C28.1951 27.1586 26.3791 28.8621 26.3791 32.3277C26.3791 35.7933 28.898 39.1415 29.2495 39.6114C29.601 40.0813 34.2063 47.1933 41.2579 50.2432C42.9352 50.9686 44.2445 51.4019 45.2654 51.7264C46.9494 52.2622 48.4818 52.1866 49.6932 52.0054C51.0437 51.8033 53.8521 50.3021 54.438 48.6574C55.0238 47.0126 55.0238 45.6029 54.848 45.3092C54.6723 45.0155 54.2037 44.8393 53.5008 44.4868Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2672_19458">
          <rect
            width="78.4523"
            height="78.6055"
            fill="white"
            transform="translate(0.5625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CallSupport() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
    >
      <path
        d="M44.4942 78.6055C66.2005 78.6055 83.7969 61.0091 83.7969 39.3028C83.7969 17.5964 66.2005 0 44.4942 0C22.7878 0 5.19141 17.5964 5.19141 39.3028C5.19141 61.0091 22.7878 78.6055 44.4942 78.6055Z"
        fill="#1E3A8A"
      />
      <path
        d="M53.4539 41.7131L52.3932 40.6527L51.3327 41.7132L47.9643 45.0816L38.2748 35.392L41.6431 32.0237L42.7037 30.963L41.6431 29.9023L34.2611 22.5204L33.2005 21.4597L32.1398 22.5204L27.7108 26.9494L27.2715 27.3887L27.2715 28.0099C27.2712 31.6969 27.9972 35.3478 29.408 38.7542C30.8188 42.1606 32.8867 45.2558 35.4937 47.863C38.1007 50.4701 41.1958 52.5383 44.6021 53.9493C48.0084 55.3603 51.6593 56.0866 55.3463 56.0866H55.9676L56.4069 55.6472L60.8376 51.2165L61.8984 50.1557L60.8375 49.095L53.4539 41.7131Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
