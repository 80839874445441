import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import formatCurrency from "../../utils/formatCurrency";
import { useTheme } from "../../utils/ThemeProvider";

const upColor = "#ec0000";
const downColor = "#00da3c";

export default function EodGraph(props: {
  loading: boolean;
  rowData: any[];
  graphHeight: number;
}) {
  const [options, setOptions] = useState({});
  const {theme} = useTheme();
  const upBorderColor = theme === "dark" ? "#00da3c":"#fff";
  const downBorderColor = theme === "dark" ? "#ec0000":"#fff";
  useEffect(() => {
    if (props.rowData.length > 0) {
      const data = props.rowData.map((item: any) => [
        item.date,
        parseFloat(item.dayOpen),
        parseFloat(item.dayHigh),
        parseFloat(item.dayLow),
        parseFloat(item.ltp),
        parseInt(item.volume),
        item.dayOpen < item.ltp ? 1 : -1,
        parseFloat(item.totalOi),
      ]);
      setOptions({
        dataset: {
          source: data,
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ['Price', 'Volume', 'OI'],
          textStyle: {
            color: theme === "dark" ? "#8c8c8c" : "#595959", 
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          position: function (pos:any, params:any, el:any, elRect:any, size:any) {
            const obj: Record<string, number> = {
              top: 10
            };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
          formatter: function (params:any) {
            const tooltipData = params[0].data;
            return (`
              <div style="text-align: left;">
               <strong>${params[0].dataIndex}</strong><br />
                <strong>${tooltipData[0]}</strong><br />
                <div class= "flex gap-2 items-center">
                <div class="icon bg-[#C47321]"></div>
                OI:<strong> ${tooltipData[7]}</strong> 
                </div>
                 <div class= "flex gap-2 items-center">
                <div class="icon bg-[#08AEBF]"></div>
                Volume:<strong> ${tooltipData[5]}</strong> 
                </div>
                <div class= "flex gap-2 items-center">
                <div class="icon bg-[#00da3c]"></div>
                <strong>Price</strong>
                </div>
                <span class="pl-1">Open:</span> <strong>${formatCurrency(tooltipData[1])}</strong><br />
                <span class="pl-1">High:</span> <strong>${formatCurrency(tooltipData[2])}</strong><br />
                <span class="pl-1">Low:</span> <strong>${formatCurrency(tooltipData[3])}</strong><br />
                <span class="pl-1">Close:</span><strong> ${formatCurrency(tooltipData[4])}</strong><br />
              </div>
            `);
          },
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ],
          label: {
            backgroundColor: '#777'
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
            brush: {
              type: ['lineX', 'clear']
            }
          },
        },
        brush: {
          xAxisIndex: 'all',
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1
          }
        },
        grid: [
          {
            left: "2%",
            right: "6%",
            // bottom: 200,
            height: '50%'
          },
          {
            left: "2%",
            right: "6%",
            top: '63%',
            height: '16%'
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            // axisLine: { onZero: true },
            splitLine: { show: false },
            min: "dataMin",
            max: "dataMax",
            axisPointer: {
              z: 100
            },
            axisLine:{
              show:true,
              lineStyle: {
                color: theme === "dark" ? "#8c8c8c" : "#595959",
              },
            }
          },
          {
            type: "category",
            gridIndex: 1,
            boundaryGap: true,
            // axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            min: "dataMin",
            max: "dataMax",
            axisLine:{
              show:true,
              lineStyle: {
                color: theme === "dark" ? "#8c8c8c" : "#595959",
              },
            }
          },
        ],
        yAxis: [
          {
            scale: true,
            splitArea: {
              show: true,
            },
            position:"right",
            name:"Price",
            axisLine:{
              show:true,
              lineStyle: {
                color: theme === "dark" ? "#8c8c8c" : "#595959",
              },
            }
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            // axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            position:"right",
            axisLine:{
              show:true,
              lineStyle: {
                color: theme === "dark" ? "#8c8c8c" : "#595959",
              },
            }
          },
        ],
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0, 1],
            start: 10,
            end: 100,
          },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: "slider",
            // bottom: 10,
            top: '85%',
            start: 10,
            end: 100,
          },
        ],
        // visualMap: {
        //   show: false,
        //   seriesIndex: 1,
        //   dimension: 6,
        //   pieces: [
        //     {
        //       value: 1,
        //       color: upColor,
        //     },
        //     {
        //       value: -1,
        //       color: downColor,
        //     },
        //   ],
        // },
        series: [
          {
            name:"Price",
            type: "candlestick",
            itemStyle: {
              color: downColor,
              color0: upColor,
              borderColor: upBorderColor,
              borderColor0: downBorderColor,
            },
            encode: {
              x: 0,
              y: [1, 4, 3, 2],
            },
          },
          {
            name: "Volume",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            itemStyle: {
              color: "#08AEBF",
            },
            // large: true,
            encode: {
              x: 0,
              y: 5,
            },
          },
        ],
      });
    }
  }, [props.rowData]);

  return (
      <div className="chart__wrapper">
        <ReactECharts option={options} style={{ height: `${props.graphHeight > 380 ? props.graphHeight : 380}px` }} showLoading={props.loading}/>
      </div>
  );
}