import AppLayout from '../../../layout/AppLayout'
import FutureSpurtz from '../../../components/future-spurtz/FutureSpurtz'
export default function FutureSpurtzPage() {
  return (
    <AppLayout>
      <FutureSpurtz />
    </AppLayout>
  )
}

