// import { useTheme } from 'lib/ThemeProvider'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icons } from '../../utils/icons'
import { useScrollTop } from '@wojtekmaj/react-hooks'
import { cn } from '../../lib/utils'
import { buttonVariants } from '../ui/button'
import { ThemeToggle } from '../shared/ThemeToggle'
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'
import { Logo } from '../ui/logo'
function Header() {
    const scrollTop = useScrollTop()
    return (
        <header
            className={`${scrollTop && scrollTop > 80
                ? "fixed bg-white dark:bg-zinc-900"
                : "absolute text-white"
                } top-0 z-30 w-full border-b text-black duration-300 dark:text-white`}
        >
            <div className="container flex  items-center justify-between  p-3 lg:items-end">
                <Link
                    to="/"
                    className="flex flex-col items-center justify-center gap-y-1"
                >
                    <Logo showText={false} />
                    <p className="font-extrabold">TRADE DONS</p>
                </Link>
                <div className="hidden items-end gap-6 lg:flex">
                    <Link className="text-blue-600" to="/">
                        <span className="flex gap-3 text-red-600">
                            Live <Icons.radio className="animate-pulse" />
                        </span>
                        Market Data
                    </Link>
                    <a 
                    href="https://tradingasprofession.in/"
                    target="_blank" 
                    rel="noopener noreferrer"
                    >Education</a>
                    <Link to="/support">Support</Link>
                    <Link to="/pricing">Pricing</Link>
                </div>

                <div className="hidden items-center gap-4  lg:flex">
                    <Link
                        className={cn(
                            buttonVariants({
                                variant: "default",
                                className: "px-8 py-3 text-sm",
                            })
                        )}
                        to="/login"
                    >
                        Login
                    </Link>
                    <Link
                        className={cn(
                            buttonVariants({
                                variant: "outline",
                                className:
                                    "bg-transparent px-8 py-3 dark:bg-transparent dark:text-white dark:hover:bg-white dark:hover:text-black",
                            })
                        )}
                        to="/register"
                    >
                        Register
                    </Link>
                    <div className="hidden md:block">
                        <ThemeToggle />
                    </div>
                </div>
                <div className="relative z-10 flex items-center  dark:text-white lg:hidden">
                    <ThemeToggle />
                    <Sheet>
                        <SheetTrigger className="lg:hidden">
                            <Icons.menu />
                        </SheetTrigger>
                        <SheetContent className="flex min-h-screen items-center justify-center  text-center">
                            <div className="flex flex-col gap-6 ">
                                <Link to="/">Market Data</Link>
                                <Link to="/">Education</Link>
                                <Link to="/">Support</Link>
                                <Link to="/">Pricing</Link>

                                <Link
                                    className={cn(
                                        buttonVariants({
                                            variant: "default",
                                            className: "px-8 py-3 text-sm",
                                        })
                                    )}
                                    to="/login"
                                >
                                    Login
                                </Link>
                                <Link
                                    className={cn(
                                        buttonVariants({
                                            variant: "outline",
                                            className: "bg-transparent px-8 py-3 dark:text-white",
                                        })
                                    )}
                                    to="/register"
                                >
                                    Register
                                </Link>
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>
            </div>
        </header>
    )
}

export default Header