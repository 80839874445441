import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export function OpenInterestSpot(props: {
  rows?: any | Record<string, string>,

}) {
  return (
    <div className="mt-4 flex h-full custom-scrollbar w-full justify-between custom-scrollbar gap-4 overflow-x-auto pb-2 text-xs">
      <SpotPriceCard
        title1="Spot Price"
        value1={props.rows.spotPrice === undefined || props.rows.spotPrice === "" ? "-" : formatCurrency(parseFloat(props.rows.spotPrice))}
        title2="Current Future Price"
        value2={props.rows.ltp === undefined || props.rows.ltp === "" ? "-" : formatCurrency(parseFloat(props.rows.ltp))}
      />
      <SpotPriceCard
        title1="Current Build-Ups"
        value1={props.rows.buildup === undefined || props.rows.buildup === "" ? "-" : (props.rows.buildup)}
        title2="Last Traded Price"
        value2={props.rows.ltp === undefined || props.rows.ltp === "" ? "-" : formatCurrency(parseFloat(props.rows.ltp))}
      />
      <SpotPriceCard
        title1="Previous Day Close"
        value1={props.rows.prevClose === undefined || props.rows.prevClose === "" ? "-" : formatCurrency(parseFloat(props.rows.prevClose))}
        title2="Today's Open"
        value2={props.rows.todayOpen === undefined || props.rows.todayOpen === "" ? "-" : formatCurrency(parseFloat(props.rows.todayOpen))}
      />
      <SpotPriceCard
        title1="Today's Day High"
        value1={props.rows.dayhigh === undefined || props.rows.dayhigh === "" ? "-" : formatCurrency(parseFloat(props.rows.dayhigh))}
        title2="Today's Day Low"
        value2={props.rows.dayLow === undefined || props.rows.dayLow === "" ? "-" : formatCurrency(parseFloat(props.rows.dayLow))}
      />
    </div>
  );
}
