import { format } from "date-fns";
import { CalendarDaysIcon, Loader } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import { Button, buttonVariants } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Checkbox } from "../ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { SelectGroup, SelectItem, SelectLabel } from "../ui/select";
import { TrendFinderTable } from "./TrendFinderTable";
import { FoiTimeInterval } from "../../data/openInterest";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import SelectBox from "../shared/SelectBox";
import LiveSwitch from "../shared/LiveSwitch";
import { cn } from "../../lib/utils";
import useMarketStatus from "../../hooks/useMarketStatus";
import Breadcrumb from "../shared/Breadcrumb";
import { toast } from "sonner";
import {
  ExpiryStrikePrice,
  InstrumentExpiryStrikePrice,
  getTrendFinderData,
  getTrendFinderLiveData,
} from "../../api/options";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { getIndexSubscription } from "../../api/futures/open-interest";
import { useNavigate } from "react-router-dom";
import TFMobileCard from "./TFMobileCard";
import TrendFinderSpotPrice from "./TrendFinderSpot";
import CollapseButton from "../shared/CollapseButton";
import convertToYYYYMMDD, {
  findNearestDate,
  findNearestStrikePrices,
  formatDate,
} from "../shared/dateConverter";
import { getTimeDataMap } from "./helper";
import { RefreshRateData } from "../../constants/TimeInterval";
import { dateSort } from "../../utils/datesort";
import { configEnvironemt } from "../../config";
import useSpotPriceStore from "../../store/spotPrice";
import TFChart from "./TFChart";
import TFSentiment from "./TFSentiment";
import { TrendfinderLiveMessageType, breadcrumbAdvancedTF, breadcrumbTrendFinder } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { useTheme } from "../../utils/ThemeProvider";
import { baseURL } from "../../api/base";

const dataMap = new Map<string, Map<string, TrendfinderLiveMessageType[]>>();

export default function TrendFinder(props: {
  trendFinderList: InstrumentExpiryStrikePrice[],
  valueBeforeAfter: number
}) {
  const expiryDates = props.trendFinderList[0].ExpiryStrikePriceList.map((exp) => exp.Expiry);
  const { spotPrices } = useSpotPriceStore();
  const initialDate = findNearestDate(expiryDates);
  const environment = configEnvironemt.environment;
  const [historical, setHistorical] = useState<boolean>(false);
  const [graphVisible, setGraphVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<any[] | []>([]);
  const [selectedStrikePrices, setSelectedStrikePrices] = useState<string[]>([]);
  const [strikePriceList, setStrikePriceList] = useState<string[]>([]);
  const [instrument, setInstrument] = useState(props.trendFinderList[0].ProductName);
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [timeInterverl, setTimeInterverl] = useState(FoiTimeInterval[1].value);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `TRENDINGOI_OPTIDX_${props.trendFinderList[0]?.ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [toIdentifier, setToIdentifer] = useState("");
  const [date, setDate] = useState<any>(new Date());
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [overAllTrend, setOverAllTrend] = useState("");
  const [historicSpotData, setHistoricSpotData] = useState<InstrumentExpiryStrikePrice[]|null>(null);
  const marketStatus = useMarketStatus();
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const [collapseData, setCollapseData] = useState({});
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const { theme } = useTheme();
  const [tableHeight, setTableHeight] = useState(370);
  const containerRef = useRef<HTMLDivElement>(null);
  let id:any;
  const handleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const handleSwitch = () => {
    dataMap.clear();
    setRows([]);
    setSelectedStrikePrices([]);
     // historic to live
     if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(props.trendFinderList[0].ProductName);
      Init();
    }
    if (!historical) {
      clearInterval(id);
      setHistorical(true);
      setIsLoading(false);
      dataMap.clear();
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  function processMessage(message: string[]) {
    message.forEach((item) => {
      let keystrike = item.split(",")[0];
      let time = item.split(",")[1];
      let msgData = {
        ltp: item.split(",")[2],
        calloi: item.split(",")[3],
        putoi: item.split(",")[4],
        oidiff: item.split(",")[5],
        changeindir: item.split(",")[6],
        changeindirper: item.split(",")[7],
        trend: item.split(",")[8],
        dayHighLow: item.split(",")[15],
      };
      if (selectedStrikePrices.includes(keystrike)) {
        if (dataMap.has(keystrike)) {
          let keyValue = dataMap.get(keystrike);
          let dataArray: any[] = [];
          dataArray?.push(msgData);
          keyValue?.set(time, dataArray);
        } else {
          const innerMap = new Map<string, any[]>();
          let valueData: any[] = [];
          valueData?.push(msgData);
          innerMap.set(time, valueData);
          dataMap.set(keystrike, innerMap);
        }
      }
    });
    const CollapsableData = {
      currentfutureprice: message[message.length - 2].split(",")[9],
      premiuminCall: message[message.length - 2].split(",")[11],
      atmCallIV: message[message.length - 2].split(",")[10],
      atmPutIV: message[message.length - 2].split(",")[12],
      premiumInput: message[message.length - 2].split(",")[13],
      trend: message[message.length - 2].split(",")[8],
      SpotPrice: message[message.length - 2].split(",")[14],
    };
    setCollapseData(CollapsableData);
  }

   function calculateData() {
    let rowdata: any[] = [];
    let previousChangeInCallOi = 0;
    let previousChangeInPutOi = 0;
    let timedataMap = getTimeDataMap(timeInterverl);
    if (timedataMap && timedataMap.length > 0) {
      timedataMap.forEach((time: any, index: number) => {
        let changeincalloi = 0;
        let changeinputoi = 0;
        let diffinoi = 0;
        let changeindirection = 0;
        let directionofchangeper = 0;
        let ltpValue = 0;
        let localtrend ;
        let localDayHighLow;

        dataMap.forEach((dataValueWithTime, strikeprice) => {
          if (dataValueWithTime.has(time)) {
            let item = dataValueWithTime.get(time);
            if (item !== undefined) {
              if (item[0].calloi === "") {
                changeincalloi = changeincalloi;
              } else {
                changeincalloi = changeincalloi + parseFloat(item[0].calloi);
              }
              if (item[0].putoi === "") {
                changeinputoi = changeinputoi;
              } else {
                changeinputoi = changeinputoi + parseFloat(item[0].putoi);
              }
              changeindirection = changeindirection + parseFloat(item[0].changeindir);
              ltpValue = item[0].ltp === "" ? -100000000000000 : parseFloat(item[0].ltp);
              localDayHighLow = item[0].dayHighLow === "" ? "-" : item[0].dayHighLow

            }
          }
        });
        diffinoi = changeinputoi - changeincalloi;
        
        if (index > 0 && rowdata.length) {
          if (diffinoi === 0) {
            if (rowdata[rowdata.length - 1].changeInDirection >= 0) {
              rowdata[rowdata.length - 1].directionOfChangePercent = "∞";
              rowdata[rowdata.length - 1].trend = "Bullish";
            } else {
              rowdata[rowdata.length - 1].directionOfChangePercent = "-∞";
              rowdata[rowdata.length - 1].trend =  "Bearish";
            }
          } else {
            let newLocalDiff = diffinoi;
            if(diffinoi<0)
            {
              newLocalDiff=(-1)*diffinoi;
            }
            rowdata[rowdata.length - 1].directionOfChangePercent =
              (100 * rowdata[rowdata.length - 1].changeInDirection) / newLocalDiff;
              const dirOfChangePercent =  rowdata[rowdata.length - 1].directionOfChangePercent;
              localtrend = (dirOfChangePercent >= 0 && dirOfChangePercent <= 50)
                  ? "Mild Bullish"
                  : dirOfChangePercent > 50
                    ? "Bullish"
                    : (dirOfChangePercent < 0 && dirOfChangePercent >= -50)
                      ? "Mild Bearish"
                      : "Bearish";
              rowdata[rowdata.length - 1].trend = localtrend;
              const newDiffCallOI = changeincalloi - previousChangeInCallOi
              const newDiffPutOI = changeinputoi - previousChangeInPutOi
              if (rowdata[rowdata.length - 1].changeInCallOi < changeincalloi && rowdata[rowdata.length - 1].changeinputoi > changeinputoi && Math.abs(newDiffCallOI)> 200000) {
                setOverAllTrend("Bearish");
              } else if (rowdata[rowdata.length - 1].changeInCallOi > changeincalloi && rowdata[rowdata.length - 1].changeinputoi < changeinputoi && Math.abs(newDiffPutOI)>200000) {
                setOverAllTrend("Bullish");
              } else {
                setOverAllTrend("Sideways");
              }
          }
        }
    
        let arrowDirection =
          changeindirection === 0
            ? "noChange"
            : changeindirection < 0
              ? "down"
              : "up";
              
        if (ltpValue !== 0) {
          rowdata.push({
            time: time,
            ltp: ltpValue === -100000000000000 ? "-" : ltpValue,
            dayHighLow: localDayHighLow,
            changeInCallOi: changeincalloi,
            changeInPutOi: changeinputoi,
            diffInOi: diffinoi,
            directionOfChange: arrowDirection,
            changeInDirection: changeindirection,
            directionOfChangePercent:
              changeindirection < 0
                ? "-∞"
                : changeindirection >= 0
                  ? "∞"
                  : directionofchangeper,
            DayHighLowDiffInOI: "-",
            trend: changeindirection < 0
            ? "Bearish"
            : changeindirection >= 0
              ? "Bullish"
              : localtrend,
          });
        }
        
        previousChangeInCallOi = changeincalloi;
        previousChangeInPutOi = changeinputoi;
      });
    }
    setIsLoading(false)
    return rowdata;
  }

  function updateRowDataOnStrikePriceRemoval(strikePrice: any) {
    if (dataMap.has(strikePrice)) {
      let keyValue = dataMap.get(strikePrice);
      let prevdiffinoi = 0;
      let data: TrendfinderLiveMessageType[] | undefined = [];
      rows.forEach((item) => {
        if (keyValue?.has(item.time)) {
          let directionofchangeper = 0;
          data = keyValue.get(item.time);
          if (data !== undefined) {
            item.changeInCallOi =
              item.changeInCallOi - parseFloat(data[0].calloi);
            item.changeInPutOi = item.changeInPutOi - parseFloat(data[0].putoi);
            item.changeInDirection =
              item.changeInDirection - parseFloat(data[0].changeindir);
            item.diffInOi =
              item.diffInOi -
              (parseFloat(data[0].putoi) - parseFloat(data[0].calloi));
            if (prevdiffinoi === 0 && item.changeInDirection < 0) {
              directionofchangeper = -10000000000;
            } else if (prevdiffinoi === 0 && item.changeInDirection > 0) {
              directionofchangeper = 10000000000;
            } else {
              directionofchangeper =
                (item.changeInDirection / prevdiffinoi) * 100;
            }
            item.directionOfChangePercent =
              directionofchangeper === -10000000000
                ? "-∞"
                : directionofchangeper === 10000000000
                  ? "∞"
                  : directionofchangeper;
            item.trend =
              directionofchangeper >= 0 && directionofchangeper <= 50
                ? "Mild Bullish"
                : directionofchangeper > 50
                  ? "Bullish"
                  : directionofchangeper < 0 && directionofchangeper >= -50
                    ? "Mild Bearish"
                    : "Bearish";
            item.directionOfChange =
              item.changeInDirection === 0
                ? "noChange"
                : item.changeInDirection < 0
                  ? "down"
                  : "up";
            prevdiffinoi = item.diffInOi;
          }
        }
      });
    }
    dataMap.delete(strikePrice);
  }

  async function liveMsgTrendFinder(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    await processMessage(messages);
      const fRows = calculateData();
      const reversedRow = fRows;
      setRows(reversedRow);
    }
  }

  async function getHistoricTrendFinderData(identifier: string, topicName:string) {
    let response;
    if (historical && date!=="" && identifier !== undefined) {
      response = await getTrendFinderData(identifier, topicName, date);
    }else{
      response = await getTrendFinderData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      await processMessage(messages);
      const fRows = calculateData();
      const reversedRow = fRows;
      setRows(reversedRow);
    }
  }

  const handleProductChange = (productName: string) => {
    dataMap.clear();
    setRows([]);
    setCollapseData({});
    setInstrument(productName);
    if(!historical){
      setIsLoading(true);
      getExpiryList(productName, props.trendFinderList);
    }
    if(historical){
      setDate("");
      setExpiry("");
      setSelectedStrikePrices([]);
      setOverAllTrend("");
    }
  };

  const handleExpiryChange = (expiryDate: string) => {
    dataMap.clear();
    setRows([]);
    setExpiry(expiryDate);
    if (instrument !== undefined && !historical) {
      getStrikePriceListByExpiry(instrument, props.trendFinderList, expiryDate);
    } else{
      getStrikePriceListByExpiry(instrument, historicSpotData, expiryDate);
    }
  };

  function handleDateSelect (date:any) {
    dataMap.clear();
    setRows([]);
    setCollapseData({});
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
      setHistoricSpotData(data);
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  function getExpiryList(
    productName: string,
    indexDataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    const expiryList: string[] = [];
    let nearSpotPrice:any;
    if (indexDataList !== null && indexDataList.length > 0) {
      let filteredList = indexDataList.filter(
        (product) => product.ProductName === productName
      );
      let expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expiryStrikePriceData.map((exp) => exp.Expiry);
      const nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null && nearestDate !== undefined) {
        setExpiry(nearestDate);
        if(!historical){
          nearSpotPrice = spotPrices[productName] ? spotPrices[productName]: indexDataList.find((item) => item.ProductName === productName)?.SpotPrice;
        } else{
         const historicFilteredList = indexDataList?.filter((item) => item.ProductName === productName);
         if(historicFilteredList && historicFilteredList.length > 0){
          nearSpotPrice = historicFilteredList[0].SpotPrice;
         }
        }
        getStrikePriceList(
          expiryStrikePriceData,
          nearestDate,
          nearSpotPrice,
          productName
        );
      }
      expiryStrikePriceData.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
      const sortedDate = dateSort(expiryList);
      setExpiryList(sortedDate);
    }
  }

  async function getStrikePriceList(
    expiryStrikePriceList: ExpiryStrikePrice[],
    expiry: string,
    spot: string,
    productName: string
  ) {
    let strikePriceList: string[] = [];
    let nearestStrikePrice:any;
    if (expiryStrikePriceList !== null && expiryStrikePriceList.length > 0) {
      let filteredList = expiryStrikePriceList.filter(
        (item) => item.Expiry === expiry
      );
      strikePriceList = filteredList[0].StrikePrice;
      const shortedList = strikePriceList.sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );
      setStrikePriceList(shortedList);
      if(!historical){
        nearestStrikePrice = await findNearestStrikePrices(
          spot,
          strikePriceList,
          props.valueBeforeAfter,
          props.valueBeforeAfter,
          productName
        );
      }else{
        nearestStrikePrice = await findNearestStrikePrices(
          spot,
          strikePriceList,
          props.valueBeforeAfter,
          props.valueBeforeAfter,
        );
      }
      if (nearestStrikePrice) {
        setSelectedStrikePrices(nearestStrikePrice);
      }
        handleIdentifierChange(productName, expiry, nearestStrikePrice, timeInterverl);
    }
  }

  async function getStrikePriceListByExpiry(
    productName: string,
    indexDataList: InstrumentExpiryStrikePrice[] | null,
    expiry: string
  ) {
    let selectedStrikePrices: string[] = [];
    if (indexDataList !== null && indexDataList.length > 0) {
      let filteredList = indexDataList.filter(
        (product) => product.ProductName === productName
      );
      let expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      let expiryData = expiryStrikePriceData.filter(
        (exp) => exp.Expiry === expiry
      );
      const strikePriceList = expiryData[0].StrikePrice;
      const nearSpotPrice = spotPrices[productName] ? spotPrices[productName] : indexDataList.find((item) => item.ProductName === productName)?.SpotPrice;
      const shortArray = strikePriceList.sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );
      setStrikePriceList(shortArray);
      if(!historical){
        selectedStrikePrices = await findNearestStrikePrices(
          nearSpotPrice,
          strikePriceList,
          props.valueBeforeAfter,
          props.valueBeforeAfter,
          productName
        );
      } else{
        const historicSpot = filteredList[0].SpotPrice;
        selectedStrikePrices = await findNearestStrikePrices(
          historicSpot,
          strikePriceList,
          props.valueBeforeAfter,
          props.valueBeforeAfter,
        );
      }
      if (selectedStrikePrices) {
        setSelectedStrikePrices(selectedStrikePrices);
        handleIdentifierChange(
          productName,
          expiry,
          selectedStrikePrices,
          timeInterverl
        );
      }
    }
  }

  async function handleIdentifierChange(
    product: string,
    expiry: string,
    strikePrice: string[],
    time: string
  ) {
    if(historical && date === "" && instrument===""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
      (idx) => idx === product
    );
    let topicNameLocal = `TRENDINGOI_${!!isIndex ? "OPTIDX" : "OPTSTK"
      }_${product}`;
    let localToIdentifier = "";
    strikePrice.forEach((item) => {
      if (localToIdentifier !== "") {
        localToIdentifier =
          localToIdentifier +
          "," +
          `trendingoi_${!!isIndex ? "OPTIDX" : "OPTSTK"
          }_${product}_${expiry}_${item}_${time}`;
      } else {
        localToIdentifier =
          localToIdentifier +
          `trendingoi_${!!isIndex ? "OPTIDX" : "OPTSTK"
          }_${product}_${expiry}_${item}_${time}`;
      }
    });
    if (environment === "test") {
      topicNameLocal += "_test";
    }
    setTopicName(topicNameLocal);
    if(!historical){
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId === undefined ? "" : userId,
          localToIdentifier
        );
      } 
    }
    setToIdentifer(localToIdentifier);
  }

  const handleCheckboxChange = async (
    checked: string | boolean,
    item: string
  ) => {
    let updatedItems = [];
    if (!checked) {
      updatedItems = selectedStrikePrices.filter(
        (selectedItem) => selectedItem !== item
      );
      updateRowDataOnStrikePriceRemoval(item);
      setSelectedStrikePrices(updatedItems);
    } else {
      setSelectedStrikePrices([...selectedStrikePrices, item]);
      updatedItems = [...selectedStrikePrices, item];
    }
    await handleIdentifierChange(
      instrument,
      expiry,
      updatedItems,
      timeInterverl
    );
  };

  const handleGraph = () => {
    setGraphVisible(!graphVisible);
  };

  async function Init() {
    dataMap.clear();
    setRows([]);
    await getInstrumentExpiryStrikePriceList(props.trendFinderList);
  }

  async function getInstrumentExpiryStrikePriceList(
    indexExpiryPriceList: any[]
  ) {
    if (indexExpiryPriceList != null && indexExpiryPriceList.length > 0) {
      const initialInstrument = instrument ? instrument : indexExpiryPriceList[0].ProductName
      await getExpiryList(
        initialInstrument,
        indexExpiryPriceList
      );
    }
  }

  const fetchDataAtInterval = async () => {
    await liveMsgTrendFinder(topicName, toIdentifier);
};

const registerGetTrendFinderLiveData = async (identifier:string) => {
   await getTrendFinderLiveData(identifier)
}

const calculateTableHeight = () => {
  if (containerRef.current) {
    const containerHeight = containerRef.current.clientHeight;
    const topBarHeight = document.querySelector("header")?.clientHeight || 0;
    const windowHeight = window.innerHeight;
    let offset:number;
    if(graphVisible){
      offset = containerHeight + topBarHeight + 88;
    }else{
      offset = containerHeight + topBarHeight + 28;
    }
    if(windowHeight - offset > 370){
      setTableHeight(windowHeight - offset);
    }else{
      setTableHeight(370);
    }
  }
}

useEffect(() => {
  calculateTableHeight(); 
  window.addEventListener("resize", calculateTableHeight);
  return () => window.removeEventListener("resize", calculateTableHeight);
}, [collapsed, historical, date, isLoading, graphVisible]);

useEffect(()=>{
  setTimeout(()=>{
    setIsLoading(false)},12000)
},[instrument,expiry, selectedStrikePrices, date])

  useEffect(() => {
   if(!historical){
    Init();
    }
  }, [historical, userId, marketStatus]);

  React.useEffect(()=>{
    if(!historical && marketStatus === "open"){
    fetchDataAtInterval()
    }
  },[toIdentifier, marketStatus])

  useEffect(() => {
    let timeoutId:any;
    if(!historical && marketStatus === "open"){
      const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
          id = setInterval(fetchDataAtInterval, seconds * 1000);
        }
      }else if(marketStatus === "after-open" && !historical){
        registerGetTrendFinderLiveData(toIdentifier)
        timeoutId = setTimeout(fetchDataAtInterval, 500)
      }else if( marketStatus!=="loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
        getHistoricTrendFinderData(toIdentifier, topicName);
    }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toIdentifier, historical, marketStatus])

  useEffect(() => {
    if (historical) {
        setRows([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if(date !=="" && instrument !==""){
      getHistoricTrendFinderData(toIdentifier, topicName);
      }
    } 
  }, [historical, toIdentifier]);

  if (userId === undefined) return <></>;

  if (isLoggedIn === false) {
    navigate("/login");
  }
  
  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={props.valueBeforeAfter=== 7 ? breadcrumbTrendFinder: breadcrumbAdvancedTF} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-6 sm:grid-cols-3 min-[1072px]:grid-cols-7">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e: string) => {
            handleProductChange(e);
          }}
          placeholder="Please Select Name"
        >
          <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
            <SelectLabel className="-ml-3">Index</SelectLabel>
            {props.trendFinderList &&
              props.trendFinderList.slice(0, 4).map((item, index) => (
                <SelectItem key={index} value={item.ProductName}>
                  {item.ProductName}
                </SelectItem>
              ))}
            <SelectLabel className="-ml-3">Stocks</SelectLabel>
            {props.trendFinderList &&
              props.trendFinderList
                .slice(5, props.trendFinderList.length)
                .map((item, index) => (
                  <SelectItem key={index} value={item.ProductName}>
                    {item.ProductName}
                  </SelectItem>
                ))}
          </SelectGroup>
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox
          title="Expiry:"
          value={expiry}
          onValueChange={(e: string) => {
            setCollapseData({});
            handleExpiryChange(e);
          }}
          placeholder="Please Select Expiry"
        >
          {expiryList &&
            expiryList?.map((item, index) => {
              const expiryListData = formatDate(item);
              return (
                <SelectItem key={index} value={item}>
                  {expiryListData}
                </SelectItem>
              );
            })}
        </SelectBox>
        
        <SelectBox
          title="Time Interval:"
          value={timeInterverl}
          onValueChange={(e: string) => {
            setTimeInterverl(e);
            handleIdentifierChange(instrument, expiry, selectedStrikePrices, e);
          }}
          placeholder="5 min"
        >
          {FoiTimeInterval.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectBox>

        <SelectBox
          title="Refresh Rate:"
          value={refreshRate}
          onValueChange={(e:any) => {
            setRefreshRate(e)
          }}
          placeholder="5 min"
        >
          {RefreshRateData.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectBox>

        <div className="w-full pt-5">
          <AlertDialog>
            <AlertDialogTrigger
              style={{ borderColor: "rgb(37, 99, 235)" }}
              className="mt-0 flex w-full items-center justify-between border bg-white px-3 py-2.5 text-start text-[10px] shadow dark:border-zinc-600 dark:bg-transparent sm:text-[12px] border-blue-600 text-blue-600"
            >
              <span>Change Strike Prices</span>
            </AlertDialogTrigger>
            <AlertDialogContent className="w-full dark:text-zinc-50 max-w-[1280px] pb-6 pt-4 text-sm ">
              <p className="border-b pb-1">Select strike prices</p>
              <p className="mt-4">
                Total Strike Prices : {selectedStrikePrices.length}
              </p>

              <p className="text-xs sm:text-sm">
                <span className="mr-1 inline-block">
                  Currently Selected Strike Prices:{" "}
                </span>
                {selectedStrikePrices.map((item, index) => (
                  <span
                    className="mr-1 inline-block text-xs font-semibold "
                    key={index}
                  >
                    {item}
                    {index === selectedStrikePrices.length - 1 ? "" : ","}
                  </span>
                ))}
              </p>

              <div className="mt-2 custom-scrollbar grid grid-cols-3 gap-y-6 md:grid-cols-5 lg:grid-cols-8 h-48 overflow-auto ">
                {strikePriceList &&
                  strikePriceList?.map((item, index) => {
                    return (
                      <div key={index} className="flex items-center"
                      // ref={ref}
                      >
                        <Checkbox
                          checked={selectedStrikePrices.includes(
                            item.toString()
                          )}
                          onCheckedChange={(checked) =>
                            handleCheckboxChange(checked, item.toString())
                          }
                        />
                        <label className="pl-3 pr-4 text-xs capitalize md:text-sm">
                          {item}
                        </label>
                      </div>
                    );
                  })}
              </div>

              <div className="sm:mt-4 flex justify-end gap-4">
                <Button
                  onClick={() => {
                    dataMap.clear();
                    setRows([]);
                    setSelectedStrikePrices([]);
                  }}
                >
                  Clear All
                </Button>

                <AlertDialogAction
                  className={buttonVariants({ variant: "default" })}
                >
                  Ok
                </AlertDialogAction>
              </div>
            </AlertDialogContent>
          </AlertDialog>
        </div>
        <div className="pt-5 flex items-center gap-2">
          <Checkbox onCheckedChange={handleGraph} checked={graphVisible} />
          <p className="text-[12px]">Show Graph View</p>
        </div>
      </div>

      <div className={cn({ hidden: collapsed })}>
        <TrendFinderSpotPrice row={collapseData} overAllTrend={overAllTrend}/>
      </div>
      <div className="flex items-center justify-between gap-5">
        <div className="my-2 flex items-center justify-between">
          <div>
            <span className="mr-1 pl-2 inline-block text-xs font-medium">
              Selected Strike Prices:{" "}
            </span>
            {selectedStrikePrices &&
              selectedStrikePrices.map((item, index) => {
                const strikePriceExists = dataMap.has(item);
                return (
                  <span
                    className={`mr-1 inline-block text-xs font-semibold ${strikePriceExists ? (theme === "dark"?"text-white":"text-grey-600") : "text-red-500"
                      }`}
                    key={index}
                  >
                    {item}
                    {index === selectedStrikePrices.length - 1 ? "" : ","}
                  </span>
                );
              })}
          </div>
        </div>
        <CollapseButton
          collapsed={collapsed}
          handleCollapse={handleCollapsed}
        />
      </div>
      </div>

      {graphVisible ? (
        <div className="custom-scrollbar overflow-x-auto pb-2 mb-4">
          <div className={windowWidth && windowWidth >= 640 ? "grid w-full grid-cols-2 gap-6 mt-3 px-2":"grid w-full grid-cols-1 gap-6 mt-3 px-2"}>
            <TFChart data={rows} loading={isLoading} graphHeight={tableHeight}/>
            <TFSentiment data={rows.reverse()} loading={isLoading} graphHeight={tableHeight}/>
          </div>
        </div>
      ) : (
        <>
          {windowWidth && windowWidth >= 640 ? (
            <TrendFinderTable loading={isLoading} rowData={rows} tableHeight={tableHeight} />
          ) : (
            <div>
              {isLoading ? (
                <Loader className="mx-auto animate-spin my-12" />
              ) : (
                <TFMobileCard rows={rows} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}