import { useEffect, useState } from "react";
import AppLayout from "../../../layout/AppLayout";
import Eod from "../../../components/eod/Eod";
import useIndexExpiryDetailsStore from "../../../store/foiInstrumentExpiry";
import { Icons } from "../../../utils/icons";

export default function EodPage() {
  const { fetchIndexExpiryDetails, indexExpiryDetails } = useIndexExpiryDetailsStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (indexExpiryDetails.length === 0) {
      setLoading(true);
      fetchIndexExpiryDetails();
      setLoading(false);
    }
  }, [indexExpiryDetails]);

  return (
    <AppLayout>
      {loading ? (
        <div className="flex h-[calc(100vh-90px)] items-center justify-center">
          <Icons.spinner
            className="mr-2 h-12 w-12 animate-spin"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          {/* {indexExpiryDetails.length > 0 && <Eod />} */}
          {indexExpiryDetails.length>0 && <Eod indexList={indexExpiryDetails}/>}
        </>
      )}
    </AppLayout>
  );
}
