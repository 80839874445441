import {
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { columns } from "./Columns";
import { TOHTable } from "./OHColumns";
import { Loader, TriangleIcon } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface TableTypes { rowData?: any, loading: boolean, spotPrice?: number | string | null, tableHeight: number;
}
export default function OLTable({ rowData, loading, spotPrice, tableHeight }: TableTypes) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        calliv: false,
        putiv: false,
        premiumleftincall: false,
        premiumleftinput: false,
    });

    const [spotRow, setSpotRow] = useState<TOHTable | undefined>(undefined)
    const [spotPriceRowIndex, setSpotPriceRowIndex] = useState<number | null>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const [newSpotPrice, setNewSpotPrice] = useState<any>()
    const [filteredList, setFilteredList] = useState<[]| TOHTable[]>([])

    const table = useReactTable({
        data: filteredList,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        state: { columnVisibility },
        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        table.setPageSize(30)
    }, [])

    useEffect(() => {
        if (spotPrice) {
            setNewSpotPrice(spotPrice)
        }
    }, [spotPrice])

    useEffect(() => {
        if (spotPriceRowIndex !== null && tableWrapperRef.current) {
            const tableRows = tableWrapperRef.current.querySelectorAll("tr");
            const spotRowScrollView = tableRows[spotPriceRowIndex];
            if (spotRowScrollView) {
                spotRowScrollView.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
            }
        }
    }, [spotPriceRowIndex]);

    useEffect(() => {
        if (rowData && rowData.length > 0 && newSpotPrice) {
            let rowWithSpotPrice: TOHTable | undefined;
            for (const row of rowData) {
                if (row?.strikeprice === newSpotPrice) {
                    rowWithSpotPrice = row;
                    break;
                }
            }
            setSpotRow(prev => {
                if (prev?.strikeprice !== rowWithSpotPrice?.strikeprice) {
                    return rowWithSpotPrice
                } else {
                    return prev
                }
            });
            const rowIndex = rowData.findIndex(
                (row: any) => row?.strikeprice === newSpotPrice
            );
            setSpotPriceRowIndex(rowIndex !== -1 ? rowIndex : null);
        } else {
            setSpotPriceRowIndex(null);
        }

    }, [spotPrice, rowData, newSpotPrice]);

    useEffect(() => {
        if (spotRow) {
            let index: number;
            index = rowData.findIndex((e: TOHTable) => e?.strikeprice === spotRow?.strikeprice)
            let arr: TOHTable[] = [];

            for (let i = index - 1; i >= (index - 9); i--) {
                if (i < 0) {
                    break
                } else {
                    arr.push(rowData[i])
                }
            }
            for (let i = index; i <= (index + 9); i++) {
                if (i < 0) {
                    break
                } else {
                    arr.push(rowData[i])
                }
            }
            arr.sort((a, b) => a?.strikeprice - b?.strikeprice);
            setFilteredList(arr)
        } else if (newSpotPrice !== undefined && !spotRow) {
            let nearestIndex: number = -1;
            let minDiff: number = Infinity;

            for (let i = 0; i < rowData.length; i++) {
                if (typeof parseFloat(newSpotPrice) === 'number') {
                    const diff = Math.abs(rowData[i]?.strikeprice - newSpotPrice);
                    if (diff < minDiff) {
                        minDiff = diff;
                        nearestIndex = i;
                    }
                }
            }

            if (nearestIndex !== -1) {
                let arr: TOHTable[] = [];
                for (let i = nearestIndex - 9; i <= nearestIndex + 9; i++) {
                    if (i >= 0 && i < rowData.length) {
                        arr.push(rowData[i]);
                    }
                }
                arr.sort((a, b) => a?.strikeprice - b?.strikeprice);
                setFilteredList(arr);
            }
        }

    }, [rowData, newSpotPrice, spotRow])

    return (
        <>
            <div
            style={{ maxHeight: `${tableHeight}px` }} 
            ref={tableWrapperRef}
                className={cn("rounded-t-none custom-scrollbar relative w-full overflow-auto rounded-lg border-2 dark:border-zinc-600 border-b-0 rounded-b-none",
                )}>
                <Table>
                    <TableHeader >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{ paddingLeft: "10px !important", paddingRight: "10px !important", fontWeight: 500 }}
                                            className={cn(
                                                "border-b dark:bg-zinc-800 py-4 text-zinc-900 dark:text-zinc-50 dark:border-zinc-600 text-[13px]",
                                                { "bg-gray-400 dark:bg-gray-400 text-zinc-900 dark:text-zinc-900 border-gray-400": header.column.id === "strikeprice" }
                                            )}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </th>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {!loading ?
                            table.getRowModel().rows?.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell, index) => {
                                            const defaultPainted = ["dayopen", "dayhigh", "putdayopen", "putdayhigh"];
                                            const paintableCall = ["newdayhigh", "newdaylow", "ohol", "hittime", "chance", "callltp"]
                                            const paintablePut = ["putltp", "putdayopen", "putdayhigh", "putnewdayhigh", "putnewdaylow", "putohol", "puthittime", "putchance"];
                                            return (
                                                <TableCell
                                                key={index}
                                                    className={
                                                        cn(
                                                            "py-1 px-2 !text-xs border-b border-zinc-300 dark:border-b-zinc-400",
                                                            {
                                                                "highlight-cell":
                                                                (Number(row?.original?.strikeprice) < Number(spotPrice ? spotPrice : "") && paintableCall.includes(`${cell.column.id}`)) ||
                                                                (Number(row?.original?.strikeprice) > Number(spotPrice ? spotPrice : "") && paintablePut.includes(`${cell.column.id}`))
                                                            },
                                                            { "open-low": defaultPainted.includes(`${cell.column.id}`) },
                                                            { "strike-price border-none": cell.column.id === "strikeprice" },
                                                            { "strike-price": (Number(row?.original?.strikeprice) === Number(spotPrice ? spotPrice : "")) },
                                                        )
                                                    }

                                                >
                                                    <span className="relative z-10 block">
                                                        {
                                                            flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()) !== "" || null || undefined ?
                                                                flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )
                                                                : "-"
                                                        }
                                                    </span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )
                            :
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    <Loader className="mx-auto animate-spin" />
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <div className="flex dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1">
                <div className="flex pl-4 items-center justify-between w-full ">
                    <div className="flex items-center ">
                        <p className="text-sm font-medium">Rows per page</p>
                        <Select
                            value={`${table.getState().pagination.pageSize}`}
                            onValueChange={(value) => {
                                table.setPageSize(Number(value))
                            }}
                        >
                            <SelectTrigger className="h-8 w-[64px] dark:bg-zinc-800 border-none">
                                <SelectValue placeholder={table.getState().pagination.pageSize} />
                            </SelectTrigger>
                            <SelectContent side="top">
                                {[10, 20, 30, 40].map((pageSize) => {
                                    return (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    )
                                })}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => table.previousPage()}
                            className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                            disabled={!table.getCanPreviousPage()}
                        >
                            <TriangleIcon className="w-3 -rotate-90 fill-zinc-500 hover:text-fill-400" />
                            Prev
                        </button>
                        <div className="flex w-[50px] items-center justify-center text-sm font-medium">
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                        </div>
                        <button
                            className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            Next
                            <TriangleIcon className="w-3 rotate-90 fill-zinc-500 hover:fill-zinc-400" />
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
}