import AppLayout from '../../../layout/AppLayout'
import FoiScreen from '../../../components/open-interest'
export default function OpenInterestPage() {
  return (
    <AppLayout>
      <FoiScreen />
    </AppLayout>
  )
}

