import convertToYYYYMMDD from "../components/shared/dateConverter";
import baseApi from "./base";

export const getGlobalMarketData = async () => {
  try {
    const response = await baseApi.get(
      `/api/Dashboard/GetGlobalMarketPriceDetails`
    );
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getStockData = async () => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetStockPriceDetails`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getTopGainersData = async () => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetTopGainers?Count=5`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getTopLoserData = async () => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetTopLosers?Count=5`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getIndexData = async () => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetIndexPriceDetails`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getHolidayData =async (year:string) => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetHolidayList?financial_year=${year}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const screensNameData =async (userId:string) => {
  try {
    const response = await baseApi.get(`api/Account/GetUserSubscribedScreens?userId=${userId}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export const userDataUpdate = async (userId:string) => {
  try {
  const response = await baseApi.get(`/api/Account/GetUserInformation?userID=${userId}`);
  return response.data;    
  } catch (error) {
    return null;
  }
}

export const getLiveMarketTimeData = async (topicName:string, toIdentifier:string, historicIdentifier?:string, isCumulative?:boolean) => {
  try {
    const response = await baseApi.get(`/api/Dashboard/GetLiveData?TopicName=${topicName};${toIdentifier}${historicIdentifier? `&historicIdentifier=${historicIdentifier}` :""}${isCumulative? `&isCumulative=${isCumulative}`:""}`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};


export const userBillingData = async (userId:string) => {
  try {
  const response = await baseApi.get(`/api/Account/UserBilling?userID=${userId}`);
  return response.data;    
  } catch (error) {
    return null;
  }
}

export const getPreOpenMarketData = async (date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/Dashboard/GetPreOpenData${date? `?Date=${formattedDate}`:""}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error) {
    return [];
  }
}