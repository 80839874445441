// import { useQuery } from "react-query";
import ActiveStrikeOi from "../../../components/oi-graph/OiGraphComponent";
import AppLayout from "../../../layout/AppLayout";
// import { getInstrumentExpiryStrikePrice } from "../../../api/options";
import { Icons } from "../../../utils/icons";
import useInstrumentStrikePrice from "../../../store/instrumentExpiryStrikePrice";
import { useEffect, useState } from "react";

export default function OiGraphPage() {
  // const {isLoading, data:optionOiGraph} = useQuery({
  //   queryKey:["optionOIGraph-list"],
  //   queryFn:() => getInstrumentExpiryStrikePrice()
  // });

  const { GetInstrumentPrice, bears } = useInstrumentStrikePrice();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bears === null) {
      GetInstrumentPrice();
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bears]);
  return (
    <AppLayout>
      {loading ? (
        <div className="flex h-[calc(100vh-90px)] items-center justify-center">
          <Icons.spinner
            className="mr-2 h-12 w-12 animate-spin"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>{bears !== null && <ActiveStrikeOi optionOiGraph={bears} />}</>
      )}
    </AppLayout>
  );
}
