import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export function StrikePriceWiseSpotCard({ row }: { row: any | [] }) {
  return (
    <div className="mt-4 flex h-full w-full custom-scrollbar justify-between gap-4 overflow-x-auto text-xs">
      <SpotPriceCard
        title1="Call Build-Ups"
        value1={row.buildupcall !== undefined && row.buildupcall !== null && row.buildupcall !== "" ? row.buildupcall : "-"}
        title2="LTP Of Call"
        value2={row.ltpcall !== undefined && row.ltpcall !== "0" && row.ltpcall !== "" ? formatCurrency(parseFloat(row.ltpcall)) : "-"}
      />
      <SpotPriceCard
        title1="Spot Price"
        value1={row.spotPrice !== undefined && row.spotPrice !== "0" && row.spotPrice !== "" ? formatCurrency(parseFloat(row.spotPrice)) : "-"}
        title2="Current Future Price"
        value2={row.currentFuturePrice !== undefined && row.currentFuturePrice !== "0" && row.currentFuturePrice !== "" ? formatCurrency(parseFloat(row.currentFuturePrice)) : "-"}
      />
      <SpotPriceCard
        title1="Call IV"
        value1={row.collapseCallIV !== undefined && row.collapseCallIV !== "0" && row.collapseCallIV !== "" ? formatCurrency(parseFloat(row.collapseCallIV)) : "-"}
        title2="Put IV"
        value2={row.collapsePutIV !== undefined && row.collapsePutIV !== "0" && row.collapsePutIV !== "" ? formatCurrency(parseFloat(row.collapsePutIV)) : "-"}
      />
      <SpotPriceCard
        title1="Put Build-Ups"
        value1={row.buildupput !== undefined && row.buildupput !== null && row.buildupput !== "" ? row.buildupput : "-"}
        title2="LTP Of Put"
        value2={row.ltpput !== undefined && row.ltpput !== "0" && row.ltpput !== "" ? formatCurrency(parseFloat(row.ltpput)) : "-"}
      />
    </div>
  );
}

