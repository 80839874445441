import {
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { OsTableType, columns } from "./Columns";
import "../option-chain/optionschain.css";
import { Loader } from "lucide-react";

interface TableTypes {
  rowData: OsTableType[];
  loading: boolean;
  tableHeight: number;
}

export default function StrikePWTable({
  rowData,
  loading,
  tableHeight,
}: TableTypes) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    calliv: false,
    putiv: false,
    premiumleftincall: false,
    premiumleftinput: false,
  });

  const table = useReactTable({
    data: rowData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const [loader, setLoader] = useState(loading);

  useEffect(() => {
    setLoader(loading)
  }, [loading]);

  return (
    <div className="w-full">
      <div
        style={{ maxHeight: `${tableHeight}px` }}
        className="custom-scrollbar relative w-full overflow-x-auto rounded-md border-2 dark:border-zinc-600"
      >
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn(
                      "bg-zinc-200 px-2 py-4 md:px-4 text-center text-[12px] md:text-[13px] font-medium text-zinc-800 dark:border-zinc-600 dark:bg-zinc-900 dark:text-zinc-50",
                      {
                        "strike-price": header.column.id === "strikeprice",
                      },
                      {
                        hidden: ["CALLS", "PUTS"].includes(header.column.id),
                      },
                      {
                        "table-heading-border":
                          header.column.id.includes("time") ||
                          header.column.id.includes("put") ||
                          header.column.id.includes("call"),
                      }
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!loader ? (
              table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <TableCell
                        key={index}
                        className={cn(
                          "border-b !text-xs border-zinc-300 dark:border-b-zinc-600 py-2",
                          {
                            "strike-price border-none ":
                              cell.column.id === "strikeprice",
                          }
                        )}
                      >
                        <span className="relative z-10 block">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  <Loader className="animate-spin mx-auto" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
