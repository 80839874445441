import { z } from "zod";
import baseApi from "../base";
import convertToYYYYMMDD from "../../components/shared/dateConverter";

const FuturesOpenInterstSchema = z
  .object({
    ProductName: z.string().nonempty(),
    CurrentMonth: z.string().nonempty(),
    NextMonth: z.string().nonempty(),
    FarMonth: z.string().nonempty(),
    SpotPrice: z.string().nonempty(),
  })
  .array();

export type TFuturesOpenInterst = z.infer<typeof FuturesOpenInterstSchema>;

const TFutureOpenInterestHistoricRowSchema = z.object({
  Identifier: z.string().nonempty(),
  DataDateTime: z.string().nonempty(),
  BuildUps: z.string(),
  LastTradePrice: z.number(),
  LastTradePriceChange: z.number(),
  CurrentOIChange: z.number(),
  TodaysChangeInOI: z.number(),
  TotalOI: z.number(),
  Volume: z.number(),
  BreakOut: z.string(),
  DayHigh: z.number(),
  DayLow: z.number(),
  PrevDayClose: z.number(),
  TodayOpen: z.number(),
});

const TFutureOpenInterestHistoricSchema =
  TFutureOpenInterestHistoricRowSchema.array();

export type TFutureOpenInterestHistoric = z.infer<
  typeof TFutureOpenInterestHistoricSchema
>;

export type TFutureOpenInterestHistoricRow = z.infer<
  typeof TFutureOpenInterestHistoricRowSchema
>;

export const getIndexAndExpiryDetails = async () => {
  const { data } = await baseApi.get("/api/FOS/GetIndexAndExpiryDetails");
  //   console.log(data.error);

  return data as TFuturesOpenInterst;
};

export type TFoiRow = [
  string,
  string,
  number,
  number,
  number,
  number,
  number,
  number,
  string,
  number,
  number,
  number,
  number,
];

export const getFOIHistoricData = async (identifier: string) => {
  const { data } = await baseApi.get(
    `/api/FOS/GetFOIData?Identifier=${identifier}`
  );
  try {
    const parsedData =
      await TFutureOpenInterestHistoricSchema.safeParseAsync(data);

    if (parsedData.success && parsedData.data.length > 0) {
      var reversedData = parsedData.data.reverse();
      return {
        rows: reversedData,
        dayHigh:
          reversedData[0].BuildUps == ""
            ? reversedData[1].DayHigh
            : reversedData[0].DayHigh,
        dayLow:
          reversedData[0].BuildUps == ""
            ? reversedData[1].DayLow
            : reversedData[0].DayLow,
      };
    }
  } catch (error: any) {
    return null;
  }
};

export const getIndexSubscription = async (
  indexName: string,
  userId: string,
  toIdentifier: string
) => {
  try {
    if (
      userId !== undefined &&
      userId !== "" &&
      indexName !== undefined &&
      indexName !== "" &&
      toIdentifier !== ""&&
      toIdentifier !== undefined
    ) {
      const { data } = await baseApi.get(
        `/api/Account/GetIndexSubscription?IndexName=${indexName}&UserId=${userId}&ToIdentifier=${toIdentifier}`
      );
      return data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getFOIInterMediateData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date !== undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/FOS/GetFOIInterMediateData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getFoiMarketMoversData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }

    const apiUrl = `/api/FOS/GetMarketMoverData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`;
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const getFutureSpurtzData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/FOS/GetFuturesSpurtzData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error: any) {
    return [];
  }
}

export const getFutureBuzzData = async (identifier: string, topicName:string, date?: string) => {
  try {
    let formattedDate: string | undefined = undefined;
    if (date != undefined) {
      formattedDate = convertToYYYYMMDD(date);
    }
    const apiUrl = `/api/FOS/GetOIbuzzData?Identifier=${identifier}&TopicName=${topicName}${date ? `&Date=${formattedDate}` : ''}`
    const response = await baseApi.get(apiUrl);
    return response.data;
  } catch (error: any) {
    return [];
  }
}