export default function processMessage(message: string[], newSpot?:string) {
    const upRows = message.map((item) => {
      const splitMsg = item.split(",")
      return ({
        name: splitMsg[0],
        oi: splitMsg[1],
        change: splitMsg[2],
        open: splitMsg[3],
        high: splitMsg[4],
        low: splitMsg[5],
        ltp: splitMsg[6],
        buildUp: splitMsg[7],
        collLtp:splitMsg[8],
        prevDay: splitMsg[9],
        todayOpen: splitMsg[10],
        todayHigh: splitMsg[11],
        todayLow: splitMsg[12],
        spotPrice:splitMsg[14]
      })
    });
    return upRows;
  }