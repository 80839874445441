import BuildUpBadge from "../shared/BuildUpBadge";
import formatCurrency from "../../utils/formatCurrency";

const innerCardStyles = "border px-3 py-2 dark:border-zinc-600 flex items-center justify-between gap-2";
const noborderStyles = "flex items-center justify-center col-span-2 text-center";

export default function StrikePriceWiseMobileCard({ rows }: { rows: any[] }) {
  return (
    <div>
      {rows.map((item, index) => {
        return (
          <div
            key={index}
            className="mt-2 grid grid-cols-2 gap-3 rounded-md border p-3 text-[10px] font-medium dark:border-zinc-600 "
          >

            <div className={noborderStyles}>
              <span className="ml-4 font-semibold">Time: {" "} </span>
              {item.time}
            </div>

            <div className={innerCardStyles}>
              <span>Total OI CALL:{" "}</span>
              {formatCurrency(parseFloat(item.totaloicall))}
            </div>

            <div className={innerCardStyles}>

              Change In LTP: {" "}<span>{formatCurrency(parseFloat(item.changeinltpcall))}</span>
            </div>
            <div className={innerCardStyles}>
              <span>Today&apos;s OI Change: {" "} </span>
              {formatCurrency(parseFloat(item.todayoichangecall))}
            </div>
            <div className={innerCardStyles}>
              <span>Current Oi Change in Call: {" "} </span>

              {formatCurrency(parseFloat(item.currentoichangecall))}
            </div>
            <div className={`${noborderStyles} text-sm`}>
              <span>LTP Call: {" "}</span>

              <span>{formatCurrency(parseFloat(item.ltpcall))}</span>
            </div>

            <div className="col-span-2 text-center">
              <BuildUpBadge text={item.buildupcall.trim()} />
            </div>

            <div className="col-span-2 bg-gray-400 py-2 text-center text-sm font-semibold text-black">
              <span>Strike Price: {" "} </span>
              {formatCurrency(parseFloat(item.strikeprice))}
            </div>
            <div className="col-span-2 text-center">
              <BuildUpBadge text={item.buildupput.trim()} />
            </div>
            <div className={`${noborderStyles} text-sm`}>
              <span>LTP Put: {" "} </span>
              {formatCurrency(parseFloat(item.ltpput))}
            </div>
            <div className={innerCardStyles}>
              <span>Total Oi in Put: {" "} </span>
              {formatCurrency(parseFloat(item.totaloiput))}
            </div>
            <div className={innerCardStyles}>
              <span>Change in LTP: {" "} </span>
              {formatCurrency(parseFloat(item.changeinltpput))}
            </div>
            <div className={innerCardStyles}>
              <span>Today&apos;s OI Change: {" "}</span>
              {formatCurrency(parseFloat(item.todayoichangeput))}
            </div>
            <div className={innerCardStyles}>
              Current OI Change in Put: {" "}
              <span>{formatCurrency(parseFloat(item.currentoichangeput))}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
