export const preOpenArr = [
  "INDIGO",
  "GMRINFRA",
  "DEEPAKNTR",
  "HINDPETRO",
  "ALKEM",
  "BANDHANBNK",
  "AUBANK",
  "VEDL",
  "ZYDUSLIFE",
  "GUJGASLTD",
  "BPCL",
  "RBLBANK",
  "ASTRAL",
  "MANAPPURAM",
  "GODREJCP",
  "IDFCFIRSTB",
  "UNITDSPR",
  "HINDALCO",
  "AARTIIND",
  "NMDC",
  "JUBLFOOD",
  "EXIDEIND",
  "LAURUSLABS",
  "UBL",
  "ABCAPITAL",
  "BHARTIARTL",
  "ESCORTS",
  "BIOCON",
  "PERSISTENT",
  "COLPAL",
  "IPCALAB",
  "TRENT",
  "ICICIBANK",
  "PFC",
  "ACC",
  "BERGEPAINT",
  "IDFC",
  "INFY",
  "SIEMENS",
  "OFSS",
  "TVSMOTOR",
  "HCLTECH",
  "LICHSGFIN",
  "PVRINOX",
  "IOC",
  "SUNTV",
  "LTTS",
  "TATACONSUM",
  "NATIONALUM",
  "INDIAMART",
  "GODREJPROP",
  "DIVISLAB",
  "PETRONET",
  "ULTRACEMCO",
  "POLYCAB",
  "BALRAMCHIN",
  "BALKRISIND",
  "MGL",
  "COFORGE",
  "NESTLEIND",
  "NAUKRI",
  "AUROPHARMA",
  "INDHOTEL",
  "BATAINDIA",
  "ASIANPAINT",
  "BHARATFORG",
  "BAJAJ-AUTO",
  "SBICARD",
  "MARUTI",
  "ITC",
  "ADANIENT",
  "BSOFT",
  "LTF",
  "ICICIPRULI",
  "BHEL",
  "AXISBANK",
  "TATAMOTORS",
  "SRF",
  "GAIL",
  "CROMPTON",
  "DABUR",
  "SBIN",
  "HINDUNILVR",
  "TITAN",
  "MOTHERSON",
  "TATASTEEL",
  "HDFCBANK",
  "INDUSTOWER",
  "EICHERMOT",
  "LTIM",
  "BSESNSX50",
  "MPHASIS",
  "PIDILITIND",
  "BANKBARODA",
  "SENSEX",
  "GRASIM",
  "ABB",
  "CANFINHOME",
  "BRITANNIA",
  "LUPIN",
  "COALINDIA",
  "CHAMBLFERT",
  "CANBK",
  "BEL",
  "TATAPOWER",
  "LT",
  "UPL",
  "BANKEX",
  "RELIANCE",
  "NAVINFLUOR",
  "COROMANDEL",
  "CIPLA",
  "WIPRO",
  "GNFC",
  "SUNPHARMA",
  "ONGC",
  "IEX",
  "TATACOMM",
  "BAJAJFINSV",
  "ADANIPORTS",
  "DLF",
  "BAJFINANCE",
  "NTPC",
  "ATUL",
  "PNB",
  "HDFCLIFE",
  "HAL",
  "MUTHOOTFIN",
  "SHRIRAMFIN",
  "TCS",
  "CONCOR",
  "HAVELLS",
  "LALPATHLAB",
  "SHREECEM",
  "FEDERALBNK",
  "RECLTD",
  "CUMMINSIND",
  "SYNGENE",
  "JSWSTEEL",
  "IGL",
  "HDFCAMC",
  "APOLLOHOSP",
  "MCX",
  "CUB",
  "OBEROIRLTY",
  "SAIL",
  "TORNTPHARM",
  "ABFRL",
  "M&MFIN",
  "DALBHARAT",
  "M&M",
  "IDEA",
  "KOTAKBANK",
  "AMBUJACEM",
  "IRCTC",
  "SBILIFE",
  "TECHM",
  "PIIND",
  "RAMCOCEM",
  "HEROMOTOCO",
  "JKCEMENT",
  "ICICIGI",
  "INDUSINDBK",
  "VOLTAS",
  "CHOLAFIN",
  "ABBOTINDIA",
  "BOSCHLTD",
  "INDIACEM",
  "HINDCOPPER",
  "TATACHEM",
  "PEL",
  "DIXON",
  "METROPOLIS",
  "MARICO",
  "GLENMARK",
  "APOLLOTYRE",
  "MRF",
  "MFSL",
  "POWERGRID",
  "PAGEIND",
  "GRANULES",
  "JINDALSTEL",
  "ASHOKLEY",
  "DRREDDY",
  "NIFTY MEDIA",
  "NIFTY FMCG",
  "NIFTY NEXT 50",
  "NIFTY METAL",
  "NIFTY SERV SECTOR",
  "NIFTY INFRA",
  "NIFTY IT",
  "NIFTY PVT BANK",
  "NIFTY MID SELECT",
  "NIFTY REALTY",
  "NIFTY COMMODITIES",
  "NIFTY FIN SERVICE",
  "NIFTY PSU BANK",
  "NIFTY PHARMA",
  "NIFTY AUTO",
  "NIFTY ENERGY",
];


export const convertUTCtoIST = (dateString: string) => {
  const date = new Date(dateString);

  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
};