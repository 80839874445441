import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export function OptionChainSpotCard({ row, collapseField }: { row?: any | [], collapseField:any }) {

  return (
    <div className="flex h-full w-full custom-scrollbar justify-between gap-[22px] overflow-x-auto text-xs">
      <SpotPriceCard
        title1="CALL ATM Delta"
        value1={collapseField.callAtmDelta !== undefined && collapseField.callAtmDelta !== "" &&  collapseField.callAtmDelta !== "0" ? formatCurrency(parseFloat(collapseField.callAtmDelta)) : "-"}
        title2="CALL ATM Theta"
        value2={collapseField.callAtmTheta !== undefined && collapseField.callAtmTheta !== "" &&  collapseField.callAtmTheta !== "0" ? formatCurrency(parseFloat(collapseField.callAtmTheta)) : "-"}
      />
      <SpotPriceCard
        title1="Spot Price"
        value1={row.spotPrice !== undefined && row.spotPrice !== "0" ? formatCurrency(parseFloat(row.spotPrice)) : "-"}
        title2="Current Future Price"
        value2={row.currentFuturePrice !== undefined && row.currentFuturePrice !== "0" ? formatCurrency(parseFloat(row.currentFuturePrice)) : "-"}
      />

      <SpotPriceCard
        title1="ATM CALL IV"
        value1={collapseField.callAtmIV !== undefined && collapseField.callAtmIV !== "" &&  collapseField.callAtmIV !== "0"  ? formatCurrency(parseFloat(collapseField.callAtmIV)) : "-"}
        title2="ATM PUT IV"
        value2={collapseField.putAtmIV !== undefined && collapseField.putAtmIV !== "" &&  collapseField.putAtmIV !== "0"  ? formatCurrency(parseFloat(collapseField.putAtmIV)) : "-"}
      />

      <SpotPriceCard
        title1="PUT ATM Delta"
        value1={collapseField.putAtmDelta !== undefined && collapseField.putAtmDelta !== "" &&  collapseField.putAtmDelta !== "0" ? formatCurrency(parseFloat(collapseField.putAtmDelta)) : "-"}
        title2="PUT ATM Theta"
        value2={collapseField.putAtmTheta !== undefined && collapseField.putAtmTheta !== "" &&  collapseField.putAtmTheta !== "0" ? formatCurrency(parseFloat(collapseField.putAtmTheta)) : "-"}
      />
    </div>
  );
}
