import React, { useEffect } from 'react';
import { useTheme } from '../../utils/ThemeProvider';

const GlobalNewsWidget = () => {
  const { theme } = useTheme();

  useEffect(() => {
    const container = document.querySelector('#global-news-widget');

    // Remove existing script before adding a new one
    while (container?.firstChild) {
      container.removeChild(container.firstChild);
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.innerHTML = JSON.stringify({
      feedMode: 'all_symbols',
      colorTheme: theme,
      isTransparent: false,
      displayMode: 'regular',
      width: "100%",
      height: 830,
      locale: 'in',
    });

    container?.appendChild(script);

  }, [theme]);

  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget" id="global-news-widget"></div>
      <div className="tradingview-widget-copyright">
        {/* <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank">
        </a> */}
      </div>
    </div>
  );
};

export default GlobalNewsWidget;
