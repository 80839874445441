import { Icons } from "../../utils/icons";
import OptionChainPopulateData from "./OptionChain";
import useInstrumentStrikePrice from "../../store/instrumentExpiryStrikePrice";
import { useEffect, useState } from "react";

export default function OptionChain() {
    // const { isLoading, data: optionInstrumentList } = useQuery({
    //   queryKey: ["optionInstrumentdata-list"],
    //   queryFn: () => getInstrumentExpiryStrikePrice(),
    // });
    const { GetInstrumentPrice, bears } = useInstrumentStrikePrice();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      if (bears === null) {
        setLoading(true);
        GetInstrumentPrice();
        setLoading(false);
      } 
    }, [bears]);
    
    if (loading) {
      return (
        <div className="flex h-[calc(100vh-90px)] items-center justify-center">
          <Icons.spinner
            className="mr-2 h-12 w-12 animate-spin"
            aria-hidden="true"
          />
        </div>
      );
    } else {
      return (
        <>
          {
            bears!== null && <OptionChainPopulateData optionInstrumentList={bears} />
          }
        </>
      )
    }
  }