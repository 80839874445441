import {
  ColumnDef,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import BuildUpBadge from "../shared/BuildUpBadge";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import { Loader } from "lucide-react";

export type OpenInterestTypes = {
  time: string;
  buildup: string;
  totaloi: number;
  todaysoichange: number;
  currentoichange: number;
  changeinltp: number;
  volume: number;
  ltp: number;
  dayHigh: string;
  dayLow: string;
  breakout: string;
};

const columns: ColumnDef<OpenInterestTypes>[] = [
  {
    accessorKey: "time",
    header: () => <div className="min-w-[80px] text-center">Time (IST)</div>,
    cell: ({ row }) => {
      return (
        <p>
          {row.getValue("time") === "15:30"
            ? `${row.getValue("time")}-EOD`
            : row.getValue("time")}
        </p>
      );
    },
  },
  {
    accessorKey: "buildup",
    header: () => <div className="min-w-max text-center">Build-Ups</div>,
    cell: ({ row }) => {
      const buildupcallData = row.getValue<string>("buildup");
      return (
        <div className="flex justify-center">
          <BuildUpBadge text={buildupcallData && buildupcallData.trim()} />
        </div>
      );
    },
  },
  {
    accessorKey: "ltp",
    header: () => <div className="min-w-[84px] text-center">LTP</div>,
    cell: ({ row }) => {
      const ltpData = parseFloat(row.getValue<string>("ltp"));
      return <p>{ltpData && formatCurrency(ltpData)}</p>;
    },
  },
  {
    accessorKey: "changeinltp",
    header: () => <div className="min-w-[84px] text-center">Change in LTP</div>,
    cell: ({ row }) => {
      const changeInLtpData = parseFloat(row.getValue<string>("changeinltp"));
      return <p>{changeInLtpData && formatDecimal(changeInLtpData)}</p>;
    },
  },
  {
    accessorKey: "currentoichange",
    header: () => <div className="min-w-[140px] text-center">Current OI-Change</div>,
    cell: ({ row }) => {
      const currentoichange = parseFloat(row.getValue<string>("currentoichange"));
      return <p>{currentoichange && formatCurrency(currentoichange)}</p>;
    },
  },
  {
    accessorKey: "todaysoichange",
    header: () => <div className="min-w-[150px] text-center">Today's Change In OI</div>,
    cell: ({ row }) => {
      const todayOiData = parseFloat(row.getValue<string>("todaysoichange"));
      return <p>{todayOiData && formatCurrency(todayOiData)}</p>;
    },
  },
  {
    accessorKey: "totaloi",
    header: () => <div className="min-w-[110px] text-center">Total-OI</div>,
    cell: ({ row }) => {
      const totaloicallData = parseFloat(row.getValue<string>("totaloi"));
      return <p>{totaloicallData && formatCurrency(totaloicallData)}</p>;
    },
  },
  {
    accessorKey: "volume",
    header: () => <div className="min-w-[60px] text-center">Volume</div>,
    cell: ({ row }) => {
      const volume = parseFloat(row.getValue<string>("volume"));
      return <p>{volume && formatCurrency(volume)}</p>;
    },
  },
  {
    accessorKey: "breakout",
    header: () => <div className="min-w-[72px]">Break Out</div>,
    cell: ({ row }) => {
      const breakoutValue = row.getValue<string>("breakout")
      const matchResult = breakoutValue.match(/\(([^)]+)\)/);
      const numericPart = matchResult?.[1] ? parseFloat(matchResult[1]) : 0;
      const formattedNumericPart = formatCurrency(numericPart);
      const formattedBreakoutValue = matchResult
        ? breakoutValue.replace(/\(([^)]+)\)/, `(${formattedNumericPart})`)
        : breakoutValue;
      return (<div className={cn("border border-transparent min-w-max p-1.5",
        { "border-green-500 text-green-500": breakoutValue.includes("D.H.B.") },
        { "border-red-500 text-red-500": breakoutValue.includes("D.L.B.") },
      )}>{formattedBreakoutValue}</div>)
    }
  },
  {
    accessorKey: "dayhigh",
    header: () => <div className="min-w-[72px]">Day High</div>,
    cell: ({ row }) => {
      const dayhigh = parseFloat(row.getValue<string>("dayhigh"));
      return <p>{dayhigh && formatCurrency(dayhigh)}</p>;
    },
  },
  {
    accessorKey: "dayLow",
    header: () => <div className="min-w-[72px]">Day Low</div>,
    cell: ({ row }) => {
      const dayLow = parseFloat(row.getValue<string>("dayLow"));
      return <p>{dayLow && formatCurrency(dayLow)}</p>;
    },
  },
];

interface TableTypes {
  rowData: OpenInterestTypes[];
  loading: boolean;
  tableHeight: number;
}

export default function OpenInterestTable({ rowData, loading, tableHeight }: TableTypes) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    calliv: false,
    putiv: false,
    premiumleftincall: false,
    premiumleftinput: false,
  });

  const table = useReactTable({
    data: rowData === undefined ? [] : rowData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const [loader, setLoader] = useState(loading)
  useEffect(() => {
    setLoader(loading)
  }, [loading])

  return (
    <div style={{ maxHeight: `${tableHeight}px` }} className="tw custom-scrollbar relative max-h-[520px] overflow-auto rounded-lg border-2 dark:border-zinc-600">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="py-3  border-b dark:border-zinc-600 text-center font-semibold text-zinc-800 bg-zinc-200 dark:bg-zinc-800"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {
            !loader ?
              table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={index}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn({
                      "bg-zinc-200/50 dark:bg-zinc-800": index % 2 !== 0,
                    })}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <TableCell
                          key={index}
                          className={cn("py-2 px-2 text-center !text-xs")}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No Results
                  </TableCell>
                </TableRow>
              )
              :
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  <Loader className="animate-spin mx-auto" />
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
}