import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export default function TrendFinderSpotPrice({ row, overAllTrend }: { row: any | {}, overAllTrend:string }) {
  return (
    <div className="mt-4 flex h-full w-full justify-between gap-3 overflow-x-auto pb-1 text-xs custom-scrollbar">
      <SpotPriceCard
        title1="Spot Price"
        value1={row.SpotPrice !== undefined && row.spotPrice !== "" ? formatCurrency(parseFloat(row.SpotPrice)) : "-"}
        title2="Current Future Price"
        value2={row.currentfutureprice !== undefined && row.currentfutureprice !== "" ? formatCurrency(parseFloat(row.currentfutureprice)) : "-"}
      />
      <SpotPriceCard
        title1="Overall Trend"
        value1={overAllTrend!== "" ? overAllTrend:"-"}
        title2="Current Trend"
        value2={row.trend !== undefined && row.trend !== "" ? row.trend : "-"}
      />

      <SpotPriceCard
        title1="ATM Call IV"
        value1={row.atmCallIV !== undefined && row.atmCallIV !== "" ? formatCurrency(parseFloat(row.atmCallIV)) : "-"}
        title2="ATM Put IV"
        value2={row.atmPutIV !== undefined && row.atmPutIV !== "" ? formatCurrency(parseFloat(row.atmPutIV)) : "-"}
      />

      <SpotPriceCard
        title1="Premium In Call(ATM)"
        value1={row.premiuminCall !== undefined && row.premiuminCall !== "" ? formatCurrency(parseFloat(row.premiuminCall)) : "-"}
        title2="Premium In Put(ATM)"
        value2={row.premiumInput !== undefined && row.premiumInput !== "" ? formatCurrency(parseFloat(row.premiumInput)) : "-"}
      />
    </div>
  );
}
