import { Triangle } from "lucide-react";

import OiIntBadge from "../shared/OiIntBadge";
import { cn } from "../../lib/utils";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";

export type EoTypes = {
    time?: string;
    name?: string;
    chart?: string;
    minBO?: string;
    minBoDays?: string;
    oiInt?: string;
    oHL?: string;
    ltp?: string;
    ltpChangePercent?: string;
    oiChangePercent?: string;
    highLow: string;
};

const SortingUi = ({ column, title, className }: { column: any, title: string, className?: string }) => (
    <div className={cn("flex items-center min-w-max justify-center gap-1 cursor-pointer", className)} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        <span>{title}</span>
        <div>
            <Triangle className={cn("h-[8px] w-[8px] text-zinc-600 fill-zinc-600 dark:fill-[#d9d9d9]", { "fill-green-500 dark:fill-green-600 text-green-500": column.getIsSorted() === "desc" })} />
            <Triangle className={cn("h-[8px] w-[8px] text-zinc-600 fill-zinc-600 dark:fill-[#d9d9d9] rotate-180", { "fill-green-500 dark:fill-green-600 text-green-500": column.getIsSorted() === "asc" })} />
        </div>
    </div>)

export const columns: any = [
    {
        accessorKey: "time",
        header: "Time",
    },
    {
        accessorKey: "name",
        header: () => <p className="min-w-max">Name</p>,
        cell: ({ row }: any) => {
            return <p className={cn(
                { "text-green-500": row.original.highLow === "high" },
                { "text-red-500": row.original.highLow === "low" }
            )}>
                {row.getValue("name")}
            </p>
        },
    },
    {
        accessorKey: "chart",
        header: "Chart",
    },
    {
        accessorKey: "minBoDays",
        header: "Min B.O. Days",
        cell: ({ row }: any) => {
            return <p className={cn(
                { "text-green-500": row.original.highLow === "high" },
                { "text-red-500": row.original.highLow === "low" }
            )}>
                {row.getValue("minBoDays")}
            </p>
        }
    },
    {
        accessorKey: "ltp",
        header: "LTP",
        cell: ({ row }: any) => {
            return <p className={cn(
                { "text-green-500": row.original.highLow === "high" },
                { "text-red-500": row.original.highLow === "low" }
            )}>
                {formatCurrency(parseFloat(row.getValue("ltp")))}
            </p>
        }

    },
    {
        accessorKey: "oiChangePercent",
        header: "OI Chng%",
        cell: ({ row }: any) => {
            const value = row.getValue("oiChangePercent")
            return <p className={cn(
                { "text-green-500": row.original.highLow === "high" },
                { "text-red-500": row.original.highLow === "low" }
            )}>
                {formatCurrency(value)}
                {value && row.getValue("oiChangePercent") !== "-" ? "%" : ""}
                {value && row.getValue("oiChangePercent") === 0 ? "%" : ""}
            </p>
        },
    },
];

export const LoserGainerColumns: any = [
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "ltp",
        header: ({ column }: any) => <SortingUi column={column} title="LTP" />,
        cell: ({ row }: any) => <p>{formatCurrency(row.getValue("ltp"))}</p>
    },
    {
        accessorKey: "ltpChangePercent",
        header: ({ column }: any) => <SortingUi column={column} title="LTP Change%" />,
        cell: ({ row }: any) => {
            const value = parseFloat(row.getValue("ltpChangePercent"));
            return (
                <p className={value >= 0 ? "text-green-500" : "text-red-600"}>
                    {value && formatDecimal(value)} {value && "%"}
                </p>
            );
        },
    },
    {
        accessorKey: "oiChangePercent",
        header: ({ column }: any) => <SortingUi column={column} title="OI Change%" />,
        cell: ({ row }: any) => {
            const value = parseFloat(row.getValue("oiChangePercent"));
         if(value.toString() === "NaN"){
            return <p>-</p>
         }else{
            return (
                <p className={cn({ "text-green-500": value > 0 }, { "text-red-500": value < 0 })}>
                    {value && formatDecimal(value)}
                    {value && row.getValue("oiChangePercent") !== "-" ? "%" : ""}
                    {value == 0 && row.getValue("oiChangePercent") == 0 ? "%" : ""}
                </p>
            );
         }
        },
    },
    {
        accessorKey: "minBoDays",
        header: ({ column }: any) => <SortingUi column={column} title="Min B.O. Days" />,
        cell: ({ row }: any) => {
            const [, , dHigh] = row.getValue("minBoDays").split(" ");
            return <p className={dHigh === "High" || dHigh === "high"? "text-green-500":"text-red-500"}>{row.getValue("minBoDays") ? row.getValue("minBoDays") : "-"}</p>
        }
    },
    {
        accessorKey: "oHL",
        header: ({ column }: any) => <SortingUi column={column} title="O=H/L" />,
        cell: ({ row }: any) => {
            return <p>{row.getValue("oHL") ? row.getValue("oHL") : "-"}</p>
        }
    },
    {
        accessorKey: "oiInt",
        header: ({ column }: any) => <SortingUi column={column} title="OI Int." />,
        cell: ({ row }: any) => {
            const oiIntData = row.getValue("oiInt");
            return <OiIntBadge text={oiIntData.trim()} />;
        },
    },
];