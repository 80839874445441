import useSidebarStore from "../../store/sidebar";
import DataTable from "../../components/dashboard/DataTable";
import Events from "../../components/dashboard/Events";
import Instruments from "../../components/dashboard/Instruments";
import QuickAcess from "../../components/dashboard/QuickAcess";
import RightSidebar from "../../components/dashboard/RightSidebar";
import AppLayout from "../../layout/AppLayout";
import { cn } from "../../lib/utils";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useEffect, useState } from "react";
import useMarketStatus from "../../hooks/useMarketStatus";
import { getIndexSubscription } from "../../api/futures/open-interest";
import {
  getIndexData,
  getLiveMarketTimeData,
  getStockData,
  getTopGainersData,
  getTopLoserData,
  screensNameData,
  userDataUpdate,
} from "../../api/dashboard";
import { redirect } from "react-router-dom";
import TradingViewWidget from "../../components/dashboard/GlobalDataTable";
import GlobalNewsWidget from "../../components/dashboard/GlobalNews";
import { ScrollArea } from "../../components/ui/scroll-area";
import Breadcrumb from "../../components/shared/Breadcrumb";
import { Icons } from "../../utils/icons";
import useScreensDetails from "../../store/screensDetails";
import useInstrumentStrikePrice from "../../store/instrumentExpiryStrikePrice";
import { configEnvironemt } from "../../config";
import useIndexExpiryDetailsStore from "../../store/foiInstrumentExpiry";
const breadcrumb = [
  { label: "Home", link: "/dashboard" },
  { label: "Dashboard", link: "/dashboard" },
];
function Dashboard() {
  const environment = configEnvironemt.environment;
  const { isSidebarOpen } = useSidebarStore();
  const [instrumentData, setInstrumentData] = useState<any[]>([]);
  const [stock, setStock] = useState<any[]>([]);
  const [topGainer, setTopGainer] = useState<any[]>([]);
  const [topLooser, setTopLooser] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topicName, setTopicName] = useState("dashboard_data");
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const newUserId = useAuthStore.getState().user.newId;
  const user = useAuthStore.getState().user;
  const updateUser = useAuthStore((state) => state.updateUser);

  const { setScreens } = useScreensDetails();

  const toIdentifier = "dashboard_data";
  const marketStatus = useMarketStatus();

  const { GetInstrumentPrice, bears } = useInstrumentStrikePrice();
  const {fetchIndexExpiryDetails, indexExpiryDetails} = useIndexExpiryDetailsStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bears === null) {
      GetInstrumentPrice();
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bears]);

  useEffect(() =>{
    if(indexExpiryDetails.length === 0){
      fetchIndexExpiryDetails();
    }
  },[indexExpiryDetails])


  async function getSubscriptionName() {
     await getIndexSubscription(
      topicName,
      userId === undefined ? "" : userId,
      toIdentifier
    );
  }

  async function getHistoricIndexData() {
    const data = await getIndexData();
    setInstrumentData(data);
  }

  async function getHistoricStockData() {
    const response = await getStockData();
    setStock(response);
  }

  async function getHistoricTopGainerData() {
    const response = await getTopGainersData();
    setTopGainer(response);
  }

  async function getHistoricTopLoserData() {
    const response = await getTopLoserData();
    setTopLooser(response);
  }

  async function checkScreenData() {
    if (newUserId !== undefined && newUserId !== "") {
      const data = await screensNameData(newUserId);
      setScreens(data);
    }
  }
  
  async function checkNewUserId() {
    if((user.id?.length===36 || user.newId==="" ) &&  user.id!== undefined){
        const data = await userDataUpdate(user.id);
        updateUser({
          newId:user.id,
          id:user.id,
          name:data.FirstName,
          email:data.Email,
          phone: data.Phone, 
          street: data.Street, 
          city:data.City, 
          state: data.State
        }, true);
    }
  }

  function fetchDataHistoric(){
    getHistoricIndexData();
    getHistoricStockData();
    getHistoricTopGainerData();
    getHistoricTopLoserData();
  }

  async function liveMarketMsg(){
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    let messages = data.toString().split("\n") as string[];
    setInstrumentData(
      messages.slice(1, 5).map((item: string) => ({
              IndexName: item.split(",")[0],
              Price: item.split(",")[1],
              PriceChange: item.split(",")[2],
              PriceChangePercent: item.split(",")[3],
            }))
          );
          setStock(
            messages.slice(5, messages.length).map((item: string) => ({
              StockName: item.split(",")[0],
              LastTradePrice: item.split(",")[1],
              PriceChangePercent: item.split(",")[2],
              OIChangePercent: item.split(",")[3],
            }))
          );
          setTopGainer(
            messages
              .slice(messages.length - 5, messages.length)
              .map((item: string) => ({
                StockName: item.split(",")[0],
                LastTradePrice: item.split(",")[1],
                PriceChangePercent: item.split(",")[2],
                OIChangePercent: item.split(",")[3],
              }))
          );
          setTopLooser(
            messages.slice(5, 10).map((item: string) => ({
              StockName: item.split(",")[0],
              LastTradePrice: item.split(",")[1],
              PriceChangePercent: item.split(",")[2],
              OIChangePercent: item.split(",")[3],
            }))
          );
    }
  }

  useEffect(() => {
    checkScreenData();
  }, [newUserId]);

  useEffect(() => {
    checkNewUserId()
  },[])

useEffect(() =>{
if(marketStatus === "open"){
  getSubscriptionName()
  liveMarketMsg()
}
},[userId, marketStatus])
  useEffect(() => {
    let id:any;
    if (marketStatus === "open") {
      getSubscriptionName();
       id = setInterval(liveMarketMsg, 5 * 1000);
    } else if(marketStatus === "after-open"){
      getSubscriptionName();
      liveMarketMsg();
    }else if(marketStatus!== "loading" && (marketStatus === "pre-open" || marketStatus === "closed")){
      fetchDataHistoric();
    }
    return () => clearInterval(id);
  }, [userId, marketStatus]);


  useEffect(() => {
    if (environment === "test") {
      setTopicName(topicName + "_test");
    }
  }, [environment]);

  if (isLoggedIn === false) {
    redirect("/login");
  }
  if (isLoading) {
    return <Icons.spinner />;
  }

  return (
    <AppLayout>
      <div className="bg-zinc-50 dark:bg-zinc-900">
        <div className="mx-auto max-w-full bg-zinc-50 dark:bg-zinc-900">
          <div className="mt-4">
            <Breadcrumb items={breadcrumb} />
          </div>
          <div className="mb-4 pt-4">
            <p className="pl-1">Index</p>
            <Instruments instrumentsData={instrumentData} />
          </div>
          <div className="sm:hidden">
            <QuickAcess />
          </div>
          <div
            className={cn(
              "m-auto mt-4 h-full max-w-full grid-cols-5 gap-6",
              { "xl:grid": isSidebarOpen }
            )}
          >
            <div className="col-span-4">
              <div className="mt-2 capitalize md:grid gap-3 md:grid-cols-2">
                <div>
                  <div className="flex items-center justify-between pr-2">
                    <p className="mb-2 pl-1">Stock</p>
                    {/* <Customize instrumentData={instrumentData} /> */}
                  </div>
                  <DataTable loading={loading} data={stock} />
                </div>
                <div className="mb-2">
                  <p className="mb-2 pl-1">Global Market</p>
                  <TradingViewWidget />
                </div>
                <div>
                  <p className="mb-2 pl-1">Top Gainers (Price & O-I Wise)</p>
                  <DataTable loading={loading} data={topGainer} />
                </div>
                <div>
                  <p className="mb-2 pl-1">Top Losers (Price & O-I Wise)</p>
                  <DataTable loading={loading} data={topLooser} />
                </div>
              </div>

              <div className="mt-8 pb-4">
                <p className="mb-2 pl-1">Live Market News & Events</p>
                <div className="grid gap-3 sm:grid-cols-2">
                  <div>
                    <p className=" rounded-t-lg border  border-blue-600 bg-blue-600 px-3 py-3  capitalize text-white">
                      {"Global Market News"}
                    </p>
                    <ScrollArea className="h-[335px] w-full border bg-white shadow-sm dark:border-zinc-600 dark:bg-zinc-900  ">
                      <GlobalNewsWidget />
                    </ScrollArea>
                  </div>
                  <div>
                    <Events title="INDIAN Market Events" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default Dashboard;