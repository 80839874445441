import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { BuzzLiveMessageType } from "./constant";
import formatCurrency from "../../utils/formatCurrency";

let advance: number = 0;
let decline: number = 0;
const getSeriesData = (rowData: BuzzLiveMessageType[]): { x: string, y: number, data: BuzzLiveMessageType }[] => {
    return rowData.filter(item =>item.name).map((item) => ({
        x: item.name,
        y: parseFloat(item.change), 
        data: item
    }));
}

export default function Buzzmap({
  rowData,
  graphHeight
}: {
  rowData: BuzzLiveMessageType[];
  graphHeight:number
}) {
  const options: ApexOptions = {
    legend: {
      show: false,
    },
    chart: {
      type: "treemap",
      width: "100%",
      toolbar:{
        tools:{
          download:true,
        },
        autoSelected:"zoom",
        offsetX: -16,
        offsetY: -12,
      },
    },
    tooltip: {
      shared:true,
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const { name, oi, change, open, high, low, ltp } =
          w.config.series[seriesIndex].data[dataPointIndex].data;
        return `
              <div style="padding: 10px; background-color: #fff; border: 1px solid #ddd;">
              <div style="color: #000"> <strong>${name}</strong></div>
                  <div style="color: #000">OI:<strong> ${oi}</strong> </div>
                  <div style="color: #000">Change:<strong> ${formatCurrency(parseFloat(change))}%</strong></div>
                  <div style="color: #000">Open:<strong> ${formatCurrency(parseFloat(open))}</strong></div>
                  <div style="color: #000">High:<strong> ${formatCurrency(parseFloat(high))}</strong></div>
                  <div style="color: #000">Low:<strong> ${formatCurrency(parseFloat(low))}</strong></div>
                  <div style="color: #000">LTP:<strong> ${formatCurrency(parseFloat(ltp))}</strong></div>
              </div>
          `;
      },
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetX: -400,
        offsetY: -200,
    },
    },
    plotOptions: {
      treemap: {
        colorScale: {
          ranges: [
            {
              from: -100,
              to: 0,
              color: "#AB0F04",
            },
            {
              from: 0,
              to: 100,
              color: "#04AB09",
            },
          ],
        },
        distributed:true,
        enableShades:true,
        shadeIntensity:0.5,
      },
    },
    
  };
  const series = [
    {
      data: getSeriesData(rowData),
    },
  ];
  advance = series[0].data.filter((item) => item.y >= 0).length;
  decline = series[0].data.length - advance;
  
  return (
    <>
      <div className="flex justify-center items-center gap-4 font-semibold">
        <p>Advance: <span className={"text-[#98DD9B] pl-1"}>{advance}</span></p>
        <p>|</p>
        <p>Decline: <span className={"text-[#DC9E9A] pl-1"}>{decline}</span></p>
      </div>
      <ReactApexChart
        series={series}
        options={options}
        type="treemap"
        width="100%"
        height={`${graphHeight > 380 ? graphHeight : 380}px`}
      />
    </>
  );
}