import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type screenDetails = {
  screens: string[];
  setScreens: (data: any) => void;
};

const useScreensDetails = create<screenDetails>()(
  devtools(
    persist(
      (set) => ({
        screens:[],
        setScreens: (data) =>
        set(() => ({
          screens: [
            ...data,
          ],
        })),
      }),
      {
        name: "screenDetails",
        // partialize: (state) =>
        //   Object.fromEntries(
        //     Object.entries(state).filter(
        //       ([key]) => !["setScreens"].includes(key)
        //     )
        //   ),
      }
    ),
    { name: "screenDetails" }
  )
);

export default useScreensDetails;

