import { Select, SelectContent, SelectTrigger, SelectValue } from "../ui/select";

interface BoxProps {
    title: string;
    value: string;
    onValueChange: any;
    placeholder?: string;
    children: React.ReactNode;
    disabled?:boolean
}
const SelectBox = ({
    value,
    onValueChange,
    placeholder,
    children,
    title,
    disabled
}: BoxProps) => {
    return (
        <div className="w-full">
            <p className="mb-1 pl-1 text-xs">{title}</p>
            <Select value={value} onValueChange={onValueChange} disabled={disabled}>
                <SelectTrigger className="w-full shadow">
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent>{children}</SelectContent>
            </Select>
        </div>
    );
};

export default SelectBox