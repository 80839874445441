import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { toast } from "sonner";
import { baseURL } from "../../api/base";
import usePaymentDetails from "../../store/paymentDetails";
import { Icons } from "../../utils/icons";

const PaymentCheckStatus = () => {
  const { merchantTransactionPlanId, setMerchantTransID , userInfo, setUserInfo} = usePaymentDetails();
  const navigate = useNavigate();
  let userId = useGetFromStore(useAuthStore, (state) => state.user.newId);
  const checkStatus = () => {
    const id = parseInt(merchantTransactionPlanId.id);
    const bodyJson = {
      userId: userId,
      subscriptionId: id,
      merchantTransactionId: merchantTransactionPlanId.merchantTransactionId,
      ...userInfo
    }
    const dbURL = `${baseURL}/api/Account/CheckPaymentStatus`;
    fetch(dbURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyJson)
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.success === true) {
            navigate("/dashboard");
            toast.success(`Successfully Subscribed`,{
              position:"top-right"
            });
            setMerchantTransID({
              id: "",
              merchantTransactionId: "",
            });
            setUserInfo({
              firstName:"",
              lastName:"",
              address:"",
              phone:"",
              pincode:"",
              terms:false,
              city:"",
              state:""
            })
          } else {
            navigate("/features/plans");
            toast.warning("Something went wrong",{
              position:"top-right"
            });
            setMerchantTransID({
              id: "",
              merchantTransactionId: "",
            });
          }
        });
      })
      .catch((err: any) => {
        toast.error(err.message,{
          position:"top-right"
        });
        navigate("/features/plans");
      });
  };
  React.useEffect(() => {
    if (
      merchantTransactionPlanId.id !== "" &&
      merchantTransactionPlanId.merchantTransactionId !== "" &&
      userId !== "" &&
      userId !== undefined &&
      merchantTransactionPlanId.id !== undefined &&
      merchantTransactionPlanId.merchantTransactionId !== undefined
    ) {
      checkStatus();
    }
  }, [userId]);

  return (
    <div className="flex h-[calc(100vh-90px)] items-center justify-center">
      <Icons.spinner
        className="mr-2 h-12 w-12 animate-spin"
        aria-hidden="true"
      />
    </div>
  );
};

export default PaymentCheckStatus;
