import * as React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { Logo } from "../ui/logo";
import { Link } from "react-router-dom";
import { Icons } from "../../utils/icons";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { dashboardNav } from "../../data/dashboardNav";
import useScreensDetails from "../../store/screensDetails";

const triggerStyles: string =
  "text-sm capitalize font-semibold dark:text-white";

export function MobileNav() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { screens } = useScreensDetails();
  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 lg:hidden"
        >
          <Icons.menu className="h-6 w-6 dark:text-white" />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="pl-1 pr-0 dark:bg-zinc-800">
        <Link
          aria-label="Home"
          to="/"
          className="block w-24"
          onClick={() => setIsOpen(false)}
        >
          <Logo showText={false} />
        </Link>

        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-3">
          <div className="pl-1 pr-7">
            <Accordion type="single" collapsible className="w-full">
              {dashboardNav.map((e, index) => (
                <AccordionItem key={index} value={e.heading}>
                  <AccordionTrigger className={triggerStyles}>
                    {e.heading}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="flex flex-col space-y-2">
                      {e.links.map((item, subindex) => (
                        <Link
                          to={screens.includes(item.href) ? item.href : "/features/plans"}
                          // to={"/features/plans"}
                          key={subindex}
                          className="my-1 block text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                          onClick={() => setIsOpen}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
