import { Icons } from "../../utils/icons";
import StrikePriceWise from "./StrikePriceWise";
import useInstrumentStrikePrice from "../../store/instrumentExpiryStrikePrice";
import { useEffect, useState } from "react";

export default function StrikePriceWiseScreen() {
  const { GetInstrumentPrice, bears } = useInstrumentStrikePrice();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (bears === null) {
      GetInstrumentPrice();
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bears]);

  if (loading) {
    return (
      <div className="flex h-[calc(100vh-90px)] items-center justify-center">
        <Icons.spinner
          className="mr-2 h-12 w-12 animate-spin"
          aria-hidden="true"
        />
      </div>
    );
  } else {
    return <>{bears!== null && <StrikePriceWise optionIndexList={bears} />}</>;
  }
}

