import { create } from 'zustand';
import baseApi from '../api/base';

interface BearStoreState {
  bears: null;
  isError: boolean,
  GetInstrumentPrice: () => Promise<void>;
  removeAllBears: () => void;
}

export const useInstrumentStrikePrice = create<BearStoreState>((set) => ({
  bears: null,
  isError:false,
  GetInstrumentPrice: async () => {   
    try {
      const response = await baseApi.get('/api/Options/GetInstrumentExpiryStrikePrice');
      set({ bears: response.data, isError: false });
    } catch (error) {
      console.error('Error fetching data:', error);
      set({isError:true})
    }
  },
  removeAllBears: () => set({ bears:null, isError: false }), 
}));

export default useInstrumentStrikePrice;
