import React, { useEffect } from "react";
import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import { useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  SelectGroup,
  SelectItem,
  SelectLabel,
} from "../ui/select";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import { Input } from "../ui/input";
import OSTable from "./OSTable";
import { InstrumentExpiryStrikePrice, getOptionSpurtzData } from "../../api/options";
import convertToYYYYMMDD, { findNearestDate, formatDate } from "../shared/dateConverter";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { toast } from "sonner";
import { getIndexSubscription } from "../../api/futures/open-interest";
import { useNavigate } from "react-router";
import { OSSpot } from "./OSSpot";
import CollapseButton from "../shared/CollapseButton";
import { dateSort } from "../../utils/datesort";
import SelectBox from "../shared/SelectBox";
import { configEnvironemt } from "../../config";
import { breadcrumbOptionSpurtz } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { baseURL } from "../../api/base";

export default function OptionSpurtz(props: {
  optionSpurtzList: InstrumentExpiryStrikePrice[]
}) {
  const expiryDates = props.optionSpurtzList[0].ExpiryStrikePriceList.map(
    (exp) => exp.Expiry
  );
  const initialDate = findNearestDate(expiryDates);
  const marketStatus = useMarketStatus();
  const environment = configEnvironemt.environment;
  const [historical, setHistorical] = useState(false)
  const [date, setDate] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [instrument, setInstrument] = useState(props.optionSpurtzList[0].ProductName);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const [toIdentifier, setToIdentifier] = useState(
    `optionssprutz_OPTIDX_${props.optionSpurtzList[0].ProductName}_${expiry}`
  );
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `OPTIONSSPURTZ_OPTIDX_${props.optionSpurtzList[0]?.ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [longBuild, setLongBuild] = useState<any[] | []>([]);
  const [shortBuild, setShortBuild] = useState<any[] | []>([]);
  const [shortUnwind, setShortUnwind] = useState<any[] | []>([]);
  const [longUnwind, setLongUnwind] = useState<any[] | []>([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false);
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  const [collapseData, setCollapseData] = useState<any>({});
  let id:any;
  const handleSwitch = () => {
    setLongBuild([]);
    setShortBuild([]);
    setShortUnwind([]);
    setLongUnwind([]);
    setCollapseData([])
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(props.optionSpurtzList[0]?.ProductName);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setHistorical(true);
      setIsLoading(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  const processMessage = async (messages: string[]) => {
    const upRows = messages.map((msg) => {
      return {
        category: msg.split(",")[0],
        strikePrice: msg.split(",")[1],
        type: msg.split(",")[2],
        ltp: msg.split(",")[3],
        prevClose: msg.split(",")[4],
        ltpChangePercent: msg.split(",")[5],
        oiChangePercent: msg.split(",")[6],
        newOi: msg.split(",")[7],
        oldOi: msg.split(",")[8],
        oiChange: msg.split(",")[9],
        volume: msg.split(",")[10],
      }
    });

    const uprowsnew = upRows.filter((row) => row.ltp !== "0");
    const CollapseData = {
      currentfutureprice: messages[messages.length - 1].split(",")[11],
      callbuildupsATM: messages[messages.length - 1].split(",")[12],
      ATMcallIV: messages[messages.length - 1].split(",")[13],
      PremiumincallATM: messages[messages.length - 1].split(",")[14],
      PutBuildUpATM: messages[messages.length - 1].split(",")[15],
      ATMputIV: messages[messages.length - 1].split(",")[16],
      PremiuminputATM: messages[messages.length - 1].split(",")[17],
      SpotPrice: messages[messages.length - 1].split(",")[18]
    }

    setCollapseData(CollapseData);
    const longBuildUp = uprowsnew.filter((row) => row.category === "Long Build Up").sort((a, b) => parseFloat(b.oiChange) - parseFloat(a.oiChange));
    const shortBuildUp = uprowsnew.filter((row) => row.category === "Short Build Up").sort((a, b) => parseFloat(b.oiChange) - parseFloat(a.oiChange));
    const shortUnwinding = uprowsnew.filter((row) => row.category === "Short Covering").sort((a, b) => parseFloat(a.oiChange) - parseFloat(b.oiChange));
    const longUnwinding = uprowsnew.filter((row) => row.category === "Long Unwinding").sort((a, b) => parseFloat(a.oiChange) - parseFloat(b.oiChange));
    setIsLoading(false);
    return { longBuildUp, shortBuildUp, shortUnwinding, longUnwinding };
  }

  async function liveMsgOptionSpurtz(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const { longBuildUp, shortBuildUp, shortUnwinding, longUnwinding } = await processMessage(messages);
    setLongBuild(longBuildUp);
    setShortBuild(shortBuildUp);
    setShortUnwind(shortUnwinding);
    setLongUnwind(longUnwinding);
    }
  }

  const getHistoricSpurtzData = async (identifier: string, topicName:string, refDate?:any) => {
    let response;
    if (historical && identifier !== undefined && date !== '') {
      response = await getOptionSpurtzData(identifier, topicName, !refDate? date : refDate);
    }else {
      response = await getOptionSpurtzData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const { longBuildUp, shortBuildUp, shortUnwinding, longUnwinding } = await processMessage(messages);
      setLongBuild(longBuildUp);
      setShortBuild(shortBuildUp);
      setShortUnwind(shortUnwinding);
      setLongUnwind(longUnwinding);
    }
  }

  function handleProductChange(productName: string) {
    setLongBuild([]);
    setShortBuild([]);
    setShortUnwind([]);
    setLongUnwind([]);
    setCollapseData({});
    setInstrument(productName);
    if(!historical){
      getExpiryList(productName, props.optionSpurtzList)
    }
    if(historical){
      setDate("")
      setExpiry("");
    }
  }

  function handleDateSelect (date:any) {
    setLongBuild([]);
    setShortBuild([]);
    setShortUnwind([]);
    setLongUnwind([]);
    setCollapseData({});
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  async function handleIdentifierChange(
    product: string,
    expiry: string,
    refDate?:any
  ) {
    if(historical && date === ""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    setLongBuild([]);
    setShortBuild([]);
    setShortUnwind([]);
    setLongUnwind([]);
    setCollapseData({});
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find((idx) => idx === product);
    let topicNameLocal = `OPTIONSSPURTZ_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}`;
    const toIdentifierLocal = `optionssprutz_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}_${expiry}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setToIdentifier(toIdentifierLocal);
    setTopicName(topicNameLocal);
    if (!historical) {
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId !== undefined ? userId : "",
          toIdentifierLocal
        );
      }else if(marketStatus!=="loading" && (marketStatus === "closed" || marketStatus==="pre-open")){
        getHistoricSpurtzData(toIdentifierLocal, topicNameLocal);
      }
    }else if(historical &&  refDate!=="" && refDate !== date && toIdentifier  === toIdentifierLocal){
      getHistoricSpurtzData(toIdentifierLocal, topicNameLocal, refDate);
    }
  }

  function getExpiryList(
    productName: string, 
    indexDataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    const expiryList: string[] = [];
    let nearestDate: any;
    if (indexDataList !== null && indexDataList.length > 0) {
      const filteredList = indexDataList.filter((product) => product.ProductName === productName);
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expiryStrikePriceData.map((exp) => exp.Expiry);
      nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null && nearestDate !== undefined) {
        setExpiry(nearestDate);
      }
      expiryStrikePriceData.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
    }
    const sortedDate = dateSort(expiryList);
    setExpiryList(sortedDate);
    handleIdentifierChange(productName, nearestDate, refDate)
  }
  const fetchDataAtInterval = async () => {
    await liveMsgOptionSpurtz(topicName, toIdentifier);
};

useEffect(()=>{
  setTimeout(()=>{
    setIsLoading(false)},22000)
},[instrument,expiry, date])

  React.useEffect(() => {
    if (historical) {
      setLongBuild([]);
      setShortBuild([]);
      setShortUnwind([]);
      setLongUnwind([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (date !== "" && instrument !== "" && toIdentifier !== undefined) {
        getHistoricSpurtzData(toIdentifier, topicName);
      }
    } 
  }, [historical, toIdentifier]);

  React.useEffect(() => {
    if(!historical){
      if (props.optionSpurtzList !== null && props.optionSpurtzList.length > 0) {
        getExpiryList(instrument, props.optionSpurtzList)
      }
    }
  }, [historical, userId, marketStatus]);

  React.useEffect(()=>{
    if(!historical && marketStatus === "open"){
    fetchDataAtInterval()
    }
  },[toIdentifier, marketStatus])

  useEffect(() => {
    let timeoutId:any;
    if(!historical && marketStatus === "open"){
        id = setInterval(fetchDataAtInterval, 10 * 1000);
      }else if(marketStatus === "after-open" && !historical){
        timeoutId = setTimeout(fetchDataAtInterval, 1000)
      }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toIdentifier, historical, marketStatus])

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  if (isLoggedIn === false) {
    navigate("/login");
  }
  if (userId === undefined) return <></>;

  return (
    <div className="mx-auto max-w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbOptionSpurtz} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-3 min-[1071px]:grid-cols-4">
        <SelectBox
          title="Select Name:"
          value={instrument}
          onValueChange={(e: string) => {
            handleProductChange(e);
          }}
          placeholder="Please Select Name"
        >
          <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
            <SelectLabel className="-ml-3">Index</SelectLabel>
            {props.optionSpurtzList && props.optionSpurtzList.slice(0, 4).map((item, index) => (
              <SelectItem key={index} value={item.ProductName}>
                {item.ProductName}
              </SelectItem>
            ))}
            <SelectLabel className="-ml-3">Stocks</SelectLabel>
            {props.optionSpurtzList && props.optionSpurtzList.slice(5, props.optionSpurtzList.length).map((item, index) => (
              <SelectItem key={index} value={item.ProductName}>
                {item.ProductName}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
            <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox
          title="Expiry:"
          value={expiry}
          onValueChange={(e: string) => {
           setExpiry(e);
           setCollapseData({});
           handleIdentifierChange(instrument, e);
          }}
          placeholder="Please Select Expiry"
        >
          {expiryList &&
            expiryList.map((item, index) => {
              const date = formatDate(item);
              return (
                <SelectItem key={index} value={item}>
                  {date}
                </SelectItem>
              );
            })}
        </SelectBox>
        
        <div>
          <p className="text-[12px]">Search</p>
          <div className="border shadow dark:border-zinc-600 mt-0.5">
            <Input
              placeholder="Search"
              className="h-full border-0 px-2 dark:zoom-in-100 bg-white shadow dark:bg-zinc-900"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={cn({ "hidden": collapsed })}>
        <OSSpot rows={collapseData} />
      </div>
      <CollapseButton collapsed={collapsed} handleCollapse={handleCollapse} />
      <div className="mx-auto overflow-x-auto custom-scrollbar">
        <div className="w-full grid grid-cols-2 gap-3 lg:gap-4 ">
          <OSTable height={longBuild.length > 0 || shortBuild.length > 0 ? true : false} loading={isLoading} searchInput={searchInput} title="Long Build Up" data={longBuild} />
          <OSTable height={longBuild.length > 0 || shortBuild.length > 0 ? true : false} loading={isLoading} searchInput={searchInput} title="Short Build Up" data={shortBuild} />
          <OSTable height={shortUnwind.length > 0 || longUnwind.length > 0 ? true : false} loading={isLoading} searchInput={searchInput} title="Short Covering" data={shortUnwind} />
          <OSTable height={shortUnwind.length > 0 || longUnwind.length > 0 ? true : false} loading={isLoading} searchInput={searchInput} title="Long Unwinding" data={longUnwind} />
        </div>
      </div>
    </div>
  );
}