import React, { useEffect, useRef, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { FoiTimeInterval } from "../../data/openInterest";
import LiveSwitch from "../shared/LiveSwitch";
import Breadcrumb from "../shared/Breadcrumb";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import useMarketStatus from "../../hooks/useMarketStatus";
import { toast } from "sonner";
import { cn } from "../../lib/utils";
import CollapseButton from "../shared/CollapseButton";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { CalendarDaysIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import format from "date-fns/format/index";
import {
  RefreshRateData,
  TimeIntervalData,
} from "../../constants/TimeInterval";
import { PremiumSpot } from "./PremiumSpot";
import { InstrumentExpiryStrikePrice, getOptionPremiumData } from "../../api/options";
import convertToYYYYMMDD, { findNearestDate, findNearestStrikePrice, formatDate } from "../shared/dateConverter";
import { getIndexSubscription } from "../../api/futures/open-interest";
import SelectBox from "../shared/SelectBox";
import { dateSort } from "../../utils/datesort";
import useSpotPriceStore from "../../store/spotPrice";
import { configEnvironemt } from "../../config";
import PremiumBarChart from "./PChart";
import { navigationPremium, nearStrikesPremium } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { baseURL } from "../../api/base";
import { Checkbox } from "../ui/checkbox";

export default function Premium(props: {
  optionPremium: InstrumentExpiryStrikePrice[];
}) {
  const expiryDates = props.optionPremium[0].ExpiryStrikePriceList.map(
    (exp) => exp.Expiry
  );
  const initialDate = findNearestDate(expiryDates);
  const environment = configEnvironemt.environment;
  const [collapsed, setCollapsed] = useState(false);
  const [collapseData, setCollapseData] = useState<any>({});
  const [historical, setHistorical] = useState(false);
  const [timeInterval, setTimeInterval] = useState(TimeIntervalData[1].value);
  const [instrument, setInstrument] = useState(props.optionPremium[0].ProductName);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [toIdentifier, setToIdentifier] = useState(
    `premium_${props.optionPremium[0].ProductName}_${expiry}_strikes`
  );
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `PREMIUM_OPTIDX_${props.optionPremium[0]?.ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [rows, setRows] = useState<any[] | []>([]);
  const [date, setDate] = useState<any>(new Date());
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [strikes, setStrikes] = useState(nearStrikesPremium[0].value);
  const [historicSpotData, setHistoricSpotData] = useState<InstrumentExpiryStrikePrice[]|null>(null);
  const [historicSpot, setHistoricSpot] = useState("");
  const [graphHeight, setGraphHeight] = useState(500);
  const [showLTP, setShowLTP] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { spotPrices, setSpotPrice } = useSpotPriceStore()
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  const marketStatus = useMarketStatus();
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  let id:any;
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const handleSwitch = () => {
    setRows([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(props.optionPremium[0]?.ProductName);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setIsLoading(false);
      setHistorical(true);
      setCollapseData({});
      setRows([]);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  const processMessage = async (messages: string[], newStrike?:string) => {
    const upRows = messages.map((msg) => {
      setSpotPrice(instrument, parseFloat(messages[messages.length - 1].split(",")[12].replace("\r", "")))
      return {
        strikePrice: msg.split(",")[0],
        callLTP: msg.split(",")[1],
        callIV: msg.split(",")[3],
        putLTP: msg.split(",")[2],
        putIV: msg.split(",")[4],
      }
    })

    const CollapseData = {
      currentfutureprice: messages[messages.length - 1].split(",")[5],
      callbuildupsATM: messages[messages.length - 1].split(",")[6],
      ATMcallIV: messages[messages.length - 1].split(",")[7],
      PremiumincallATM: messages[messages.length - 1].split(",")[8],
      PutBuildUpATM: messages[messages.length - 1].split(",")[9],
      ATMputIV: messages[messages.length - 1].split(",")[10],
      PremiuminputATM: messages[messages.length - 1].split(",")[11],
      SpotPrice: (marketStatus === "open" || marketStatus === "after-open") && !historical?  messages[messages.length - 1].split(",")[12].replace("\r", ""): newStrike
    }
    setCollapseData(CollapseData);
    upRows.splice(0, 1)
    setIsLoading(false);
    return upRows;
  }

  async function liveMsgPremium(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
      const messages = data.split("\n") as string[];
      const fRow = await processMessage(messages);
        setRows(fRow);
    }
  }
 
  async function findStrikePrice(product:string, newExpiry:string){
    let strikePriceList: string[] = [];
    let nearestStrikePrice:any;
  if(!historical){
    const nearSpotPrice = spotPrices[product] ? spotPrices[product] : props.optionPremium.find((item) => item.ProductName === product)?.SpotPrice;
    if(props.optionPremium!== null && props.optionPremium.length>0){
      const filteredList = props.optionPremium.filter(
        (item) => item.ProductName === product
      );
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const filterExpiryList = expiryStrikePriceData.filter((item) => item.Expiry === newExpiry);
      strikePriceList = filterExpiryList[0].StrikePrice;
      nearestStrikePrice = strikePriceList && await findNearestStrikePrice(nearSpotPrice, strikePriceList, product);
    }
  }else{
    const filteredList = historicSpotData?.filter((item) => item.ProductName=== product);
    if(filteredList && filteredList.length > 0){
      const expiryData = filteredList[0].ExpiryStrikePriceList.filter((exp) => exp.Expiry === newExpiry);
      strikePriceList = expiryData[0].StrikePrice;
      const historicSpot = filteredList[0].SpotPrice;
      nearestStrikePrice = await findNearestStrikePrice(historicSpot, strikePriceList)
    }
  }
  return nearestStrikePrice;
  }

  const getHistoricPremiumData = async (identifier: string, topicName:string, productName?: string, newExpiry?:string, refDate?:any) => {
    let newStrike;
    if(productName && newExpiry){
       newStrike = await findStrikePrice(productName, newExpiry);
    }
    let response;
    if (historical && identifier !== undefined && date !== '') {
       response = await getOptionPremiumData(identifier, topicName, !refDate? date : refDate);
    }else{
      response = await getOptionPremiumData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const message = response.split("\n") as string[];
      const fRow = await processMessage(message, newStrike);
      setRows(fRow);
    }
  }

  function handleProcuctChange(productName: string) {
    setRows([]);
    setCollapseData({});
    setInstrument(productName);
    if(!historical){
      getExpiryList(productName, props.optionPremium);
    }
    if(historical){
      setDate("");
      setExpiry("");
    }
  }

  function handleDateSelect (date:any) {
    setRows([]);
    setCollapseData({});
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
      setHistoricSpotData(data);
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  async function handleIdentifierChange(
    productName: string, 
    exp: string,
    refDate?:any
  ) {
    setToIdentifier("");
    if(historical && date === ""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    setRows([]);
    setCollapseData({});
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find((idx) => idx === productName);
    let topicNameLocal = `PREMIUM_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${productName}`;
    const toIdentifierLocal = `premium_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${productName}_${exp}_strikes`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    if(!historical){
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId !== undefined ? userId : "",
          toIdentifierLocal
        );
      }else if(marketStatus!=="loading" && (marketStatus ==="pre-open" || marketStatus === "closed")){
        getHistoricPremiumData(toIdentifierLocal, topicNameLocal, productName, exp);
      }
    }
    if(historical && refDate !=="" && refDate !== date && toIdentifier === toIdentifierLocal){
      getHistoricPremiumData(toIdentifierLocal, topicNameLocal, productName, exp, refDate)
    }
    setToIdentifier(toIdentifierLocal);
  }

  function getExpiryList(
    productName: string, 
    expiryDataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    const expiryList: string[] = [];
    let nearestDate: any;
    if (expiryDataList !== null && expiryDataList.length > 0) {
      const filteredList = expiryDataList.filter((product) => product.ProductName === productName);
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expiryStrikePriceData.map((exp) => exp.Expiry);
      nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null && nearestDate !== undefined) {
        setExpiry(nearestDate);
      }
      expiryStrikePriceData.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
      if(historical){
        const localHistoricSpot = filteredList[0].SpotPrice;
        setHistoricSpot(localHistoricSpot);
      }
    }
    const sortedDate = dateSort(expiryList);
    setExpiryList(sortedDate);
    handleIdentifierChange(productName, nearestDate, refDate)
  }

  const fetchDataAtInterval = async () => {
    await liveMsgPremium(topicName, toIdentifier);
};

const calculateGraphHeight = () => {
if(containerRef.current){
  const containerHeight = containerRef.current.clientHeight;
  const topBarHeight = document.querySelector("header")?.clientHeight || 0;
  const windowHeight = window.innerHeight;
  const offset = containerHeight + topBarHeight + 76;
 setGraphHeight(windowHeight - offset)
}
}

useEffect(() => {
  calculateGraphHeight(); 
  window.addEventListener("resize", calculateGraphHeight);
  return () => window.removeEventListener("resize", calculateGraphHeight);
}, [collapsed, historical, date, isLoading]);

useEffect(()=>{
  setTimeout(()=>{
    setIsLoading(false)},12000)
},[instrument,expiry, date])

  React.useEffect(() => {
    if (historical) {
      setRows([]);
      if (date !== "" && instrument !== "" && toIdentifier !== undefined) {
        getHistoricPremiumData(toIdentifier, topicName, instrument, expiry);
      }
    } 
  }, [historical, toIdentifier]);

  React.useEffect(() => {
    if(!historical){
      if (props.optionPremium !== null && props.optionPremium.length > 0) {
        getExpiryList(instrument, props.optionPremium);
     }
    }
  }, [historical, userId, marketStatus]);

  React.useEffect(()=>{
    if(!historical && marketStatus === "open"){
    fetchDataAtInterval()
    }
  },[toIdentifier, marketStatus])

  useEffect(() => {
    let timeoutId:any;
    if(!historical && marketStatus === "open"){
      const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
          id = setInterval(fetchDataAtInterval, seconds * 1000);
        }
      }else if(marketStatus === "after-open" && !historical){
        timeoutId = setTimeout(fetchDataAtInterval, 100)
      }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toIdentifier, historical, marketStatus])

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="flex items-center justify-between mt-4">
        <Breadcrumb items={navigationPremium} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-5 sm:grid-cols-4 min-[1064px]:grid-cols-7">
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Select Name:</p>
          <Select
            value={instrument}
            onValueChange={(e: string) => {
              handleProcuctChange(e);
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Name" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
                <SelectLabel className="-ml-3">Index</SelectLabel>
                {props.optionPremium &&
                  props.optionPremium.slice(0, 4).map((item, index) => (
                    <SelectItem key={index} value={item.ProductName}>
                      {item.ProductName}
                    </SelectItem>
                  ))}
                <SelectLabel className="-ml-3">Stocks</SelectLabel>
                {props.optionPremium &&
                  props.optionPremium.slice(5, props.optionPremium.length).map((item, index) => (
                    <SelectItem key={index} value={item.ProductName}>
                      {item.ProductName}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Expiry:</p>
          <Select
            value={expiry}
            onValueChange={(e: string) => {
              setExpiry(e);
              setCollapseData({});
              handleIdentifierChange(instrument, e);
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Expiry" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {expiryList &&
                  expiryList.map((item, index) => {
                    const date = formatDate(item);
                    return (
                      <SelectItem key={index} value={item}>
                        {date}
                      </SelectItem>
                    );
                  })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
       
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Time Interval:</p>
          <Select value={timeInterval} 
          onValueChange={(e:string) => {
            setTimeInterval(e)
            handleIdentifierChange(instrument, expiry)
          }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Interval" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {FoiTimeInterval.map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.value}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Refresh Rate:</p>
          <Select value={refreshRate} onValueChange={(e) => setRefreshRate(e)}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Refresh Rate" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {RefreshRateData.map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.value}`}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <SelectBox value={strikes} onValueChange={setStrikes} placeholder="Show Strikes" title="Show Strikes">
          {nearStrikesPremium.map((item, index) => {
            return (
              <SelectItem key={index} value={item.value}>
                {item.name}
              </SelectItem>
            );
          })}
        </SelectBox>
        <div className="flex items-center gap-2 pt-5" >
            <Checkbox onCheckedChange={(checked:boolean) => setShowLTP(checked)} checked={showLTP} />
            <p className="text-[12px]">Show LTP</p>
          </div>
      </div>
      <div className="mt-2 flex items-center justify-between sm:hidden"></div>
      <div className={cn({ hidden: collapsed })}>
        <PremiumSpot rows={collapseData} />
      </div>
      <CollapseButton collapsed={collapsed} handleCollapse={handleCollapse} />
      </div>
      <div className="mt-1 custom-scrollbar w-full overflow-auto pb-4">
        <div className="min-w-[750px] mx-auto">
          <PremiumBarChart 
          loading={isLoading} 
          spotPrice={!historical ? collapseData.SpotPrice : historicSpot} 
          strikes={strikes} 
          data={rows} 
          graphHeight={graphHeight}
          showLTP = {showLTP}
          />
        </div>
      </div>
    </div>
  );
}