import React, { useRef, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { FoiTimeInterval, optionImpliedInstrument } from "../../data/openInterest";
import LiveSwitch from "../shared/LiveSwitch";
import Breadcrumb from "../shared/Breadcrumb";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import useMarketStatus from "../../hooks/useMarketStatus";
import { toast } from "sonner";
import { cn } from "../../lib/utils";
import CollapseButton from "../shared/CollapseButton";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { CalendarDaysIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import format from "date-fns/format/index";
import { RefreshRateData, TimeIntervalData } from "../../constants/TimeInterval";
import { IVSPot } from "./IVSpot";
import IVGraph from "./IVGraph";
import { getImpliedVolitilityData } from "../../api/options";
import { getIndexSubscription } from "../../api/futures/open-interest";
import { configEnvironemt } from "../../config";
import { navigationIV } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";

export default function ImpliedVolatility() {
  const environment = configEnvironemt.environment;
  const [collapsed, setCollapsed] = useState(false);
  const [historical, setHistorical] = useState(false);
  const [timeInterval, setTimeInterval] = useState(TimeIntervalData[1].value);
  const [instrument, setInstrument] = useState(optionImpliedInstrument[0].name);
  const [collapseData, setCollapseData] = useState<any>({});
  const [toIdentifier, setToIdentifier] = useState(`ASIV_OPTIDX_${instrument}_${timeInterval}`);
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value)
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `ASIV_OPTIDX_${optionImpliedInstrument[0].name}`;
    if(environment === "test"){
      baseTopicName+= "_test";
    }
    return baseTopicName;
  });
  const [rows, setRows] = useState<any[] | []>([]);
  const [date, setDate] = useState<any>(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [graphHeight, setGraphHeight] = useState(500);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const marketStatus = useMarketStatus();
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  let id:any;
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const handleSwitch = () => {
    setRows([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(optionImpliedInstrument[0]?.name);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setIsLoading(false);
      setHistorical(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
    }
  };

  const processMessage = async (message: string[]) => {
    const upRows = message.map((item) => {
      return {
        time: item.split(",")[0].split("-")[0],
        price: item.split(",")[10],
        callIV: item.split(",")[1],
        putIV: item.split(",")[2],
      };
    });
    const CollapseData = {
      callbuildupsATM: message[message.length - 1].split(",")[3],
      PutBuildUpATM: message[message.length - 1].split(",")[4],
      ATMcallIV: message[message.length - 1].split(",")[5],
      ATMputIV: message[message.length - 1].split(",")[6],
      PremiumincallATM: message[message.length - 1].split(",")[7],
      PremiuminputATM: message[message.length - 1].split(",")[8],
      currentfutureprice: message[message.length - 1].split(",")[9],
      SpotPrice: message[message.length - 1].split(",")[10]
    }
    setCollapseData(CollapseData);
    const filteredData = upRows.filter((item) => item.time !=="");
    setIsLoading(false);
    return filteredData;
  };

  async function liveMsgIVGraph(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
      const message = data.split("\n") as string[];
      const fRow = await processMessage(message);
      setRows(fRow);
    }
  }

  const getHistoricImpliedData = async (identifier: string, topicName:string) => {
    let response;
    if (historical && identifier !== undefined && date !== '') {
       response = await getImpliedVolitilityData(identifier, topicName, date);
    }else{
      response = await getImpliedVolitilityData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const message = response.split("\n") as string[];
      const fRow = await processMessage(message);
      setRows(fRow);
    }
  };

  function handleProcductChange(productName: string) {
    setCollapseData({});
    setInstrument(productName);
    if(!historical){
      handleIdentifierChange(productName, timeInterval);
    }
    if(historical){
      setDate("")
    }
  }

  function handleDateSelect (date:Date) {
    setRows([]);
    setCollapseData({});
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
     handleIdentifierChange(instrument, timeInterval);
  }

  async function handleIdentifierChange(
    productName: string,
    time: string
  ) {
    if(historical && date === ""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    setRows([]);
    let topicNameLocal = `ASIV_OPTIDX_${productName}`;
    const toIdentifierLocal = `ASIV_OPTIDX_${productName}_${time}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    if(!historical){
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId !== undefined ? userId : "",
          toIdentifierLocal
        );
      } else if(marketStatus !== "loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
        getHistoricImpliedData(toIdentifierLocal, topicNameLocal)
      }
    }
    setToIdentifier(toIdentifierLocal);
  }

  const fetchDataAtInterval = async () => {
    await liveMsgIVGraph(topicName, toIdentifier);
};

const calculateGraphHeight = () => {
  if(containerRef.current){
    const containerHeight = containerRef.current.clientHeight;
    const topBarHeight = document.querySelector("header")?.clientHeight || 0;
    const windowHeight = window.innerHeight;
    const offset = containerHeight + topBarHeight + 70;
   setGraphHeight(windowHeight - offset)
  }
  }

React.useEffect(() => {
    calculateGraphHeight(); 
    window.addEventListener("resize", calculateGraphHeight);
    return () => window.removeEventListener("resize", calculateGraphHeight);
  }, [collapsed, historical, date, isLoading]);

React.useEffect(()=>{
  setTimeout(()=>{
    setIsLoading(false)},12000)
},[instrument, date]);

  React.useEffect(() => {
    if (historical) {
      setRows([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (date !== "" && instrument !== "" && toIdentifier !== undefined) {
        getHistoricImpliedData(toIdentifier, topicName);
      }
    } 
  }, [historical, toIdentifier, date]);

React.useEffect(() => {
  if(!historical){
    handleProcductChange(instrument);
  }
},[historical, userId, marketStatus]);

React.useEffect(()=>{
  if(!historical && marketStatus === "open"){
  fetchDataAtInterval()
  }
},[toIdentifier, marketStatus])

React.useEffect(()=>{
  let timeoutId:any;
  if(!historical && marketStatus === "open"){
    const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
          id = setInterval(fetchDataAtInterval, seconds * 1000);
          return () => clearInterval(id);
        }
  }else if(marketStatus === "after-open" && !historical){
    timeoutId = setTimeout(fetchDataAtInterval, 1000)
  } else if(marketStatus!== "loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
    getHistoricImpliedData(toIdentifier, topicName)
  }
  return () => {
    clearInterval(id);
    clearTimeout(timeoutId);
  }
},[toIdentifier, historical, marketStatus, refreshRate]);

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="flex items-center justify-between mt-4">
        <Breadcrumb items={navigationIV} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 min-[1064px]:grid-cols-4">
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Name:</p>
          <Select
            value={instrument}
            onValueChange={(e: string) => {
              handleProcductChange(e);
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Please Select Name" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {optionImpliedInstrument.map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.value}`}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Time Interval:</p>
          <Select value={timeInterval}
            onValueChange={(e: string) => {
              setTimeInterval(e);
              handleIdentifierChange(instrument, e);
            }}>
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Interval" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {FoiTimeInterval.map((item, index) => {
                  return (
                    <SelectItem key={index} value={item.value}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Refresh Rate:</p>
          <Select
            value={refreshRate}
            onValueChange={(e) => setRefreshRate(e)}
          >
            <SelectTrigger className="w-full shadow">
              <SelectValue placeholder="Refresh Rate" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {RefreshRateData.map((item, index) => {
                  return (
                    <SelectItem key={index} value={`${item.value}`}>
                      {item.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className={cn({ hidden: collapsed })}>
        <IVSPot rows={collapseData} />
      </div>
      <CollapseButton collapsed={collapsed} handleCollapse={handleCollapse} />
      </div>
      <div className="mt-1 custom-scrollbar overflow-x-auto">
        <div className="min-w-[730px] mx-auto">
          <IVGraph data={rows} loading={isLoading} graphHeight={graphHeight}/>
        </div>
      </div>
    </div>  
  );
}