import LandingLayout from "../../layout/LandingLayout";

export default function PrivacyPolicy() {
    return (
        <LandingLayout>
            <h1 className="font-montserrat text-center text-[28px] font-semibold sm:text-[36px] md:text-[48px]">
                Trade Dons - Privacy Policy
            </h1>
            <ul className="mt-[60px] list-decimal pl-4">
                {data.map((item, index) => (
                    <li key={index} className="mt-4">
                        <h3 className=" text-xl font-medium capitalize text-zinc-800 dark:text-zinc-50">
                            {item.title.toLowerCase()}
                        </h3>
                        <p className="mt-1 text-sm font-normal text-zinc-600 dark:text-zinc-400 sm:text-base">
                            {item.desc}
                        </p>
                    </li>
                ))}

                <li className="mt-4">
                    <h3 className="text-xl font-medium capitalize">
                        Companies Contact Details
                    </h3>
                    <ol className="ml-4 mt-2 list-decimal font-normal dark:text-zinc-400">
                        <li>
                            {" "}
                            Trade Dons Fintech Pvt Ltd. is an Indian company incorporated in
                            Mumbai.
                        </li>
                        <li className="mt-2">
                            {" "}
                            Clients shall communicate with the Company with the communication
                            methods described within this policy and/or at the following
                            address:
                        </li>
                    </ol>
                </li>
            </ul>

            <div className="mt-4">
                <h3 className="font-mdedium text-xl ">Correspondence Address:</h3>
                <p className="mt-1 font-normal dark:text-zinc-400">
                    Gokul. Dattani park. Thakur village. Kandivali (East). Mumbai-400101.
                    India
                </p>
            </div>
        </LandingLayout>
    );
}

const data = [
    {
        title: "Introduction",
        desc: "Trade Dons is Fintech Company under Trade Dons Fintech Pvt Ltd and the domain name www.tradedons.com",
    },
    {
        title: "PRIVACY POLICY",
        desc: "Protecting the privacy and safeguarding the personal and financial information of Trade Dons clients and website visitors is one of our highest priorities. The following Privacy Statement explains how we collect and protect your information.",
    },
    {
        title: "COLLECTION OF PERSONAL INFORMATION",
        desc: "We collect information required to open an account, to transact business effectively and to safeguard your assets and your privacy. To do this, we gather information to help us assess your needs and preferences. The information we collect directly from you includes information required to communicate with you, including your name, mailing address, telephone number, e-mail address. We also collect birth date and your location information.",
    },
    {
        title: "USAGE OF PERSONAL INFORMATION",
        desc: "We use personal information only as appropriate to provide you quality service and security. For example, we may use the information collected from you to verify your identity and contact information. We may also use this information to establish and set up your account, issue an account number and a secure password, maintain your account activity and contact you with account information. This information helps us improve our services to you, inform you about additional products, services or promotions that may be of interest to you.",
    },
    {
        title: "OUR AFFILIATES AND PARTNERS",
        desc: "We may share information with affiliates if the information is required to provide the product or service you have requested or to provide you the opportunity to participate in the products or services our affiliates offer. We may also forge partnerships and alliances, which may include joint marketing agreements, with other companies who offer high-quality products and services that might be of value to our clients. In order to ensure that these products and services meet your needs and are delivered in a manner that is useful and relevant, we may share some information with partners, affiliates and alliances. This allows them to better understand the offers that are most relevant and useful. The use of your personal information is limited to the purposes identified in our relationship with the partner or affiliate.",
    },
    {
        title: "NON-AFFILIATED THIRD PARTIES",
        desc: "We do not sell, license, lease or otherwise disclose your personal information to any third party for any reason, except as described below. We reserve the right to disclose your personal information to third parties where required by law to regulatory, law enforcement or other government authorities. We may also disclose your information as necessary to credit reporting or collection agencies, or when necessary to protect our rights or property. To help us improve our services to you, we may engage another business to help us to carry out certain internal functions such as account processing, fulfilment, client service, client satisfaction surveys or other data collection activities relevant to our business. We may also provide a party with client information from our database to help us analyze and identify client needs and notify clients of product and service offerings. Use of the information shared is strictly limited to the performance of the task we request and for no other purpose. All third parties with which we share personal information are required to protect personal information in a manner similar to the way we protect personal information.",
    },
    {
        title: "RESTRICTION OF RESPONSIBILITY",
        desc: "If at any time you choose to purchase a product or service offered by another company, any personal information you share with that company will no longer be controlled under our Privacy Statement. We are not responsible for the privacy policies or the content of sites we link to and have no control of the use or protection of information provided by you or collected by those sites. Whenever you elect to link to a cobranded Web site or to a linked Web site, you may be asked to provide registration or other information. Please note that the information you are providing is going to a third party and you should familiarize yourself with the privacy policy provided by that third party.",
    },
    {
        title: "OPTING OUT OF DISCLOSURES OF NON-PUBLIC PERSONAL INFORMATION",
        desc: `You may direct us not to disclose non-public personal information to certain non-affiliated third parties. To opt out of sharing non-public personal information with non-affiliated third parties, please contact a client service representative. An opt out election made by one account owner of a joint account is applicable to all account owners of the joint account. An opt-out election must be made for each separate account you hold with us.`,
    },
    {
        title: "USE OF COOKIES",
        desc: `A cookie is a small text file stored on the user’s computer for record-keeping and security purposes. It allows us to enhance user experience while browsing our website. TRADE DONS issues cookies upon landing on our website, unless the cookie settings on user’s browsers are disabled. Please be notified that by turning off cookie tracking in your browser, some of our services might become unusable such as promotions or registration. Cookies used by us do not contain any personal information nor do they contain account or password information. They merely allow the site to recognize that a page request comes from someone who has already logged on. To administer and improve our Web site, we may use a third party to track and analyse usage and statistical volume information, including page requests, form requests, and click paths. The third-party may use cookies to track behaviour and may set cookies on behalf of us. These cookies do not contain any personally identifiable information.`,
    },
    {
        title: "COMMUNICATIONS",
        desc: `A cookie is a small text file stored on the user’s computer for record-keeping and security purposes. It allows us to enhance user experience while browsing our website. TRADE DONS issues cookies upon landing on our website, unless the cookie settings on user’s browsers are disabled. Please be notified that by turning off cookie tracking in your browser, some of our services might become unusable such as promotions or registration. Cookies used by us do not contain any personal information nor do they contain account or password information. They merely allow the site to recognize that a page request comes from someone who has already logged on. To administer and improve our Web site, we may use a third party to track and analyse usage and statistical volume information, including page requests, form requests, and click paths. The third-party may use cookies to track behaviour and may set cookies on behalf of us. These cookies do not contain any personally identifiable information.`,
    },
    {
        title: "CHANGES TO THIS PRIVACY STATEMENT",
        desc: `Unless otherwise indicated for a particular service, any communications or material of any kind that you e-mail or otherwise transmit through the services, including information, data, questions, comments or suggestions will be treated as non-proprietary and non-confidential. By accepting this Agreement you grant a license to us to use your Communication details.`,
    },
];
