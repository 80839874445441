export const breadcrumbPlan = [
    { label: "Features", link: "/dashboard" },
    { label: "Purchase", link: "/features/purchase" },
  ];

export const terms = [
    " No refunds, transfers, or cancellations will be accommodated once payment is made. Please carefully consider your decision before subscribing.",
    "Trade Dons is not responsible for issues related to broker additions, broker API functionality, or broker order placements.",
    "We do not guarantee profits or take responsibility for losses incurred during trading. Trade Dons is solely a data analytics tool.",
    "You understand that Trade Dons does not offer buy/sell calls; it functions solely as an OI analysis tool.",
  ];