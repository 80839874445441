import { buttonVariants, Button } from '../components/ui/button';
import { Link, useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";
// import { ShieldQuestion } from "lucide-react";
// import AppLayout from "../layout/AppLayout";
// import { Button, buttonVariants } from "../components/ui/button";
// import { Link } from "react-router-dom";
// import { cn } from "../lib/utils";

// function ErrorPage() {
//     return (
//         <AppLayout>
//             <div className="min-h-[calc(100vh-70px)] flex items-center justify-center">
//                 <div className="shadow p-4 pb-12 border bg-white dark:border-zinc-600 dark:bg-zinc-800 sm:max-w-2xl w-full text-center rounded-lg text-white">
//                     <p className="text-[120px] font-bold text-center font-poppins text-blue-500 dark:text-white">404</p>
//                     <p className="text-blue-500 font-semibold text-lg text-center">Page Not Found</p>
//                     <p className="max-w-md mt-5 text-gray-400 dark:text-zinc-200 text-lg mx-auto">We're sorry the page you requested colud not be found. Please go back to the homepage</p>
//                     <Link className={cn(buttonVariants({ variant: "default", className: "bg-white border-blue-600 border dark:border-white rounded-full text-blue-600 hover:bg-white/90 mt-4" }))} to="/dashboard">Go Home</Link>
//                 </div>

//             </div>
//         </AppLayout>
//     )
// }

// export default ErrorPage


// const window = require('global/window');

interface IHasErrorProps {}
/** @todo - style, add props, and add business logic to this HasError  */
export const HasError = (_props: IHasErrorProps) => {

  const redirectToHomePage = () => {
    window.location.href = "/";
  };
  return (
    <div  className="min-h-[calc(100vh-70px)] flex items-center justify-center">
      <div className="shadow p-4 pb-12 border bg-white dark:border-zinc-600 dark:bg-zinc-800 sm:max-w-2xl w-full text-center rounded-lg text-white">
      <p className="text-[20px] font-bold text-center pb-8 font-poppins text-blue-500 dark:text-white">
        Something went wrong. Please try again.
      </p>
      
      <Button
        size="default"
        variant="default"
        color="secondary"
        onClick={redirectToHomePage}
      >
        Go to Dashboard
      </Button>
       {/* <Link to="/dashboard" >Go to Dashboard</Link> */}
      </div>
    </div>
  );
};