import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Icons } from "../../utils/icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";

function ColumnSettings({ table }: { table: any }) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="link">
          Column Settings
          <Icons.chevronDown className="ml-2 w-5 dark:text-white" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className=" mx-1 max-w-[1280px] dark:text-white p-0">
        <h2 className="rounded-md rounded-b-none border-b dark:text-white border-b-zinc-800  px-5 py-3 text-sm dark:border-b-white dark:bg-zinc-800 ">
          Select Columns
        </h2>
        <ScrollArea className="px-6 py-10 pt-6 h-[320px] sm:h-auto overflow-auto custom-scrollbar">
          <p className="mb-8 text-sm ">
            Select the column you want to show or hide in option chain
          </p>
          <h3 className="mb-4 text-sm font-semibold capitalize md:text-sm ">
            Select Call Side Columns
          </h3>
          <div className="grid grid-cols-2 gap-x-6 gap-y-2 sm:grid-cols-5 ">
            {table
              .getAllColumns()
              .filter((column: any) => column.getCanHide())
              .map((columns: any) => {
                if (columns.id === "CALLS") {
                  return columns.columns.map((column: any) => {
                    return (
                      <div key={column.id} className="flex items-center">
                        <Checkbox
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) =>
                            column.toggleVisibility(!!value)
                          }
                          id={column.id}
                        />
                        <label

                          className="pl-3 pr-4 text-xs capitalize md:text-sm"
                          htmlFor={column.id}
                        >
                          {column.columnDef &&
                            (column.columnDef.header as string)}
                        </label>
                      </div>
                    );
                  });
                }
              })}
          </div>
          <div>
            <h3 className="capitalizetext-sm mb-4  mt-8  font-semibold">
              Select Put Side columns
            </h3>
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 sm:grid-cols-5">
              {table
                .getAllColumns()
                .filter((column: any) => column.getCanHide())
                .map((columns: any) => {
                  if (columns.id === "PUTS") {
                    return columns.columns.map((column: any) => {
                      return (
                        <div key={column.id} className="flex  items-center">
                          <Checkbox
                            checked={column.getIsVisible()}
                            onCheckedChange={(value) =>
                              column.toggleVisibility(!!value)
                            }
                            id={column.id}
                          />
                          <label
                            className="pl-3 pr-4 text-xs capitalize md:text-sm"
                            htmlFor={column.id}
                          >
                            {column.columnDef.header as string}
                          </label>
                        </div>
                      );
                    });
                  }
                })}
            </div>
          </div>
        </ScrollArea>
        <div className="mb-8 flex items-center flex-wrap justify-end gap-4 pr-6">
          <AlertDialogCancel className="mt-0">Cancel</AlertDialogCancel>
          <AlertDialogAction>Continue</AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ColumnSettings;
