import { cn } from "../../lib/utils"
import browserName from "../shared/BrowserName";

let browser = browserName();

function BuildUpBadge({ text }: { text: string }) {
  return (
    <p
      className={text !== undefined ? cn(
        { "bg-yellow-500": text === "Long Unwinding" },
        { "bg-blue-500 dark:bg-blue-600": text === "Short Covering" },
        { "bg-red-500 dark:bg-red-600": text === "Short Build Up" },
        { "bg-green-500": text === "Long Build Up" },
        { "text-black dark:text-white": text.includes("-") },
        { "text-white": !text.includes("-") },
        browser==='apple safari'?
        "flex items-center justify-center py-1 px-1 w-full text-[12px] font-medium sm:min-w-max sm:text-[12px] rounded"
        :"flex h-full items-center w-full justify-center  px-1 py-1 text-[12px] font-medium rounded"
      ):""}
    >
      {text}
    </p>
  );
}

export default BuildUpBadge;
