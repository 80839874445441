import AppLayout from '../../../layout/AppLayout'
import Buzz from '../../../components/buzz/Buzz';

export default function BuzzPage() {
  return (
    <AppLayout>
      <Buzz />
    </AppLayout>
  )
}
