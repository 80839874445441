import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import {
  SelectItem,
} from "..//ui/select";
import { Button, buttonVariants } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import SelectBox from "../shared/SelectBox";
import { toast } from "sonner";
import { Input } from "../ui/input";
import POTable from "./POTable";
import POSpot from "./POSpot";
import { getPreOpenMarketData } from "../../api/dashboard";
import { convertUTCtoIST, preOpenArr } from "./constant";

const breadcrumb = [
  { label: "Dashboard", link: "/dashboard" },
  { label: "Features", link: "/dashboard" },
  { label: "Pre Open Market", link: "/features/pre-open" },
];
export default function PreOpen() {

  const [historical, setHistorical] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [instrument, setInstrument] =useState("All F&O Stocks");
  const [searchInput, setSearchInput] = useState("");
  const [marketAdvances, setMarketAdvances] = useState<any | []>([]);
  const [marketDeclines, setMarketDeclines] = useState<any | []>([]);
  const [indiceAdvances, setIndiceAdvances] = useState<any | []>([]);
  const [indiceDeclines, setIndiceDeclines] = useState<any | []>([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [collapseData, setCollapseData] = useState<any>({});

  const handleSwitch = () => {
      setMarketAdvances([]);
      setMarketDeclines([]);
      setIndiceAdvances([]);
      setIndiceDeclines([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
    }
    // Live to historic
    if (!historical) {
      setIsLoading(false)
      setHistorical(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
    }
  };

  const processMessage  = (message: string[]) => {
    let updatedCollapseData = { ...collapseData };
    const rows = message.map((item) => {
      const splitValues = item.split(",");
      if(splitValues[0].trim() === "NIFTY 50"){
        const utcTime = splitValues[8]?.replace("\r", "");
        const istTime = convertUTCtoIST(utcTime);
        
        updatedCollapseData = {
          ...updatedCollapseData,
          nifty: splitValues[1],
          premiumCall: splitValues[6],
          premiumPut: splitValues[7],
          time: istTime,
        };
      }
      if(splitValues[0].trim() === "NIFTY BANK"){
        updatedCollapseData = {
          ...updatedCollapseData,
          bankNifty: splitValues[1],
        };
      }
      if(preOpenArr.includes(splitValues[0])){
        return{
          name:splitValues[0],
          todayOpen: splitValues[1],
          prevDayClose: splitValues[2],
          ltpChangePercent: splitValues[3],
          ltpChange: splitValues[4],
          prevDayBreak: splitValues[5]
        };
      }
      return null;
    }).filter(row => row !== null);

    setCollapseData(updatedCollapseData);
    
    const positiveAdvances = rows.filter((row) => 
    row && parseFloat(row.ltpChange) >=0 && 
    parseFloat(row.ltpChangePercent)>=0  && 
    parseFloat(row.ltpChange) !== 0 &&
    !row.name.toLowerCase().includes("nifty"))
    .sort((a, b) => {
      if (!a || !b) return 0;
      return parseFloat(b.ltpChangePercent) - parseFloat(a.ltpChangePercent)});
    
    const negativeAdvances = rows
    .filter((row) => {
      if (!row) return false;
      const ltpChangePercent = parseFloat(row.ltpChangePercent);
      return (
        parseFloat(row.ltpChange) < 0 &&
        ltpChangePercent < 0 &&
        !row.name.toLowerCase().includes("nifty")
      );
    })
    .sort((a, b) => {
      if (!a || !b) return 0;
      return parseFloat(a.ltpChangePercent) - parseFloat(b.ltpChangePercent)});
    
    const indiceAdvances = rows.filter((row) => 
      row && parseFloat(row.ltpChange) >=0 && 
      parseFloat(row.ltpChangePercent)>=0 && 
      parseFloat(row.ltpChange) !== 0 &&
      row.name.toLowerCase().includes("nifty"))
      .sort((a, b) => {
        if (!a || !b) return 0;
        return parseFloat(b.ltpChangePercent) - parseFloat(a.ltpChangePercent)});
     
      const indiceDecline = rows
      .filter((row) => {
        if (!row) return false;
        const ltpChangePercent = parseFloat(row.ltpChangePercent);
        return (
          parseFloat(row.ltpChange) < 0 &&
          ltpChangePercent < 0 &&
          row.name.toLowerCase().includes("nifty")
        );
      })
      .sort((a, b) => {
        if (!a || !b) return 0;
        return parseFloat(a.ltpChangePercent) - parseFloat(b.ltpChangePercent)});
      setIsLoading(false);
    return {positiveAdvances, negativeAdvances, indiceAdvances, indiceDecline};
  }

  const fetchPreOpenData = async() => {
    let response:any;
    if(!historical){
       response = await getPreOpenMarketData();
    }else if(historical && date !==""){
      response = await getPreOpenMarketData(date);
    }
    if(response !== null && typeof response === "string" && response.length > 0){
      const message = response.split("\n") as string[];
      const {positiveAdvances, negativeAdvances, indiceAdvances, indiceDecline} = processMessage(message);
      setMarketAdvances(positiveAdvances);
      setMarketDeclines(negativeAdvances);
      setIndiceAdvances(indiceAdvances);
      setIndiceDeclines(indiceDecline);
    }
  }

  React.useEffect(() =>{
    fetchPreOpenData()
  },[date]);

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[date])

  return (
    <div className="mx-auto w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumb} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 min-[1071px]:grid-cols-4">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e: string) => {
          }}
          placeholder="Please Select Name"
        >
          <SelectItem key={instrument} value={instrument}>
            {instrument}
          </SelectItem>
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date| undefined) =>{
                  setDate(e);
                  setIsCalendarOpen(false);
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div>
          <p className="text-[12px]">Search</p>
          <div className="border dark:border-zinc-600 mt-0.5 shadow">
            <Input
              placeholder="Search"
              className="h-full border-0 px-2 dark:zoom-in-100 bg-white  dark:bg-zinc-900"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="text-[12px]">Data last Updated At: {collapseData?.time}</div> */}
     

      
        <div>
          <p className="text-[12px]">Last Updated at:</p>
          <div className="border dark:border-zinc-600 mt-0.5 shadow">
            <p
              className="h-full border-0 px-2 py-2 dark:zoom-in-100 bg-white  dark:bg-zinc-900 text-[13px]"
              >
              {`${collapseData?.time? collapseData?.time:"-"}`}
              </p>
          </div>
        </div>
        
      </div>
      
      <div>
        <POSpot row={collapseData} />
      </div>

      <div className="md:grid grid-cols-2 gap-3 lg:gap-4 mt-4">
      <POTable loading={isLoading} height={false} searchInput={searchInput} title="Pre-open Market Advances" data={marketAdvances}  />
      <POTable loading={isLoading} height={false} searchInput={searchInput} title="Pre-open Market Declines" data={marketDeclines}  />
      <POTable loading={isLoading} height={false} searchInput={searchInput} title="Pre-open Indice Advances" data={indiceAdvances}  />
      <POTable loading={isLoading} height={false} searchInput={searchInput} title="Pre-open Indice Declines" data={indiceDeclines}  />
      </div>
    </div>
  );
}
