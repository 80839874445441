import { create } from 'zustand';
import baseApi from '../api/base';
import { TFuturesOpenInterst } from '../api/futures/open-interest';

interface IndexExpiryDetailsState  {
    indexExpiryDetails: TFuturesOpenInterst;
    loading: boolean;
    error: string | null;
    fetchIndexExpiryDetails: () => Promise<void>;
}

const useIndexExpiryDetailsStore = create<IndexExpiryDetailsState>((set) => ({
    indexExpiryDetails: [],
    loading: false,
    error: null,
    fetchIndexExpiryDetails: async () => {
      set({ loading: true, error: null });
      try {
        const { data } = await baseApi.get("/api/FOS/GetIndexAndExpiryDetails");
        set({ indexExpiryDetails: data, loading: false });
      } catch (error:any) {
        set({ loading: false, error: error.message });
      }
    },
  }));
  
  export default useIndexExpiryDetailsStore;