import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { useEffect, useMemo, useState } from "react";
import { LoserGainerColumns, columns } from "./Columns";
import { Loader, TriangleIcon } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

const emptyArr: any = []
export default function IMTable({
  title,
  columns,
  data,
  searchInput,
  loading
}: {
  title?: string;
  columns: any;
  data?: any;
  searchInput?: string
  loading: boolean
}) {

  const [filtering, setFiltering] = useState(searchInput)
  const initialData = data === undefined ? emptyArr : data
  const memoizeColumns = useMemo(() => columns, [columns])
  const table = useReactTable({
    data: initialData,
    columns: memoizeColumns,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { globalFilter: filtering, },
    onGlobalFilterChange: setFiltering,
    getSortedRowModel: getSortedRowModel()
  });

  const [loader, setloader] = useState(true)
  useEffect(() => {
    setloader(loading)
  }, [loading])


  useEffect(() => {
    setFiltering(searchInput)
  }, [searchInput])

  return (
    <>
      <div className="mb-4 w-full">
        <h3 className="dark:bg-zinc-800 rounded-t-lg border-2 text-center text-sm border-b-2 font-semibold dark:border-zinc-600 bg-zinc-50 py-3">
          {title}
        </h3>
        <div
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: "1px" }}
          className={cn("custom-scrollbar rounded-t-none relative w-full overflow-auto rounded-lg border-2 dark:border-zinc-600 border-b-0 rounded-b-none max-h-[516px]",
          )}
        >
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  <th className="border-b dark:border-zinc-600 bg-white dark:bg-zinc-800 border-zinc-200" />
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="border-b text-black bg-white dark:bg-zinc-800 dark:border-zinc-600 border-zinc-200 py-3"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length !== 0 ? (
                table.getRowModel().rows.map((row, index) => (
                  <TableRow key={row.id}>
                    <td className={cn("pl-2", { "dark:bg-zinc-800 bg-zinc-200": index % 2 })}>{index + 1}.</td>
                    {row.getVisibleCells().map((cell) => {
                      <td>{index + 1}.</td>
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            "md:text-[13px]",
                            { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                            { "text-start pl-2": cell.column.id === "name" },
                            "py-2"
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          ) === ""
                            ? "-"
                            : flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell />
                  <TableCell
                    colSpan={columns.length}
                    className="h-12 text-center md:text-[13px]"
                  >
                    {loader ? <Loader className="animate-spin mx-auto" /> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex border-t bg-zinc-50 text-zinc-400 border-t-zinc-200  dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1">
          <div className="flex pl-4 items-center justify-between w-full ">
            <div className="flex items-center ">
              <p className="text-sm font-medium">Rows per page</p>
              <Select
                value={`${table.getState().pagination.pageSize}`}
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
              >
                <SelectTrigger className="h-8 w-[64px] bg-zinc-50 dark:bg-zinc-800 border-none">
                  <SelectValue placeholder={table.getState().pagination.pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                  {[10, 20, 30, 40].map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => table.previousPage()}
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                disabled={!table.getCanPreviousPage()}
              >
                <TriangleIcon className="w-2 text-zinc-500 -rotate-90 fill-zinc-500 hover:text-fill-400" />
                Prev
              </button>
              <div className="flex w-[50px] items-center justify-center text-sm font-medium">
                {table.getPageCount() === 0 ? 0 : table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </div>
              <button
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                Next
                <TriangleIcon className="w-2 rotate-90 text-zinc-500 fill-zinc-500 hover:text-zinc-600 hover:fill-zinc-600 " />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const IMGainers = (props: { gainer?: any, searchInput: string, loading: boolean }) => {
  return (
    <IMTable
      loading={props.loading}
      title="Top Gainers"
      columns={LoserGainerColumns}
      data={props.gainer}
      searchInput={props.searchInput}
    />
  );
};
export const IMLoser = (props: { loser?: any, searchInput: string, loading: boolean }) => {
  return (
    <IMTable
      loading={props.loading}
      title="Top Losers"
      columns={LoserGainerColumns}
      data={props.loser}
      searchInput={props.searchInput}
    />
  );
};

export const IMLive = ({ searchInput, data, loading }: { searchInput: string, data: {}[], loading: boolean }) => {
  return <IMTable title="Live Market Intraday Mover" loading={loading} searchInput={searchInput} columns={columns} data={data} />;
};
