import React, { useEffect, useState } from 'react'
import { Icons } from '../../utils/icons';
import OpenInterest from './OpenInterest';
import useIndexExpiryDetailsStore from '../../store/foiInstrumentExpiry';

const Foi = () => {
    const {fetchIndexExpiryDetails, indexExpiryDetails} = useIndexExpiryDetailsStore();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(indexExpiryDetails.length === 0){
            setLoading(true);
            fetchIndexExpiryDetails()
            setLoading(false);
        }
    },[indexExpiryDetails]);

    if(loading){
        return (
            <div className="flex h-[calc(100vh-90px)] items-center justify-center">
                <Icons.spinner
                className="mr-2 h-12 w-12 animate-spin"
                aria-hidden="true"
                />
            </div>
        )
    } else {
        return (
        <>
        {indexExpiryDetails.length>0 && <OpenInterest indexList={indexExpiryDetails}/>}
        </>
        )
    }
}

export default Foi;