import { format, parse } from "date-fns";
import { getProductSpotPrice } from "../../api/options";
import browserName from "./BrowserName";
export function formatDate(inputDate: string) {
  const day = inputDate.slice(0, 2);
  const month = inputDate.slice(2, 5);
  const year = inputDate.slice(5);

  const formatedDate = `${day}-${month}-${year}`;
  return formatedDate;
}

const convertToYYYYMMDD = (timestamp: string): string => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function findNearestDate(dateList: string[], refernceDate?: Date) {
  let dateObjects: Date[];
  const currentDate = !refernceDate ? new Date() : refernceDate;
  let browser = browserName();
  if (browser === "apple safari") {
    dateObjects = dateList.map((dateString) => {
      const day = parseInt(dateString.slice(0, 2));
      const monthAbbreviation = dateString.slice(2, 5);
      const year = parseInt(dateString.slice(5, 9));
      const monthNames: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames.indexOf(monthAbbreviation) + 1;
      const standardizedDateString = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      return new Date(standardizedDateString);
    });
  } else {
    dateObjects = dateList.map((date) => new Date(date));
  }
  if (!(currentDate instanceof Date) || isNaN(currentDate.valueOf())) {
    throw new Error("Invalid reference date");
  }
  const currentDateIndex = dateObjects.findIndex(
    (date) => date.toDateString() === currentDate.toDateString()
  );
  if (currentDateIndex !== -1) {
    return dateList[currentDateIndex];
  }
  const upcomingDates = dateObjects.filter((date) => date > currentDate);
  if (upcomingDates.length > 0) {
    const nearestDateObject = upcomingDates.reduce((nearest, date) => {
      return date < nearest ? date : nearest;
    });
    const nearestDateIndex = dateObjects.findIndex(
      (date) => date === nearestDateObject
    );
    return dateList[nearestDateIndex];
  }
  return null;
}

export async function findNearestStrikePrice(
  spotPrice: string,
  strikePrices: string[],
  productName?: string
) {
  let response;
  if (productName !== undefined && productName !== "") {
    response = await getProductSpotPrice(productName);
  }
  const newSpotPrice = !response ? spotPrice : response;
  let nearestStrikeprice: any;
  let minDifference = Number.MAX_SAFE_INTEGER;
  if (strikePrices?.length)
    for (const strikePrice of strikePrices) {
      const difference = Math.abs(
        parseFloat(newSpotPrice) - parseFloat(strikePrice)
      );
      if (difference < minDifference) {
        minDifference = difference;
        nearestStrikeprice = strikePrice;
      }
    }
  return nearestStrikeprice;
}

function findNearestStrikePriceIndex(spotPrice: any, strikePrices: any) {
  let nearestStrikePriceIndex = 0;
  let minDifference = Number.MAX_SAFE_INTEGER;
  for (let i = 0; i < strikePrices.length; i++) {
    const difference = Math.abs(spotPrice - strikePrices[i]);
    if (difference < minDifference) {
      minDifference = difference;
      nearestStrikePriceIndex = i;
    }
  }
  return nearestStrikePriceIndex;
}

export async function findNearestStrikePrices(
  spotPrice: any,
  strikePrices: any,
  valueBefore: number,
  valueAfter: number,
  productName?: string
) {
  let response;
  if (productName !== undefined && productName !== "") {
    response = await getProductSpotPrice(productName);
  }
  const newSpotPrice = !response ? spotPrice : response;
  const nearestStrikePriceIndex = findNearestStrikePriceIndex(
    newSpotPrice,
    strikePrices
  );
  // const nearestStrikePriceIndex = findNearestStrikePriceIndex(spotPrice, strikePrices);
  const selectedStrikePrices = [];
  for (let i = -valueBefore; i <= valueAfter; i++) {
    const index = nearestStrikePriceIndex + i;
    if (index >= 0 && index < strikePrices.length) {
      selectedStrikePrices.push(strikePrices[index]);
    }
  }
  return selectedStrikePrices;
}

export function futureEODDate(dateString: string) {
  if (dateString !== "" && dateString !== undefined) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }
}

export default convertToYYYYMMDD;
