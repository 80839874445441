import { Link } from "react-router-dom";
import {
    CallSupport,
    Email,
    LiveChat,
    WhatsApp,
} from "./icons";
import React, { useEffect } from "react";
import Footer from "../landing-page/Footer";
import Header from "../landing-page/Header";
import { Button } from "../ui/button";

export default function Support() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <Header />
            <section
                style={{ backgroundImage: "url('/assets/support.jpg')" }}

                className="bg-center min-h-24 overflow-hidden min-h-[500px] pb-8 bg-cover bg-no-repeat flex items-end relative justify-center after:w-full after:h-full after:absolute after:left-0 after:bg-black after:pb-8 after:top-0 after:opacity-50 after:z-10"
            >
                <div className="z-20 px-6 py-8 shadow rounded-md relative max-w-2xl mx-auto bg-white dark:bg-gray-900 dark:text-white text-center">
                    <h1 className="mb-4 font-semibold text-4xl text-blue-500">TRADEDONS SUPPORT</h1>
                    <p className="mb-8 text-lg" >
                        Simply click the button below to schedule a call with our support now. Once you fill out the form, you'll receive a time slot to meet with one of our experts and get your questions answered.
                    </p>
                    <Button >
                        <Link
                            to="/book-slot"
                            className="sm:text-xl bottom-8 px-12  z-10 "
                        >
                            Book Your Slot
                        </Link>
                    </Button>
                </div>
            </section>
            <section className="bg-blue-900 text-white py-16 px-6">
                <div className="pb-12 text-center items-center border-b max-w-6xl mx-auto    ">
                    <p className="text-2xl min-w-max  m-1 font-semibold text-center">
                        ( Monday - saturday )
                    </p>
                    <span className="bg-white min-w-max inline-block m-1 px-6 py-2 rounded-md  font-semibold text-lg text-blue-900 max-w-max mx-auto">
                        4:30 AM UTC to 2:00 PM UTC
                    </span>
                    <span className="text-lg m-1 block sm:inline-block">OR</span>
                    <span className="bg-white min-w-max inline-block m-1 px-6 py-2 rounded-md font-semibold text-lg text-blue-900 max-w-max mx-auto">
                        10:00 AM IST to 7:30 PM IST
                    </span>
                </div>

                <div className="mt-12 text-center">
                    <h3 className="text-3xl mb-10 font-semibold ">Support Desk</h3>
                    <div className="grid grid-cols-2 sm:grid-cols-4 max-w-4xl mx-auto">
                        <Card icon={<Email />} desc="Email" />
                        <Card icon={<LiveChat />} desc="Live Chat" />
                        <Card icon={<WhatsApp />} desc="WhatsApp" />
                        <Card icon={<CallSupport />} desc="Call Support" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

interface TCard {
    icon?: React.ReactNode;
    heading?: string;
    desc?: string;
    className?: string;
    iconWidth?: string;
};

function Card({ icon, heading, desc, className, iconWidth }: TCard) {
    return (
        <div className={`text-center ${className}`}>
            <div className={`mx-auto ${iconWidth ? iconWidth : "w-24"}`}>{icon}</div>
            <h3 className="sm:text-xl capitalize font-semibold my-4">{heading}</h3>
            <p className="max-w-xs m-auto text-sm sm:text-base">{desc}</p>
        </div>
    );
}

