import { useEffect } from "react";
import LandingLayout from "../../layout/LandingLayout";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import RefundConstant from "./RefundConstant";
import AppLayout from "../../layout/AppLayout";

export default function Refund() {
  const isLoggedIn = useGetFromStore(useAuthStore,(state) => state.isLoggedIn);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {
      !isLoggedIn ?
      <LandingLayout>
      <RefundConstant/>
      </LandingLayout>
      :
      <AppLayout>
      <RefundConstant/>
      </AppLayout>
    }
    </>
  );
}