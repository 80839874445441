import { FoiTimeInterval } from "../../data/openInterest";

const timeList_5Min = [
  "15:30",
  "15:25",
  "15:20",
  "15:15",
  "15:10",
  "15:05",
  "15:00",
  "14:55",
  "14:50",
  "14:45",
  "14:40",
  "14:35",
  "14:30",
  "14:25",
  "14:20",
  "14:15",
  "14:10",
  "14:05",
  "14:00",
  "13:55",
  "13:50",
  "13:45",
  "13:40",
  "13:35",
  "13:30",
  "13:25",
  "13:20",
  "13:15",
  "13:10",
  "13:05",
  "13:00",
  "12:55",
  "12:50",
  "12:45",
  "12:40",
  "12:35",
  "12:30",
  "12:25",
  "12:20",
  "12:15",
  "12:10",
  "12:05",
  "12:00",
  "11:55",
  "11:50",
  "11:45",
  "11:40",
  "11:35",
  "11:30",
  "11:25",
  "11:20",
  "11:15",
  "11:10",
  "11:05",
  "11:00",
  "10:55",
  "10:50",
  "10:45",
  "10:40",
  "10:35",
  "10:30",
  "10:25",
  "10:20",
  "10:15",
  "10:10",
  "10:05",
  "10:00",
  "09:55",
  "09:50",
  "09:45",
  "09:40",
  "09:35",
  "09:30",
  "09:25",
  "09:20",
];
const timeList_3Min = [
  "15:30",
  "15:27",
  "15:24",
  "15:21",
  "15:18",
  "15:15",
  "15:12",
  "15:09",
  "15:06",
  "15:03",
  "15:00",
  "14:57",
  "14:54",
  "14:51",
  "14:48",
  "14:45",
  "14:42",
  "14:39",
  "14:36",
  "14:33",
  "14:30",
  "14:27",
  "14:24",
  "14:21",
  "14:18",
  "14:15",
  "14:12",
  "14:09",
  "14:06",
  "14:03",
  "14:00",
  "13:57",
  "13:54",
  "13:51",
  "13:48",
  "13:45",
  "13:42",
  "13:39",
  "13:36",
  "13:33",
  "13:30",
  "13:27",
  "13:24",
  "13:21",
  "13:18",
  "13:15",
  "13:12",
  "13:09",
  "13:06",
  "13:03",
  "13:00",
  "12:57",
  "12:54",
  "12:51",
  "12:48",
  "12:45",
  "12:42",
  "12:39",
  "12:36",
  "12:33",
  "12:30",
  "12:27",
  "12:24",
  "12:21",
  "12:18",
  "12:15",
  "12:12",
  "12:09",
  "12:06",
  "12:03",
  "12:00",
  "11:57",
  "11:54",
  "11:51",
  "11:48",
  "11:45",
  "11:42",
  "11:39",
  "11:36",
  "11:33",
  "11:30",
  "11:27",
  "11:24",
  "11:21",
  "11:18",
  "11:15",
  "11:12",
  "11:09",
  "11:06",
  "11:03",
  "11:00",
  "10:57",
  "10:54",
  "10:51",
  "10:48",
  "10:45",
  "10:42",
  "10:39",
  "10:36",
  "10:33",
  "10:30",
  "10:27",
  "10:24",
  "10:21",
  "10:18",
  "10:15",
  "10:12",
  "10:09",
  "10:06",
  "10:03",
  "10:00",
  "09:57",
  "09:54",
  "09:51",
  "09:48",
  "09:45",
  "09:42",
  "09:39",
  "09:36",
  "09:33",
  "09:30",
  "09:27",
  "09:24",
  "09:21",
  "09:18",
];

const timeList_10Min = [
  "15:30",
  "15:25",
  "15:15",
  "15:05",
  "14:55",
  "14:45",
  "14:35",
  "14:25",
  "14:15",
  "14:05",
  "13:55",
  "13:45",
  "13:35",
  "13:25",
  "13:15",
  "13:05",
  "12:55",
  "12:45",
  "12:35",
  "12:25",
  "12:15",
  "12:05",
  "11:55",
  "11:45",
  "11:35",
  "11:25",
  "11:15",
  "11:05",
  "10:55",
  "10:45",
  "10:35",
  "10:25",
  "10:15",
  "10:05",
  "09:55",
  "09:45",
  "09:35",
  "09:25",
];

const timeList_15Min = [
  "15:30",
  "15:15",
  "15:00",
  "14:45",
  "14:30",
  "14:15",
  "14:00",
  "13:45",
  "13:30",
  "13:15",
  "13:00",
  "12:45",
  "12:30",
  "12:15",
  "12:00",
  "11:45",
  "11:30",
  "11:15",
  "11:00",
  "10:45",
  "10:30",
  "10:15",
  "10:00",
  "09:45",
  "09:30",
];

const timeList_30Min = [
  "15:30",
  "15:15",
  "14:45",
  "14:15",
  "13:45",
  "13:15",
  "12:45",
  "12:15",
  "11:45",
  "11:15",
  "10:45",
  "10:15",
  "09:45",
];

const timeList_60Min = [
  "15:30",
  "15:15",
  "14:15",
  "13:15",
  "12:15",
  "11:15",
  "10:15",
];

const getTimeListByInterval = (interval: string) => {
  switch (interval) {
    case "3Min":
      return timeList_3Min;
    case "5Min":
      return timeList_5Min;
    case "10Min":
      return timeList_10Min;
    case "15Min":
      return timeList_15Min;
    case "30Min":
      return timeList_30Min;
    case "60Min":
      return timeList_60Min;
      default:
        return [];
  }
};

export const getTimeDataMap = (time: string) => {
    let timeList:string[]|any[]=[];
  FoiTimeInterval.forEach((interval) => {
    if (interval.value === time) {
         timeList = getTimeListByInterval(time);
    }
  });
  return timeList;
};
