import { MouseEventHandler } from "react";
import { Button } from "../ui/button";
import { Icons } from "../../utils/icons";

export default function CollapseButton({
  handleCollapse,
  collapsed,
}: {
  handleCollapse: MouseEventHandler;
  collapsed: boolean;
}) {
  return (
    <Button
      onClick={handleCollapse}
      variant="link"
      className="collapse-btn min-w-max px-2 bg-transparent font-semibold underline hover:bg-none"
    >
      {collapsed ? (
        <>
          Expand Toolbar
          <Icons.chevronDown className="ml-2 w-5 dark:text-white" />
        </>
      ) : (
        <>
          Collapse Toolbar
          <Icons.chevronUp className="ml-2 w-5 dark:text-white" />
        </>
      )}
    </Button>
  );
}
