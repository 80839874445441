export const configEnvironemt = {
    environment: "production",
    // environment: "test"
}



const titleConfig = {
    // '/': 'Home',
    '/privacy-policy': 'Privacy Policy',
    '/terms-and-conditions': 'Terms and Conditions',
    '/refund': 'Refund Policy',
    '/disclaimer': 'Disclaimer',
    // '/pricing': 'Pricing Plans',
    '/login': 'Login',
    '/register': 'Register',
    '/reset': 'Reset Password',
    '/dashboard':'Dashboard',
    '/futures/open-interest': 'Future Open Interest',
    '/futures/intraday-mover': 'Future Intraday Mover',
    '/futures/future-spurtz': 'Future Spurtz',
    '/futures/eod': ' Future EOD',
    '/options/strike-price-wise': 'Options Strike-Price-Wise',
    '/options/option-chain': 'Options Chain',
    '/options/trend-finder': 'Options Trend Finder',
    '/options/advanced-trend-finder': 'Advanced Trend Finder',
    '/options/expert-opinion': 'Options Expert Opinion',
    '/options/option-spurtz': 'Options Spurtz',
    '/options/premium': 'Options Premium',
    '/options/implied-volatility': 'Options IV Graph',
    '/options/head-office': 'Options Head Office',
    '/options/oi-graph': 'Options OI Graph',
    '/features/pre-open': 'Pre-Open',
    '/features/plans': 'Plans',
    '/features/purchase': 'Plans Subscribe',
    '/book-slot': 'Book Slot',
    '/user-billing':'My Billing'
  };
  
  export default titleConfig;
  