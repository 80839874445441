import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Loader, Triangle, TriangleIcon, } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import browserName from "../shared/BrowserName";
let browser = browserName();
export type SpurtzTypes = {
  strikePrice: string,
  type: string;
  ltp: string;
  prevClose: string;
  ltpChangePercent: string;
  oiChangePercent: string;
  newOi: string;
  oldOi: string;
  oiChange: string;
  volume: string
};

const SortingUi = ({ column, title, className }: { column: any, title: string, className?: string }) => (
  <div className={cn("flex items-center min-w-max text-center gap-1 cursor-pointer", className)} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
    <span>{title}</span>
    <div>
      <Triangle className={cn("h-2 w-1.5 fill-zinc-600 dark:fill-[#d9d9d9]", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "desc" })} />
      <Triangle className={cn("h-2 w-1.5 fill-zinc-600 dark:fill-[#d9d9d9] rotate-180", { "fill-green-500 dark:fill-green-500 text-green-500": column.getIsSorted() === "asc" })}
      />
    </div>  </div>)
const columns: any = [
  {
    accessorKey: "strikePrice",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Strike" />,
    cell: ({ row }: any) => {
      const strikePrice = parseFloat(row.getValue("strikePrice"));
      return <p>{formatCurrency(strikePrice)}</p>
    }
  },
  {
    accessorKey: "type",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Type" />,
    cell: ({ row }: any) => {
      const type = row.getValue("type");
      return <p className={type === "CE" ? "text-green-500" : "text-red-500"}>{type}</p>
    }
  },

  {
    accessorKey: "ltp",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="LTP" />,
    cell: ({ row }: any) => {
      const ltp = parseFloat(row.getValue("ltp"));
      return <p>{formatCurrency(ltp)}</p>
    }
  },
  {
    accessorKey: "prevClose",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Prev. Close" />,
    cell: ({ row }: any) => {
      const prevClose = parseFloat(row.getValue("prevClose"));
      return <p>{formatCurrency(prevClose)}</p>
    }
  },
  {
    accessorKey: "ltpChangePercent",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="% Chng. LTP" />,
    cell: ({ row }: any) => {
      const data = parseFloat(row.getValue("ltpChangePercent"));
      return <p className={`${data > 0 && "text-green-500"}  ${data < 0 && "text-red-500"}`}>
        {formatDecimal(data)}
        {data && row.getValue("ltpChangePercent") !== "-" ? "%" : ""}</p>
    }
  },
  {
    accessorKey: "oiChangePercent",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="% Chng. OI" />,
    cell: ({ row }: any) => {
      const data = parseFloat(row.getValue("oiChangePercent"));
      return <p className={`${data > 0 && "text-green-500"}  ${data < 0 && "text-red-500"}`}>
        {formatDecimal(data)}
        {data && row.getValue("oiChangePercent") !== "-" ? "%" : ""}</p>
    }
  },
  {
    accessorKey: "newOi",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="New OI" />,
    cell: ({ row }: any) => {
      const newOi = parseFloat(row.getValue("newOi"));
      return <p>{formatCurrency(newOi)}</p>
    }

  },
  {
    accessorKey: "oldOi",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Old OI" />,
    cell: ({ row }: any) => {
      const oldOi = parseFloat(row.getValue("oldOi"));
      return <p>{formatCurrency(oldOi)}</p>
    }
  },
  {
    accessorKey: "oiChange",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="OI Chng." />,
    cell: ({ row }: any) => {
      const oiChange = parseFloat(row.getValue("oiChange"));
      return <p className={oiChange >= 0 ? "text-green-500" : "text-red-500"}>{formatCurrency(oiChange)}</p>
    }
  },
  {
    accessorKey: "volume",
    header: ({ column }: { column: any }) => <SortingUi column={column} title="Volume" />
  },
];

export default function OSTable({ title, searchInput, loading, data, height }: { title?: string, searchInput?: string, loading: boolean, data: any[], height?: boolean }) {
  const [filtering, setFiltering] = useState(searchInput)
  const memoizeColumns = useMemo(() => columns, [])

  const table = useReactTable({
    data: data,
    columns: memoizeColumns,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { globalFilter: filtering },
    onGlobalFilterChange: setFiltering,
  });

  const [loader, setLoader] = useState(true)
  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
    setFiltering(searchInput)
  }, [searchInput])

  return (
    <>
      <div className="mb-4 w-full">
        <h3 className="dark:bg-zinc-800  rounded-t-lg border text-center py-3 text-sm border-b font-semibold dark:border-zinc-600 bg-zinc-100">
          {title}
        </h3>
        <div className={cn(browser==="apple safari"?"twh custom-scrollbar whitespace-nowrap border-t-0 rounded-none relative rounded-b-none border-b-0 w-full overflow-auto  border-2 dark:border-zinc-600":"twh custom-scrollbar border-t-0 rounded-none relative rounded-b-none border-b-0 w-full overflow-auto  border-2 dark:border-zinc-600",
          { "h-[450px]": height })}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="border-b py-3">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                          { "text-start": cell.column.id === "strike" },"!text-xs"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {loader ? <Loader className="mx-auto animate-spin" /> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex text-zinc-400 dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1">
          <div className="flex pl-4 items-center justify-between w-full ">
            <div className="flex items-center ">
              <p className="text-sm font-medium">Rows per page</p>
              <Select
                value={`${table.getState().pagination.pageSize}`}
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
              >
                <SelectTrigger className="h-8 mt-1 text-zinc-900 dark:text-white ml-1 w-[64px] dark:bg-zinc-800 border-none bg-zinc-50">
                  <SelectValue placeholder={table.getState().pagination.pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                  {[10, 20, 30, 40].map((pageSize) => (
                    <SelectItem key={pageSize} value={`${pageSize}`}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => table.previousPage()}
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                disabled={!table.getCanPreviousPage()}
              >
                <TriangleIcon className="w-3 -rotate-90 fill-zinc-500 text-zinc-500" />
                Prev
              </button>
              <div className="flex w-[50px] items-center justify-center text-sm font-medium">
                {table.getPageCount() === 0 ? 0 : table.getState().pagination.pageIndex + 1}  of{" "}
                {table.getPageCount()}
              </div>
              <button
                className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                Next
                <TriangleIcon className="w-3 rotate-90 fill-zinc-500 text-zinc-500" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}
