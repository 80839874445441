import React from 'react';
import { Logo } from "../ui/logo";
import { Link } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';

const AuthNavBar = () => {
  return (
    <nav className='flex items-center justify-between px-4 py-2'>
        <Link to={"/"}>
            <Logo showText={true}/>
        </Link>

        <ThemeToggle/>
        </nav>
  )
}

export default AuthNavBar