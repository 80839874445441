type CurrencyOptions = "INR";

function formatCurrency(
  value: number,
  sign?: boolean,
  currency?: CurrencyOptions
): string {
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return "-";
  }

  const formattedValue = numericValue.toFixed(2);
  if (sign) {
    return Number(formattedValue).toLocaleString("en-IN", {
      style: "currency",
      currency,
    });
  }
  return Number(formattedValue).toLocaleString("en-IN", {
    currency,
  });
}

export default formatCurrency;


export const formatIfCurrency = (value: string): string => {
  if (!Number.isNaN(parseFloat(value))) {
    return formatCurrency(parseFloat(value));
  }
  return value;
};


export function formatDecimal(value: number): string {
  const numericValue = Number(value);

  if (isNaN(numericValue)) {
    return "-";
  }

  if (numericValue < 100) {
    return numericValue.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return numericValue.toLocaleString("en-IN");
}
