export const MarketStatusIcon = (props:{marketStatus:string}) => {
    
    const fillColor = props.marketStatus === "open" ? "#22C55E" : (props.marketStatus === "closed" || props.marketStatus === "after-open") ? "#E54B4F": "#EAB308"; 
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1">
          <ellipse 
            id="Ellipse 3" 
            cx="7.82297" 
            cy="8.49994" 
            rx="4.34641" 
            ry="4.32099" 
            fill={fillColor} 
          />
          <path 
            id="Ellipse 4" 
            d="M15.1471 8.49995C15.1471 12.5166 11.871 15.7777 7.82353 15.7777C3.77609 15.7777 0.5 12.5166 0.5 8.49995C0.5 4.4833 3.77609 1.22217 7.82353 1.22217C11.871 1.22217 15.1471 4.4833 15.1471 8.49995Z" 
            stroke={fillColor} 
          />
        </g>
      </svg>
    );
  };
  