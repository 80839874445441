import PreOpen from '../../components/pre-open/PreOpen';
import AppLayout from "../../layout/AppLayout";
// import { getInstrumentExpiryStrikePrice } from '../../../api/options';
import { Icons } from "../../utils/icons";
import { useEffect, useState } from "react";
import { getInstrumentExpiryStrikePrice } from '../../api/options';
import { useQuery } from 'react-query';

export default function PreOpenPage() {

  return (
    <AppLayout>
  <PreOpen />
   
    </AppLayout>
  );
}
