function browserName(){
    var sBrowser, sUsrAg = navigator.userAgent;
  // The order matters here, and this may report false positives for unlisted browsers.
  
  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "Microsoft Edge";
  }
  return sBrowser.toLowerCase();
//   alert("You are using: " + sBrowser);
}

export default browserName;