import LandingLayout from "../../layout/LandingLayout";

export default function Disclaimer() {
    return (
        <LandingLayout>
            <h1
                className="font-montserrat uppercase text-center text-[28px] font-semibold sm:text-[36px] md:text-[48px]">
                Disclaimer
            </h1>
            <p className="mt-12 text-start text-sm font-normal sm:text-lg">
                The information made available is for educational purposes only. Staff at TRADE DONS do not recommend, advocate, or solicit the purchase, sale, or holding of any investment; the use of any investment selection methodology; the use of any specific financial tools or trading platforms; or the use of any specific financial planner, advisor, or broker TRADE DONS. Do remember that trading and investing always involve high levels of risk. Any money that you allocate to trading and investing may be lost completely and at any time. Any responsibility for any trading or investing activity that you might undertake is yours and yours alone. The demonstrations of trading processes (including but not limited to choosing an investment and making a trade) presented are not a representation or prediction that any account will or is likely to achieve profits or losses similar to those discussed. Future results can be dramatically different from the possibilities that are presented in training. Past performance does not guarantee future results.
                Any inquiries or questions regarding these terms and conditions should be directed in writing to our privacy officer at care@tradedons.com.
            </p>
        </LandingLayout>
    );
}
