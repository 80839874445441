import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { cn } from "../../lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Loader, Triangle, TriangleIcon } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import browserName from "../shared/BrowserName";
let browser = browserName();

const columns = [
  {
    accessorKey: "SubscriptionName",
    header: () => "Plan Name",
  },
  {
    accessorKey: "PaymentId",
    header: () => "Receipt ID",
  },
  {
    accessorKey: "PurchaseDateTime",
    header: () => "Start Date",
    cell: ({row}:any)=>{
      const value = row.getValue("PurchaseDateTime");
      const startDate = value.split(" ");
      return(
        <p>{startDate[0]}</p>
      )
    }
  },
  {
    accessorKey: "ExpiryDateTime",
    header: () => "End Date",
    cell: ({row}:any)=>{
      const value = row.getValue("ExpiryDateTime");
      const startDate = value.split(" ");
      return(
        <p>{startDate[0]}</p>
      )
    }
  },
  {
    accessorKey: "SubscriptionDuration",
    header: () => "Validity Days",
  },
  {
    accessorKey: "PaidAmount",
    header: () => "Amount",
    cell: ({row}:any) =>{
      const paidAmount = row.getValue("PaidAmount");
      return (
        <p>{parseFloat(paidAmount)/100}</p>
      )
    }
  },
  {
    accessorKey: "IsValid",
    header: () => "Plan Validity",
    cell: ({ row }: any) => {
      const planValidity = row.getValue("IsValid");
      return (
        <div className="w-full text-center">
          <div className={`${planValidity === true ? "bg-green-500" : "bg-red-500"}  w-full py-1 capitalize rounded`}>
            {planValidity === true ?"Valid":"Expired"}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "IsPurchased",
    header: () => "Payment",
    cell: ({ row }: any) => {
      const payment = row.getValue("IsPurchased");
      return (
        <div className="w-full text-center">
          <div className="py-1 px-1 font-semibold capitalize w-full bg-green-500 rounded">{payment === true ?"purchased":"complementary"}</div>
        </div>
      );
    },
  },
  {
    accessorKey: "PurchaseDateTime",
    header: () => "Purchased At",
    id: "purchaseAt",
    cell: ({row}:any)=>{
      const value = row.getValue("ExpiryDateTime");
      const startDate = value.split(" ");
      return(
        <p>{`${startDate[0]}, ${startDate[1]}`}</p>
      )
    }
  },
];

export default function PlanBillingsTable({ title, loading, data, height, tableHeight }:{ title?: string,  loading: boolean, data: any[], height?: boolean, tableHeight:number }) {
  const memoizeColumns = useMemo(() => columns, []);

  const table = useReactTable({
    data: data,
    columns: memoizeColumns,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <div className="my-4 w-full">
    <h3 className="dark:bg-zinc-800 rounded-t-lg border-2 text-center text-sm border-b-2 font-semibold dark:border-zinc-600 bg-zinc-50 py-3">
      {title}
    </h3>
    <div
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: "1px" }}
      className={cn("custom-scrollbar rounded-t-none relative w-full overflow-auto rounded-lg border-2 dark:border-zinc-600 border-b-0 rounded-b-none max-h-[516px]",
      )}
    >
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              <th className="border-b dark:border-zinc-600 bg-white dark:bg-zinc-800 border-zinc-200" />
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="border-b text-black bg-white dark:bg-zinc-800 dark:border-zinc-600 border-zinc-200 py-3"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length !== 0 ? (
            table.getRowModel().rows.map((row, index) => (
              <TableRow key={row.id}>
                <td className={cn("pl-2", { "dark:bg-zinc-800 bg-zinc-200": index % 2 })}>{index + 1}.</td>
                {row.getVisibleCells().map((cell) => {
                  <td>{index + 1}.</td>
                  return (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        "!text-[13px]",
                        { "bg-zinc-200 dark:bg-zinc-800": index % 2 !== 0 },
                        { "text-start pl-2": cell.column.id === "name" },
                        "py-2"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      ) === ""
                        ? "-"
                        : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell />
              <TableCell
                colSpan={columns.length}
                className="h-12 text-center md:text-[13px]"
              >
                {loader ? <Loader className="animate-spin mx-auto" /> : "No results."}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
    <div className="flex border-t bg-zinc-50 text-zinc-400 border-t-zinc-200  dark:bg-zinc-800 border-2 pr-2 rounded-lg rounded-t-none dark:border-zinc-600 items-center justify-between space-x-2 py-1">
      <div className="flex pl-4 items-center justify-between w-full ">
        <div className="flex items-center ">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className="h-8 w-[64px] bg-zinc-50 dark:bg-zinc-800 border-none">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => table.previousPage()}
            className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
            disabled={!table.getCanPreviousPage()}
          >
            <TriangleIcon className="w-2 text-zinc-500 -rotate-90 fill-zinc-500 hover:text-fill-400" />
            Prev
          </button>
          <div className="flex w-[50px] items-center justify-center text-sm font-medium">
            {table.getPageCount() === 0 ? 0 : table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <button
            className="dark:text-zinc-500 hover:dark:text-zinc-400 flex gap-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
            <TriangleIcon className="w-2 rotate-90 text-zinc-500 fill-zinc-500 hover:text-zinc-600 hover:fill-zinc-600 " />
          </button>
        </div>
      </div>
    </div>
  </div>
  );
}
