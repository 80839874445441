import { cn } from "../../lib/utils";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import BuildUpBadge from "../shared/BuildUpBadge";

export type TEodTable = {
    date: string;
    oiInterpretation: string;
    ltp: string;
    totalOi: string;
    dayOpen: string;
    dayHigh: string;
    dayLow: string;
    volume: string;
    dayRange: string;
    ltpChange: string;
    oiChange: string;
    ltpChangePercent: string;
    oiChangePercent: string;
};


export const columns: any = [
    {
        accessorKey: "date",
        header: () => <div className="min-w-[90px] text-center">Date</div>,
    },
    {
        accessorKey: "oiInterpretation",
        header: "Build-Ups",
        cell: ({ row }: any) => {
            const data = row.getValue("oiInterpretation");
            return  <div className="flex justify-center">
            <BuildUpBadge text={data && data.trim()} />
          </div>;
        },
    },
    {
        accessorKey: "ltp",
        header: () => <span className="min-w-max">LTP</span>,
        cell:({row}:any) =>{
            const ltpData = row.getValue("ltp");
            return <p>{ltpData && formatCurrency(parseFloat(ltpData))}</p>;
        }
    },
    {
        accessorKey: "totalOi",
        header: () => <div className="min-w-[80px]">Total OI</div>,
        cell: ({ row }:any) => {
            const totalOi = row.getValue("totalOi");
            return <p>{totalOi && formatCurrency(parseFloat(totalOi))}</p>;
          },
    },
    {
        accessorKey: "dayOpen",
        header: () => <div className="min-w-[60px]">Day Open</div>,
        cell:({row}:any) =>{
            const dayOpen = row.getValue("dayOpen");
            return <p>{dayOpen && formatCurrency(parseFloat(dayOpen))}</p>;
        }
    },
    {
        accessorKey: "dayHigh",
        header: () => <div className="min-w-[60px]">Day High</div>,
        cell:({row}:any) =>{
            const dayHigh = row.getValue("dayHigh");
            return <p>{dayHigh && formatCurrency(parseFloat(dayHigh))}</p>;
        }

    },
    {
        accessorKey: "dayLow",
        header: () => <div className="min-w-[60px]">Day Low</div>,
        cell:({row}:any) =>{
            const dayLow = row.getValue("dayLow");
            return <p>{dayLow && formatCurrency(parseFloat(dayLow))}</p>;
        }

    },
    {
        accessorKey: "volume",
        header: () => <div className="min-w-[80px] text-center">Volume</div>,
        cell:({row}:any) =>{
            const volume = row.getValue("volume");
            return <p className="text-center">{volume && formatCurrency(parseFloat(volume))}</p>;
        }
    },

    {
        accessorKey: "dayRange",
        header: () => <div className="min-w-[50px]">Day Range</div>,
        cell:({row}:any) =>{
            const dayRange = row.getValue("dayRange");
            return <p>{dayRange && formatCurrency(parseFloat(dayRange))}</p>;
        }
    },
    {
        accessorKey: "ltpChange",
        header: () => <div className="min-w-[110px]">LTP Change</div>,
        cell: ({ row }: any) => {
            let data = row.getValue("ltpChange")
            return <p
                className={cn(
                    { "text-green-500": data > 0 },
                    { "text-red-500": data < 0 },
                )}
            >
                {data && formatCurrency(parseFloat(data))}
            </p>
        }
    },
    {
        accessorKey: "oiChange",
        header: "OI Change",
    },
    {
        accessorKey: "ltpChangePercent",
        header: () => <div className="min-w-[110px]">% Chng. in LTP</div>,
        cell: ({ row }: any) => {
            let data = row.getValue("ltpChangePercent")
            return <p
                className={cn(
                    { "text-green-500": data > 0 },
                    { "text-red-500": data < 0 },
                )}
            >
                {data && formatDecimal(parseFloat(data))}
                {data && (row.getValue("ltpChangePercent") !== "-" || row.getValue("ltpChangePercent") !== "0") ? "%" : ""}
            </p>
        }
    },
    {
        accessorKey: "oiChangePercent",
        header: () => <div className="min-w-[110px]">% Chng. in OI</div>,
        cell: ({ row }: any) => {
            let data = row.getValue("oiChangePercent")
            return <p
                className={cn(
                    { "text-green-500": data > 0 },
                    { "text-red-500": data < 0 },
                )}
            >
                {data && formatDecimal(parseFloat(data))}
                {data && (row.getValue("oiChangePercent") !== "-" || row.getValue("oiChangePercent") !== "0") ? "%" : ""}
            </p>
        }
    },
];