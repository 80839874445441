export function quicksort(arr: string[], low: number, high: number): void {
    if (low < high) {
        let pi = partition(arr, low, high);

        quicksort(arr, low, pi - 1);
        quicksort(arr, pi + 1, high);
    }
}

function partition(arr: string[], low: number, high: number): number {
    let pivot = parseFloat(arr[high]);
    let i = low - 1;

    for (let j = low; j < high; j++) {
        if (parseFloat(arr[j]) < pivot) {
            i++;

            // Swap arr[i] and arr[j]
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
    }

    // Swap arr[i + 1] and arr[high]
    [arr[i + 1], arr[high]] = [arr[high], arr[i + 1]];

    return i + 1;
}


export function parseMessageDate(dateString: string): string {
    const parts = dateString.split("-");
    const day = parseInt(parts[0]);
    const monthName = parts[1];
    return `${day}-${monthName}`;
  }
  

    // function parseMessageDate(dateString: string): Date {
        //   const parts = dateString.split("-");
        //   const day = parseInt(parts[0]);
        //   const monthName = parts[1];
        //   const monthIndex = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(monthName);
        //   const year = currentDate.getFullYear();
        //   return new Date(year, monthIndex, day);
        // }

