import { ColumnDef } from "@tanstack/react-table";
import BuildUpBadge from "../shared/BuildUpBadge";
import formatCurrency, { formatDecimal } from "../../utils/formatCurrency";
import browserName from "../shared/BrowserName";

let browser = browserName();

export type OsTableType = {
    time: string;
    buildupcall: string;
    totaloicall: number;
    todayoichangecall: number;
    currentoichangecall: number;
    changeinltpcall: number;
    premiumleftincall: number;
    calliv: number;
    ltpcall: number;
    strikeprice: number;
    ltpput: number;
    premiumleftinput: number;
    putiv: number;
    changeinltpput: number;
    currentoichangeput: number;
    todayoichangeput: number;
    totaloiput: number;
    buildupput: string;
};

export const columns: ColumnDef<OsTableType>[] = [

    {
        accessorKey: "time",
        header: () => <div>Time (IST)</div>,
    },
    {
        accessorKey: "buildupcall",
        header: () => <div>Build-Ups</div>,
        cell: ({ row }) => {
            const buildupcallData = row.getValue<string>("buildupcall");
            return <BuildUpBadge text={buildupcallData ? buildupcallData.trim() : "-"} />;
        },
    },
    {
        accessorKey: "totaloicall",
        header: () => <div>Total OI In Call</div>,
        cell: ({ row }) => {
            const totaloicallData = parseFloat(row.getValue<string>("totaloicall"));
            return <p>{totaloicallData ? formatCurrency((totaloicallData)) : "-"}</p>;
        },
    },
    {
        accessorKey: "todayoichangecall",
        header: () => <div>Today's Change In OI</div>,
        cell: ({ row }) => {
            const todayoichangecallData = parseFloat(row.getValue<string>("todayoichangecall"));
            return (
                <p>
                    {todayoichangecallData ? formatCurrency(todayoichangecallData) : "-"}
                </p>
            );
        },
    },
    {
        accessorKey: "currentoichangecall",
        header: () => <div>Current OI Change</div>,
        cell: ({ row }) => {
            const currentoichangecall = parseFloat(row.getValue<string>("currentoichangecall"));
            return <p>{currentoichangecall ? formatCurrency(currentoichangecall) : "-"}</p>;
        },
    },
    {
        accessorKey: "changeinltpcall",
        header: () => <div>Change In LTP</div>,
        cell: ({ row }) => {
            const changeInLtpData = parseFloat(row.getValue<string>("changeinltpcall"));
            return <p>{changeInLtpData ? formatDecimal(changeInLtpData) : "-"}</p>;
        },
    },
    {
        accessorKey: "premiumleftincall",
        header: () => <div>Premium Left in Call</div>,
        cell: ({ row }) => {
            const premiumleftincallData = parseFloat(row.getValue<string>("premiumleftincall"));
            return (
                <p>
                    {premiumleftincallData ? formatCurrency(premiumleftincallData) : "-"}
                </p>
            );
        },
    },
    {
        accessorKey: "calliv",
        header: "Call IV",
        cell: ({ row }) => {
            const callivData = parseFloat(row.getValue<string>("calliv"));
            return <p>{callivData ? formatCurrency(callivData) : "-"}</p>;
        },
    },
    {
        accessorKey: "ltpcall",
        header: () => <div>LTP Call</div>,
        cell: ({ row }) => {
            const ltpcallData = parseFloat(row.getValue<string>("ltpcall"));
            return <p>{ltpcallData ? formatDecimal(ltpcallData) : "-"}</p>;
        },
    },

    {
        accessorKey: "strikeprice",
        header: () => <div>Strike Price</div>,
        enableHiding: false,
    },

    // PUT

    {
        accessorKey: "ltpput",
        header: () => <div>LTP Put</div>,
        cell: ({ row }) => {
            const ltpputData = parseFloat(row.getValue<string>("ltpput"));
            return <p>{ltpputData ? formatDecimal(ltpputData) : "-"}</p>;
        },
    },
    {
        accessorKey: "premiumleftinput",
        header: () => <div>Premium Left in PUT</div>,
        cell: ({ row }) => {
            const premiumleftinputData = parseFloat(row.getValue<string>("premiumleftinput"));
            return (
                <p>{premiumleftinputData ? formatCurrency(premiumleftinputData) : "-"}</p>
            );
        },
    },
    {
        accessorKey: "putiv",
        header: () => <div>PUT IV</div>,
        cell: ({ row }) => {
            const putivData = parseFloat(row.getValue<string>("putiv"));
            return <p>{putivData ? formatCurrency(putivData) : "-"}</p>;
        },
    },
    {
        accessorKey: "changeinltpput",
        header: () => <div>Change In LTP</div>,
        cell: ({ row }) => {
            const changeinltpputData = parseFloat(row.getValue<string>("changeinltpput"));
            return (
                <p>{changeinltpputData ? formatDecimal(changeinltpputData) : "-"}</p>
            );
        },
    },
    {
        accessorKey: "currentoichangeput",
        header: () => <div>Current OI Change</div>,
        cell: ({ row }) => {
            const currentoichangeputData = parseFloat(row.getValue<string>("currentoichangeput"));
            return (
                <p>
                    {currentoichangeputData ? formatCurrency(currentoichangeputData) : "-"}
                </p>
            );
        },
    },
    {
        accessorKey: "todayoichangeput",
        header: () => <div>Today's Change In OI</div>,
        cell: ({ row }) => {
            const todayoichangeputData = parseFloat(row.getValue<string>("todayoichangeput"));
            return (
                <p>{todayoichangeputData ? formatCurrency(todayoichangeputData) : "-"}</p>
            );
        },
    },
    {
        accessorKey: "totaloiput",
        header: () => <div>Total OI In Put</div>,
        cell: ({ row }) => {
            const totaloiputData = parseFloat(row.getValue<string>("totaloiput"));
            return <p>{totaloiputData ? formatCurrency(totaloiputData) : "-"}</p>;
        },
    },
    {
        accessorKey: "buildupput",
        header: () => <div>Build-Ups</div>,
        cell: ({ row }) => {
            const buildupputData = row.getValue<string>("buildupput");
            return <BuildUpBadge text={buildupputData ? buildupputData.trim() : "-"} />;
        },
    },

];