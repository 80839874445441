import CreatePassword from "./CreatePassword";
import ResetEmail from "../Register/component/ResetEmail";
import VerifyResetEmail from "../Register/component/VerifyResetEmail";
import { useGetFromStore } from "../../../hooks/useGetFromStore";
import useAuthStore from "../../../store/auth";
import useResetStore from "../../../store/reset";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthNavBar from "../../../components/shared/AuthNavBar";

function ForgotPassword() {
  const navigate = useNavigate()
  const { screen, updateScreen } = useResetStore();

  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);

  useEffect(() => {
    updateScreen("email");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    navigate("/waitlist");
  }

  return (
    <>
      <AuthNavBar />
      <AnimatePresence mode="wait">
        <motion.div key={screen}>
          <div className="min-h-[calc(100vh-56px)] flex items-center justify-center">
            {screen === "email" ? (
              <ResetEmail />
            ) : screen === "verify-otp" ? (
              <VerifyResetEmail />
            ) : screen === "create-password" ? (
              <CreatePassword />
            ) : (
              <></>
            )}
          </div>

        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default ForgotPassword;
