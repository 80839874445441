import { useEffect } from "react";

const TradingViewWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
    script.async = true;
    script.innerHTML = `
      {
        "width": "100%",
        "height": "280px",
        "symbolsGroups": [
          {
            "name": "Indices",
            "originalName": "Indices",
            "symbols": [
              {
                "name": "FOREXCOM:SPXUSD",
                "displayName": "S&P 500"
              },
              {
                "name": "FOREXCOM:NSXUSD",
                "displayName": "US 100"
              },
              {
                "name": "FOREXCOM:DJI",
                "displayName": "Dow 30"
              },
              {
                "name": "INDEX:NKY",
                "displayName": "Nikkei 225"
              },
              {
                "name": "INDEX:DEU40",
                "displayName": "DAX Index"
              },
              {
                "name": "FOREXCOM:UKXGBP",
                "displayName": "UK 100"
              }
            ]
          }
        ],
        "showSymbolLogo": false,
        "colorTheme": "light",
        "isTransparent": true,
        "locale": "in"
      }
    `;
    // @ts-ignore: Object is possibly 'null'.
    document.querySelector("#globalData").appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container custom-scrollbar relative max-h-[314px] overflow-x-auto overflow-y-auto rounded-lg border-2 dark:border-zinc-600">
      <div
        className="tradingview-widget-container__widget "
        id="globalData"
      ></div>
      <div className="tradingview-widget-copyright">

      </div>
    </div>
  );
};

export default TradingViewWidget;
