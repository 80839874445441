import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export default function POSpot({ row }: { row: any | [] }) {
  return (
    <div className="mt-4 flex custom-scrollbar h-full w-full justify-between gap-4 overflow-x-auto pb-2 text-xs">
      <SpotPriceCard
        title1="Nifty"
        value1={formatCurrency(parseFloat(row?.nifty)) || "-"}
        title2="Bank Nifty"
        value2={formatCurrency(parseFloat(row?.bankNifty)) || "-"}
      />
      <SpotPriceCard
        title1="Dow Jones(US 30)"
        value1="-"
        title2="Hang Seng (HKEX)"
        value2="-"
      />

      <SpotPriceCard
        title1="Dollar Index (DXY)"
        value1="-"
        title2="FTSE (UK 100)"
        value2="-"
      />

      <SpotPriceCard
        title1="Premium In Call(ATM)"
        value1={formatCurrency(parseFloat(row?.premiumCall)) || "-"}
        title2="Premium In Put(ATM)"
        value2={ formatCurrency(parseFloat(row?.premiumPut)) || "-"}
      />
    </div>
  );
}
