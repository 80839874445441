const FoiInstrumentData = [
  {
    name: "BANKNIFTY",
    value: "BANKNIFTY",
  },

  {
    name: "NIFTY",
    value: "NIFTY",
  },
  {
    name: "FINNIFTY",
    value: "FINNIFTY",
  },
  {
    name: "MIDCPNIFTY",
    value: "MIDCPNIFTY",
  },
];

const optionImpliedInstrument = [
  {
    name: "BANKNIFTY",
    value: "BANKNIFTY",
  },
  {
    name: "FINNIFTY",
    value: "FINNIFTY",
  },
  {
    name: "NIFTY",
    value: "NIFTY",
  },
];

const FoiExpiry = [
  {
    name: "Current Month",
    value: "CurrentMonth",
  },
  {
    name: "Next Month",
    value: "NextMonth",
  },
  {
    name: "Far Month",
    value: "FarMonth",
  },
];

const marketMoverExpiry = [
  {
    name: "Current Month",
    value: "currentmonth",
  },
  {
    name: "Next Month",
    value: "nextmonth",
  },
  {
    name: "Far Month",
    value: "farmonth",
  },
];

const FoiRefreshRateData = [
  {
    name: "10 sec",
    value: "10 sec",
  },

  {
    name: "15 sec",
    value: "15 sec",
  },
  {
    name: "30 sec",
    value: "30 sec",
  },
  {
    name: "1 min",
    value: "1 min",
  },
  {
    name: "2 min",
    value: "2 min",
  },
  {
    name: "3 min",
    value: "3 min",
  },
  {
    name: "1 Day",
    value: "1 day",
  },
];


const FoiTimeInterval = [
  {
    name: "3 min",
    value: "3Min",
  },
  {
    name: "5 min",
    value: "5Min",
  },
  {
    name: "10 min",
    value: "10Min",
  },
  {
    name: "15 min",
    value: "15Min",
  },
  {
    name: "30 min",
    value: "30Min",
  },
  {
    name: "60 min",
    value: "60Min",
  },
];

export {
  FoiExpiry,
  FoiInstrumentData,
  FoiRefreshRateData,
  FoiTimeInterval,
  marketMoverExpiry,
  optionImpliedInstrument
};
