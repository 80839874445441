import React from 'react'
import { Button } from '../ui/button'
import { cn } from '../../lib/utils'
import usePaymentDetails from '../../store/paymentDetails';
import { baseURL } from '../../api/base';
import { useGetFromStore } from '../../hooks/useGetFromStore';
import useAuthStore from '../../store/auth';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../utils/ThemeProvider';

interface PlanCardProps {
    plan:Record<string, any>,
    className:string,
   
}
function PlanCard( props: PlanCardProps) {
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const {setMerchantTransID} = usePaymentDetails();
  const navigate = useNavigate();
  const { theme } = useTheme()

  const userId = useGetFromStore(useAuthStore, (state) => state.user.newId);
 
  // const handleClick = () => {
  //     setLoading(true)
  //       fetch(`${baseURL}/api/Account/SendRequestToPhonePe?userID=${userId}&amount=${props.plan.TotalPrice}`,{
  //         method:"POST",
  //         headers:{
  //           'accept': 'application/json',
  //           'Content-Type': 'application/json', 
  //         },
  //       }).then((res) =>{
  //         res.json().then((data) =>{
  //           console.log("datamsg", data);
  //           setMerchantTransID({
  //             id:String(props.plan.ID),
  //             merchantTransactionId:data.merchantTransactionId
  //           });
  //           window.open(data.url, "_blank", "noreferrer");
  //         })
  //       }).catch((err) =>{
  //         setLoading(false);
  //         toast.error("Something went wrong", err.message);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       })
  //     };

      // const checkSubscriptionStatus = () => {
      //   console.log("checkSubscriptionStatus function called");
        
      //   setLoading(true);
      //   fetch(`${baseURL}/api/Account/IsUserSubcribed?userId=${userId}`).then((res) => {
      //     res.json().then((data) => {
      //       console.log("data at plancard", data);
            
      //      if(data === true){
      //       setIsDisabled(data)
      //      }
      //     })
      //   }).finally(()=>{
      //     setLoading(false)
      //   })
      // }

      // React.useEffect(() => {
      //   if(userId!== undefined && userId !== ""){
      //     checkSubscriptionStatus();
      //   }
      // },[userId]);

      const handleClick = () => {
        if(isLoggedIn){
          navigate("/features/purchase", {state:props.plan});
        } else {
          navigate("/register")
        }
      }
      
    return (
        <div className={cn("pt-12 pb-8 px-3 text-center bg-zinc-100 dark:bg-zinc-800 border dark:border-zinc-600", { clsasName: props.className })}>
            <p className='text-2xl font-semibold'>
                {props.plan.Name}
            </p>
            <p className='text-[14px] max-w-[80%] mx-auto mt-3'>{props.plan.Description}</p>
            <p className='mt-10 text-xl font-semibold'>
              Rs. {props.plan.BasicPrice} <span className='text-xs font-normal'>Excluding GST</span>
            </p>
             <p className='text-xl mb-8 mt-2 text-center'>
              Rs. {props.plan.TotalPrice}<span className={`font-normal text-xs`}> Including {props.plan.GSTPercentages}% GST</span>
              </p>
            <div>
                Validity {(props.plan.Duration/30)>=  1 ? `${(props.plan.Duration/30).toString().split(".")[0]} ${(props.plan.Duration/30)>=  2? "Months":"Month"}`: `${props.plan.Duration} Days`} 
            </div>
            <Button 
            className={!isDisabled?'bg-zinc-200 mt-10 hover:bg-zinc-300 border-zinc-400 cursor-pointer' :"bg-zinc-100 mt-10 cursor-pointer"}
            variant="outline"
            // disabled={isLoggedIn=== false}
            onClick={handleClick} 
            >Subscribe</Button>
        </div>
    )
}

export default PlanCard;