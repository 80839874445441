import PlanBillingsTable from "./BillingTable";
import { useState, useRef, useEffect } from "react";
import User from "./user";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { userBillingData } from "../../api/dashboard";

export default function UserBillinInfo() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [plans, setPlans] = useState([]);
    const [tableHeight, setTableHeight] = useState(440);
    const containerRef = useRef<HTMLDivElement>(null);
    const userId = useGetFromStore(useAuthStore, (state) => state.user.id)

    const calculateTableHeight = () => {
        if (containerRef.current) {
          const containerHeight = containerRef.current.clientHeight;
          const topBarHeight = document.querySelector("header")?.clientHeight || 0;
          const windowHeight = window.innerHeight;
          const offset = containerHeight + topBarHeight + 116;
          setTableHeight(windowHeight - offset);
        }
      }

      async function userBillingTableData(){
        if(userId!==undefined && userId !== ""){
          const data = await userBillingData(userId);
          if(data && data.length >0){
            setPlans(data)
          }
        }
      }

      useEffect(() => {
        calculateTableHeight(); 
        window.addEventListener("resize", calculateTableHeight);
        return () => window.removeEventListener("resize", calculateTableHeight);
      }, [isLoading]);

      useEffect(() =>{
        setTimeout(()=>{
          setIsLoading(false)},12000);
      },[])

      useEffect(()=>{
        userBillingTableData();
      },[userId])

        return (
            <>
            <div ref={containerRef}>
            <User/>
            </div>
            <div className="w-full ">
            <PlanBillingsTable height={plans.length>0 ? true : false} loading={isLoading} title="Plan Billing" data={plans} tableHeight={tableHeight} />
          </div>
            </>
        )
}