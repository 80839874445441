import React from "react";
import HeaderLegal from "../components/landing-page/HeaderLegal";
import Footer from "../components/landing-page/Footer";

export default function LandingLayout({ children }: { children: React.ReactNode }) {
    return (
        <>
            <HeaderLegal />
            <main className="px-6 py-24">
                <div className="m-auto max-w-[1400px] bg-zinc-100 px-5 py-[50px] dark:bg-zinc-800 dark:text-zinc-50  sm:p-[50px]">
                    {children}
                </div>
            </main>
            <Footer />
        </>
    );
}