import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export default function EodSpot({ row }: { row: any | {} }) {
  return (
    <div className="mt-4 flex h-full w-full justify-between gap-3 overflow-x-auto text-xs custom-scrollbar">
      <SpotPriceCard
        title1="Spot Price"
        value1={row.spotPrice !== undefined && row.spotPrice !== "" ? formatCurrency(parseFloat(row.spotPrice)) : "-"}
        title2="Current Future Price"
        value2={row.ltp !== undefined && row.ltp !== "" ? formatCurrency(parseFloat(row.ltp)) : "-"}
      />
      <SpotPriceCard
        title1="Current Build-Ups"
        value1={row.oiInterpretation !== undefined && row.oiInterpretation !== "" ? row.oiInterpretation : "-"}
        title2="Last Traded Price"
        value2={row.ltp !== undefined && row.ltp !== "" ? formatCurrency(parseFloat(row.ltp)) : "-"}
      />

      <SpotPriceCard
        title1="Previous Day Close"
        value1={row.prevClose !== undefined && row.prevClose !== "" ? formatCurrency(parseFloat(row.prevClose)) : "-"}
        title2="Today&apos;s Open"
        value2={row.dayOpen !== undefined && row.dayOpen !== "" ? formatCurrency(parseFloat(row.dayOpen)) : "-"}
      />

      <SpotPriceCard
        title1="Today&apos;s Day High"
        value1={row.dayHigh !== undefined && row.dayHigh !== "" ? formatCurrency(parseFloat(row.dayHigh)) : "-"}
        title2="Today&apos;s Day Low"
        value2={row.dayLow !== undefined && row.dayLow !== "" ? formatCurrency(parseFloat(row.dayLow)) : "-"}
      />
    </div>
  );
}
