import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./utils/ThemeProvider";
import ReactQueryProvider from "./utils/ReactQueryProvider";
import { Toaster } from "sonner";
import ErrorBoundary from "./pages/errorBoundary";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <MyApp />
  // {/* </React.StrictMode> */ }
);

function MyApp() {
  return (
    <BrowserRouter>
      <ThemeProvider defaultTheme="dark" storageKey="theme">
        <ReactQueryProvider>
          <ErrorBoundary>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
          </ErrorBoundary>
          <Toaster />
        </ReactQueryProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
