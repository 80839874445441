import { cn } from "../../lib/utils";
import { Icons } from "../../utils/icons";

export default function OiIntBadge({ text }: { text: string }) {
    return (
        <p
            className={cn("flex h-full font-medium box-content items-center min-w-max gap-1 justify-center py-0 px-1 text-[14px] text-white sm:min-w-max rounded",
                { "bg-green-500": text === "L.B." },
                { "bg-blue-500": text === "S.C." },
                { "bg-red-500": text === "S.B." },
                { "bg-yellow-500": text === "L.U." },
            )}
        >
            {text === "L.B." && <Icons.arrowUp className="w-4 mb-0.5" />}
            {text === "S.C." && <Icons.arrowUp className="w-4 mb-0.5" />}
            {text === "S.B." && <Icons.arrowDown className="w-4" />}
            {text === "L.U." && <Icons.arrowDown className="w-4" />}
            {text}
        </p>
    );
}