import { StrikePriceWiseTimeInterval } from "../../data/strikepricewise";
import { Loader } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import StrikePriceWiseDataTable from "./StrikePWTable";
import { StrikePriceWiseSpotCard } from "./StrikePriceWiseSpot";
import { cn } from "../../lib/utils";
import useMarketStatus from "../../hooks/useMarketStatus";
import Breadcrumb from "../shared/Breadcrumb";
import LiveSwitch from "../shared/LiveSwitch";
import { InstrumentExpiryStrikePrice } from "../../api/options";
import { toast } from "sonner";
import StrikePriceWiseDropdown from "./StrikePriceWiseDropdown";
import { getOptionsInterMediateData } from "../../api/options";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import StrikePriceWiseMobileCard from "./StrikePriceWiseMobileCard";
import CollapseButton from "../shared/CollapseButton";
import { findNearestDate, findNearestStrikePrice } from "../shared/dateConverter";
import useSpotPriceStore from "../../store/spotPrice";
import { configEnvironemt } from "../../config";
import { breadcrumbStrikePrice } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";

export default function StrikePriceWise({
  optionIndexList,
}: {
  optionIndexList: InstrumentExpiryStrikePrice[];
}) {
  const expiryDates = optionIndexList[0].ExpiryStrikePriceList.map(
    (exp) => exp.Expiry
  );
  const { spotPrices } = useSpotPriceStore();
  const environment = configEnvironemt.environment;
  const initialDate = findNearestDate(expiryDates);
  const [historical, setHistorical] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [lastRow, setLastRow] = useState<any | []>([]);
  const [strikePrice, setStrikePrice] = useState("");
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `OISTRIKEPRICE_OPTIDX_${optionIndexList[0].ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [timeInterval, setTimeInterval] = useState(StrikePriceWiseTimeInterval[1].value);
  const [instrument, setInstrument] = useState(optionIndexList[0].ProductName);
  const [expiry, setExpiry] = useState(initialDate ? initialDate : "");
  const [isLoading, setIsLoading] = useState(true);
  const marketStatus = useMarketStatus();
  const windowWidth = useWindowWidth();
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  const [date, setDate] = useState<any>(new Date())
  const [toIdentifier, setToIdentifier] = useState(() => {
    let nearestStrikePrice = optionIndexList.find((item) => item.ProductName === instrument)?.SpotPrice;
    const spotPrice = spotPrices[instrument] ? spotPrices[instrument] : optionIndexList[0].SpotPrice;
    findNearestStrikePrice(spotPrice.toString(), optionIndexList[0].ExpiryStrikePriceList[0].StrikePrice, instrument)
      .then((data) =>
        nearestStrikePrice = data
      )
      .catch((err) => console.log(err))
    return `OPTIDX_${optionIndexList[0].ProductName}_${expiry}_${nearestStrikePrice}_5Min`
  }
  );
  const [tableHeight, setTableHeight] = useState(440);
  const containerRef = useRef<HTMLDivElement>(null);

  let id:any;

  const handleSwitch = () => {
    setRows([]);
    setLastRow([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(optionIndexList[0]?.ProductName);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setHistorical(true);
      setIsLoading(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
      setStrikePrice("");
    }
  };

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
    calculateTableHeight(); 
  };

  const calculateTableHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const topBarHeight = document.querySelector("header")?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      const offset = containerHeight + topBarHeight + 36;
      setTableHeight(windowHeight - offset);
    }
  }

  function processMessage(messages: string[], newSpot?:string) {
    const upRows = messages.map((item) => ({
      time: item.split(",")[0],
      buildupcall: item.split(",")[1],
      totaloicall: item.split(",")[2],
      todayoichangecall: item.split(",")[3],
      currentoichangecall: item.split(",")[4],
      changeinltpcall: item.split(",")[5],
      premiumleftincall: item.split(",")[6],
      calliv: item.split(",")[7],
      ltpcall: item.split(",")[8],
      strikeprice: (marketStatus === "open" || marketStatus ==="after-open") && !historical? strikePrice: newSpot,
      ltpput: item.split(",")[9],
      premiumleftinput: item.split(",")[10],
      putiv: item.split(",")[11],
      changeinltpput: item.split(",")[12],
      currentoichangeput: item.split(",")[13],
      todayoichangeput: item.split(",")[14],
      totaloiput: item.split(",")[15],
      buildupput: item.split(",")[16],
      collapseCallIV: item.split(",")[19],
      collapsePutIV: item.split(",")[20],
      currentFuturePrice: item.split(",")[17],
      spotPrice: (marketStatus === "open" || marketStatus === "after-open") && !historical ? item.split(",")[18]: newSpot,
    }));
    useSpotPriceStore
      .getState()
      .setSpotPrice(
        instrument,
        parseFloat(messages[messages.length - 1].split(",")[18])
      );
      const filteredRow = upRows.filter((item) => item.time !== "15:30")
    setIsLoading(false);
    return filteredRow;
  }

  async function liveMsgStrikePriceWiseData(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
    const fRows = processMessage(messages);
    const reversedRow = fRows.reverse();
    setRows(reversedRow);
    setLastRow(reversedRow[0]);
    }
  }

  async function getHistoricOptionsData(identifier: string, topicName:string, currentStrike?:string) {
    // setIsLoading(true);
    let response;
    if (historical && identifier !== undefined && date !== '') {
      response = await getOptionsInterMediateData(identifier, topicName, date);
      }else{
      response = await getOptionsInterMediateData(identifier, topicName);
      }
      if (response != null && response.length > 0) {
        const messages = response.split("\n") as string[];
        const fRows = processMessage(messages, currentStrike);
        const reversedRow = fRows.reverse();
        setRows(reversedRow);
        setLastRow(reversedRow[0]);
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[instrument,expiry, strikePrice, date])

  useEffect(() => {
    if (historical) {
      setRows([]);
      setLastRow([]);
      if (date !== "" && instrument !== "") {
        getHistoricOptionsData(toIdentifier, topicName, strikePrice);
      }
    } 
}, [historical, toIdentifier]);
  

  useEffect(() => {
    calculateTableHeight(); 
    window.addEventListener("resize", calculateTableHeight);
    return () => window.removeEventListener("resize", calculateTableHeight);
  }, [collapsed, historical, date, isLoading]);


  if (isLoggedIn === false) {
    navigate("/login");
  }
  if (userId === undefined) return <></>;

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbStrikePrice} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      {optionIndexList && (
        <StrikePriceWiseDropdown
          optionIndexList={optionIndexList}
          userId={userId ? userId : ""}
          strikePrice={strikePrice}
          setStrikePrice={setStrikePrice}
          historical={historical}
          marketStatus={marketStatus}
          setToIdentifier={setToIdentifier}
          setTopicName={setTopicName}
          getHistoricOptionsData={getHistoricOptionsData}
          setExpiry={setExpiry}
          instrument={instrument}
          setInstrument={setInstrument}
          timeInterval={timeInterval}
          expiryDate={expiry}
          setTimeInterval={setTimeInterval}
          setRows={setRows}
          setLastRows={setLastRow}
          environment={environment}
          setDate={setDate}
          date={date}
          setIsLoading={setIsLoading}
          toIdentifier={toIdentifier}
          topicName = {topicName}
          liveMsgStrikePriceWiseData={liveMsgStrikePriceWiseData}
        />
      )}
      <div className={cn({ hidden: collapsed })}>
        {lastRow && <StrikePriceWiseSpotCard row={lastRow} />}
      </div>
      <CollapseButton collapsed={collapsed} handleCollapse={handleCollapse} />
      </div>
      <div className="mt-2">
        {windowWidth && windowWidth >= 600 ? (
          <StrikePriceWiseDataTable loading={isLoading} rowData={rows} tableHeight={tableHeight} />
        ) : (
          <>
            {rows.length ? (
              <StrikePriceWiseMobileCard rows={rows} />
            ) : (
              <>
                {isLoading ? (
                  <Loader className="mx-auto animate-spin" />
                ) : (
                  <div className="text-center py-10">No Results</div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}