//OHCOLUMNS

import { ColumnDef } from "@tanstack/react-table";
import formatCurrency from "../../utils/formatCurrency";
import { cn } from "../../lib/utils";
import { Triangle } from "lucide-react";

export type TOHTable = {
    dayopen: number;
    dayhigh: number;
    newdayhigh: number;
    newdaylow: number;
    ohol: string;
    hittime: number;
    chance: string;
    callltp: number;
    strikeprice: number;
    putltp: number;
    putdayopen: string;
    putdayhigh: string;
    putnewdayhigh: number;
    putnewdaylow: number;
    putohol: number;
    puthittime: number;
    putchance: string;
    putcallltp: number;
    putstrikeprice: number;
};

const SortingUi = ({ column, title, className }: { column: any, title: string, className?: string }) => (
    <div className={cn("flex items-center justify-center cursor-pointer", className)} onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        <span>{title}</span>
        <div className="ml-[3px]">
            <Triangle className={cn("h-1.5 w-1.5 text-zinc-500 fill-zinc-500 ", { "fill-green-500 text-green-500 dark:fill-green-500": column.getIsSorted() === "desc" })} />
            <Triangle className={cn("h-1.5 w-1.5 text-zinc-500 fill-zinc-500  rotate-180", { "fill-green-500 text-green-500 dark:fill-green-500": column.getIsSorted() === "asc" })} />
        </div>
    </div>
)

const ohcolumns: ColumnDef<TOHTable>[] = [
    {
        accessorKey: "dayopen",
        header: ({ column }: any) => <SortingUi column={column} title="Day Open" className=" mx-auto w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("dayopen");
            return (
                <p > {data ? formatCurrency(parseFloat(data)) : "-"}</p>
            );
        },
    },
    {
        accessorKey: "dayhigh",
        header: ({ column }: any) => <SortingUi column={column} title="Day High" className=" mx-auto  w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("dayhigh")
            return <>{data ? formatCurrency(parseFloat(data)) : "-"}
            </>;

        }

    },
    {
        accessorKey: "newdayhigh",
        header: ({ column }: any) => <SortingUi column={column} title="New D. High" className=" mx-auto w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("newdayhigh")
            return <>{data ? formatCurrency(parseFloat(data)) : "-"}
            </>;
        }
    },
    {
        accessorKey: "newdaylow",
        header: ({ column }: any) => <SortingUi column={column} title="New D. Low" className=" mx-auto w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("newdaylow");
            return <>{data ? formatCurrency(parseFloat(data)) : "-"}</>
        },
    },
    {
        accessorKey: "ohol",
        header: () => <div className=" mx-auto w-full">O=H/ O=L</div>,
        cell: ({ row }) => {
            const data = row.getValue<string>("ohol");
            return (
                <p className={cn(
                    { "bg-green-500 text-white px-4 py-1.5 font-semibold capitalize": data.includes("O=H" || "o=h") },
                    { "bg-red-500 text-white px-4 py-1.5 font-semibold capitalize": data.includes("O=L" || "o=l") },

                )}>{data ? data : "-"}
                </p>
            );
        },
    },
    {
        accessorKey: "hittime",
        header: () => <div className=" mx-auto w-full">Hit Time</div>,
        cell: ({ row }) => {
            const data = row.getValue<string>("hittime");
            return <>{data ? data : "-"}</>;
        },
    },
    {
        accessorKey: "chance",
        header: ({ column }: any) => <SortingUi column={column} title="Chance" className=" mx-auto w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("chance");
            if (data) {
                if (data === "-") {
                    return <p>{data}</p>
                } else if (data.toLowerCase() === "hit") {
                    return <p className="mx-auto bg-yellow-500 flex items-center justify-center text-yellow-950 gap-1">
                        <span className="text-[12px]">
                            {data}
                        </span>
                        ✔
                    </p>
                } else {
                    return <p className="flex items-center justify-center font-semibold text-red-500 gap-1">{data} %</p>
                }
            } else {
                return <>-</>
            }
        },
    },
    {
        accessorKey: "callltp",
        header: ({ column }: any) => <SortingUi column={column} title="Call LTP" className=" mx-auto w-full" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("callltp");
            return <p>{data ? formatCurrency(parseFloat(data)) : "-"}</p>;
        },
    },
    {
        accessorKey: "strikeprice",
        header: () => <div className=" mx-auto w-full">Strike</div>,
        enableHiding: false,
    },

    // PUT

    {
        accessorKey: "putltp",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="Put LTP" />,
        cell: ({ row }) => {
            const putltp = row.getValue<string>("putltp");
            return <p>{putltp && formatCurrency(parseFloat(putltp))}</p>;
        },
    },
    {
        accessorKey: "putchance",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="Chance" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("putchance");
            if (data) {
                if (data === "-") {
                    return <p>{data}</p>
                } else if (data.toLowerCase() === "hit") {
                    return <p className="mx-auto bg-yellow-500 flex items-center justify-center font-semibold text-yellow-950 gap-1 max-w-max px-2 text-center">
                        <span className="text-[12px]">
                            {data}
                        </span>
                        ✔

                    </p>
                } else {
                    return <p className="flex items-center justify-center font-semibold text-red-500 gap-1">{data} %</p>
                }
            } else {
                return <>-</>
            }
        },
    },
    {
        accessorKey: "puthittime",
        header: () => <div className=" mx-auto w-full">Hit Time</div>,
        cell: ({ row }) => {
            const data = row.getValue<string>("puthittime");
            return <>{data ? data : "-"}</>;
        },
    },
    {
        accessorKey: "putohol",
        header: () => <div className=" mx-auto w-full">O=H/ O=L</div>,
        cell: ({ row }) => {
            const data = row.getValue<string>("putohol");
            return (
                <p className={cn("text-[12px]",
                    { "bg-green-500 text-white px-2 py-1 font-semibold capitalize": data.includes("O=H" || "o=h") },
                    { "bg-red-500 text-white px-2 py-1 font-semibold capitalize": data.includes("O=L" || "o=l") },

                )}>{data ? (data) : "-"}
                </p>
            );
        },
    },
    {
        accessorKey: "putnewdaylow",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="New D. Low" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("putnewdaylow");
            return (
                <> {data && formatCurrency(parseFloat(data))}</>
            );
        },
    },
    {
        accessorKey: "putnewdayhigh",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="New D. High" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("putnewdayhigh");
            return (
                <p>{data && formatCurrency(parseFloat(data))}</p>
            );
        },
    },
    {
        accessorKey: "putdayhigh",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="Day High" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("putdayhigh");
            return <p>{data && formatCurrency(parseFloat(data))}</p>;
        },
    },
    {
        accessorKey: "putdayopen",
        header: ({ column }: any) => <SortingUi column={column} className=" mx-auto w-full" title="Day Open" />,
        cell: ({ row }) => {
            const data = row.getValue<string>("putdayopen");
            return <p>{data && formatCurrency(parseFloat(data))}</p>;
        },
    },

];

export default ohcolumns;