import AppLayout from "../layout/AppLayout";
import { buttonVariants } from "../components/ui/button";
import { Link } from "react-router-dom";
import { cn } from "../lib/utils";

export default function NetworkErr() {
    return (
        <AppLayout>
            <div className="min-h-[calc(100vh-70px)] flex items-center justify-center">
                <div className="shadow p-4 pb-12 border bg-white dark:border-zinc-600 dark:bg-zinc-800 sm:max-w-2xl w-full text-center rounded-lg text-white">
                    <p className="text-[120px] font-bold text-center font-poppins text-blue-500 dark:text-white">
                        Sorry,
                        </p>
                    <p className="text-blue-500 font-semibold text-2xl text-center uppercase">
                        Something went Wrong. Please Try later.
                        </p>
                    {/* <p className="max-w-md mt-5 text-gray-400 dark:text-zinc-200 text-lg mx-auto">
                        We're sorry due to network error the page you requested colud not be found. Please go back to the homepage
                        </p> */}
                    <Link className={cn(buttonVariants({ variant: "default", className: "bg-white border-blue-600 border dark:border-white rounded-full text-blue-600 hover:bg-white/90 mt-4" }))} to="/dashboard">
                        Click here to for Dashboard
                        </Link>
                </div>
            </div>
        </AppLayout>
    )
}