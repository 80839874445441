import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export default function BuzzSpot({ row }: { row: any | {} }) {
  return (
    <div className="mt-4 flex h-full w-full justify-between gap-3 overflow-x-auto text-xs custom-scrollbar">
      <SpotPriceCard
        title1="Spot Price"
        value1={row.spotPrice !== undefined && row.spotPrice !== "" ? formatCurrency(parseFloat(row.spotPrice)) : "-"}
        title2="Current Future Price"
        value2={row.collLtp !== undefined && row.collLtp !== "" ? formatCurrency(parseFloat(row.collLtp)) : "-"}
      /> 
      <SpotPriceCard
        title1="Current Build-Ups"
        value1={row.buildUp !== undefined && row.buildUp !== "" ? row.buildUp : "-"}
        title2="Last Traded Price"
        value2={row.collLtp !== undefined && row.collLtp !== "" ? formatCurrency(parseFloat(row.collLtp)) : "-"}
      />

      <SpotPriceCard
        title1="Previous Day Close"
        value1={row.prevDay !== undefined && row.prevDay !== "" ? formatCurrency(parseFloat(row.prevDay)) : "-"}
        title2="Today&apos;s Open"
        value2={row.todayOpen !== undefined && row.todayOpen !== "" ? formatCurrency(parseFloat(row.todayOpen)) : "-"}
      />

      <SpotPriceCard
        title1="Today&apos;s Day High"
        value1={row.todayHigh !== undefined && row.todayHigh !== "" ? formatCurrency(parseFloat(row.todayHigh)) : "-"}
        title2="Today&apos;s Day Low"
        value2={row.todayLow !== undefined && row.todayLow !== "" ? formatCurrency(parseFloat(row.todayLow)) : "-"}
      />
    </div>
  );
}
