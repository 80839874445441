import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import { useEffect, useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { SelectItem } from "../ui/select";
import { IMGainers, IMLoser } from "./IMTable";
import { marketMoverExpiry } from "../../data/openInterest";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import { RefreshRateData } from "../../constants/TimeInterval";
import { toast } from "sonner";
import SelectBox from "../shared/SelectBox";
import { getFoiMarketMoversData, getIndexSubscription } from "../../api/futures/open-interest";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { Input } from "../ui/input";
import LiveMarketTable from "./LiveMarketTable";
import { configEnvironemt } from "../../config";
import { breadcrumbIntraday } from "./constant";
import { getLiveMarketTimeData } from "../../api/dashboard";

export default function IntradayMover() {
  const environment = configEnvironemt.environment;
  const marketStatus = useMarketStatus();
  const [historical, setHistorical] = useState(false);
  const [date, setDate] = useState<any>(new Date())
  const [isLoading, setIsLoading] = useState(true);
  const [expiry, setExpiriy] = useState(marketMoverExpiry[0].value);
  const [searchInput, setSearchInput] = useState("")
  const [toIdentifier, setToIdentifier] = useState(`marketmovers_data_${marketMoverExpiry[0].value}`);
  const [gainerRow, setGainerRow] = useState<any | []>([]);
  const [loserRow, setLoserRow] = useState<any | []>([]);
  const [newHighLow, setNewHighLow] = useState<any | []>([]);
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const navigate = useNavigate();
  const topicName = `marketmovers_data`;
  const instrument = "All F&O Stocks";
  let id:any;

  const handleSwitch = () => {
    setGainerRow([]);
    setLoserRow([]);
    setNewHighLow([]);
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setExpiriy(marketMoverExpiry[0].value);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setIsLoading(false)
      setHistorical(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setExpiriy("")
    }
  };

  const firstSubscription = async () => {
    await getIndexSubscription(
      topicName,
      userId === undefined ? "" : userId,
      toIdentifier
    );
  }

  function processMessage(message: string[]) {
    const rows = message.map((item) => {
      const splitValues = item.split(",");
      if (splitValues[0] === "newhighlowmaker") {
        return {
          time: splitValues[1],
          name: splitValues[2].split("_")[1],
          chart: "-",
          minBoDays: splitValues[3],
          ltp: splitValues[4],
          oiChangePercent: splitValues[5],
          highLow: splitValues[6].replace("\r", "")
        }
      } else {
        return {
          name: item.split(",")[0],
          ltp: item.split(",")[1],
          ltpChangePercent: parseFloat(item.split(",")[2]),
          oiChangePercent: item.split(",")[3],
          minBoDays: item.split(",")[4],
          oHL: item.split(",")[5],
          oiInt: item.split(",")[6]
        }
      }
    });
    const positiveRows = rows.filter((row) => row.ltpChangePercent && row.ltpChangePercent > 0).sort((a, b) => (b.ltpChangePercent as number) - (a.ltpChangePercent as number));
    const negativeRows = rows.filter((row) => row.ltpChangePercent && row.ltpChangePercent < 0).sort((a, b) => (a.ltpChangePercent as number) - (b.ltpChangePercent as number));
    const newHighLowMaker = rows.filter((row) => row.chart && row.chart === "-");
    let newHighLowSortOnTime;
    if (newHighLowMaker !== null && newHighLowMaker.length > 0) {
      newHighLowSortOnTime = newHighLowMaker.sort((a, b) => {
        if (a.time && b.time) {
          const [hoursA, minutesA, secondsA] = a?.time.split(":").map(Number);
          const [hoursB, minutesB, secondsB] = b?.time.split(":").map(Number);
          if (hoursA !== hoursB) {
            return hoursB - hoursA;
          }else if (minutesA !== minutesB) {
            return minutesB - minutesA;
          } else {
            return secondsB - secondsA;
          }
        } else {
          if (!a.time && !b.time) {
            return 0; 
          } else if (!a.time) {
            return -1; 
          } else {
            return 1; 
          }
        }
      });
    }
    setIsLoading(false);
    return { positiveRows, negativeRows, newHighLowSortOnTime };
  }

  async function liveMsgMarketMover(topicName:string, toIdentifier:string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
    const messages = data.split("\n") as string[];
      const { positiveRows, negativeRows, newHighLowSortOnTime } = processMessage(messages);
    if (newHighLowSortOnTime && newHighLowSortOnTime.length >= 200) {
      const numberOfRowsToRemove = newHighLowSortOnTime.length - 200;
      for (let i = 0; i < numberOfRowsToRemove; i++) {
          newHighLowSortOnTime.pop();
      }
    }
      setGainerRow(positiveRows);
      setLoserRow(negativeRows);
      setNewHighLow(newHighLowSortOnTime);
    }
  }

    async function getHistoricMarketMover(identifier: string, topicName:string) {
    let response;
  if (historical && identifier !== "" && date !== "") {
      response = await getFoiMarketMoversData(identifier, topicName, date);
    }  else{
      response = await getFoiMarketMoversData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const { positiveRows, negativeRows, newHighLowSortOnTime } = processMessage(messages);
      setGainerRow(positiveRows);
      setLoserRow(negativeRows);
      setNewHighLow(newHighLowSortOnTime);
    }
    setIsLoading(false);
  }

  function handleDateSelect (date:any) {
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    setExpiriy(marketMoverExpiry[0].value);
    handleTopicNameChange(instrument, marketMoverExpiry[0].value)
  }

  async function handleTopicNameChange(
    productName: string,
    expiry: string,
  ) {
    if (historical && !date) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setGainerRow([]);
    setLoserRow([]);
    setNewHighLow([]);
    let localToIdentifier = `marketmovers_data_${expiry}`;
    setToIdentifier(localToIdentifier);
  }
  const fetchDataAtInterval = async () => {
    await liveMsgMarketMover(topicName, toIdentifier);
  };
  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},12000)
  },[instrument,expiry, date])

  useEffect(() => {
    if (historical) {
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (expiry !== "" && date!=="" && toIdentifier!== "") {
        getHistoricMarketMover(toIdentifier, topicName);
      }
    } else  {
      if (marketStatus === "open" && historical === false){
        firstSubscription();
        fetchDataAtInterval();
      }else if(marketStatus === "after-open" && !historical){
        firstSubscription();
        fetchDataAtInterval()
      } else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        getHistoricMarketMover(toIdentifier, topicName);
      }
    }
  }, [historical, userId, toIdentifier, date, marketStatus]);

  useEffect(() => {
    if(!historical && marketStatus === "open"){
    const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
    if (selectedRefreshRate) {
      const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
      id = setInterval(fetchDataAtInterval, seconds * 1000);
    }
  }else if(marketStatus === "after-open" && !historical){
    firstSubscription();
    fetchDataAtInterval()
  }else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
    getHistoricMarketMover(toIdentifier, topicName);
  }
    return () => clearInterval(id);
  },[historical, refreshRate, toIdentifier, marketStatus])

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto max-w-full">
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbIntraday} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-3 min-[1071px]:grid-cols-5">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e: string) => {
            if(!historical){
              handleTopicNameChange(e, expiry);
              }else{
                setDate("");
                setExpiriy("");
              }
          }}
          placeholder="Please Select Name"
        >
          <SelectItem key={instrument} value={instrument}>
            {instrument}
          </SelectItem>
        </SelectBox>
        
        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    handleDateSelect(e)
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox
          title="Expiry:"
          value={expiry}
          onValueChange={(e: string) => {
            setExpiriy(e);
            handleTopicNameChange(instrument, e)
          }}
          placeholder="Please Select Expiry"
        >
          {marketMoverExpiry.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectBox>

        <SelectBox
          title="Refresh Rate"
          value={refreshRate}
          onValueChange={setRefreshRate}
          placeholder="Refresh Rate"
        >
          {RefreshRateData.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.name}
            </SelectItem>
          ))}
        </SelectBox>
        <div>
          <p className="text-[12px]">Search</p>
          <div className="border shadow dark:border-zinc-600 mt-0.5">
            <Input
              placeholder="Search"
              className="h-full border-none px-2 dark:zoom-in-100 bg-white  dark:bg-zinc-900"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="md:grid mt-4 grid-cols-2 items-start justify-between gap-4">
        <div className="flex-1 w-full">
          <IMGainers loading={isLoading} gainer={gainerRow} searchInput={searchInput} />
          <IMLoser loading={isLoading} loser={loserRow} searchInput={searchInput} />
        </div>
        <div className="flex-1 w-full">
          <LiveMarketTable marketStatus={marketStatus} historical={historical} loading={isLoading} searchInput={searchInput} data={newHighLow} />
        </div>
      </div>
    </div>
  );
}