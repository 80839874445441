import React, { useState } from "react";
import {
  SelectGroup,
  SelectItem,
  SelectLabel,
} from "../ui/select";
import { StrikePriceWiseTimeInterval } from "../../data/strikepricewise";
import {
  InstrumentExpiryStrikePrice,
  ExpiryStrikePrice,
} from "../../api/options";
import convertToYYYYMMDD, { findNearestDate, findNearestStrikePrice, formatDate } from "../shared/dateConverter";
import { getIndexSubscription } from "../../api/futures/open-interest";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import format from "date-fns/format";
import { CalendarDaysIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { RefreshRateData } from "../../constants/TimeInterval";
import { dateSort } from "../../utils/datesort";
import SelectBox from "../shared/SelectBox";
import useSpotPriceStore from "../../store/spotPrice";
import { toast } from "sonner";
import { baseURL } from "../../api/base";

const StrikePriceWiseDropdown = ({
  optionIndexList,
  marketStatus,
  historical,
  strikePrice,
  userId,
  setStrikePrice,
  setToIdentifier,
  setTopicName,
  getHistoricOptionsData,
  expiryDate,
  setExpiry,
  instrument,
  setInstrument,
  timeInterval,
  setTimeInterval,
  setRows,
  setLastRows,
  environment,
  date,
  setDate,
  setIsLoading,
  toIdentifier,
  topicName,
  liveMsgStrikePriceWiseData
}: {
  optionIndexList: InstrumentExpiryStrikePrice[];
  historical: boolean;
  marketStatus: string;
  strikePrice: string;
  userId: string,
  setStrikePrice: React.Dispatch<React.SetStateAction<string>>
  setToIdentifier: React.Dispatch<React.SetStateAction<string>>,
  setTopicName: React.Dispatch<React.SetStateAction<string>>
  getHistoricOptionsData: (identifier: string, topicName:string, currentStrike?:string) => Promise<void>
  expiryDate: string;
  setExpiry: React.Dispatch<React.SetStateAction<string>>
  instrument: string;
  setInstrument: React.Dispatch<React.SetStateAction<string>>
  timeInterval: any,
  setTimeInterval: any,
  setLastRows: any,
  setRows: any,
  environment: string,
  setDate: any,
  date: any,
  setIsLoading: any,
  topicName:string,
  toIdentifier:string,
  liveMsgStrikePriceWiseData:any
}) => {

  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [strikePriceList, setStrikePriceList] = useState<string[] | null>(null);
  const [refreshRate, setRefreshRate] = useState(RefreshRateData[0].value);
  const { spotPrices } = useSpotPriceStore();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [historicSpotData, setHistoricSpotData] = useState<InstrumentExpiryStrikePrice[]|null>(null);
  let id:any;

  async function getInstrumentExpiryStrikePriceList(
    indexExpiryPriceList: any[]
  ) {
    if (indexExpiryPriceList != null && indexExpiryPriceList.length > 0) {
      const initialInstrument = instrument ? instrument : indexExpiryPriceList[0].ProductName
      await getExpiryList(
        initialInstrument,
        indexExpiryPriceList
      );
    }
  }

  function handleProductChange(productName: string) {
    setRows([]);
    setLastRows([]);
    setInstrument(productName);
    if(!historical){
    setIsLoading(true);
      getExpiryList(productName, optionIndexList);
    }
    if(historical){
      setDate("");
      setExpiry("");
      setStrikePrice("");
    }
  }

  function handleDateSelect (date:any) {
    setRows([]);
    setLastRows([]);
    setDate(date);
    setIsCalendarOpen(false);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
      setHistoricSpotData(data);
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  function handleExpiryChange(expiry: string) {
    setIsLoading(true);
    setExpiry(expiry);
    if (instrument !== undefined && !historical) {
      getStrikePriceListByExpiry(instrument, optionIndexList, expiry);
    }else{
      getStrikePriceListByExpiry(instrument, historicSpotData, expiry)
    }
  }
  async function getStrikePriceListByExpiry(
    productName: string,
    indexdataList: InstrumentExpiryStrikePrice[] | null,
    expiry: string
  ) {
    let strikePriceList: string[] = [];
    let nearestStrikePrice:any;
    if (indexdataList != null && indexdataList.length > 0) {
      let filteredList = indexdataList.filter(
        (product) => product.ProductName === productName
      );
      let expirystrikePricedata = filteredList[0].ExpiryStrikePriceList;
      let expirydata = expirystrikePricedata.filter(
        (exp) => exp.Expiry === expiry
      );
      strikePriceList = expirydata[0].StrikePrice;
      const nearSpotPrice = spotPrices[productName] ? spotPrices[productName] : optionIndexList.find((item) => item.ProductName === productName)?.SpotPrice;
      if(!historical){
        nearestStrikePrice = await findNearestStrikePrice(nearSpotPrice, strikePriceList, productName);
      }
      else{
        const historicSpot = filteredList[0]?.SpotPrice;
        nearestStrikePrice = await findNearestStrikePrice(historicSpot, strikePriceList);
      }
      if (nearestStrikePrice !== undefined && nearestStrikePrice !== null) {
        setStrikePrice(nearestStrikePrice);
      }
      handleIdentifierChange(productName, expiry, nearestStrikePrice, timeInterval)
    }
    const sortedList = strikePriceList.sort((a,b) =>parseInt(a)-parseInt(b))
    setStrikePriceList(sortedList);
  }

  async function handleIdentifierChange(
    product: string,
    expiry: string,
    strikePrice: string,
    timeInterval: string
  ) {
    if(historical && date === "" && instrument===""){
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    setRows([]);
    setLastRows([]);
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find(
      (idx) => idx === product
    );
    let topicNameLocal = `OISTRIKEPRICE_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}`;
    let localToIdentifier = `${!!isIndex ? "OPTIDX" : "OPTSTK"
      }_${product}_${expiry}_${strikePrice}_${timeInterval}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    if(!historical){
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId === undefined ? "" : userId,
          localToIdentifier
        );
      } else if(marketStatus!=="loading" && (marketStatus === "closed" || marketStatus === "pre-open")){
        getHistoricOptionsData(localToIdentifier, topicNameLocal, strikePrice);
      }
    }
    setToIdentifier(localToIdentifier);
  }

  function getExpiryList(
    productName: string,
    indexdataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    const expiryList: string[] = [];
    if (indexdataList != null && indexdataList.length > 0) {
      const filteredList = indexdataList.filter(
        (product) => product.ProductName === productName
      );
      let expirystrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expirystrikePriceData.map((exp) => exp.Expiry);
      const nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null) {
        setExpiry(nearestDate);
        getStrikePriceList(expirystrikePriceData, nearestDate, productName, filteredList);
      }
      expirystrikePriceData.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
    }
    const sortedDate = dateSort(expiryList);
    setExpiryList(sortedDate);
  }

  async function getStrikePriceList(
    expiryStrikePriceList: ExpiryStrikePrice[],
    expiry: string,
    productName: string,
    filteredDataList?:InstrumentExpiryStrikePrice[]
  ) {
    let strikePriceList: string[] = [];
    let nearestStrikePrice: any;
    if (expiryStrikePriceList != null && expiryStrikePriceList.length > 0) {
      let filteredList = expiryStrikePriceList.filter(
        (item) => item.Expiry === expiry
      );
      strikePriceList = filteredList[0].StrikePrice;
      const nearSpotPrice = spotPrices[productName] ? spotPrices[productName] :optionIndexList.find((item) => item.ProductName === productName)?.SpotPrice;
      if(!historical){
        nearestStrikePrice = await findNearestStrikePrice(nearSpotPrice, strikePriceList, productName);
      }else{
        if(filteredDataList && filteredDataList?.length > 0 ) {
          const historicSpot = filteredDataList[0]?.SpotPrice;
          nearestStrikePrice = await findNearestStrikePrice(historicSpot, strikePriceList);
        }
      }
      if (nearestStrikePrice !== undefined && nearestStrikePrice !== null) {
        setStrikePrice(nearestStrikePrice);
      }
    }
    const sortedList = strikePriceList.sort((a,b) =>parseInt(a)-parseInt(b))
    setStrikePriceList(sortedList);
    if (productName !== undefined && nearestStrikePrice !== null) {
      handleIdentifierChange(productName, expiry, nearestStrikePrice, timeInterval);
    }
  }

  async function Init() {
    await getInstrumentExpiryStrikePriceList(optionIndexList);
  }

  const fetchDataAtInterval = async () => {
    await liveMsgStrikePriceWiseData(topicName, toIdentifier);
};

  React.useEffect(() => {
    if (!historical) {
      Init();
    }
  }, [historical, userId, marketStatus]);

  React.useEffect(()=>{
    if(!historical && marketStatus === "open"){
    fetchDataAtInterval()
    }
  },[toIdentifier, marketStatus])

  React.useEffect(()=>{
    let timeoutId:any;
    if(!historical && marketStatus === "open"){
      const selectedRefreshRate = RefreshRateData.find(rate => rate.value === refreshRate);
        if (selectedRefreshRate) {
          const valueParts = selectedRefreshRate.value.split(" ");
          const timeValue = parseInt(valueParts[0]);
          const timeUnit = valueParts[1];
          let seconds;
            if (timeUnit === "min") {
             seconds = timeValue * 60;
            } else {
              seconds = timeValue;
            } 
          id = setInterval(fetchDataAtInterval, seconds * 1000);
        }
      } else if(marketStatus === "after-open" && !historical){
        timeoutId = setTimeout(fetchDataAtInterval, 500)
      }
      return () => {
        clearInterval(id);
        clearTimeout(timeoutId);
      };
  },[toIdentifier, historical, marketStatus])

  return (
    <div className="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-3 min-[1071px]:grid-cols-6">
      <SelectBox value={instrument}
        onValueChange={(e: any) => {
          handleProductChange(e);
        }} title="Name:" placeholder="Please Select Name">
        <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
          <SelectLabel className="-ml-3">Index</SelectLabel>
          {optionIndexList &&
            optionIndexList.slice(0, 4).map((item, index) => {
              return (
                <SelectItem key={index} value={`${item.ProductName}`} >
                  {item.ProductName}
                </SelectItem>
              );
            })}
          <SelectLabel className="-ml-3">Stocks</SelectLabel>
          {optionIndexList &&
            optionIndexList.slice(5, optionIndexList.length).map((item, index) => {
              return (
                <SelectItem key={index} value={`${item.ProductName}`}>
                  {item.ProductName}
                </SelectItem>
              );
            })}
        </SelectGroup>
      </SelectBox>
      <div className="w-full">
        <p className="mb-1 pl-1 text-xs">Date:</p>
        <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
          <PopoverTrigger asChild>
            <Button
              disabled={!historical}
              variant="outline"
              className={cn(
                "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                !date && "text-muted-foreground"
              )}
            >
              {date ? format(date, "PPP") : <span>Please Select Date</span>}
              <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={date}
              onSelect={(e:Date |undefined) => {
                if(e instanceof Date){
                  handleDateSelect(e)
                }else{
                  toast.error("Invalid Date Selected");
                }
                // setDate(e);
                // setIsCalendarOpen(false)
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>

      <SelectBox title="Expiry:" placeholder="Please Select Expiry" value={expiryDate}
        onValueChange={(e: any) => {
          handleExpiryChange(e)
        }}
      >
        {expiryList &&
          expiryList.map((item, index) => {
            const date = formatDate(item);
            return (
              <SelectItem key={index} value={`${item}`}>
                {date}
              </SelectItem>
            );
          })}
      </SelectBox>

      <SelectBox
        title="Strike Price"
        placeholder="Select Strike Price"
        value={strikePrice}
        onValueChange={(e: any) => {
          setStrikePrice(e);
          setIsLoading(true);
          handleIdentifierChange(instrument, expiryDate, e, timeInterval);
        }}>
        <SelectGroup className="max-h-[400px] custom-scrollbar overflow-y-scroll">
          {strikePriceList &&
            strikePriceList.map((item, index) => {
              return (
                <SelectItem key={index} value={`${item}`}>
                  {item}
                </SelectItem>
              );
            })}
        </SelectGroup>
      </SelectBox>
     
      <SelectBox title="Time Interval:" placeholder="Time Interval" value={timeInterval} onValueChange={(e: any) => {
        setTimeInterval(e);
        handleIdentifierChange(instrument, expiryDate, strikePrice, e);
      }}>
        {StrikePriceWiseTimeInterval.map((item, index) => {
          return (
            <SelectItem key={index} value={`${item.value}`}>
              {item.name}
            </SelectItem>
          );
        })}
      </SelectBox>
      <SelectBox title="Refresh Rate:" placeholder="Refresh Rate" value={refreshRate} 
      onValueChange={(e:any)=>{
        setRefreshRate(e)
      }}>
        {RefreshRateData.map((item, index) => {
          return (
            <SelectItem key={index} value={`${item.value}`}>
              {item.name}
            </SelectItem>
          );
        })}
      </SelectBox>
    </div>
  );
};

export default StrikePriceWiseDropdown;