import React from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'
import { Icons } from '../../utils/icons'
import { cn } from '../../lib/utils'
import { buttonVariants } from '../ui/button'
import Card from './Card'
import Slider from './Slider'
import Step from './Step'
import Footer from './Footer'
import { useGetFromStore } from '../../hooks/useGetFromStore'
import useAuthStore from '../../store/auth'
import { useNavigate } from 'react-router-dom'
export default function LandingPage() {
    const navigate = useNavigate();
    const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);

    React.useEffect(() => {
        if (isLoggedIn) {
            navigate("/dashboard");
        } else {
            navigate("/");
        }
    }, [isLoggedIn]);
    return (
        <main className="font-montserrat dark:bg-zinc-950 dark:text-white">
            <div className="relative">
                <div className="absolute z-[2] h-full w-full bg-zinc-950 opacity-95" />
                <video
                    className="absolute top-0 z-[1] h-full  w-full object-cover"
                    loop
                    autoPlay
                    muted
                    preload="metadata"
                    poster="/assets/landing-page/bg1.png"
                >
                    <source src="/assets/landing-page/bg/herovid.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <Header />

                <section className="relative  z-10 flex h-full max-h-[800px] min-h-screen flex-col items-center justify-center px-4 py-12 pt-32 text-center">
                    <h1 className=" font-montserrat text-[110px] font-bold leading-[120px] text-blue-600">
                        FAST DATA
                    </h1>
                    <p className="mt-3  text-[36px] font-semibold uppercase text-white">
                        Best Tools <br className="sm:hidden" /> Better Trading
                    </p>
                    <p className={`font-poppins mb-8 mt-4 max-w-[630px] text-[26px] font-medium text-zinc-400`}>
                        Real-Time OI-Data for Analysing F&O segment
                        <br />
                        with a wide range of tools
                    </p>

                    <Link
                        to="/register"
                        className={cn(
                            buttonVariants({
                                variant: "default",
                                className: "px-[33px] py-[14px] text-[15px] text-base",
                            })
                        )}
                    >
                        Explore Now
                        <Icons.rightArrow className="ml-3" />
                    </Link>
                </section>
            </div>

            <section className="relative hidden p-4 px-0  md:block">
                <div className="absolute top-0 z-[2] h-full w-full bg-zinc-950 opacity-90" />
                <video
                    className="absolute top-0 z-[1] h-full  w-full object-cover"
                    loop
                    autoPlay
                    muted
                    preload="metadata"
                    poster="/assets/landing-page/hero-bg.png"
                >
                    <source src="/assets/landing-page/bg/herovid.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="relative z-10 m-auto min-h-[600px] max-w-[800px]">
                    <img
                        src="/assets/landing-page/dashboard.png"
                        className="relative z-10 m-auto -mt-28 hidden max-w-[800px] dark:block"
                        alt="dashboard"
                    />
                    <img
                        src="/assets/landing-page/dashboard-light.png"
                        className="relative z-10 m-auto -mt-28 dark:hidden"
                        alt="dashboard"
                    />
                </div>
            </section>

            <section className="relative overflow-hidden bg-blue-100 px-4 py-12 pb-28 pt-28 dark:bg-zinc-950">
                <div className="relative z-10 ">
                    <h3 className="mb-12 text-center text-5xl font-bold md:text-[60px] ">
                        Reasons Traders Choose
                        <br className="md:hidden" /> Trade Dons
                    </h3>

                    <div className="container relative z-10  grid justify-center gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                        <Card
                            src="reasons/simplicity.svg"
                            heading="Simplicity"
                            desc=" Easy to use Interface. No Tech Expertise required"
                        />

                        <Card
                            src="reasons/speed.svg"
                            heading="Speed"
                            desc="fastest and real-time. 1-sec refresh rate for better analysis"
                        />

                        <Card
                            src="reasons/ai.svg"
                            heading="AI Enabled"
                            desc=" india’s one & only real AI based platform for  analyzing open interest data"
                        />

                        <Card
                            src="reasons/tutorials.svg"
                            heading="Tutorials"
                            desc="easy to understand video tutorials available at no cost"
                        />
                    </div>
                </div>
                <img
                    src="/assets/landing-page/blue-shape.png"
                    alt="bg-shape"
                    className="absolute bottom-0 left-0 right-0 h-60 w-full sm:h-auto"
                />
            </section>

            <section className="relative z-10 ">
                <div className="m-auto grid max-w-5xl items-start gap-12 py-6 lg:grid-cols-2 lg:py-0">
                    <Link to="/" className="-mt-12 hidden  md:block">
                        <img
                            src="/assets/landing-page/trade-decesion-md.png"
                            width={500}
                            height={700}
                            alt="img"
                            className="m-auto rounded-lg "
                        />
                    </Link>
                    <Link to="/" className="-mt-12 hidden md:block">
                        <img
                            src="/assets/landing-page/live-market-md.png"
                            width={500}
                            height={700}
                            alt="img"
                            className="m-auto rounded-lg "
                        />
                    </Link>

                    {/* For Mobile */}
                    <Link to="/" className=" md:hidden">
                        <img
                            src="/assets/landing-page/trade-decesion-sm.png"
                            width={400}
                            height={600}
                            alt="img"
                            className="m-auto rounded-lg"
                        />
                    </Link>
                    <Link to="/" className="md:hidden">
                        <img
                            src="/assets/landing-page/live-market-sm.png"
                            width={400}
                            height={600}
                            alt="img"
                            className="m-auto rounded-lg"
                        />
                    </Link>
                </div>
            </section>

            <section className="relative bg-zinc-950 pt-24 text-center text-white">
                <div className="absolute top-0 z-[2] h-full w-full bg-zinc-950 opacity-90" />
                <video
                    className="absolute top-0 z-[1] h-full  w-full object-cover"
                    loop
                    autoPlay
                    muted
                    preload="metadata"
                    poster="/assets/landing-page/bg1.png"
                >
                    <source
                        src="/assets/landing-page/bg/data-driven.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <h3 className="relative z-10 text-[80px] font-bold capitalize">
                    data-driven trading
                </h3>
                <p className="relative z-10 mb-9 px-4 text-[24px] capitalize">
                    we are backed by university grade trading academy
                </p>

                <h5 className="relative z-10 px-4 text-[40px] font-bold uppercase">
                    TRADING AS PROFESSION ACADEMY
                </h5>

                <div className="relative z-10  overflow-hidden px-4 ">
                    <img
                        src="/assets/landing-page/bg/bg-shape.png"
                        alt="bg"
                        className="mt-16 absolute bottom-0 w-full left-0 right-0 h-[80%] rounded-t-[100px]"
                    />
                    <img
                        src="/assets/landing-page/tap.png"
                        width={500}
                        height={300}
                        alt="tap"
                        className="relative z-10 m-auto"
                    />
                    <p className="relative z-10 m-auto max-w-max  rounded-md border bg-gradient-to-r from-zinc-950 to-blue-950  px-4 py-2 text-[24px] font-bold  capitalize text-white">
                        the only institute in india that teaches data-driven trading
                    </p>
                </div>
            </section>

            <section className="relative bg-blue-100 pb-16 dark:bg-zinc-950">
                <img
                    src="/assets/landing-page/top-left.png"
                    width={400}
                    height={100}
                    alt="bg"
                    className="absolute left-0 top-0"
                />
                <img
                    src="/assets/landing-page/bottom-right.png"
                    width={400}
                    height={100}
                    alt="bg"
                    className="absolute bottom-0 right-0"
                />

                <div className="container relative z-10 pt-24 text-center">
                    <h3 className="text-2xl font-bold sm:text-[60px]">
                        We Understand You
                    </h3>
                    <p className="m-auto mt-[20px] max-w-[708px] text-[22px] capitalize">
                        we are india’s fastest growing trading community, we are well aware
                        of what is required for being a succesfull F&O traders
                    </p>

                    <div className="m-auto mt-8 grid max-w-4xl  gap-5 sm:grid-cols-2 md:grid-cols-3">
                        <Card
                            src="understand/market.png"
                            heading="Foreign Markets"
                            desc="now, you can explore data of US/UK/FOREX markets by using our platform"
                            bg="dark:bg-zinc-900 bg-white"
                        />
                        <Card
                            src="understand/support.png"
                            heading="Live Support"
                            desc="we are available on whatsapp
                chat & call support to support
                you during live market hours"
                            bg="dark:bg-zinc-900 bg-white"
                        />
                        <Card
                            src="understand/trade.png"
                            heading="Sprint Trade"
                            desc="fast & easy to use platform
                for single window trading
                with multiple rokers"
                            bg="dark:bg-zinc-900 bg-white"
                        />
                    </div>
                </div>
            </section>

            <section className="bg-zinc-50 dark:bg-zinc-950">
                <div className="m-auto max-w-5xl p-4 py-12 pb-16 ">
                    <h1 className="mb-4 text-center text-2xl font-bold capitalize sm:text-[60px]">
                        People Like Us
                    </h1>
                    <p className="mt-[20px] text-center text-[20px] capitalize">
                        convince yourself, read what they want to say
                    </p>

                    <div className="mt-12  items-center  gap-6 md:flex ">
                        <div className="m-auto w-full max-w-sm rounded-md border border-zinc-400 bg-white px-4 py-6 shadow-md dark:border-zinc-600 dark:bg-zinc-900">
                            <img
                                src="/assets/logos/google-text.png"
                                width={100}
                                height={100}
                                alt="google-logo"
                            />
                            <p className="text-xs text-white/40">Reviews</p>
                            <p className="mt-1 flex items-center gap-2">
                                <span>5.0</span>
                                <img
                                    src="/assets/landing-page/5star.png"
                                    width={120}
                                    height={40}
                                    alt="rating stars"
                                />
                            </p>
                            <p className="mt-5 text-xl font-medium lg:text-2xl lg:font-semibold">
                                Trusted by
                            </p>
                            <p className="text-3xl font-extrabold text-blue-900 dark:text-blue-600">
                                15500+
                            </p>
                            <p className="text-xl font-medium lg:text-2xl lg:font-semibold">
                                customers for fastest & real time market data
                            </p>
                        </div>
                        <div className="max-h-80 mt-4 md:mt-0 md:h-60">
                            <Slider />
                        </div>
                    </div>
                </div>
            </section>

            <section className="overflow-hidden bg-blue-100 pt-12 text-center dark:bg-zinc-950">
                <h3 className="text-2xl font-bold sm:text-[60px]">Education</h3>
                <p className="mt-[20px] text-[20px] capitalize">
                    we are there for your growth
                </p>

                <div className="m-auto mt-24 grid max-w-6xl gap-4 gap-y-12 px-4 md:grid-cols-2">
                    <div className="relative m-auto max-w-lg rounded-md border bg-white text-center text-zinc-900">
                        <p className="m-auto -mt-6 max-w-max  rounded-md  bg-blue-900 px-4 py-2  text-xl text-white dark:bg-blue-600">
                            For Pro Traders
                        </p>
                        <p className="mt-12 px-4 text-4xl font-extrabold  text-blue-900 dark:text-blue-600">
                            OPTION INCOME <br /> BLUE PRINT
                        </p>
                        <p className="m-auto mt-2 max-w-md px-4 font-medium">
                            Discover the revolutionary power of data-driven options trading
                            for consistent pro
                        </p>
                        <img
                            src="/assets/landing-page/holding-phone1.png"
                            width={800}
                            height={800}
                            alt="holding phone"
                            className="mt-6 w-full"
                        />
                        <Link
                            to="/register"
                            className={cn(
                                buttonVariants({
                                    variant: "default",
                                    className: "absolute bottom-4 left-[30%]",
                                })
                            )}
                        >
                            Explore Now <Icons.rightArrow className="ml-2" />
                        </Link>
                    </div>

                    <div className="relative m-auto h-full  max-w-lg rounded-md border bg-zinc-900 text-center text-white">
                        <p className="m-auto -mt-6 max-w-max  rounded-md  bg-blue-900 px-4 py-2  text-xl text-white dark:bg-blue-600">
                            For BEGINNERS
                        </p>
                        <p className="mt-12 px-4 text-4xl font-extrabold  text-blue-600">
                            TRADING INCOME <br /> BLUE PRINT
                        </p>
                        <p className="m-auto mt-2 max-w-md px-4 font-medium">
                            Discover the revolutionary power of data-driven options trading
                            for consistent pro
                        </p>
                        <div className="flex justify-start ">
                            <img
                                src="/assets/landing-page/holding-phone2.png"
                                width={400}
                                height={400}
                                alt="holding phone"
                                className="relative top-10 mt-6 h-[400px] w-auto"
                            />
                        </div>
                        <Link
                            to="/register"
                            className={cn(
                                buttonVariants({
                                    variant: "default",
                                    className: "absolute bottom-4 left-[30%]",
                                })
                            )}
                        >
                            Explore Now <Icons.rightArrow className="ml-2" />
                        </Link>
                    </div>
                </div>
            </section>

            <section className="bg-zinc-100 py-12 dark:bg-zinc-900">
                <h3 className="capitalize] mb-8 text-center text-[60px] font-bold">
                    Start trading with data in
                    <span className=" text-blue-900 dark:text-blue-600"> 4 Steps</span>
                </h3>
                <div className="m-auto max-w-4xl text-center">
                    <div className="m-auto grid max-w-7xl gap-4 p-4 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        <Step text="Register & Verify" bg="c1.png" img="1.png" />
                        <Step text="Select Your Plan" bg="c2.png" img="2.png" />
                        <Step text="Watch Tutorials" bg="c3.png" img="3.png" />
                        <Step text="Start Trading With Data" bg="c4.png" img="4.png" />
                    </div>
                    <Link
                        to="/register"
                        className={cn(
                            buttonVariants({
                                variant: "default",
                                className: "mt-12 px-[33px] py-[14px] text-[15px]",
                            })
                        )}
                    >
                        Register Now
                        <Icons.rightArrow className="ml-2" />
                    </Link>
                </div>
            </section>

            <section className='max-w-5xl mx-auto py-12 px-4'>
                <h1 className='text-2xl sm:text-4xl font-bold text-center'>Our Partners</h1>
                <p className='text-center mb-12'>to make market more accessible</p>

                <div className='px-3 sm:px-6 py-6 rounded-lg shadow dark:bg-zinc-800'>
                    <a className='max-w-[120px] mx-auto' href='https://tradingview.com'
                        target='_blank' rel="noreferrer">
                        <img src="/tv.png" alt="tradingview" className='mx-auto max-h-[80px] max-w-[120px] dark:invert' />
                    </a>
                    <p className='text-center mt-4'>
                        <span className='text-blue-600'> TradingView </span>
                        is a charting platform for traders and investors, loved and visited by millions of users worldwide. It offers state-of-the-art charting tools and a space where people driven by markets can chat, chart, and prepare for trades. Among other things, it provides the essence of market research — data — and presents it in various forms: you can track important upcoming events in the Economic calendar or browse stocks in the Screener to find the best opportunities for your portfolio. Whatever your trading strategy needs, just visit TradingView.
                    </p>
                </div>
            </section>

            <Footer />
        </main>
    )
}

