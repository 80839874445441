import { format } from "date-fns";
import { CalendarDaysIcon } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  SelectGroup,
  SelectItem,
} from "../ui/select";
import useMarketStatus from "../../hooks/useMarketStatus";
import { cn } from "../../lib/utils";
import LiveSwitch from "../shared/LiveSwitch";
import { toast } from "sonner";
import OHTable from "./HOTable";
import SelectBox from "../shared/SelectBox";
import CollapseButton from "../shared/CollapseButton";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { HOSpot } from "./HOSpot";
import { InstrumentExpiryStrikePrice, getHeadOfficeData } from "../../api/options";
import { SelectLabel } from "@radix-ui/react-select";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import convertToYYYYMMDD, { findNearestDate, findNearestStrikePrice, formatDate } from "../shared/dateConverter";
import { getIndexSubscription } from "../../api/futures/open-interest";
import { useNavigate } from "react-router-dom";
import { dateSort } from "../../utils/datesort";
import useSpotPriceStore from "../../store/spotPrice";
import { configEnvironemt } from "../../config";
import OLTable from "./OLTable";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { baseURL } from "../../api/base";
import { breadcrumbHO } from "./constant";

export default function HeadOffice(props: {
  headerOfficeList: InstrumentExpiryStrikePrice[];
}) {
  const environment = configEnvironemt.environment;
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  const [date, setDate] = useState<any>(new Date())
  const [instrument, setInstrument] = useState(props?.headerOfficeList[0]?.ProductName);
  const [expiry, setExpiry] = useState("");
  const [expiryList, setExpiryList] = useState<string[] | null>(null);
  const [historical, setHistorical] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [identifier, setIdentifier] = useState(`HeadOffice_OPTIDX_${instrument}_${expiry}`);
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const handleCollapse = () => setCollapsed((collapse) => !collapse)
  const [oh, setOh] = useState(true)
  const [openLowRows, setOpenLowRows] = useState<any[] | []>([]);
  const [openHighRows, setOpenHighRows] = useState<any[] | []>([]);
  const [topicName, setTopicName] = useState(() => {
    let baseTopicName = `HEADOFFICE_OPTIDX_${props?.headerOfficeList[0].ProductName}`;
    if (environment === "test") {
      baseTopicName += "_test";
    }
    return baseTopicName;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [historicSpotData, setHistoricSpotData] = useState<InstrumentExpiryStrikePrice[]|null>(null);
  const [colapsableData, setColapsableData] = useState<any[] | []>([]);
  const [tableSpot, setTableSpot] = useState<string>("")
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const navigate = useNavigate();
  const { spotPrices, setSpotPrice } = useSpotPriceStore();
  const marketStatus = useMarketStatus();
  const [tableHeight, setTableHeight] = useState(350);
  const containerRef = useRef<HTMLDivElement>(null);
  let id: any;
  const handleSwitch = () => {
    setOpenHighRows([]);
    setOpenLowRows([]);
    setColapsableData([])
    // historic to live
    if (historical) {
      setIsLoading(true);
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date())
      setInstrument(props?.headerOfficeList[0]?.ProductName);
    }
    // Live to historic
    if (!historical) {
      clearInterval(id);
      setHistorical(true);
      setIsLoading(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("")
      setInstrument("")
      setExpiry("")
    }
  };

  function processMessage(messages: string[]) {
    const processData = messages.map((item) => {
      const splitMsg = item.split(",");
      return {
        dayopen: splitMsg[0],
        dayhigh: splitMsg[1],
        newdayHigh: splitMsg[2],
        newdaylow: splitMsg[3],
        ohol: splitMsg[4],
        hittime: splitMsg[5],
        chance: splitMsg[6],
        callltp: splitMsg[7],
        strikeprice: splitMsg[8],
        putltp: splitMsg[9],
        putchance: splitMsg[10],
        puthittime: splitMsg[11],
        putohol: splitMsg[12],
        putnewdaylow: splitMsg[13],
        putnewdayhigh: splitMsg[14],
        putdayhigh: splitMsg[15],
        putdayopen: splitMsg[16]
      }
    });

    setSpotPrice(instrument, parseFloat(messages[messages.length - 1].split(',')[20]))
    const ColapsableData = [{
      currentfutureprice: messages[messages.length - 1].split(',')[17],
      premiumInCall: messages[messages.length - 1].split(',')[18],
      premiumInPut: messages[messages.length - 1].split(',')[19],
      SpotPrice: messages[messages.length - 1].split(',')[20]
    }]
    setColapsableData(ColapsableData)
    const strikePriceListArr = props.headerOfficeList.find((item) => item.ProductName === instrument)?.ExpiryStrikePriceList[0].StrikePrice;
    if (strikePriceListArr && historical === false && marketStatus === "open") {
      findNearestStrikePrice(messages[messages.length - 1].split(',')[20], strikePriceListArr)
        .then((data) => {
          setTableSpot(data);
        });
    }
    const filteredData = processData.map((row) => {
      if (row.hittime === "09:15") {
        return {
          dayopen: "-",
          dayhigh: "-",
          newdayHigh: "-",
          newdaylow: "-",
          ohol: "-",
          hittime: "-",
          chance: "-",
          callltp: "-",
          strikeprice: row.strikeprice,
          putltp: row.putltp,
          putchance: row.putchance,
          puthittime: row.puthittime,
          putohol: row.putohol,
          putnewdaylow: row.putnewdaylow,
          putnewdayhigh: row.putnewdayhigh,
          putdayhigh: row.putdayhigh,
          putdayopen: row.putdayopen
        };
      } else if (row.puthittime === "09:15") {
        return {
          dayopen: row.dayopen,
          dayhigh: row.dayhigh,
          newdayHigh: row.newdayHigh,
          newdaylow: row.newdaylow,
          ohol: row.ohol,
          hittime: row.hittime,
          chance: row.chance,
          callltp: row.callltp,
          strikeprice: row.strikeprice,
          putltp: "-",
          putchance: "-",
          puthittime: "-",
          putohol: "-",
          putnewdaylow: "-",
          putnewdayhigh: "-",
          putdayhigh: "-",
          putdayopen: "-"
        };
      } else {
        return row;
      }
    });
    const openHignData = filteredData.filter((msg) => (msg.ohol === "O=H" || msg.putohol === "O=H")).sort((a, b) => parseInt(b.strikeprice) - parseInt(a.strikeprice));
    const openLowData = filteredData.filter((msg) => (msg.ohol === "O=L" || msg.putohol === "O=L")).sort((a, b) => parseInt(a.strikeprice) - parseInt(b.strikeprice));
    setIsLoading(false);
    return { openHignData, openLowData };
  }

  async function liveMsgHOData(topicName: string, toIdentifier: string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
      const messages = data.split("\n") as string[];
      const { openHignData, openLowData } = await processMessage(messages);
      setOpenHighRows(openHignData);
      setOpenLowRows(openLowData);
    }
  }


  async function getHistoricHeadOfficeData(identifier: string, topicName: string, refDate?:any) {
    let response;
  if(identifier!==""){
    if (historical && date !== '') {
      response = await getHeadOfficeData(identifier, topicName, !refDate ? date : refDate );
    } else {
      response = await getHeadOfficeData(identifier, topicName)
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const { openHignData, openLowData } = await processMessage(messages);
      setOpenHighRows(openHignData);
      setOpenLowRows(openLowData);
    }
  }
  }

  const handleOpenHighLow = (value: string) => {
    if (value === "account" && !oh) {
      setOh(true);
    } else if (value === "password" && oh) {
      setOh(false);
    }
  }

  const handleIdentifierChange = async (
    product: string,
    expiry: string,
    refDate?: any
  ) => {
    if (historical && date === "") {
      setIsLoading(false)
    } else {
      setIsLoading(true);
    }
    setOpenHighRows([]);
    setOpenLowRows([]);
    let strikePriceList: string[] = [];
    let nearestStrikePrice:any;
    if(!historical){
      const nearSpotPrice = spotPrices[product] ? spotPrices[product] : props.headerOfficeList.find((item) => item.ProductName === product)?.SpotPrice;
      const filteredList = props.headerOfficeList.filter(
        (item) => item.ProductName === product
      );
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const filterExpiryList = expiryStrikePriceData.filter((item) => item.Expiry === expiry);
      strikePriceList = filterExpiryList[0].StrikePrice;
      nearestStrikePrice = await findNearestStrikePrice(nearSpotPrice, strikePriceList, product);
    } else{
      const historicFilteredList = historicSpotData?.filter((item) => item.ProductName === product);
      if(historicFilteredList && historicFilteredList.length > 0){
        const expiryDates = historicFilteredList[0].ExpiryStrikePriceList.filter((exp) => exp.Expiry === expiry);
        strikePriceList = expiryDates[0].StrikePrice;
        const historicSpot = historicFilteredList[0].SpotPrice;
        nearestStrikePrice = await findNearestStrikePrice(historicSpot, strikePriceList)
      }
    }
    setTableSpot(nearestStrikePrice);
    const isIndex = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"].find((idx) => idx === product);
    let topicNameLocal = `HEADOFFICE_${!!isIndex ? "OPTIDX" : "OPTSTK"}_${product}`;
    let identifierLocal = `${!!isIndex ? "HeadOffice_OPTIDX" : "HeadOffice_OPTSTK"}_${product}_${expiry}`;
    if (environment === "test") {
      topicNameLocal += "_test"
    }
    setTopicName(topicNameLocal);
    if (!historical) {
      if (marketStatus === "open" || marketStatus === "after-open") {
        await getIndexSubscription(
          topicNameLocal,
          userId === undefined ? "" : userId,
          identifierLocal
        );
      } 
      // else if (marketStatus !== "loading" && (marketStatus === "closed" || marketStatus === "pre-open")) {
      //   getHistoricHeadOfficeData(identifierLocal, topicNameLocal);
      // }
    }
    if(historical &&  refDate!=="" && refDate !== date && identifier  === identifierLocal){
      getHistoricHeadOfficeData(identifierLocal, topicNameLocal, refDate);
    }
    setIdentifier(identifierLocal);
  }

  const handleProductChange = async (productName: string) => {
    setOpenHighRows([]);
    setOpenLowRows([]);
    setColapsableData([]);
    setInstrument(productName);
    if(!historical){
      getExpiryList(productName, props.headerOfficeList);
    }
    if (historical) {
      setDate("");
      setExpiry("");
    }
  };

  function handleDateSelect (date:any) {
    setOpenHighRows([]);
    setOpenLowRows([]);
    setColapsableData([]);
    setDate(date);
    setIsCalendarOpen(false);
    setIsLoading(true);
    const formattedDate = convertToYYYYMMDD(date);
    fetch(`${baseURL}/api/Options/GetInstrumentExpiryStrikePriceForHistoric?HistoricDate=${formattedDate}`)
    .then((res) => res.json().then((data:InstrumentExpiryStrikePrice[]) => {
      setHistoricSpotData(data);
     getExpiryList(instrument, data, date)
    }))
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
  }

  function getExpiryList(
    productName: string, 
    indexDataList: InstrumentExpiryStrikePrice[] | null,
    refDate?:Date
  ) {
    let expiryList: string[] = [];
    let nearestDate: any;
    if (indexDataList !== null && indexDataList.length > 0) {
      const filteredList = indexDataList.filter((product) => product.ProductName === productName);
      const expiryStrikePriceData = filteredList[0].ExpiryStrikePriceList;
      const expiryDates = expiryStrikePriceData.map((exp) => exp.Expiry);
      nearestDate = findNearestDate(expiryDates, refDate);
      if (nearestDate !== null && nearestDate !== undefined) {
        setExpiry(nearestDate);
      }
      expiryStrikePriceData.forEach((item: any) => {
        expiryList.push(item.Expiry);
      });
      const sortedDate = dateSort(expiryList);
      setExpiryList(sortedDate);
      handleIdentifierChange(productName, nearestDate, refDate)
    }
  }

  const fetchDataAtInterval = async () => {
    await liveMsgHOData(topicName, identifier);
  };
  const calculateTableHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const topBarHeight = document.querySelector("header")?.clientHeight || 0;
      const windowHeight = window.innerHeight;
      const offset = containerHeight + topBarHeight + 74;
      setTableHeight(windowHeight - offset);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 25000)
  }, [instrument, expiry, date])

  useEffect(() => {
    if (!historical) {
      if (props.headerOfficeList !== null && props.headerOfficeList.length > 0) {
        getExpiryList(instrument, props.headerOfficeList);
      }
    }
  }, [historical, userId, marketStatus]);

  useEffect(() => {
    if (historical) {
      setOpenHighRows([]);
      setOpenLowRows([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if (date !== "" && instrument !== "" && identifier !== undefined) {
        getHistoricHeadOfficeData(identifier, topicName);
      }
    }
  }, [historical, identifier]);

  useEffect(()=>{
    if(!historical && marketStatus === "open"){
      fetchDataAtInterval()
    }
  },[identifier, marketStatus])

  useEffect(() => {
    let timeoutId: any;
    if (!historical && marketStatus === "open") {
      id = setInterval(fetchDataAtInterval, 5 * 1000);
    } else if (marketStatus === "after-open" && !historical) {
      timeoutId = setTimeout(fetchDataAtInterval, 1000)
    } else if (marketStatus !== "loading" && (marketStatus === "closed" || marketStatus === "pre-open")) {
      getHistoricHeadOfficeData(identifier, topicName);
    }
    return () => {
      clearInterval(id);
      clearTimeout(timeoutId);
    };
  }, [identifier, historical, marketStatus])

  useEffect(() => {
    calculateTableHeight(); 
    window.addEventListener("resize", calculateTableHeight);
    return () => window.removeEventListener("resize", calculateTableHeight);
  }, [collapsed, historical, date, isLoading]);

  if (isLoggedIn === false) {
    navigate("/login");
  }
  if (userId === undefined) return <></>;

  return (
    <div className="mx-auto max-w-full">
      <div ref={containerRef}>
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={breadcrumbHO} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-5 sm:grid-cols-3 min-[1072px]:grid-cols-4">
        <SelectBox
          title="Name:"
          placeholder="Please Select Name"
          onValueChange={(e: string) => {
            handleProductChange(e);
          }}
          value={instrument}
        >
          <SelectGroup className="h-[400px] custom-scrollbar overflow-y-scroll">
            <SelectLabel className="ml-3">Index</SelectLabel>
            {props?.headerOfficeList &&
              props.headerOfficeList.slice(0, 4).map((item, index) => (
                <SelectItem key={index} value={item.ProductName}>
                  {item.ProductName}
                </SelectItem>
              ))}
            <SelectLabel className="ml-3">Stocks</SelectLabel>
            {props.headerOfficeList &&
              props.headerOfficeList.slice(5, props?.headerOfficeList?.length).map((item, index) => (
                <SelectItem key={index} value={item.ProductName}>
                  {item.ProductName}
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-0.5 text-[13px]">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0 dark:bg-zinc-900 relative">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:any) => {
                    handleDateSelect(e)
                 
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <SelectBox
          title="Expiry:"
          placeholder="Please Select Expiry"
          value={expiry}
          onValueChange={(e: string) => {
            setExpiry(e);
            setColapsableData([]);
            handleIdentifierChange(instrument, e);
          }}
        >
          <SelectGroup>
            {expiryList && expiryList?.map((item, index) => {
              const date = formatDate(item);
              return (
                <SelectItem key={index} value={`${item}`}>
                  {date}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectBox>
        
        <div className="w-full mt-6">
          <Tabs defaultValue="account" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger onClick={() => handleOpenHighLow("account")} value="account" >Open=High</TabsTrigger>
              <TabsTrigger onClick={() => handleOpenHighLow("password")} value="password">Open=Low</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <div className={cn({ hidden: collapsed }, "mt-[12px]")}>
        <HOSpot row={colapsableData} />
      </div>
      <CollapseButton collapsed={collapsed} handleCollapse={handleCollapse} />
      </div>
      <div>
        {
          oh ?
            <OHTable
              spotPrice={tableSpot}
              rowData={openHighRows}
              loading={isLoading}
              tableHeight={tableHeight} 
            />
            : <OLTable
              spotPrice={tableSpot}
              rowData={openLowRows}
              loading={isLoading} 
              tableHeight={tableHeight} />
        }
      </div>
    </div>
  );
}