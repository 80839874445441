import formatCurrency from "../../utils/formatCurrency";
import { SpotPriceCard } from "../shared/SpotPriceCard";

export function HOSpot({ row }: { row: any | [] }) {
    return (
        <div className="flex h-full w-full custom-scrollbar justify-between gap-5 overflow-x-auto text-xs">
            <SpotPriceCard
                title1="Spot Price"
                value1={row[0]?.SpotPrice ? formatCurrency(parseFloat(row[0]?.SpotPrice)) : "-"}
                title2="Current Future Price"
                value2={row[0]?.currentfutureprice ? formatCurrency(parseFloat(row[0]?.currentfutureprice)) : "-"}
            />
            <SpotPriceCard
                title1="Down Jones (US 30)"
                value1="-"
                title2="Hang Seng (HKEX)"
                value2="-"
            />

            <SpotPriceCard
                title1="Dollar Index (DXY)"
                value1={"-"}
                title2="FTSE (UK 100)"
                value2={"-"}
            />

            <SpotPriceCard
                title1="Premium In Call(ATM)"
                value1={row[0]?.premiumInCall ? formatCurrency(parseFloat(row[0]?.premiumInCall)) : "-"}
                title2="Premium In Put(ATM)"
                value2={row[0]?.premiumInPut ? formatCurrency(parseFloat(row[0]?.premiumInPut)) : "-"}
            />
        </div>
    );
}
