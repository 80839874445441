import { ColumnDef } from "@tanstack/react-table";
import { OptionProgress } from "./OptionProgress";
import formatCurrency from "../../utils/formatCurrency";
import BuildUpBadge from "../shared/BuildUpBadge";

const allTotalOICallData: number[] = [];
const alltotaloiputData: number[] = [];

export type OptionChainData = {
  ltp: number;
  id?: number,
  buildupcall?: string
  totaloicall?: string
  todayoichangecall?: string
  oichangecall?: string
  oichangepercall?: string
  premiumleftincall?: string
  calliv?: string
  callivchange?: string
  ltppercal?: string
  changeinltpcall?: string
  ltpcall?: string
  volumecall?: string
  oequaltohcall?: string
  oequaltolcall?: string
  deltacall?: string
  thetacall?: string
  strikeprice?: string
  ltpput?: string
  ltpchangeput?: string
  ltpperput?: string
  putiv?: string
  premiumleftinput?: string
  changeinivput?: string
  changeinoiput?: string,
  todayoichangeput?: string
  changeinltpput?: string
  oichangeperput?: string
  totaloiput?: string
  buildupput?: string
  volumeput?: string
  oequallput?: string
  oequalhput?: string
  deltaput?: string
  thetaput?: string
  pcrratio?: string
};

export const columns: ColumnDef<any>[] = [
  {
    header: "CALLS",
    columns: [
      {
        accessorKey: "buildupcall",
        header: "Build-ups",
        cell: ({ row }) => {
          const data = row.getValue<string>("buildupcall");
          return <BuildUpBadge text={data && data.trim()} />;
        },
      },
      {
        accessorKey: "totaloicall",
        header: "Total OI",
        cell: ({ row }) => {
          const totaloicallData = parseFloat(row.getValue("totaloicall"));
          for (const value of Object.values(row)) {
            const totaloicallData1: number = parseFloat(row.getValue("totaloicall"));
            allTotalOICallData.push(totaloicallData1);
          }
          allTotalOICallData.sort((a, b) => b - a);
          const width  = (totaloicallData/(allTotalOICallData[0]+10000))*100
          return (
            <div className="p-0 text-start relative inline-block">
              <span className="relative z-20 ">
                {totaloicallData && formatCurrency(totaloicallData)}
              </span>
              {
                <OptionProgress
                  value={100}
                  title="call"
                  className="absolute left-0  top-0 z-10  rounded-l-none"
                  style={{ width: `${width}%` }}
                />
              }
            </div>
          );
        },
      },
      {
        accessorKey: "todayoichangecall",
        header: "Today's OI Change",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("todayoichangecall"));
          return <div className="min-w-[110px]">{data && formatCurrency(data)}</div>;
        },
      },
      {
        accessorKey: "oichangecall",
        header: "Change in OI",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("oichangecall"));
          return <>{data && formatCurrency(data)}</>;
        },
      },
      {
        accessorKey: "premiumleftincall",
        header: "Premium Left",
        cell: ({ row }) => {
          const premiumleftincallData = parseFloat(row.getValue<string>("premiumleftincall"));
          return (
            <>
              {premiumleftincallData && formatCurrency(premiumleftincallData)}
            </>
          );
        },
      },
      {
        accessorKey: "deltacall",
        header: "Delta",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("deltacall"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "thetacall",
        header: "Theta",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("thetacall"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "oequaltohcall",
        header: "O=H",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("oequaltohcall"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "oequaltolcall",
        header: "O=L",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("oequaltolcall"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "oichangepercall",
        header: "OI Change%",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("oichangepercall"));
          return <>{data && formatCurrency(data)}</>;
        },
      },
      {
        accessorKey: "volumecall",
        header: "Volume",
        cell: ({ row }) => {
          const volumeData = parseFloat(row.getValue<string>("volumecall"));
          return <>{volumeData && formatCurrency(volumeData)}</>;
        },
      },
      {
        accessorKey: "calliv",
        header: "IV",
        cell: ({ row }) => {
          const callivData = parseFloat(row.getValue<string>("calliv"));
          if (callivData.toString() === "NaN") {
            return <p>-</p>
          } else {
            return <p>{callivData && formatCurrency(callivData)}</p>;
          }
        },
      },
      {
        accessorKey: "callivchange",
        header: "IV Change",
        cell: ({ row }) => {
          const callivchaneData = parseFloat(row.getValue<string>("callivchange"));
          if (callivchaneData.toString() === "NaN") {
            return <p>-</p>
          } else {
            return <p>{callivchaneData && formatCurrency(callivchaneData)}</p>;
          }
        },
      },
      {
        accessorKey: "ltppercall",
        header: "LTP%",
        cell: ({ row }) => {
          const ltpcallpercentData = parseFloat(row.getValue<string>("ltppercall"));
          return (
            <>{ltpcallpercentData && formatCurrency(ltpcallpercentData)}</>
          );
        },
      },
      {
        accessorKey: "changeinltpcall",
        header: "Change In LTP",
        cell: ({ row }) => {
          const changeinltpcallData = parseFloat(row.getValue<string>("changeinltpcall"));
          return (
            <>{changeinltpcallData && formatCurrency(changeinltpcallData)}</>
          );
        },
      },
      {
        accessorKey: "ltpcall",
        header: "LTP",
        cell: ({ row }) => {
          const ltpcallData = parseFloat(row.getValue<string>("ltpcall"));
          return <p>{ltpcallData && formatCurrency(ltpcallData)}</p>;
        },
      },
    ],
  },
  {
    accessorKey: "strikeprice",
    header: "Strike Price",
    enableHiding: false, 
  },

  {
    header: "PUTS",
    columns: [
      {
        accessorKey: "ltpput",
        header: "LTP",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("ltpput"));
          return <p>{data && formatCurrency(data)}</p>;
        },
      },
      {
        accessorKey: "ltpchangeput",
        header: "Change In LTP",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("ltpchangeput"));
          return <p>{data && formatCurrency(data)}</p>;
        },
      },
      {
        accessorKey: "ltpperput",
        header: "LTP %",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("ltpperput"));
          return <p>{data && formatCurrency(data)}</p>;
        },
      },
      {
        accessorKey: "putiv",
        header: "IV",
        cell: ({ row }) => {
          const putivData = parseFloat(row.getValue<string>("putiv"));
          if (putivData.toString() === "NaN") {
            return <p>-</p>
          } else {
            return <p>{putivData && formatCurrency(putivData)}</p>;

          }
        },
      },
      {
        accessorKey: "changeinivput",
        header: "Change in IV",
        cell: ({ row }) => {
          const putivData = parseFloat(row.getValue<string>("ivchangeput"));
          if (putivData.toString() === "NaN") {
            return <p>-</p>
          } else {
            return <p>{putivData && formatCurrency(putivData)}</p>;
          }
        },
      },
      {
        accessorKey: "volumeput",
        header: "Volume",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("volumeput"));
          return <>{data && formatCurrency(data)}</>;
        },
      },
      {
        accessorKey: "deltaput",
        header: "Delta",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("deltaput"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "thetaput",
        header: "Theta",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("thetaput"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "premiumleftinput",
        header: "Premium Left",
        cell: ({ row }) => {
          const premiumleftinputData = parseFloat(row.getValue<string>("premiumleftinput"));
          return (
            <p>
              {premiumleftinputData && formatCurrency(premiumleftinputData)}
            </p>
          );
        },
      },
      
      {
        accessorKey: "changeinoiput",
        header: "Change in OI",
        cell: ({ row }) => {
          const currentoichangeputData = parseFloat(row.getValue<string>("changeinoiput"));
          return (
            <p >
              {currentoichangeputData &&
                formatCurrency(currentoichangeputData)}
            </p>
          );
        },
      },
      {
        accessorKey: "todayoichangeput",
        header: "Today’s Change In OI",
        cell: ({ row }) => {
          const todayoichangeputData = parseFloat(row.getValue<string>("todayoichangeput"));
          return (
            <p className="min-w-[110px]">
              {todayoichangeputData && formatCurrency(todayoichangeputData)}
            </p>
          );
        },
      },
      {
        accessorKey: "oichangeperput",
        header: "OI Change%",
        cell: ({ row }) => {
          const oichangePerPut = parseFloat(row.getValue<string>("oichangeperput"));
          return (
            <>
              {oichangePerPut && formatCurrency(oichangePerPut)}
            </>
          );
        },
      },
      {
        accessorKey: "totaloiput",
        header: "Total OI",
        cell: ({ row }) => {
          const totaloiputData = parseFloat(row.getValue<string>("totaloiput"));
          for (const value of Object.values(row)) {
            const totaloiputData1: number = parseFloat(row.getValue("totaloiput"));
            alltotaloiputData.push(totaloiputData1);
          }
          alltotaloiputData.sort((a, b) => b - a);
          const width  = (totaloiputData/(alltotaloiputData[0]+1000))*100      
          return (
            <div className="p-0 text-start relative inline-block">
              <span className="relative z-20 ">
                {totaloiputData && formatCurrency(totaloiputData)}
              </span>
              {
                <OptionProgress
                  value={100} //
                  title="put"
                  className="absolute right-0  top-0 z-10  rounded-r-none"
                  style={{ width: `${width}%` }}
                />
              }
            </div>
          );   
        },
      },
      {
        accessorKey: "buildupput",
        header: "Build-Ups",
        cell: ({ row }) => {
          const data = row.getValue<string>("buildupput");
          return <BuildUpBadge text={data && data.trim()} />;
        },
      },
      
      {
        accessorKey: "oequallput",
        header: "O=L",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("oequallput"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
      {
        accessorKey: "oequalhput",
        header: "O=H",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("ohput"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
   
      {
        accessorKey: "pcrratio",
        header: "PCR Ratio",
        cell: ({ row }) => {
          const data = parseFloat(row.getValue<string>("pcrratio"));
          if(data.toString() ==="NaN"){
            return <p>-</p>
          }else{
            return <p>{data && formatCurrency(data)}</p>
          }
        },
      },
    ],
  },
];