import { useEffect, useRef, useState } from "react";
import { SelectGroup, SelectItem } from "../ui/select";
import { FoiInstrumentData } from "../../data/openInterest";
import SelectBox from "../shared/SelectBox";
import LiveSwitch from "../shared/LiveSwitch";
import { cn } from "../../lib/utils";
import useMarketStatus from "../../hooks/useMarketStatus";
import Breadcrumb from "../shared/Breadcrumb";
import { toast } from "sonner";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";
import { useNavigate } from "react-router-dom";
import CollapseButton from "../shared/CollapseButton";
import BuzzSpot from "./BuzzSpot";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { CalendarDaysIcon, Loader } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { format } from "date-fns";
import Buzzmap from "./BuzzMap";
import { buzzBreadcrumb } from "./constant";
import { getFutureBuzzData, getIndexSubscription } from "../../api/futures/open-interest";
import { getLiveMarketTimeData } from "../../api/dashboard";
import processMessage from "./helper";

export default function Buzz() {
  // STATE DECLARATIONS =>__________________________________________________________________
  const [collapsed, setCollapsed] = useState(false);
  const [instrument, setInstrument] = useState(FoiInstrumentData[0].value);
  const [historical, setHistorical] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [lastRow, setLastRow] = useState<any | []>([]);
  const [toIdentifier, setToIdentifier] = useState(
    `oibuzz_data_${FoiInstrumentData[0].value}`
  );
  const topicName = "oibuzz_data";
  const [graphHeight, setGraphHeight] = useState(500);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const marketStatus = useMarketStatus();
  const navigate = useNavigate();
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);
  const isLoggedIn = useGetFromStore(useAuthStore, (state) => state.isLoggedIn);
  let id:any;
  
  // ACTION FUNCTIONS =>__________________________________________________________________
  const handleCollapsed = () => setCollapsed((prev) => !prev);
  const handleSwitch = () => {
    setRows([]);
    setLastRow([]);
    // historic to live
    if (historical) {
      setHistorical(false);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate(new Date());
      setInstrument(FoiInstrumentData[0].value);
    }
    // Live to historic
    if (!historical) {
      // clearInterval(id);
      setIsLoading(false);
      setHistorical(true);
      toast(`Switched to ${historical ? "Live Data" : "Historical Data"}`);
      setDate("");
      setInstrument("");
    }
  };

  async function firstSubscriptionName() {
    await getIndexSubscription(
      topicName,
      userId === undefined ? "" : userId,
      toIdentifier
    );
  }

  async function liveMsgBuzzData(topicName: string, toIdentifier: string) {
    const data = await getLiveMarketTimeData(topicName, toIdentifier);
    if (data !== null && data.length > 0) {
      const messages = data.split("\n") as string[];
      const fRows = processMessage(messages);
      setIsLoading(false);
      const reversedRow = fRows.reverse();
      setRows(reversedRow);
      setLastRow(reversedRow[0]);
    }
  }

  async function getHistoricBuzz(identifier: string, topicName:string) {
    let response;
   if (historical && identifier !== '' && date !== '') {
      response = await getFutureBuzzData(identifier, topicName, date);
    } else{
      response = await getFutureBuzzData(identifier, topicName);
    }
    if (response !== null && response.length > 0) {
      const messages = response.split("\n") as string[];
      const fRows = processMessage(messages);
      setIsLoading(false);
      const reversedRow = fRows.reverse();
      setRows(reversedRow);
      setLastRow(reversedRow[0]);
    }
  }

  const handleTopicNameChange = async (
    productName: string,
  ) => {
    setRows([]);
    setLastRow([]);
    if(historical && date === "" && instrument!==""){ //
      setIsLoading(false)
    }else{
      setIsLoading(true);
    }
    let identifierLocal = `oibuzz_data_${productName}`;
    setToIdentifier(identifierLocal);
    if(!historical){
      if ((marketStatus === "open" || marketStatus === "after-open") && historical=== false) {
        await getIndexSubscription(
          topicName,
          userId === undefined ? "" : userId,
          identifierLocal
        );
      } else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        await getHistoricBuzz(identifierLocal,topicName);
      }
    }
  };

  const fetchDataAtInterval = async () => {
    await liveMsgBuzzData(topicName, toIdentifier);
};

const calculateGraphHeight = () => {
  if(containerRef.current){
    const containerHeight = containerRef.current.clientHeight;
    const topBarHeight = document.querySelector("header")?.clientHeight || 0;
    const windowHeight = window.innerHeight;
    const offset = containerHeight + topBarHeight + 56;
   setGraphHeight(windowHeight - offset)
  }
  }
  // USE EFFECTS =>__________________________________________________________________

  useEffect(() => {
    calculateGraphHeight(); 
    window.addEventListener("resize", calculateGraphHeight);
    return () => window.removeEventListener("resize", calculateGraphHeight);
  }, [collapsed, historical, date, isLoading]);

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)},15000)
  },[instrument]);

  useEffect(() => {
    if (historical) {
      setRows([]);
      setLastRow([]);
      if (date.toString().slice(8, 10) === new Date().getDate().toString()) {
        setDate("")
      }
      if ( date !== '' && toIdentifier !== "") {
        getHistoricBuzz(toIdentifier, topicName);
      }
    } else {
      if (marketStatus === "open" && historical === false) {
        setIsLoading(true);
        firstSubscriptionName();
         id = setInterval(fetchDataAtInterval, 2 * 1000);
      }else if(marketStatus === "after-open" && !historical){
        firstSubscriptionName();
        fetchDataAtInterval()
      } else if(marketStatus !=="open" && marketStatus!=="loading" && !historical){
        getHistoricBuzz(toIdentifier, topicName);
      }
    }
    return () => clearInterval(id);
  }, [historical, userId, toIdentifier, date, marketStatus]);

  if (userId === undefined) return <></>;
  if (isLoggedIn === false) {
    navigate("/login");
  }

  return (
    <div className="mx-auto w-full">
    <div ref={containerRef}>
      <div className="mt-4 flex justify-between">
        <Breadcrumb items={buzzBreadcrumb} />
        <LiveSwitch handleSwitch={handleSwitch} />
      </div>

      <div className="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-4">
        <SelectBox
          title="Name:"
          value={instrument}
          onValueChange={(e: string) => {
            setInstrument(e);
            handleTopicNameChange(e)
          }}
          placeholder="Please Select Name"
        >
          {FoiInstrumentData.map((item) => {
            return (
              <SelectItem key={item.value} value={item.value}>
                {item.value}
              </SelectItem>
            );
          })}
        </SelectBox>

        <div className="w-full">
          <p className="mb-1 pl-1 text-xs">Date:</p>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                disabled={!historical}
                variant="outline"
                className={cn(
                  "flex h-auto w-full justify-between  rounded-none border px-3 py-2.5 text-[10px] font-normal shadow dark:border-zinc-600 dark:bg-zinc-900  sm:text-xs",
                  !date && "text-muted-foreground"
                )}
              >
                {date ? format(date, "PPP") : <span>Please Select Date</span>}
                <CalendarDaysIcon className="ml-2 h-4 w-4 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(e:Date |undefined) => {
                  if(e instanceof Date){
                    setDate(e);
                    setIsCalendarOpen(false);
                  }else{
                    toast.error("Invalid Date Selected");
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div className={cn({ hidden: collapsed })}>
        <BuzzSpot row={lastRow} />
      </div>
      <div className="flex items-center justify-between gap-5">
        <CollapseButton
          collapsed={collapsed}
          handleCollapse={handleCollapsed}
        />
      </div>
      </div>
      <div>
        {
          rows.length > 0 ? (
            <Buzzmap rowData={rows} graphHeight={graphHeight}/>
          ) : (
            <>
            {
              isLoading ? (
                <div className="pt-40">
                <Loader className="mx-auto animate-spin" />
                </div>
              ):(
                <div className="text-center py-10">No Results</div>
              )
            }
            </>
          )
        }
      </div>
    </div>
  );
}