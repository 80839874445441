import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { useTheme } from "../../utils/ThemeProvider";
import formatCurrency from "../../utils/formatCurrency";

interface TPData {
    callIV: string,
    putIV: string,
    strikePrice: string,
}

interface TPChart {
    data: TPData[],
    spotPrice: string,
    strikes: string,
    loading: boolean,
    graphHeight:number,
    showLTP: boolean
}

const PremiumBarChart = (props: TPChart) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any[]>([]);
    const [chartType, setChartType] = useState<"bar"|"line">("bar");
    const { theme } = useTheme();
    const maxValue = Math.max(...data.map(item => Math.max(parseFloat(item.callIV), parseFloat(item.putIV))));
    const topPadding = maxValue > 100 ? 34 : 26;
    const options = {
        animation: false,
        title: {
            show: true,
            text: "TRADEDONS",
            left: "center",
            top: "center",
            textStyle: {
                fontWeight: 'bold',
                fontSize: 20,
                color: "rgba(208, 193, 201, 0.5)"
            },
            subtext: data.length <= 0 ? "No Data Available" : "",
            subtextStyle: {
                fontSize: 18
            }
        },
        grid: { top: topPadding, right: "2.5%", bottom: 52, left: "4%" },
        toolbox: {
            show: true,
            feature: {
                mark: { show: true },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            },
            right: 28,
            top: -7
        },
        legend: {
            data: ['Call Premium', 'Put Premium'],
            itemGap: 15,
            bottom: 4
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item.strikePrice),
            axisLine: {
                show: true
            }
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true
            }, 
        },

        color: ["#22c55e", "#ef4444"],
        backgroundColor: [theme === "light" ? "white" : "#27272a"],
        series: [
            {
                name: "Call Premium",
                data: data.map(item => parseFloat(item.callIV)),
                type: 'bar',
                smooth: false,
                markLine: { data: [{ xAxis: 'average', label: { show: false } }], silent: true },
                markPoint: props.showLTP ?{
                    data: data.map((item, index) => ({ name: 'Call Premium', value: formatCurrency(parseFloat(item.callLTP)), xAxis: index, yAxis: item.callIV })),
                    label: {
                        color: theme === "dark"? "#f5f5f5" : '#595959'
                    }
                }:{}
            },
            {
                name: "Put Premium",
                data: data.map(item => parseFloat(item.putIV)),
                type: 'bar',
                smooth: false,
                markLine: { silent: true, data: [{ xAxis: 'average', label: { show: false } }] },
                markPoint: props.showLTP ?{
                    data: data.map((item, index) => ({ name: 'Put Premium', value: formatCurrency(parseFloat(item.putLTP)), xAxis: index, yAxis: item.putIV })),
                    label: {
                        color: theme === "dark"? "#f5f5f5" : '#595959'
                    }
                }:{}
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true
                }
            },
            formatter: (params: any) => {
                const strikePrice = data[params[0]?.dataIndex]?.strikePrice;
                return (
                    `<div>
                        <p class="">${strikePrice}</p>
                        <div class="font-medium">
                        <div class="flex items-center gap-2"> 
                        <div class="icon bg-green-500"></div>
                        Call Premium <span class="font-semibold">${formatCurrency(params[0].value)}</span>
                         </div>
                        <div class="flex items-center gap-2">
                         <div class="icon bg-red-500"></div>
                         Put Premium <span class="font-semibold"> ${formatCurrency(params[1].value)} </span>
                          </div>
                        </div>
                    </div>`
                )
            }
        }
    };

    const loadingOption = {
        text: "",
        color: theme === "light" ? 'black' : "white",
        textColor: 'white',
        maskColor: theme === "light" ? "white" : '#27272a',
        zlevel: 0
    };
    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        const fetchData = () => {
            if (props.strikes === "10") {
                const data = findNearATM(props.spotPrice, props.data, 20)
                setData(data)
            }
            if (props.strikes === "15") {
                const data = findNearATM(props.spotPrice, props.data, 30)
                setData(data)
            }
            if (props.strikes === "25") {
                const data = findNearATM(props.spotPrice, props.data, 50)
                setData(data)
            }
            if (props.strikes === "all") {
                const nearAtmData = findNearATM(props.spotPrice, props.data, data?.length <= 60 ? data.length : 60)
                setData(nearAtmData)
            }
        };

        fetchData();
    }, [props.strikes, props.spotPrice, props.data]);

    return (
        <div className="rounded-lg shadow border bg-white dark:bg-zinc-800 dark:border-zinc-600 overflow-hidden">
            <p className="text-lg text-center mt-2 font-semibold">
                Individual Options Premium Left
            </p>
            <ReactECharts 
            loadingOption={loadingOption} 
            option={options} 
            theme={theme} 
            style={{ height: `${props.graphHeight}px`}} 
            showLoading={loading} 
            onEvents={{
                "click":(params:any) =>{
                    if(chartType === "bar"){
                        setChartType("line")
                    } else {
                        setChartType("bar")
                    }
                }
            }}
            />
        </div>
    );
};

export default PremiumBarChart;

function findNearATM(spotPrice: string, Arr: any[], NearItemsNumber: number) {
    const numericSpotPrice = parseFloat(spotPrice);
    const closestStrikeIndex = Arr.reduce((acc, row, index) => {
        const strikePrice = parseFloat(row.strikePrice);
        const currentDiff = Math.abs(numericSpotPrice - strikePrice);
        const closestDiff = Math.abs(numericSpotPrice - parseFloat(Arr[acc].strikePrice));
        return currentDiff < closestDiff ? index : acc;
    }, 0);
    const spotIndex = closestStrikeIndex;
    const startIndex = Math.max(0, spotIndex - Math.floor(NearItemsNumber / 2));
    const endIndex = Math.min(Arr.length - 1, spotIndex + Math.ceil(NearItemsNumber / 2));
    const resultArr = Arr.slice(startIndex - 1, endIndex);
    return resultArr;
}