import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ToggleStoreState {
  openInSameTab: boolean;
  toggleOpenInSameTab: () => void;
}

const useToggleStore = create<ToggleStoreState>()(
  devtools(
    persist(
      (set) => ({
        openInSameTab: false,
        toggleOpenInSameTab: () => set((state) => ({ openInSameTab: !state.openInSameTab })),
      }),
      {
        name: "openInSameTab",
        partialize: (state) => state
      }
    )
  )
);

export default useToggleStore;
