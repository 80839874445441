import browserName from "../components/shared/BrowserName";
let browser = browserName();

export const dateSort = (dateStrings: string[]) => {
     const getMonthNumber = (monthAbbreviation: string) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months.indexOf(monthAbbreviation) + 1;
    };
    const dates = (date:string) => {
        const standardizedDateString = `${parseInt(date.slice(5, 9))}-${getMonthNumber(date.slice(2, 5)).toString().padStart(2, '0')}-${parseInt(date.slice(0, 2)).toString().padStart(2, '0')}`;
        return new Date(standardizedDateString);
    }
    const customSort = (a: string, b: string) => {
        let dateB;
        let dateA;
        if(browser === 'apple safari'){
             dateA = dates(a);
             dateB = dates(b);
        }else{
             dateA = new Date(`${a.slice(5, 9)}-${getMonthNumber(a.slice(2, 5))}-${a.slice(0, 2)}`);
             dateB = new Date(`${b.slice(5, 9)}-${getMonthNumber(b.slice(2, 5))}-${b.slice(0, 2)}`);
        }
        return dateA.getTime() - dateB.getTime();
    };
    return dateStrings.sort(customSort);
};