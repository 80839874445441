import { Check, X } from "lucide-react";
import PlanCard from "./PlanCard";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import React from "react";
import { getSubscriptionPlanDetails } from "../../api/subscription";

// function PlanSelector({ onSelect, selected }: { selected: string, onSelect: any }) {
//     return (
//         <div style={{ boxShadow: "0px 10px 15px 0px rgba(24, 59, 86, 0.20)" }} className="max-w-max px-2 rounded-full bg-white mx-auto my-8 py-1">
//             <button onClick={() => onSelect("monthly")} className={cn("px-4 duration-300 py-1  text-black", { "active-btn": selected === "monthly" })} >Monthly</button>
//             <button onClick={() => onSelect("yearly")} className={cn("px-4 duration-300 py-1 text-black", { "active-btn": selected === "yearly" })}>Yearly</button>
//         </div >
//     );
// }

const PlansData = [
    { feature: "Dashboard", basic: true, standard: true, pro: true, premium: true },
    { feature: "Intrady Mover", basic: true, standard: true, pro: true, premium: true },
    { feature: "Advanced Chart", basic: false, standard: false, pro: true, premium: true },
    { feature: "Options- Strike Price Wise", basic: true, standard: true, pro: true, premium: true },
    { feature: "Option Chain", basic: true, standard: true, pro: true, premium: true },
    { feature: "Implied Volatility Graph", basic: false, standard: true, pro: true, premium: true },
    { feature: "Future Spurtz", basic: false, standard: true, pro: true, premium: true },
    { feature: "Futures OI Buzz", basic: false, standard: true, pro: true, premium: true },
    { feature: "Future Open Interest", basic: true, standard: true, pro: true, premium: true },
    { feature: "Options Spurtz", basic: false, standard: true, pro: true, premium: true },
    { feature: "Premium", basic: false, standard: true, pro: true, premium: true },
    { feature: "Trend Finder", basic: false, standard: true, pro: true, premium: true },
    { feature: "Advanced TrendFinder", basic: false, standard: false, pro: true, premium: true },
    { feature: "Futures Data End of Day (EOD)", basic: false, standard: true, pro: true, premium: true },
    { feature: "OI Graph", basic: false, standard: true, pro: true, premium: true },
    { feature: "Pre-opening Market", basic: false, standard: false, pro: true, premium: true },
    { feature: "Expert Opinion", basic: false, standard: false, pro: true, premium: true },
    { feature: "Head Office Data", basic: false, standard: false, pro: true, premium: true },
]
export default function Plans() {
    // const [billingCycle, setBillingCycle] = useState<string>("monthly");
    // const handleBillingCycleChange = (cycle: any) => {
    //     setBillingCycle(cycle);
    // };
    const [subscriptioPlan, setSubscriptionPlan] = React.useState<Record<string, any>[]>([]);
    const getSubscriptionDetails = async () => {
        const result = await getSubscriptionPlanDetails();
        setSubscriptionPlan(result);
    }
    React.useEffect(() => {
        getSubscriptionDetails();
    }, []);

    return (
        <div className='max-w-7xl mx-auto pb-12'>
            <h1 className="text-3xl font-semibold text-center mt-12">We offer different<span className='text-blue-600 uppercase'> price plans</span> options</h1>

            {/* <PlanSelector onSelect={handleBillingCycleChange} selected={billingCycle} /> */}

            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center pt-16">
                {subscriptioPlan.map((plan, index) => (
                    <div key={index} className="relative">
                        {/* {plan.duration.toLocaleLowerCase() === "12 months" && <p className="bg-blue-600 text-white text-center absolute min-w-full -top-5 rounded-t-lg shadow-sm text-sm px-2 py-1">Recommended</p>} */}
                        <PlanCard className=" h-full" plan={plan} />
                    </div>
                ))}
            </div>

            <div className="mt-12 border rounded-md overflow-hidden dark:border-zinc-600">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="bg-white dark:bg-zinc-800 font-semibold text-black text-start pl-5">Features</TableHead>
                            <TableHead className="bg-white dark:bg-zinc-800 font-semibold text-black text-center">TD-Basic</TableHead>
                            <TableHead className="bg-white dark:bg-zinc-800 font-semibold text-black text-center">TD-Standard</TableHead>
                            <TableHead className="bg-white dark:bg-zinc-800 font-semibold text-black text-center">TD-Pro</TableHead>
                            <TableHead className="bg-white dark:bg-zinc-800 font-semibold text-black text-center">TD-Premium</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            PlansData.map((item, index) =>
                                <TableRow key={index}>
                                    <TableCell className="text-black border-b pl-5 bg-zinc-100 dark:bg-zinc-900 dark:text-white border-zinc-200 dark:border-zinc-600 text-start ">{item.feature}</TableCell>
                                    <TableCell className="text-black border-b bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-600 border-zinc-200 text-center">{item.basic === true ? <Check className="text-green-500  mx-auto" /> : <X className="text-red-500 mx-auto" />}</TableCell>
                                    <TableCell className="text-black border-b bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-600 border-zinc-200 text-center">{item.standard === true ? <Check className="text-green-500 mx-auto" /> : <X className="text-red-500 mx-auto" />}</TableCell>
                                    <TableCell className="text-black border-b bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-600 border-zinc-200 text-center">{item.pro === true ? <Check className="text-green-500 mx-auto" /> : <X className="text-red-500 mx-auto" />}</TableCell>
                                    <TableCell className="text-black border-b bg-zinc-100 dark:bg-zinc-900 dark:border-zinc-600 border-zinc-200 text-center">{item.premium === true ? <Check className="text-green-500 mx-auto" /> : <X className="text-red-500 mx-auto" />}</TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}