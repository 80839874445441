import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type spotPriceType = {
  spotPrices: Record<string, number | null>;
  setSpotPrice: (instrument: string, point: number) => void;
  clearSpotPrice: () => void; 
};

const useSpotPriceStore = create<spotPriceType>()(
  devtools(
    persist(
      (set) => ({
        spotPrices: {},
        setSpotPrice: (instrument, point) =>
        set((state) => {
            if (!isNaN(point) && point !== null && point !== undefined) {
              const newSpotPrices = { ...state.spotPrices };
              if (newSpotPrices.hasOwnProperty(instrument)) {
                newSpotPrices[instrument] = point; 
              } else {
                newSpotPrices[instrument] = point; 
              }
              return { spotPrices: newSpotPrices };
            }
            return state; 
          }),
          clearSpotPrice: () => set({ spotPrices: {} }),
      }),
      {
        name: "spotPrice",
        partialize: (state) => state
        //   Object.fromEntries(
        //     Object.entries(state).filter(
        //       ([key]) => !["setSpotPrice"].includes(key)
        //     )
        //   ),
      }
    ),
    { name: "spotPrice" }
  )
);

export default useSpotPriceStore;
